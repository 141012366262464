body.section-car-rental-policy{
  .l-main{
    .l-content{
      article{
        .node__content{
          .field{
            .field__item{
              p{
                img{
                  max-width: 17px;
                  margin-right: 5px;
                  position: relative;
                  top:2px;
                }
              }
            }
          }
        }
      }
    }
  }
}