#block-views-gallery-with-text-block{
  overflow: hidden;
  margin-top: 64px;
  .block__content{
    padding-top: 0;
  }
}

.gallery-with-text-item{
  @include breakpoint($md){
    @include span(6);
  }
  @include breakpoint($sm){
    @include span(12);
    height: unset;
  }
  @include span(3 (gutters: 0.49));
  text-align: center;
  height: 764px;
  margin-bottom: 2.81771%;
  &:nth-child(4n+4){
    @include breakpoint($md){
      @include span(6);
    }
    @include breakpoint($sm){
      @include span(12);
    }
    @include span(3 (gutters: 0.49) last);
  }
  &:nth-child(2n+2){
    @include breakpoint($md){
      @include span(6 last);
    }
    @include breakpoint($sm){
      @include span(12);
    }
  }
  img{
    max-width: 100%;
    height: auto;
  }
}

.gallery-title{
  font-weight: 300;
  color: $purple;
  text-align: left;
  font-size: 24px;
  margin: 32px 0 20px;
  line-height: 1;
  min-height: 48px;
}

.gallery-text{
  text-align: left;
  font-weight: 300;
}