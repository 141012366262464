//Footer
footer.l-footer{
  background-color: white;
}

.l-region--footer{
  @include gim_container(1266px);
  @include breakpoint($xs){
    padding-top: 17px;
  }

  padding-top: 32px;
}

#footer-logo{
  text-align: center;
  margin-bottom: 30px;
  img{
    @include breakpoint($xs){
      width: 208px;
      height: auto;
    }
    margin-bottom: 34px;
  }
  p{
    @include gim_container(950px);
    margin-bottom: 0;
    color: #000006;
    font-size: 16px;
    font-weight: 300;
    line-height: 26.06px;
  }
  a{
    margin-top: 4px;
    color: $purple;
    font-weight: bold;
    display: inline-block;
  }
  @include breakpoint($sm $xs + 1px){
    p{
      @include gim_container(650px);
      font-size: 15px;
      line-height: 20px;
    }
  }
  @include breakpoint($xs){
    margin-bottom: 14px;
    img{
      margin-bottom: 13px;
      width: 100%;
      height: auto;
      max-width: 208px;
    }
    p{
      font-size: 14px;
      line-height: 18px;
      strong{
        display: block;
        text-decoration: underline;
      }
    }
  }
}

#footer-locations{
  overflow: hidden;
  margin-bottom: 26px;
  ul{
    list-style: none;
    padding-left: 0;
    margin: 0;
  }
  li{
    @include span(1 of 5 (gutters: 0));
    border-width: 2px 1px;
    border-style: solid;
    border-color: white;
    background-color: $orange;
    padding: 9px 15px 8px;
    color: white;
    text-align: center;
    font-size: 20px;
    line-height: 1;
    @include breakpoint($sm $xs + 1px){
      @include span(1 of 3 (gutters: 0));
      border-width: 1px;
      &:nth-child(4),
      &:nth-child(5){
        @include span(1 of 2 (gutters: 0));
      }
    }
    @include breakpoint($xs){
      @include span(1 of 2 (gutters: 0));
      border-width: 1px;
      font-size: 13px;
      padding: 5px 15px;
      &:nth-child(3),
      &:nth-child(4){
        @include span(1 of 2 (gutters: 0));
      }
      &:nth-child(5){
        @include span(6 (gutters: 0));
        margin-left: 25%;
      }
    }
  }
}

.footer-titles{
  @include breakpoint($xs){
    font-size: 14px;
    margin-bottom: 10px;
  }

  color: $block-title;
  margin-bottom: 12px;
  margin-top: 0;
}
//Extra footer
.l-region--extra-footer{
  @include breakpoint($md){
    max-width: 704px;
  }
  //@include breakpoint($sm){
  //  max-width: 320px;
  //}
  @include gim_container(1180px);
  padding: 62px 0;
  padding-bottom: 35px;
  @include breakpoint(480px){
    padding: 25px;
  }
  .footer-titles {
    @include underlines($orange);
    margin-bottom: 13px;
    font-size: 24px;
    font-weight: 700;
    @include breakpoint($xs){
      font-size: 20px;
    }

    &.titles-social {
      font-size: 18px;
      float: left;
      margin-right: 8px;

      &:after {
        display: none;
      }
    }

    &:after {
      margin-top: 12px;
      @include breakpoint(480px){
        height: 3px;
      }
    }

  }

  .block__content {
    font-size: 18px;
    font-weight: 400;
  }

  #footer-payments {
    text-align: center;

    .footer-titles {
      text-align: left;
      letter-spacing: -1px;
      margin-bottom: 0;
      @include breakpoint(480px){
        margin-bottom: 13px;
      }
    }
  }
}

#footer-contact{
  @include span(3.2 (gutters: 0.8));
  @include breakpoint($lg){
    @include span(3.6 (gutters: 0.8));
  }
  @include breakpoint($md){
    @include span(1 of 2 (gutters: 0.5));
    margin-bottom: 40px;
  }
  @include breakpoint($sm){
    @include span(1 of 2 (gutters: 0.2));
    margin-bottom: 40px;
  }
  @include breakpoint($xs){
    margin-bottom: 0px;
  }
  .footer-titles:last-of-type{
    margin-bottom: 11px;
    @include breakpoint($xs){
      margin-right: 8px!important;
    }
  }
  ul{
    list-style: none;
    padding-left: 0;
    margin: 0;
    &.contact{

      line-height: 1.3;
      li{
        line-height: 25px;
      }

      .spacer {
        margin-bottom: 20px;
      }
      @include breakpoint($md){
        li:nth-child(2){
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      margin-bottom: 20px;
      a{
        color: inherit;
        text-decoration: inherit;
        transition: color 0.25s ease;
        &:hover{
          color: $purple;
        }
      }
    }
    &.social{
      li{
        @include breakpoint($md $sm){
          margin-right: 1px;
          margin-bottom: 3px;
        }
        display: inline-block;
        vertical-align: bottom;
        margin-right: 6px;
        margin-bottom: 9px;
        &.facebook a:before{
          content: '\f09a';
          background-color: $dblue;
        }
        &.twitter a:before{
          content: '\f099';
          background-color: $dblue;
        }
        &.google-plus a:before{
          content: '\f0d5';
          background-color: $dblue;
        }
        &.linkedin a:before{
          content: '\f0e1';
          background-color: $dblue;
        }
        &.youtube a:before{
          content: '\f167';
          background-color: $dblue;
        }
        &.instagram a:before{
          content: '\f16d';
          background-color: $dblue;
          // Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f7d500+14,f50207+39,7c39ad+71
      //    background: rgb(247,213,0);
      //    background: -moz-linear-gradient(45deg, rgba(247,213,0,1) 14%, rgba(245,2,7,1) 39%, rgba(124,57,173,1) 71%);
      //    background: -webkit-linear-gradient(45deg, rgba(247,213,0,1) 14%,rgba(245,2,7,1) 39%,rgba(124,57,173,1) 71%);
       //   background: linear-gradient(45deg, rgba(247,213,0,1) 14%,rgba(245,2,7,1) 39%,rgba(124,57,173,1) 71%);
       //   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7d500', endColorstr='#7c39ad',GradientType=1 );
        }
      }
      a{
        transition: bottom 0.25s ease;
        font-size: 0;
        color: transparent;
        display: inline-block;
        width: 22px;
        height: 22px;
        position: relative;
        bottom: 0;
        vertical-align: bottom;
        &:before{
          font-family: 'FontAwesome';
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          font-size: 12px;
          line-height: 22px;
          color: white;
          border-radius: 50%;
          text-align: center;
        }
        &:hover{
          bottom: 5px;
        }
      }
    }
  }
  @include breakpoint(480px){
    @include span(12);
    margin-bottom: 0px;
  }
  /*
  @include breakpoint($xs - 1px){
    @include span(12);
    margin-bottom: 30px;
    .block__content{
      max-width: 320px;
      margin: 0 auto;
    }
  }
  */
  @include breakpoint($xs){
    //@include span(6.5 (gutters: 6));
    margin-bottom: 0px;
    .block__content{
      margin: 0 auto;
      //max-width: 183px;
      .footer-titles{
        //max-width: 183px;
        margin: auto;
        margin-bottom: 20px;
        &.titles-social{
          margin-top: 3px;
        }
      }
      ul{
        padding: 0;
        //max-width: 183px;
        margin: auto;
        margin-bottom: 10px;
        li{
          font-size: 12px;
          a{
            font-size: 12px;
            line-height: 20px;
          }
        }
        &.social{
          max-width: 100%;
          li{
            margin-right: 3px;
            margin-bottom: 3px;
            a{
              width: 28px;
              height: 27px;
              &:before{
                font-size: 12px;
                line-height: 27px;
              }
            }
            &:last-child{
              //margin: 0;
            }
          }
        }
      }
    }
  }
}

#footer-info{
  @include span(2.8 (gutters: 0.8));
  padding-left: gutter(3 (gutters: 0.12));

  .menu{
    font-size: 18px;
    font-weight: 400;

    @include breakpoint(min-width $xs){
      margin: 0;
      padding: 0;
    }
    @include breakpoint(480px){
      margin-bottom: 20px;
    }
  }
  li{
    list-style: none;
    padding: 3px 0;
    margin-bottom: 8px;
    @include breakpoint($xs){
      margin-bottom: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
  a{
    text-decoration: inherit;
    line-height: 1.2;
    color: black;
    transition: color 0.25s ease;
    &:hover{
      color: $purple;
    }
  }
  @include breakpoint($lg){
    @include span(2.4 (gutters: 0.8));
    padding-left: 1.7037%;
  }
  @include breakpoint($md){
    @include span(1 of 2 (gutters: 0.5) last);
  }
  @include breakpoint($sm){
    @include span(1 of 2 (gutters: 0.2) last);
  }

  @include breakpoint(480px){
    @include span(12);
    margin-bottom: 0px;
  }
  /*
  @include breakpoint($xs - 1px){
    @include span(12);
    margin-bottom: 30px;
    h2,
    ul{
      max-width: 320px;
      margin: 0 auto;
      padding: 0;
    }
  }
  */
  @include breakpoint($xs - 1px){
    //@include span(5.5 (gutters: 6) last);
    margin-bottom: 0px;
    ul{
      padding: 0;
      margin-top: 0;
      li{
        a{
          font-size: 12px;
          line-height: 20px;
        }
      }
    }
    h2,
    ul{
      //max-width: 183px;
      margin: auto;
    }
    h2{
      margin-bottom: 20px;
    }
  }
}

#footer-payments{
  @include span(3.5 (gutters: 0.8));
  hr{
    width: 100%;
    max-width: 229px;
    float: left;
    display: block;
    @include breakpoint(480px){
      display: none!important;
    }
  }
  img{
    max-width: 227px;
    height: auto !important;
    text-align: left;
    @include breakpoint($xs){
      margin-left:unset!important;
    }
    &:first-child{
      margin-left:unset!important;
    }
    @include breakpoint(min-width $md +1){
      &:nth-child(3){
        //margin-left: 82px;
        left: -39px!important;
        position: relative;
      }
      &:last-child{
        position: relative;
        left: 38px;
      }
    }
  }
  @include breakpoint($lg){
    @include span(3.2 (gutters: 0.8));
    img{

      &:nth-child(3){
        left: 0!important;

      }
      &:last-child{

      }
    }
  }
  @include breakpoint($md){
    @include span(1 of 2 (gutters: 0.5));
    .block__content{
      max-width: 235px;
    }
  }
  @include breakpoint($sm){
    @include span(1 of 2 (gutters: 0.2));
    .block__content{
      max-width: unset;
    }
  }
  @include breakpoint(480px){
    @include span(12);
    margin-bottom: 0px;
  }
  /*
  @include breakpoint($xs - 1px){
    @include span(12);
    margin-bottom: 30px;
    .block__content{
      max-width: 320px;
      margin: 0 auto;
    }
  }
  */
  @include breakpoint($xs){
    //@include span(6.5 (gutters: 6));
    margin-bottom: 0px;
    .block__content{
      //max-width: 183px;
      margin: auto;
      .footer-titles{
        margin-bottom: 20px;
      }
      p{
        font-size: 12px;
      }
      img{
        max-width: 184px;
        width: 100%;
        height: auto !important;
        margin-left:unset!important;
      }
    }
  }
  @include breakpoint(480px){
    .block__content{
      .footer-titles{

      }
      p{
       &:last-child{
         margin-bottom: 20px;
       }
      }

      img{

      }
    }
  }
}

#footer-certified{
  font-size: 18px;
  font-weight: 400;
  line-height: 1.36;
  .footer-titles{
    &:after{
      margin-top: 6px;
    }
  }
  .block__content{
    p{
      &:nth-child(3){
        margin-bottom: 3px;
      }
    }
  }
  @include span(2.5 (gutters: 0.8) last);
  @include breakpoint($lg){
    @include span(2.8 (gutters: 0.8) last);
  }
  @include breakpoint($md){
    @include span(1 of 2 (gutters: 0.5) last);
  }
  @include breakpoint($sm){
    @include span(1 of 2 (gutters: 0.2) last);
  }
  @include breakpoint(480px){
    @include span(12);
    margin-bottom: 0px;
  }
  /*
  @include breakpoint($xs - 1px){
    @include span(12);
    margin-bottom: 30px;
    .block__content{
      max-width: 320px;
      margin: 0 auto;
    }
  }
  */
  @include breakpoint($xs - 1px){
    //@include span(5.5 (gutters: 6) last);
    margin-bottom: 0px;
    .block__content{
      //max-width: 183px;
      margin: auto;
      .footer-titles{
        margin-bottom: 20px;
      }
      p {
        font-size: 12px;
        img {
          width: 100%;
          height: auto!important;
          &:first-child{
            max-width: 65px;
            vertical-align: top;
            margin-top: 10px;
            margin-right: 10px;
            margin-left: 5px;
          }
          &:last-child{
            max-width: 40px;
          }
        }
      }
    }
  }
}

//Copyright
.l-copyright{
  @include breakpoint($xs){
    padding: 11px 20px;
  }

  background-color: $orange;
  overflow: hidden;
  .l-region{
    @include gim_container(1170px);
  }
  p{
    @include breakpoint($xs){
      padding: 0;
      line-height: 16px;
    }
    color: $black;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 1.4;
    font-weight: 400;
    padding-top: 17px;
    padding-bottom: 13px;
    &.site{
      @include span(6);
      @include breakpoint($xs){
        text-align: center;
        @include span(12);
      }
    }
    &.gim{
      padding-right: 33px;
      @include span(6 last);
      text-align: right;
      @include breakpoint($xs){
        text-align: center;
        @include span(12);
      }
    }
  }
  a{
    text-decoration: inherit;
    color: inherit;
    transition: color 0.25s ease;
    &:hover{
      color: $purple;
    }
  }
}