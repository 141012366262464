body.page-node-368{
  .l-main{
    .l-content{
      #block-webform-client-block-400{
        max-width: 900px;
        margin: auto;
        width: 100%;
        display: block;
        overflow: hidden;
        .block__content{
          width: 100%;
          display: inline-block;
          form{
            input[type="text"],
            input[type="email"],
            textarea{
              @include placeholder;
              border: 1px solid $purple;
              border-radius: 5px;
              padding: 7px 15px;
              font-size: 18px;
              line-height: 24px;
              position: relative;
              width: 100%;
              display: inline-block;
            }
            .right-group{
              @include span(1 of 2 (gutters:0.08));
              margin-left: 0;
              border: none;
              padding: 0;
              .form-item{
                margin-bottom: 27px;
              }
            }
            .left-group{
              @include span(1 of 2 (gutters:0.08) last);
              border: none;
              margin-left: 0;
              padding: 0;
              margin-bottom: 0;
            }

            .form-actions{
              @include span(1 of 2 (gutters:0.08) last);
              text-align: center;
            }

            .form-submit{
              @extend .button;
              background-color: $purple;
              text-transform: uppercase;
              border: none;
              padding: 12px 25px 11px;
              margin-top: 12px;
              &:hover{
                background-color: desaturate(darken($purple, 5%), 10%);
              }
            }
          }
        }
      }
      .block__title{
        max-width: 900px;
        margin: 20px auto;
      }
      .view-testimonials{
        max-width: 900px;
        margin: auto;
        .view-content{
          .item{

            border-radius: 10px;
            padding: 20px;
            margin-bottom: 30px;
            width: 100%;
            display: inline-block;
            box-shadow: 0px 0px 13px #dedede;
            .body{
              p{
                margin-bottom: 5px;
              }
            }
            .rest{
              text-align: right;
              color: $purple;
              font-weight: 700;
              display: inline-block;
              float: right;
              position: relative;
              padding-bottom: 7px;
              border-bottom: 2px dashed #ffec00;
              margin-top: 40px;
            }
          }
        }
        #block-block-39{
          display: none;
        }
      }
    }
  }
}
@include breakpoint($smaller){
  body.page-node-368{
    .l-main{
      .l-content{
        #block-webform-client-block-400{

          .block__content{

            form{
              input[type="text"],
              input[type="email"],
              textarea{

              }
              .right-group{
                width: 100%;
                margin: 0;
                .form-item{
                  margin-bottom: 20px;
                }
              }
              .left-group{
                width: 100%;
                margin: 0;
              }

              .form-actions{
                width: 100%;
                margin: 0;
              }

              .form-submit{

                &:hover{

                }
              }
            }
          }
        }
        .block__title{

        }
        .view-testimonials{

          .view-content{
            .item{

              .body{
                p{

                }
              }
              .rest{
                margin-top: 15px;
              }
            }
          }
          #block-block-39{

          }
        }
      }
    }
  }
}