@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,700,800&subset=greek");
/*$blue: #3f79bd;
$dblue: #205AA8;
$purple: #0F4B97;
$orange: #f5822a;
$light_grey: #f3f3f3;
$grey: #c7c9ca;
$yellow_grey: #eeefea;
$dark_grey: #8f9080;
$sidebar_grey: #eeeeeb;
$line-grey: #eeefef;
$background: $light_grey;*/
* {
  box-sizing: border-box; }

html {
  font-family: 'M PLUS Rounded 1c', sans-serif; }

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0; }

a {
  color: #064599;
  text-decoration: inherit;
  transition: color 0.25s ease; }
  a:hover {
    color: #0857c0; }

@keyframes slide {
  0%,
  100% {
    transform: translate(0, 0); }
  50% {
    transform: translate(10px, 0); } }

/* Added by BrT */
.svg-air_condition_icon, #home_our_fleet div.content .right .box:nth-of-type(2) h4:before {
  background: url("../images/sprites/svg/build/view/svg/sprite.view-10a6539b.svg") no-repeat;
  background-position: 61.121142811283654% 29.778443423023294%; }

.svg-air_condition_icon-dims, #home_our_fleet div.content .right .box:nth-of-type(2) h4:before {
  width: 47.79px;
  height: 54.37px; }

.svg-aircondition_icon {
  background: url("../images/sprites/svg/build/view/svg/sprite.view-10a6539b.svg") no-repeat;
  background-position: 23.160350862031716% 38.35653985308628%; }

.svg-aircondition_icon-dims {
  width: 65.57px;
  height: 65.58px; }

.svg-baggage_icon {
  background: url("../images/sprites/svg/build/view/svg/sprite.view-10a6539b.svg") no-repeat;
  background-position: 77.77191129883843% 53.27333065164924%; }

.svg-baggage_icon-dims {
  width: 31.84px;
  height: 49.12px; }

.svg-baggage_space_icon, #home_our_fleet div.content .left .box:nth-of-type(4) h4:before {
  background: url("../images/sprites/svg/build/view/svg/sprite.view-10a6539b.svg") no-repeat;
  background-position: 41.594097726173196% 97.51808681672024%; }

.svg-baggage_space_icon-dims, #home_our_fleet div.content .left .box:nth-of-type(4) h4:before {
  width: 48.96px;
  height: 48.96px; }

.svg-blue_underline_icon {
  background: url("../images/sprites/svg/build/view/svg/sprite.view-10a6539b.svg") no-repeat;
  background-position: 0 26.61885330365607%; }

.svg-blue_underline_icon-dims {
  width: 71.73px;
  height: 5.39px; }

.svg-ckeck_icon, #block-views-front-page-gallery-block .view-content .gallery_text ul li:before {
  background: url("../images/sprites/svg/build/view/svg/sprite.view-10a6539b.svg") no-repeat;
  background-position: 96.17732943987258% 36.74442284550588%; }

.svg-ckeck_icon-dims, #block-views-front-page-gallery-block .view-content .gallery_text ul li:before {
  width: 33.31px;
  height: 31.94px; }

.svg-date_icon, .search_box .block__content form > div .form-item-dropoff-date:before,
.search_box .block__content form > div .form-item-pickup-date:before, .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .old-enough .date:before,
.search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .dropoff-car .date:before,
.search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .pickup-car .date:before {
  background: url("../images/sprites/svg/build/view/svg/sprite.view-10a6539b.svg") no-repeat;
  background-position: 93.3934818633769% 64.89043824701194%; }

.svg-date_icon-dims, .search_box .block__content form > div .form-item-dropoff-date:before,
.search_box .block__content form > div .form-item-pickup-date:before, .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .old-enough .date:before,
.search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .dropoff-car .date:before,
.search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .pickup-car .date:before {
  width: 25.45px;
  height: 27.12px; }

.svg-door_icon {
  background: url("../images/sprites/svg/build/view/svg/sprite.view-10a6539b.svg") no-repeat;
  background-position: 59.75020619771415% 97.34690806961231%; }

.svg-door_icon-dims {
  width: 42.39px;
  height: 48.61px; }

.svg-doors_icon, #home_our_fleet div.content .left .box:nth-of-type(2) h4:before {
  background: url("../images/sprites/svg/build/view/svg/sprite.view-10a6539b.svg") no-repeat;
  background-position: 0 39.48645598194131%; }

.svg-doors_icon-dims, #home_our_fleet div.content .left .box:nth-of-type(2) h4:before {
  width: 53.6px;
  height: 70.8px; }

.svg-fuel_icon, #home_our_fleet div.content .right .box:nth-of-type(1) h4:before {
  background: url("../images/sprites/svg/build/view/svg/sprite.view-10a6539b.svg") no-repeat;
  background-position: 0 72.31211794318588%; }

.svg-fuel_icon-dims, #home_our_fleet div.content .right .box:nth-of-type(1) h4:before {
  width: 56.49px;
  height: 53.33px; }

.svg-gear_icon, #home_our_fleet div.content .left .box:nth-of-type(3) h4:before {
  background: url("../images/sprites/svg/build/view/svg/sprite.view-10a6539b.svg") no-repeat;
  background-position: 62.96603148301574% 68.28522920203734%; }

.svg-gear_icon-dims, #home_our_fleet div.content .left .box:nth-of-type(3) h4:before {
  width: 43.53px;
  height: 41.85px; }

.svg-gearbox_manual_icon {
  background: url("../images/sprites/svg/build/view/svg/sprite.view-10a6539b.svg") no-repeat;
  background-position: 97.55356387275454% 20.07868417310518%; }

.svg-gearbox_manual_icon-dims {
  width: 37.03px;
  height: 37.03px; }

.svg-help_icon, #block-block-17.help:before {
  background: url("../images/sprites/svg/build/view/svg/sprite.view-10a6539b.svg") no-repeat;
  background-position: 93.92962962962963% 89.34389140271493%; }

.svg-help_icon-dims, #block-block-17.help:before {
  width: 27px;
  height: 27px; }

.svg-home_icon, #block-system-main-menu > .menu > li.first > a:after {
  background: url("../images/sprites/svg/build/view/svg/sprite.view-10a6539b.svg") no-repeat;
  background-position: 95.70188679245283% 51.541666666666664%; }

.svg-home_icon-dims, #block-system-main-menu > .menu > li.first > a {
  width: 32px;
  height: 32px; }

.svg-icon1, body.front #icon-blocks .block__content div.insurance:before {
  background: url("../images/sprites/svg/build/view/svg/sprite.view-10a6539b.svg") no-repeat;
  background-position: 21.17527450183001% 99.00030602876669%; }

.svg-icon1-dims, body.front #icon-blocks .block__content div.insurance:before {
  width: 51.1px;
  height: 51.94px; }

.svg-icon2, body.front #icon-blocks .block__content div.service:before {
  background: url("../images/sprites/svg/build/view/svg/sprite.view-10a6539b.svg") no-repeat;
  background-position: 0 99.60997639330799%; }

.svg-icon2-dims, body.front #icon-blocks .block__content div.service:before {
  width: 52.07px;
  height: 53.14px; }

.svg-icon3, body.front #icon-blocks .block__content div.freekm:before {
  background: url("../images/sprites/svg/build/view/svg/sprite.view-10a6539b.svg") no-repeat;
  background-position: 23.21824907521578% 69.71572900158478%; }

.svg-icon3-dims, body.front #icon-blocks .block__content div.freekm:before {
  width: 53.7px;
  height: 46.08px; }

.svg-icon4, body.front #icon-blocks .block__content div.freedelivery:before {
  background: url("../images/sprites/svg/build/view/svg/sprite.view-10a6539b.svg") no-repeat;
  background-position: 82.42535672888604% 73.37465695088483%; }

.svg-icon4-dims, body.front #icon-blocks .block__content div.freedelivery:before {
  width: 46.81px;
  height: 36.66px; }

.svg-icon5, body.front #icon-blocks .block__content div.finalprices:before {
  background: url("../images/sprites/svg/build/view/svg/sprite.view-10a6539b.svg") no-repeat;
  background-position: 44.505028474494125% 69.0929616177481%; }

.svg-icon5-dims, body.front #icon-blocks .block__content div.finalprices:before {
  width: 49.41px;
  height: 44.26px; }

.svg-icon6, body.front #icon-blocks .block__content div.hiddencharges:before {
  background: url("../images/sprites/svg/build/view/svg/sprite.view-10a6539b.svg") no-repeat;
  background-position: 78.15186822016874% 97.09854927463729%; }

.svg-icon6-dims, body.front #icon-blocks .block__content div.hiddencharges:before {
  width: 48.1px;
  height: 48.1px; }

.svg-insurance_icon, #home_our_fleet div.content .right .box:nth-of-type(4) h4:before {
  background: url("../images/sprites/svg/build/view/svg/sprite.view-10a6539b.svg") no-repeat;
  background-position: 62.65734265734266% 0; }

.svg-insurance_icon-dims, #home_our_fleet div.content .right .box:nth-of-type(4) h4:before {
  width: 53.9px;
  height: 57.66px; }

.svg-kids_seat_icon, #home_our_fleet div.content .right .box:nth-of-type(3) h4:before {
  background: url("../images/sprites/svg/build/view/svg/sprite.view-10a6539b.svg") no-repeat;
  background-position: 82.61688233644485% 0; }

.svg-kids_seat_icon-dims, #home_our_fleet div.content .right .box:nth-of-type(3) h4:before {
  width: 47.39px;
  height: 53.6px; }

.svg-kidseat_icon {
  background: url("../images/sprites/svg/build/view/svg/sprite.view-10a6539b.svg") no-repeat;
  background-position: 82.22488038277511% 27.395859953999487%; }

.svg-kidseat_icon-dims {
  width: 46.2px;
  height: 52.35px; }

.svg-language_icon, #block-lang-dropdown-language:before {
  background: url("../images/sprites/svg/build/view/svg/sprite.view-10a6539b.svg") no-repeat;
  background-position: 93.92962962962963% 77.12669683257919%; }

.svg-language_icon-dims, #block-lang-dropdown-language:before {
  width: 27px;
  height: 27px; }

.svg-location_icon, .search_box .block__content form > div select:after,
.search_box .block__content form > div .select-title:after, .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .old-enough .location:before,
.search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .dropoff-car .location:before,
.search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .pickup-car .location:before {
  background: url("../images/sprites/svg/build/view/svg/sprite.view-10a6539b.svg") no-repeat;
  background-position: 99.72483293428154% 63.88963180886154%; }

.svg-location_icon-dims, .search_box .block__content form > div select:after,
.search_box .block__content form > div .select-title:after, .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .old-enough .location:before,
.search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .dropoff-car .location:before,
.search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .pickup-car .location:before {
  width: 17.17px;
  height: 23.66px; }

.svg-person_icon {
  background: url("../images/sprites/svg/build/view/svg/sprite.view-10a6539b.svg") no-repeat;
  background-position: 99.79930741382024% 0; }

.svg-person_icon-dims {
  width: 42.88px;
  height: 42.36px; }

.svg-persons_icon, #home_our_fleet div.content .left .box:nth-of-type(1) h4:before {
  background: url("../images/sprites/svg/build/view/svg/sprite.view-10a6539b.svg") no-repeat;
  background-position: 44.30276218446782% 37.934399566278124%; }

.svg-persons_icon-dims, #home_our_fleet div.content .left .box:nth-of-type(1) h4:before {
  width: 28.01px;
  height: 63.55px; }

.svg-quote1_icon {
  background: url("../images/sprites/svg/build/view/svg/sprite.view-10a6539b.svg") no-repeat;
  background-position: 34.48650606774135% 0; }

.svg-quote1_icon-dims {
  width: 76.16px;
  height: 64.58px; }

.svg-quote2_icon {
  background: url("../images/sprites/svg/build/view/svg/sprite.view-10a6539b.svg") no-repeat;
  background-position: 0 0; }

.svg-quote2_icon-dims {
  width: 76.16px;
  height: 64.58px; }

.svg-round_checked_icon {
  background: url("../images/sprites/svg/build/view/svg/sprite.view-10a6539b.svg") no-repeat;
  background-position: 99.72864430695756% 98.70706715334887%; }

.svg-round_checked_icon-dims {
  width: 20.61px;
  height: 20.61px; }

.svg-round_unchecked_icon {
  background: url("../images/sprites/svg/build/view/svg/sprite.view-10a6539b.svg") no-repeat;
  background-position: 92.23187984143723% 99.32734433774395%; }

.svg-round_unchecked_icon-dims {
  width: 22.03px;
  height: 22.03px; }

.svg-telephone_icon, #block-block-16.phone:before {
  background: url("../images/sprites/svg/build/view/svg/sprite.view-10a6539b.svg") no-repeat;
  background-position: 56.41481481481482% 50.69230769230769%; }

.svg-telephone_icon-dims, #block-block-16.phone:before {
  width: 27px;
  height: 27px; }

.svg-time_icon, .search_box .block__content form > div .form-item.form-type-textfield.form-item-pickup-time:before, .search_box .block__content form > div .form-item.form-type-textfield.form-item-dropoff-time:before {
  background: url("../images/sprites/svg/build/view/svg/sprite.view-10a6539b.svg") no-repeat;
  background-position: 65.77171361502347% 50.093900912180295%; }

.svg-time_icon-dims, .search_box .block__content form > div .form-item.form-type-textfield.form-item-pickup-time:before, .search_box .block__content form > div .form-item.form-type-textfield.form-item-dropoff-time:before {
  width: 24.36px;
  height: 24.36px; }

#admin-menu {
  z-index: 9999; }

.admin-menu .l-main .action-links,
.admin-menu .l-main .tabs.tabs--primary {
  display: inline-block;
  position: fixed;
  left: 0;
  width: 150px;
  list-style: none;
  padding: 0; }
  .admin-menu .l-main .action-links li,
  .admin-menu .l-main .tabs.tabs--primary li {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }
    .admin-menu .l-main .action-links li a,
    .admin-menu .l-main .tabs.tabs--primary li a {
      background-color: #ccc;
      padding: 10px;
      text-decoration: none;
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0;
      transition: background-color 0.25s ease, color 0.25s ease; }
      .admin-menu .l-main .action-links li a:hover,
      .admin-menu .l-main .tabs.tabs--primary li a:hover {
        background-color: #dfdfdf; }

.admin-menu .l-main .action-links {
  top: 189px;
  z-index: 99; }
  .admin-menu .l-main .action-links li {
    border: 1px solid black;
    border-left: none; }

.admin-menu .l-main .tabs.tabs--primary {
  top: 250px;
  z-index: 99;
  border: none; }
  .admin-menu .l-main .tabs.tabs--primary li {
    border: 1px solid black;
    border-left: none;
    margin-bottom: 5px; }

.l-top_region {
  background-color: #0a4396;
  position: relative;
  z-index: 9999; }

.l-region--top-region {
  max-width: 100%;
  padding: 0 49px;
  text-align: right; }
  @media (max-width: 600px) {
    .l-region--top-region {
      padding: 0 10px; } }
  @media (max-width: 479px) {
    .l-region--top-region {
      line-height: 17px;
      padding-bottom: 40px; } }

#block-block-42 {
  float: left;
  margin-top: 9px; }
  #block-block-42 .social {
    margin: 0; }
    #block-block-42 .social li {
      display: inline-block;
      vertical-align: bottom;
      margin-right: 6px;
      margin-bottom: 0px; }
      #block-block-42 .social li.facebook a:before {
        content: '\f09a';
        background-color: #0a4396; }
      #block-block-42 .social li.twitter a:before {
        content: '\f099';
        background-color: #0a4396; }
      #block-block-42 .social li.google-plus a:before {
        content: '\f0d5';
        background-color: #0a4396; }
      #block-block-42 .social li.linkedin a:before {
        content: '\f0e1';
        background-color: #0a4396; }
      #block-block-42 .social li.youtube a:before {
        content: '\f167';
        background-color: #0a4396; }
      #block-block-42 .social li.instagram a:before {
        content: '\f16d';
        background-color: #0a4396; }
    #block-block-42 .social a {
      border-radius: 50%;
      border: 1px solid #ffec00;
      transition: bottom 0.25s ease;
      font-size: 0;
      color: transparent;
      display: inline-block;
      width: 28px;
      height: 28px;
      position: relative;
      bottom: 0;
      vertical-align: bottom; }
      #block-block-42 .social a:before {
        font-family: 'FontAwesome';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        font-size: 16px;
        line-height: 28px;
        color: #ffec00;
        border-radius: 50%;
        text-align: center; }
      #block-block-42 .social a:hover {
        bottom: 5px; }

.bubble {
  padding-top: 8px;
  padding-bottom: 8px; }
  @media (max-width: 479px) {
    .bubble {
      margin-top: 3px;
      margin-bottom: 4px; } }
  .bubble .block__content {
    display: inline-block;
    vertical-align: bottom; }
    .bubble .block__content a {
      text-decoration: none;
      color: white;
      font-size: 14px;
      font-weight: 400;
      line-height: 27px;
      vertical-align: bottom; }
      @media (max-width: 479px) {
        .bubble .block__content a {
          line-height: 17px; } }
  .bubble:before {
    content: '';
    display: inline-block;
    vertical-align: bottom;
    width: 24px;
    height: 27px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat; }
    @media (max-width: 479px) {
      .bubble:before {
        width: 17px;
        height: 17px; } }

#block-block-16.phone {
  padding-right: 20px;
  margin-right: 8px; }
  @media (max-width: 600px) {
    #block-block-16.phone {
      padding-right: 9px;
      margin-right: 3px; } }
  #block-block-16.phone .block__content a {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 1px; }
    @media (max-width: 600px) {
      #block-block-16.phone .block__content a {
        font-size: 14px; } }
  #block-block-16.phone:before {
    margin-right: 10px; }
    @media (max-width: 600px) {
      #block-block-16.phone:before {
        margin-right: 5px; } }
  @media (max-width: 479px) {
    #block-block-16.phone {
      padding-right: 8px;
      margin-right: 5px;
      position: absolute;
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
      max-width: 300px;
      top: 40px;
      border-right: none; }
      #block-block-16.phone .block__content {
        margin-bottom: 4px; }
        #block-block-16.phone .block__content a {
          font-size: 0.8em; } }

#block-block-17.help {
  padding-right: 13px;
  margin-right: 11px; }
  @media (max-width: 600px) {
    #block-block-17.help {
      padding-right: 8px;
      margin-right: 3px; } }
  #block-block-17.help:before {
    margin-right: 4px; }
    @media (max-width: 600px) {
      #block-block-17.help:before {
        margin-right: 2px; } }
  @media (max-width: 479px) {
    #block-block-17.help {
      padding-right: 8px;
      margin-right: 5px; }
      #block-block-17.help .block__content a {
        font-size: 0.8em; } }

#block-lang-dropdown-language {
  display: none; }
  #block-lang-dropdown-language:before {
    margin-right: 6px; }
    @media (max-width: 600px) {
      #block-lang-dropdown-language:before {
        margin-right: 3px; } }

#lang_dropdown_form_language .form-item-lang-dropdown-select {
  margin-bottom: 0; }

#lang_dropdown_form_language .dd-container {
  width: unset !important; }

#lang_dropdown_form_language .dd-select {
  border: none;
  border-radius: 0;
  background-color: unset !important;
  width: unset !important; }

#lang_dropdown_form_language a.dd-selected {
  padding: 0;
  text-align: left;
  font-size: 18px;
  font-weight: 700;
  color: #ffec00;
  line-height: 27px;
  margin-right: 4px; }
  @media (max-width: 479px) {
    #lang_dropdown_form_language a.dd-selected {
      line-height: 17px;
      font-size: 12px; } }

#lang_dropdown_form_language .dd-pointer {
  display: none; }

#lang_dropdown_form_language ul.dd-options {
  width: 50px !important;
  right: -11px;
  top: calc(100% + 10px); }
  #lang_dropdown_form_language ul.dd-options li a {
    padding-bottom: 2px;
    padding-top: 2px;
    color: #1f5aa9;
    text-align: center; }
    #lang_dropdown_form_language ul.dd-options li a label {
      cursor: pointer; }

header.l-header {
  position: sticky;
  top: 0px;
  z-index: 9998;
  box-shadow: -10px 0 10px rgba(0, 0, 0, 0.75);
  background-color: #1f5aa9; }

.header-container {
  max-width: 1327px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1832px;
  display: block;
  position: relative; }
  @media (max-width: 1862px) {
    .header-container {
      padding-left: 15px;
      padding-right: 15px; } }
  .header-container:after {
    content: " ";
    display: block;
    clear: both; }

.l-branding {
  margin-right: 14.6%;
  float: left;
  padding: 15px 16px 15px 40px; }
  .l-branding:after {
    content: "";
    display: block;
    max-width: 280px;
    width: 100%; }
  @media (max-width: 1620px) {
    .l-branding {
      margin-right: 0; } }
  @media (min-width: 1024px) and (max-width: 1223px) {
    .l-branding {
      margin-right: 0;
      text-align: center;
      padding-left: 0;
      padding-right: 0;
      float: left; } }
  @media (min-width: 1023px) and (max-width: 1100px) {
    .l-branding .l-logo a img {
      max-width: 370px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .l-branding {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0;
      margin-right: 0;
      text-align: center; }
      .l-branding .l-logo {
        display: inline-block; } }
  @media (max-width: 767px) {
    .l-branding {
      width: 74.39864%;
      float: left;
      margin-right: 2.40546%; } }
  @media (max-width: 414px) {
    .l-branding {
      padding-left: 0; } }

.site-logo {
  margin-top: 17px;
  margin-bottom: 17px; }
  .site-logo img {
    display: block; }
  @media (min-width: 768px) and (max-width: 1223px) {
    .site-logo {
      margin-top: 10px;
      margin-bottom: 10px; } }
  @media (max-width: 767px) {
    .site-logo {
      margin-top: 10px;
      margin-bottom: 10px; }
      .site-logo img {
        max-width: 225px;
        height: auto; } }

.l-region--header {
  display: block;
  text-align: center; }
  @media (min-width: 1024px) and (max-width: 1223px) {
    .l-region--header {
      width: 100%;
      float: right;
      margin-right: 0;
      max-width: 500px;
      margin: 0 auto;
      margin-top: 37px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .l-region--header {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0;
      margin: 0 auto;
      float: unset;
      max-width: 600px; } }
  @media (max-width: 767px) {
    .l-region--header {
      width: 23.19591%;
      float: right;
      margin-right: 0; } }

footer.l-footer {
  background-color: white; }

.l-region--footer {
  max-width: 1327px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1266px;
  padding-top: 32px; }
  @media (max-width: 1296px) {
    .l-region--footer {
      padding-left: 15px;
      padding-right: 15px; } }
  .l-region--footer:after {
    content: " ";
    display: block;
    clear: both; }
  @media (max-width: 479px) {
    .l-region--footer {
      padding-top: 17px; } }

#footer-logo {
  text-align: center;
  margin-bottom: 30px; }
  #footer-logo img {
    margin-bottom: 34px; }
    @media (max-width: 479px) {
      #footer-logo img {
        width: 208px;
        height: auto; } }
  #footer-logo p {
    max-width: 1327px;
    margin-left: auto;
    margin-right: auto;
    max-width: 950px;
    margin-bottom: 0;
    color: #000006;
    font-size: 16px;
    font-weight: 300;
    line-height: 26.06px; }
    @media (max-width: 980px) {
      #footer-logo p {
        padding-left: 15px;
        padding-right: 15px; } }
    #footer-logo p:after {
      content: " ";
      display: block;
      clear: both; }
  #footer-logo a {
    margin-top: 4px;
    color: #064599;
    font-weight: bold;
    display: inline-block; }
  @media (min-width: 480px) and (max-width: 767px) {
    #footer-logo p {
      max-width: 1327px;
      margin-left: auto;
      margin-right: auto;
      max-width: 650px;
      font-size: 15px;
      line-height: 20px; } }
  @media (min-width: 480px) and (max-width: 767px) and (max-width: 680px) {
    #footer-logo p {
      padding-left: 15px;
      padding-right: 15px; } }
  @media (min-width: 480px) and (max-width: 767px) {
      #footer-logo p:after {
        content: " ";
        display: block;
        clear: both; } }
  @media (max-width: 479px) {
    #footer-logo {
      margin-bottom: 14px; }
      #footer-logo img {
        margin-bottom: 13px;
        width: 100%;
        height: auto;
        max-width: 208px; }
      #footer-logo p {
        font-size: 14px;
        line-height: 18px; }
        #footer-logo p strong {
          display: block;
          text-decoration: underline; } }

#footer-locations {
  overflow: hidden;
  margin-bottom: 26px; }
  #footer-locations ul {
    list-style: none;
    padding-left: 0;
    margin: 0; }
  #footer-locations li {
    width: 20%;
    float: left;
    border-width: 2px 1px;
    border-style: solid;
    border-color: white;
    background-color: #ffec00;
    padding: 9px 15px 8px;
    color: white;
    text-align: center;
    font-size: 20px;
    line-height: 1; }
    @media (min-width: 480px) and (max-width: 767px) {
      #footer-locations li {
        width: 33.33333%;
        float: left;
        border-width: 1px; }
        #footer-locations li:nth-child(4), #footer-locations li:nth-child(5) {
          width: 50%;
          float: left; } }
    @media (max-width: 479px) {
      #footer-locations li {
        width: 50%;
        float: left;
        border-width: 1px;
        font-size: 13px;
        padding: 5px 15px; }
        #footer-locations li:nth-child(3), #footer-locations li:nth-child(4) {
          width: 50%;
          float: left; }
        #footer-locations li:nth-child(5) {
          width: 50%;
          float: left;
          margin-left: 25%; } }

.footer-titles {
  color: #0050a3;
  margin-bottom: 12px;
  margin-top: 0; }
  @media (max-width: 479px) {
    .footer-titles {
      font-size: 14px;
      margin-bottom: 10px; } }

.l-region--extra-footer {
  max-width: 1327px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1180px;
  padding: 62px 0;
  padding-bottom: 35px; }
  @media (max-width: 1023px) {
    .l-region--extra-footer {
      max-width: 704px; } }
  @media (max-width: 1210px) {
    .l-region--extra-footer {
      padding-left: 15px;
      padding-right: 15px; } }
  .l-region--extra-footer:after {
    content: " ";
    display: block;
    clear: both; }
  @media (max-width: 480px) {
    .l-region--extra-footer {
      padding: 25px; } }
  .l-region--extra-footer .footer-titles {
    margin-bottom: 13px;
    font-size: 24px;
    font-weight: 700; }
    .l-region--extra-footer .footer-titles:after {
      content: '';
      display: block;
      background-image: linear-gradient(to right, #ffec00 0, #ffec00 41px, transparent 41px, transparent 45px, #ffec00 45px, #ffec00 86px, transparent 86px, transparent 90px, #ffec00 90px, #ffec00 131px);
      height: 6px;
      width: 131px; }
    @media (max-width: 479px) {
      .l-region--extra-footer .footer-titles {
        font-size: 20px; } }
    .l-region--extra-footer .footer-titles.titles-social {
      font-size: 18px;
      float: left;
      margin-right: 8px; }
      .l-region--extra-footer .footer-titles.titles-social:after {
        display: none; }
    .l-region--extra-footer .footer-titles:after {
      margin-top: 12px; }
      @media (max-width: 480px) {
        .l-region--extra-footer .footer-titles:after {
          height: 3px; } }
  .l-region--extra-footer .block__content {
    font-size: 18px;
    font-weight: 400; }
  .l-region--extra-footer #footer-payments {
    text-align: center; }
    .l-region--extra-footer #footer-payments .footer-titles {
      text-align: left;
      letter-spacing: -1px;
      margin-bottom: 0; }
      @media (max-width: 480px) {
        .l-region--extra-footer #footer-payments .footer-titles {
          margin-bottom: 13px; } }

#footer-contact {
  width: 23.84615%;
  float: left;
  margin-right: 3.84615%;
  /*
  @include breakpoint($xs - 1px){
    @include span(12);
    margin-bottom: 30px;
    .block__content{
      max-width: 320px;
      margin: 0 auto;
    }
  }
  */ }
  @media (max-width: 1223px) {
    #footer-contact {
      width: 27.30769%;
      float: left;
      margin-right: 3.84615%; } }
  @media (max-width: 1023px) {
    #footer-contact {
      width: 40%;
      float: left;
      margin-right: 20%;
      margin-bottom: 40px; } }
  @media (max-width: 767px) {
    #footer-contact {
      width: 45.45455%;
      float: left;
      margin-right: 9.09091%;
      margin-bottom: 40px; } }
  @media (max-width: 479px) {
    #footer-contact {
      margin-bottom: 0px; } }
  #footer-contact .footer-titles:last-of-type {
    margin-bottom: 11px; }
    @media (max-width: 479px) {
      #footer-contact .footer-titles:last-of-type {
        margin-right: 8px !important; } }
  #footer-contact ul {
    list-style: none;
    padding-left: 0;
    margin: 0; }
    #footer-contact ul.contact {
      line-height: 1.3;
      margin-bottom: 20px; }
      #footer-contact ul.contact li {
        line-height: 25px; }
      #footer-contact ul.contact .spacer {
        margin-bottom: 20px; }
      @media (max-width: 1023px) {
        #footer-contact ul.contact li:nth-child(2) {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; } }
      #footer-contact ul.contact a {
        color: inherit;
        text-decoration: inherit;
        transition: color 0.25s ease; }
        #footer-contact ul.contact a:hover {
          color: #064599; }
    #footer-contact ul.social li {
      display: inline-block;
      vertical-align: bottom;
      margin-right: 6px;
      margin-bottom: 9px; }
      @media (min-width: 767px) and (max-width: 1023px) {
        #footer-contact ul.social li {
          margin-right: 1px;
          margin-bottom: 3px; } }
      #footer-contact ul.social li.facebook a:before {
        content: '\f09a';
        background-color: #0a4396; }
      #footer-contact ul.social li.twitter a:before {
        content: '\f099';
        background-color: #0a4396; }
      #footer-contact ul.social li.google-plus a:before {
        content: '\f0d5';
        background-color: #0a4396; }
      #footer-contact ul.social li.linkedin a:before {
        content: '\f0e1';
        background-color: #0a4396; }
      #footer-contact ul.social li.youtube a:before {
        content: '\f167';
        background-color: #0a4396; }
      #footer-contact ul.social li.instagram a:before {
        content: '\f16d';
        background-color: #0a4396; }
    #footer-contact ul.social a {
      transition: bottom 0.25s ease;
      font-size: 0;
      color: transparent;
      display: inline-block;
      width: 22px;
      height: 22px;
      position: relative;
      bottom: 0;
      vertical-align: bottom; }
      #footer-contact ul.social a:before {
        font-family: 'FontAwesome';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        font-size: 12px;
        line-height: 22px;
        color: white;
        border-radius: 50%;
        text-align: center; }
      #footer-contact ul.social a:hover {
        bottom: 5px; }
  @media (max-width: 480px) {
    #footer-contact {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 0px; } }
  @media (max-width: 479px) {
    #footer-contact {
      margin-bottom: 0px; }
      #footer-contact .block__content {
        margin: 0 auto; }
        #footer-contact .block__content .footer-titles {
          margin: auto;
          margin-bottom: 20px; }
          #footer-contact .block__content .footer-titles.titles-social {
            margin-top: 3px; }
        #footer-contact .block__content ul {
          padding: 0;
          margin: auto;
          margin-bottom: 10px; }
          #footer-contact .block__content ul li {
            font-size: 12px; }
            #footer-contact .block__content ul li a {
              font-size: 12px;
              line-height: 20px; }
          #footer-contact .block__content ul.social {
            max-width: 100%; }
            #footer-contact .block__content ul.social li {
              margin-right: 3px;
              margin-bottom: 3px; }
              #footer-contact .block__content ul.social li a {
                width: 28px;
                height: 27px; }
                #footer-contact .block__content ul.social li a:before {
                  font-size: 12px;
                  line-height: 27px; } }

#footer-info {
  width: 20.38462%;
  float: left;
  margin-right: 3.84615%;
  padding-left: 3.7037%;
  /*
  @include breakpoint($xs - 1px){
    @include span(12);
    margin-bottom: 30px;
    h2,
    ul{
      max-width: 320px;
      margin: 0 auto;
      padding: 0;
    }
  }
  */ }
  #footer-info .menu {
    font-size: 18px;
    font-weight: 400; }
    @media (min-width: 479px) {
      #footer-info .menu {
        margin: 0;
        padding: 0; } }
    @media (max-width: 480px) {
      #footer-info .menu {
        margin-bottom: 20px; } }
  #footer-info li {
    list-style: none;
    padding: 3px 0;
    margin-bottom: 8px; }
    @media (max-width: 479px) {
      #footer-info li {
        margin-bottom: 0; } }
    #footer-info li:last-child {
      margin-bottom: 0; }
  #footer-info a {
    text-decoration: inherit;
    line-height: 1.2;
    color: black;
    transition: color 0.25s ease; }
    #footer-info a:hover {
      color: #064599; }
  @media (max-width: 1223px) {
    #footer-info {
      width: 16.92308%;
      float: left;
      margin-right: 3.84615%;
      padding-left: 1.7037%; } }
  @media (max-width: 1023px) {
    #footer-info {
      width: 40%;
      float: right;
      margin-right: 0; } }
  @media (max-width: 767px) {
    #footer-info {
      width: 45.45455%;
      float: right;
      margin-right: 0; } }
  @media (max-width: 480px) {
    #footer-info {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 0px; } }
  @media (max-width: 478px) {
    #footer-info {
      margin-bottom: 0px; }
      #footer-info ul {
        padding: 0;
        margin-top: 0; }
        #footer-info ul li a {
          font-size: 12px;
          line-height: 20px; }
      #footer-info h2,
      #footer-info ul {
        margin: auto; }
      #footer-info h2 {
        margin-bottom: 20px; } }

#footer-payments {
  width: 26.44231%;
  float: left;
  margin-right: 3.84615%;
  /*
  @include breakpoint($xs - 1px){
    @include span(12);
    margin-bottom: 30px;
    .block__content{
      max-width: 320px;
      margin: 0 auto;
    }
  }
  */ }
  #footer-payments hr {
    width: 100%;
    max-width: 229px;
    float: left;
    display: block; }
    @media (max-width: 480px) {
      #footer-payments hr {
        display: none !important; } }
  #footer-payments img {
    max-width: 227px;
    height: auto !important;
    text-align: left; }
    @media (max-width: 479px) {
      #footer-payments img {
        margin-left: unset !important; } }
    #footer-payments img:first-child {
      margin-left: unset !important; }
    @media (min-width: 1024px) {
      #footer-payments img:nth-child(3) {
        left: -39px !important;
        position: relative; }
      #footer-payments img:last-child {
        position: relative;
        left: 38px; } }
  @media (max-width: 1223px) {
    #footer-payments {
      width: 23.84615%;
      float: left;
      margin-right: 3.84615%; }
      #footer-payments img:nth-child(3) {
        left: 0 !important; } }
  @media (max-width: 1023px) {
    #footer-payments {
      width: 40%;
      float: left;
      margin-right: 20%; }
      #footer-payments .block__content {
        max-width: 235px; } }
  @media (max-width: 767px) {
    #footer-payments {
      width: 45.45455%;
      float: left;
      margin-right: 9.09091%; }
      #footer-payments .block__content {
        max-width: unset; } }
  @media (max-width: 480px) {
    #footer-payments {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 0px; } }
  @media (max-width: 479px) {
    #footer-payments {
      margin-bottom: 0px; }
      #footer-payments .block__content {
        margin: auto; }
        #footer-payments .block__content .footer-titles {
          margin-bottom: 20px; }
        #footer-payments .block__content p {
          font-size: 12px; }
        #footer-payments .block__content img {
          max-width: 184px;
          width: 100%;
          height: auto !important;
          margin-left: unset !important; } }
  @media (max-width: 480px) {
    #footer-payments .block__content p:last-child {
      margin-bottom: 20px; } }

#footer-certified {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.36;
  width: 17.78846%;
  float: right;
  margin-right: 0;
  /*
  @include breakpoint($xs - 1px){
    @include span(12);
    margin-bottom: 30px;
    .block__content{
      max-width: 320px;
      margin: 0 auto;
    }
  }
  */ }
  #footer-certified .footer-titles:after {
    margin-top: 6px; }
  #footer-certified .block__content p:nth-child(3) {
    margin-bottom: 3px; }
  @media (max-width: 1223px) {
    #footer-certified {
      width: 20.38462%;
      float: right;
      margin-right: 0; } }
  @media (max-width: 1023px) {
    #footer-certified {
      width: 40%;
      float: right;
      margin-right: 0; } }
  @media (max-width: 767px) {
    #footer-certified {
      width: 45.45455%;
      float: right;
      margin-right: 0; } }
  @media (max-width: 480px) {
    #footer-certified {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 0px; } }
  @media (max-width: 478px) {
    #footer-certified {
      margin-bottom: 0px; }
      #footer-certified .block__content {
        margin: auto; }
        #footer-certified .block__content .footer-titles {
          margin-bottom: 20px; }
        #footer-certified .block__content p {
          font-size: 12px; }
          #footer-certified .block__content p img {
            width: 100%;
            height: auto !important; }
            #footer-certified .block__content p img:first-child {
              max-width: 65px;
              vertical-align: top;
              margin-top: 10px;
              margin-right: 10px;
              margin-left: 5px; }
            #footer-certified .block__content p img:last-child {
              max-width: 40px; } }

.l-copyright {
  background-color: #ffec00;
  overflow: hidden; }
  @media (max-width: 479px) {
    .l-copyright {
      padding: 11px 20px; } }
  .l-copyright .l-region {
    max-width: 1327px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1170px; }
    @media (max-width: 1200px) {
      .l-copyright .l-region {
        padding-left: 15px;
        padding-right: 15px; } }
    .l-copyright .l-region:after {
      content: " ";
      display: block;
      clear: both; }
  .l-copyright p {
    color: #000000;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 1.4;
    font-weight: 400;
    padding-top: 17px;
    padding-bottom: 13px; }
    @media (max-width: 479px) {
      .l-copyright p {
        padding: 0;
        line-height: 16px; } }
    .l-copyright p.site {
      width: 49.20456%;
      float: left;
      margin-right: 1.59088%; }
      @media (max-width: 479px) {
        .l-copyright p.site {
          text-align: center;
          width: 100%;
          float: left;
          margin-left: 0;
          margin-right: 0; } }
    .l-copyright p.gim {
      padding-right: 33px;
      width: 49.20456%;
      float: right;
      margin-right: 0;
      text-align: right; }
      @media (max-width: 479px) {
        .l-copyright p.gim {
          text-align: center;
          width: 100%;
          float: left;
          margin-left: 0;
          margin-right: 0; } }
  .l-copyright a {
    text-decoration: inherit;
    color: inherit;
    transition: color 0.25s ease; }
    .l-copyright a:hover {
      color: #064599; }

#breadcrumb {
  height: 273px;
  background-image: url("/sites/all/themes/omgsub/images/road.jpg");
  background-position: center bottom;
  background-size: cover;
  background-repeat: no-repeat; }
  @media (max-width: 1023px) {
    #breadcrumb {
      height: 158px; } }
  @media (max-width: 640px) {
    #breadcrumb {
      position: sticky;
      top: 0;
      z-index: -1; } }
  @media (max-width: 479px) {
    #breadcrumb {
      display: none; } }

.breadcrumb-container {
  max-width: 1327px;
  margin-left: auto;
  margin-right: auto;
  height: calc(100% - 105px);
  position: relative; }
  @media (max-width: 1407px) {
    .breadcrumb-container {
      margin-left: 40px;
      margin-right: 40px; } }
  .breadcrumb-container:after {
    content: " ";
    display: block;
    clear: both; }
  @media (max-width: 1023px) {
    .breadcrumb-container {
      height: calc(100% - 56px); } }
  @media (max-width: 1023px) {
    .breadcrumb-container {
      display: none; } }
  .breadcrumb-container .bottom-aligner {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding-bottom: 20px; }
    @media (max-width: 1023px) {
      .breadcrumb-container .bottom-aligner {
        padding-bottom: 10px; } }
  .breadcrumb-container .you-are-here {
    color: #000;
    display: inline-block;
    font-size: 20px;
    margin: 0 5px 0;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5); }
    @media (max-width: 1023px) {
      .breadcrumb-container .you-are-here {
        font-size: 18px; } }
  .breadcrumb-container h1 {
    color: #000;
    display: inline-block;
    font-weight: 700;
    margin: 0;
    font-size: 20px;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5); }
    @media (max-width: 1023px) {
      .breadcrumb-container h1 {
        font-size: 24px; } }

.l-region--slider {
  position: relative; }

.l-above-footer {
  background-color: #1f5aa9;
  text-align: center; }

.l-region--above-footer {
  max-width: 1327px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1170px; }
  @media (max-width: 1200px) {
    .l-region--above-footer {
      padding-left: 15px;
      padding-right: 15px; } }
  .l-region--above-footer:after {
    content: " ";
    display: block;
    clear: both; }

#call-us .block__content {
  overflow: hidden; }
  @media (max-width: 479px) {
    #call-us .block__content {
      text-align: center; } }

#call-us .call-us-description {
  padding: 30px 0 30px; }
  @media (max-width: 640px) {
    #call-us .call-us-description {
      float: unset;
      max-width: 400px;
      margin: 0 auto;
      padding: 16px 0 0;
      padding-bottom: 16px;
      width: 100%; } }
  #call-us .call-us-description .logo {
    margin-bottom: 31px; }
    @media (max-width: 640px) {
      #call-us .call-us-description .logo {
        margin-bottom: 15px; } }
    #call-us .call-us-description .logo img {
      width: 100%;
      max-width: 580px; }

#call-us h5,
#call-us h6 {
  margin: 0;
  color: #ffec00;
  font-weight: 700;
  font-size: 42px; }

#call-us h6 {
  font-size: 34px;
  font-weight: 500;
  color: white;
  line-height: 1.2; }

#call-us h5 {
  line-height: 1.2;
  margin-bottom: 6px; }
  @media (max-width: 1170px) {
    #call-us h5 {
      font-size: 43px;
      line-height: 50px; } }
  @media (max-width: 767px) {
    #call-us h5 {
      font-size: 36px;
      line-height: 42px; } }
  @media (max-width: 640px) {
    #call-us h5 {
      font-size: 24px;
      line-height: 34px; } }
  @media (max-width: 479px) {
    #call-us h5 {
      line-height: 30px;
      margin-bottom: 0; } }

#call-us h6 a {
  color: white; }

@media (max-width: 1170px) {
  #call-us h6 {
    font-size: 29px;
    line-height: 35px; } }

@media (max-width: 767px) {
  #call-us h6 {
    font-size: 22px;
    line-height: 28px; } }

@media (max-width: 640px) {
  #call-us h6 {
    font-size: 16px;
    line-height: 24px; } }

#call-us h6 a {
  text-decoration: none;
  color: white; }

#call-us .call-us-link {
  width: 32.27275%;
  float: right;
  margin-right: 0;
  text-align: center; }
  @media (max-width: 640px) {
    #call-us .call-us-link {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0; } }
  #call-us .call-us-link p {
    margin: 0; }
  #call-us .call-us-link a {
    margin: 64px 0; }
    @media (max-width: 1170px) {
      #call-us .call-us-link a {
        font-size: 18px;
        padding: 20px 13px;
        margin: 59px 0 58px; } }
    @media (max-width: 767px) {
      #call-us .call-us-link a {
        padding: 14px 18px;
        font-size: 15px;
        margin: 58px 0; } }
    @media (max-width: 640px) {
      #call-us .call-us-link a {
        padding: 12px 10px;
        font-size: 14px;
        margin: 18px 0; } }

@media (max-width: 479px) {
  #call-us {
    float: left;
    width: 100%; } }

body.not-front {
  background-color: #f3f8fb; }
  body.not-front .l-main {
    max-width: 1327px;
    margin-left: auto;
    margin-right: auto;
    margin-top: -105px;
    background-color: white;
    padding-bottom: 60px; }
    @media (max-width: 1407px) {
      body.not-front .l-main {
        margin-left: 40px;
        margin-right: 40px; } }
    body.not-front .l-main:after {
      content: " ";
      display: block;
      clear: both; }
    @media (max-width: 767px) {
      body.not-front .l-main {
        margin: -56px 15px 0; } }
    @media (max-width: 640px) {
      body.not-front .l-main {
        margin-top: -138px; } }
    @media (max-width: 479px) {
      body.not-front .l-main {
        width: 100%;
        float: left;
        margin-left: 0;
        margin-right: 0;
        margin: 0;
        max-width: none; } }
    body.not-front .l-main .l-content {
      width: unset;
      float: unset;
      max-width: 1327px;
      margin-left: auto;
      margin-right: auto;
      max-width: 1268px;
      padding-right: 0%; }
      @media (max-width: 479px) {
        body.not-front .l-main .l-content {
          padding: 0 20px; } }
      @media (max-width: 1298px) {
        body.not-front .l-main .l-content {
          padding-left: 15px;
          padding-right: 15px; } }
      body.not-front .l-main .l-content:after {
        content: " ";
        display: block;
        clear: both; }
      body.not-front .l-main .l-content .block__content,
      body.not-front .l-main .l-content article {
        padding-top: 10px;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px; }
        body.not-front .l-main .l-content .block__content .node__content .field__item > h2:first-child,
        body.not-front .l-main .l-content article .node__content .field__item > h2:first-child {
          color: #064599;
          font-size: 28px;
          line-height: 1.25;
          border-bottom: 1px solid #064599;
          margin: 20px 0 15px;
          padding-bottom: 5px;
          height: 75px; }
        body.not-front .l-main .l-content .block__content .node__content .field__item img,
        body.not-front .l-main .l-content article .node__content .field__item img {
          max-width: 100%;
          height: auto !important; }
    body.not-front .l-main aside.l-region--sidebar-left {
      float: left;
      width: 325px;
      margin-right: 22px;
      z-index: 9; }
      @media (max-width: 1223px) {
        body.not-front .l-main aside.l-region--sidebar-left {
          width: 260px;
          margin-right: 9px; } }
      @media (max-width: 640px) {
        body.not-front .l-main aside.l-region--sidebar-left {
          width: 100%;
          float: left;
          margin-left: 0;
          margin-right: 0;
          margin-right: 0; } }
    body.not-front .l-main aside.l-region--sidebar-left ~ .l-content {
      padding-left: 0;
      padding-right: 33px;
      float: right;
      width: calc(100% - 348px); }
      @media (max-width: 1223px) {
        body.not-front .l-main aside.l-region--sidebar-left ~ .l-content {
          width: calc(100% - 269px);
          padding-right: 6px; } }
      @media (max-width: 640px) {
        body.not-front .l-main aside.l-region--sidebar-left ~ .l-content {
          width: 100%;
          float: left;
          margin-left: 0;
          margin-right: 0;
          padding: 0 15px; } }
      body.not-front .l-main aside.l-region--sidebar-left ~ .l-content > article {
        overflow: hidden; }

.inline-block {
  display: inline-block; }

.button, #call-us .call-us-link a, #block-block-26 .block__content p:last-child > a, body.page-search-car #car-search-view .car-features .book-button, #contact-form .form-submit, .offer-button, #edit-commerce-payment-continue, body.page-node-368 .l-main .l-content #block-webform-client-block-400 .block__content form .form-submit {
  display: inline-block;
  padding: 19px 14px 23px;
  font-size: 20px;
  line-height: 1;
  color: white;
  text-decoration: none;
  background-color: #ffec00;
  border-radius: 8px;
  vertical-align: bottom;
  font-weight: 900;
  transition: background-color 0.25s ease;
  cursor: pointer; }
  .button:hover, #call-us .call-us-link a:hover, #block-block-26 .block__content p:last-child > a:hover, body.page-search-car #car-search-view .car-features .book-button:hover, #contact-form .form-submit:hover, .offer-button:hover, #edit-commerce-payment-continue:hover, body.page-node-368 .l-main .l-content #block-webform-client-block-400 .block__content form .form-submit:hover {
    background-color: #dacb0b; }

.feature {
  line-height: 28px;
  font-weight: 300;
  text-align: center; }
  .feature span {
    margin-top: 10px;
    display: inline-block;
    width: 100%;
    text-align: center;
    font-size: 14px; }
  .feature:before {
    content: '';
    width: 30px;
    height: 30px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    vertical-align: bottom;
    display: block;
    margin: 0 auto; }
    @media (max-width: 1023px) {
      .feature:before {
        margin-right: 2px; } }
  .feature.passengers:before {
    background-image: url("/sites/all/themes/omgsub/images/persons.svg"); }
  .feature.fuel:before {
    background-image: url("/sites/all/themes/omgsub/images/GAS_ICON.svg"); }
  .feature.air-condition:before {
    background-image: url("/sites/all/themes/omgsub/images/C_ICON.svg"); }
  .feature.doors:before {
    background-image: url("/sites/all/themes/omgsub/images/DOORS_ICON.svg"); }
  .feature.luggage:before {
    background-image: url("/sites/all/themes/omgsub/images/BAGGAGES_ICON.svg"); }
  .feature.insurance:before {
    background-image: url("/sites/all/themes/omgsub/images/features_insurance.svg");
    background-size: 100%; }
  .feature.child-seat:before {
    background-image: url("/sites/all/themes/omgsub/images/CHILD_SEAT_ICON.svg"); }
  .feature.gearbox:before {
    background-image: url("/sites/all/themes/omgsub/images/GEARBOX_ICON.svg"); }
  .feature.hp_cc span {
    display: block;
    line-height: 15px;
    font-size: 12px;
    margin-top: 0; }
    .feature.hp_cc span:first-child {
      margin-top: 10px; }
  .feature.hp_cc:before {
    background-image: url("/sites/all/themes/omgsub/images/hpcc.png"); }

.no-display {
  display: none; }

.simple-page-section {
  overflow: hidden; }

.half-right {
  width: 49.20456%;
  float: right;
  margin-right: 0; }
  @media (max-width: 1023px) {
    .half-right {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0; } }
  .half-right img {
    margin-bottom: 15px; }

.half-left {
  width: 49.20456%;
  float: left;
  margin-right: 1.59088%; }
  @media (max-width: 1023px) {
    .half-left {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0; } }
  .half-left img {
    margin-bottom: 15px; }

.pager {
  display: inline-block;
  float: right; }
  .pager li {
    padding-left: 2px;
    padding-right: 2px;
    font-size: 20px; }
    .pager li.pager__item--current {
      color: #064599; }
    .pager li a {
      text-decoration: none;
      color: black; }
      .pager li a:hover {
        color: #064599; }

.subsection h6, #commerce-checkout-form-checkout .checkout-completion-message h6,
#commerce-checkout-form-complete .checkout-completion-message h6 {
  margin: 35px 0 10px;
  color: #064599;
  font-size: 18px;
  line-height: 24px;
  padding: 7px 0 7px 2px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.075); }

.price-table {
  display: block;
  background-color: #eeefea;
  overflow: hidden; }
  .price-table > div {
    display: table;
    table-layout: fixed;
    position: relative; }
    .price-table > div.final-price {
      min-height: 90px;
      width: 45.83333%;
      float: left; }
      @media (max-width: 1367px) {
        .price-table > div.final-price {
          width: 100%;
          float: left;
          margin-left: 0;
          margin-right: 0; } }
      @media (max-width: 479px) {
        .price-table > div.final-price {
          display: inline-block; } }
      .price-table > div.final-price > div:first-child {
        width: 49%; }
        @media (max-width: 1367px) {
          .price-table > div.final-price > div:first-child {
            width: 63%; } }
        @media (max-width: 1023px) {
          .price-table > div.final-price > div:first-child {
            width: 57%; } }
        @media (max-width: 479px) {
          .price-table > div.final-price > div:first-child {
            width: 100%;
            float: left;
            margin-left: 0;
            margin-right: 0; } }
      .price-table > div.final-price > div:nth-child(2) {
        width: 51%;
        vertical-align: top; }
        @media (max-width: 1367px) {
          .price-table > div.final-price > div:nth-child(2) {
            width: 37%; } }
        @media (max-width: 1023px) {
          .price-table > div.final-price > div:nth-child(2) {
            width: 43%;
            font-size: 29px; } }
        @media (max-width: 767px) {
          .price-table > div.final-price > div:nth-child(2) {
            font-size: 22px; } }
        @media (max-width: 479px) {
          .price-table > div.final-price > div:nth-child(2) {
            width: 56%;
            float: left; } }
        @media (max-width: 1023px) {
          .price-table > div.final-price > div:nth-child(2) {
            vertical-align: middle; } }
    .price-table > div.downpayment-price {
      width: 54.16667%;
      float: right;
      margin-right: 0;
      position: relative; }
      @media (max-width: 1367px) {
        .price-table > div.downpayment-price {
          width: 100%;
          float: left;
          margin-left: 0;
          margin-right: 0; } }
      @media (max-width: 479px) {
        .price-table > div.downpayment-price {
          display: inline-block; } }
      .price-table > div.downpayment-price:before {
        content: '';
        display: block;
        position: absolute;
        top: 17px;
        left: 0;
        bottom: 17px;
        width: 2px;
        border-left: 1px solid rgba(0, 0, 0, 0.45); }
        @media (max-width: 1367px) {
          .price-table > div.downpayment-price:before {
            top: 0;
            right: 17px;
            bottom: unset;
            left: 17px;
            width: unset;
            height: 2px;
            border-top: 1px solid rgba(0, 0, 0, 0.45);
            border-left: none; } }
      .price-table > div.downpayment-price > div:first-child {
        width: 56%; }
        @media (max-width: 1367px) {
          .price-table > div.downpayment-price > div:first-child {
            width: 63%; } }
        @media (max-width: 1023px) {
          .price-table > div.downpayment-price > div:first-child {
            width: 57%; } }
        @media (max-width: 479px) {
          .price-table > div.downpayment-price > div:first-child {
            width: 100%;
            float: left;
            margin-left: 0;
            margin-right: 0; } }
      .price-table > div.downpayment-price > div:nth-child(2) {
        width: 44%; }
        @media (max-width: 1367px) {
          .price-table > div.downpayment-price > div:nth-child(2) {
            width: 37%; } }
        @media (max-width: 1023px) {
          .price-table > div.downpayment-price > div:nth-child(2) {
            width: 43%;
            font-size: 29px; } }
        @media (max-width: 767px) {
          .price-table > div.downpayment-price > div:nth-child(2) {
            font-size: 22px; } }
        @media (max-width: 479px) {
          .price-table > div.downpayment-price > div:nth-child(2) {
            width: 100%; } }
    .price-table > div > div {
      display: table-cell;
      width: 50%;
      vertical-align: middle;
      padding: 20px; }
      @media (max-width: 767px) {
        .price-table > div > div:first-child {
          padding-bottom: 15px; }
        .price-table > div > div:nth-child(2) {
          padding-top: 5px; } }
      .price-table > div > div span {
        display: block;
        color: #064599; }
        .price-table > div > div span.label {
          text-transform: uppercase;
          font-size: 20px;
          letter-spacing: -1px; }
        .price-table > div > div span.explanation {
          text-transform: uppercase;
          font-weight: 900;
          font-size: 11px;
          line-height: 1.333; }
      .price-table > div > div.price-value {
        width: 50%;
        color: #064599;
        font-weight: 900;
        font-size: 36px; }
      .price-table > div > div.perdaycharge {
        position: absolute;
        padding: 0;
        bottom: 11px;
        right: 0;
        padding-left: 15px;
        color: #064599; }
        @media (max-width: 1367px) {
          .price-table > div > div.perdaycharge {
            width: 37%; } }
        @media (max-width: 1023px) {
          .price-table > div > div.perdaycharge {
            width: 43%;
            bottom: 3px; } }
        @media (max-width: 640px) {
          .price-table > div > div.perdaycharge {
            bottom: 12px; } }
        @media (max-width: 479px) {
          .price-table > div > div.perdaycharge {
            width: 44%;
            font-size: 13px;
            float: right;
            position: relative;
            bottom: -11px; } }

.info-block {
  padding-left: 55px;
  position: relative;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px; }
  @media (max-width: 767px) {
    .info-block {
      padding-left: 0; } }
  .info-block:before {
    content: '';
    display: block;
    background-image: url("/sites/all/themes/omgsub/images/info_icon.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 38px;
    height: 38px;
    vertical-align: bottom;
    position: absolute;
    top: 0;
    left: 0; }
    @media (max-width: 767px) {
      .info-block:before {
        display: none; } }

.checkmarks-list {
  padding-left: 20px;
  list-style: none; }
  .checkmarks-list li {
    padding-left: 20px;
    position: relative; }
    .checkmarks-list li:before {
      content: '';
      background-image: url("/sites/all/themes/omgsub/images/CHECK.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: auto;
      display: block;
      width: 22px;
      height: inherit;
      margin-right: 7px;
      position: absolute;
      top: 0;
      left: 0;
      vertical-align: bottom; }

@keyframes rotating {
  100% {
    transform: rotate(360deg); } }

.ajax-progress-throbber {
  position: fixed;
  bottom: 20px;
  right: 50%;
  background-color: white;
  color: black;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  font-size: 18px;
  font-weight: 300;
  padding: 5px 10px 5px 38px;
  border-radius: 5px;
  transform: translate(50%, 0);
  z-index: 999; }
  .ajax-progress-throbber .throbber {
    background: none;
    position: absolute;
    top: 5px;
    left: 10px;
    display: block;
    width: 18px;
    height: 22px;
    margin: 0;
    float: unset; }
    .ajax-progress-throbber .throbber:before {
      content: '\f110';
      font-family: 'FontAwesome';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      width: 18px;
      height: 22px;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      color: #064599;
      animation: rotating 2s linear infinite; }
  .ajax-progress-throbber .message {
    line-height: 22px;
    padding-left: 0; }

#focus-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.5s linear, opacity 0.5s linear;
  z-index: 9997;
  background-color: rgba(0, 0, 0, 0.6); }
  #focus-overlay.active {
    visibility: visible;
    opacity: 1; }

#check-availability-banner {
  visibility: hidden;
  position: fixed;
  bottom: 50px;
  right: 50px;
  display: inline-block;
  background-color: #064599;
  text-align: center;
  font-size: 24px;
  line-height: 28px;
  padding: 8px 22px;
  color: white;
  border-radius: 5px;
  opacity: 0;
  transition: visibility 0.75s ease 0.15s, opacity 0.75s ease 0.15s;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.6);
  z-index: 99; }
  @media (max-width: 479px) {
    #check-availability-banner {
      bottom: 0;
      right: 0;
      font-size: 20px;
      line-height: 24px;
      padding: 4px 14px;
      border-radius: 5px 0 0; } }
  #check-availability-banner.shown {
    opacity: 0.75;
    visibility: visible; }
    #check-availability-banner.shown:hover {
      opacity: 1;
      transition: opacity 0.25s ease; }

.page-node-242 .node__content,
.page-node-257 .node__content,
.page-node-216 .node__content,
.page-node-402 .node__content,
.page-node-243 .node__content {
  max-width: 900px;
  margin: auto;
  width: 100%;
  display: block; }

footer.l-footer {
  background-color: white; }

.l-region--footer {
  max-width: 1327px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1266px;
  padding-top: 32px; }
  @media (max-width: 1296px) {
    .l-region--footer {
      padding-left: 15px;
      padding-right: 15px; } }
  .l-region--footer:after {
    content: " ";
    display: block;
    clear: both; }
  @media (max-width: 479px) {
    .l-region--footer {
      padding-top: 17px; } }

#footer-logo {
  text-align: center;
  margin-bottom: 30px; }
  #footer-logo img {
    margin-bottom: 34px; }
    @media (max-width: 479px) {
      #footer-logo img {
        width: 208px;
        height: auto; } }
  #footer-logo p {
    max-width: 1327px;
    margin-left: auto;
    margin-right: auto;
    max-width: 950px;
    margin-bottom: 0;
    color: #000006;
    font-size: 16px;
    font-weight: 300;
    line-height: 26.06px; }
    @media (max-width: 980px) {
      #footer-logo p {
        padding-left: 15px;
        padding-right: 15px; } }
    #footer-logo p:after {
      content: " ";
      display: block;
      clear: both; }
  #footer-logo a {
    margin-top: 4px;
    color: #064599;
    font-weight: bold;
    display: inline-block; }
  @media (min-width: 480px) and (max-width: 767px) {
    #footer-logo p {
      max-width: 1327px;
      margin-left: auto;
      margin-right: auto;
      max-width: 650px;
      font-size: 15px;
      line-height: 20px; } }
  @media (min-width: 480px) and (max-width: 767px) and (max-width: 680px) {
    #footer-logo p {
      padding-left: 15px;
      padding-right: 15px; } }
  @media (min-width: 480px) and (max-width: 767px) {
      #footer-logo p:after {
        content: " ";
        display: block;
        clear: both; } }
  @media (max-width: 479px) {
    #footer-logo {
      margin-bottom: 14px; }
      #footer-logo img {
        margin-bottom: 13px;
        width: 100%;
        height: auto;
        max-width: 208px; }
      #footer-logo p {
        font-size: 14px;
        line-height: 18px; }
        #footer-logo p strong {
          display: block;
          text-decoration: underline; } }

#footer-locations {
  overflow: hidden;
  margin-bottom: 26px; }
  #footer-locations ul {
    list-style: none;
    padding-left: 0;
    margin: 0; }
  #footer-locations li {
    width: 20%;
    float: left;
    border-width: 2px 1px;
    border-style: solid;
    border-color: white;
    background-color: #ffec00;
    padding: 9px 15px 8px;
    color: white;
    text-align: center;
    font-size: 20px;
    line-height: 1; }
    @media (min-width: 480px) and (max-width: 767px) {
      #footer-locations li {
        width: 33.33333%;
        float: left;
        border-width: 1px; }
        #footer-locations li:nth-child(4), #footer-locations li:nth-child(5) {
          width: 50%;
          float: left; } }
    @media (max-width: 479px) {
      #footer-locations li {
        width: 50%;
        float: left;
        border-width: 1px;
        font-size: 13px;
        padding: 5px 15px; }
        #footer-locations li:nth-child(3), #footer-locations li:nth-child(4) {
          width: 50%;
          float: left; }
        #footer-locations li:nth-child(5) {
          width: 50%;
          float: left;
          margin-left: 25%; } }

.footer-titles {
  color: #0050a3;
  margin-bottom: 12px;
  margin-top: 0; }
  @media (max-width: 479px) {
    .footer-titles {
      font-size: 14px;
      margin-bottom: 10px; } }

.l-region--extra-footer {
  max-width: 1327px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1180px;
  padding: 62px 0;
  padding-bottom: 35px; }
  @media (max-width: 1023px) {
    .l-region--extra-footer {
      max-width: 704px; } }
  @media (max-width: 1210px) {
    .l-region--extra-footer {
      padding-left: 15px;
      padding-right: 15px; } }
  .l-region--extra-footer:after {
    content: " ";
    display: block;
    clear: both; }
  @media (max-width: 480px) {
    .l-region--extra-footer {
      padding: 25px; } }
  .l-region--extra-footer .footer-titles {
    margin-bottom: 13px;
    font-size: 24px;
    font-weight: 700; }
    .l-region--extra-footer .footer-titles:after {
      content: '';
      display: block;
      background-image: linear-gradient(to right, #ffec00 0, #ffec00 41px, transparent 41px, transparent 45px, #ffec00 45px, #ffec00 86px, transparent 86px, transparent 90px, #ffec00 90px, #ffec00 131px);
      height: 6px;
      width: 131px; }
    @media (max-width: 479px) {
      .l-region--extra-footer .footer-titles {
        font-size: 20px; } }
    .l-region--extra-footer .footer-titles.titles-social {
      font-size: 18px;
      float: left;
      margin-right: 8px; }
      .l-region--extra-footer .footer-titles.titles-social:after {
        display: none; }
    .l-region--extra-footer .footer-titles:after {
      margin-top: 12px; }
      @media (max-width: 480px) {
        .l-region--extra-footer .footer-titles:after {
          height: 3px; } }
  .l-region--extra-footer .block__content {
    font-size: 18px;
    font-weight: 400; }
  .l-region--extra-footer #footer-payments {
    text-align: center; }
    .l-region--extra-footer #footer-payments .footer-titles {
      text-align: left;
      letter-spacing: -1px;
      margin-bottom: 0; }
      @media (max-width: 480px) {
        .l-region--extra-footer #footer-payments .footer-titles {
          margin-bottom: 13px; } }

#footer-contact {
  width: 23.84615%;
  float: left;
  margin-right: 3.84615%;
  /*
  @include breakpoint($xs - 1px){
    @include span(12);
    margin-bottom: 30px;
    .block__content{
      max-width: 320px;
      margin: 0 auto;
    }
  }
  */ }
  @media (max-width: 1223px) {
    #footer-contact {
      width: 27.30769%;
      float: left;
      margin-right: 3.84615%; } }
  @media (max-width: 1023px) {
    #footer-contact {
      width: 40%;
      float: left;
      margin-right: 20%;
      margin-bottom: 40px; } }
  @media (max-width: 767px) {
    #footer-contact {
      width: 45.45455%;
      float: left;
      margin-right: 9.09091%;
      margin-bottom: 40px; } }
  @media (max-width: 479px) {
    #footer-contact {
      margin-bottom: 0px; } }
  #footer-contact .footer-titles:last-of-type {
    margin-bottom: 11px; }
    @media (max-width: 479px) {
      #footer-contact .footer-titles:last-of-type {
        margin-right: 8px !important; } }
  #footer-contact ul {
    list-style: none;
    padding-left: 0;
    margin: 0; }
    #footer-contact ul.contact {
      line-height: 1.3;
      margin-bottom: 20px; }
      #footer-contact ul.contact li {
        line-height: 25px; }
      #footer-contact ul.contact .spacer {
        margin-bottom: 20px; }
      @media (max-width: 1023px) {
        #footer-contact ul.contact li:nth-child(2) {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; } }
      #footer-contact ul.contact a {
        color: inherit;
        text-decoration: inherit;
        transition: color 0.25s ease; }
        #footer-contact ul.contact a:hover {
          color: #064599; }
    #footer-contact ul.social li {
      display: inline-block;
      vertical-align: bottom;
      margin-right: 6px;
      margin-bottom: 9px; }
      @media (min-width: 767px) and (max-width: 1023px) {
        #footer-contact ul.social li {
          margin-right: 1px;
          margin-bottom: 3px; } }
      #footer-contact ul.social li.facebook a:before {
        content: '\f09a';
        background-color: #0a4396; }
      #footer-contact ul.social li.twitter a:before {
        content: '\f099';
        background-color: #0a4396; }
      #footer-contact ul.social li.google-plus a:before {
        content: '\f0d5';
        background-color: #0a4396; }
      #footer-contact ul.social li.linkedin a:before {
        content: '\f0e1';
        background-color: #0a4396; }
      #footer-contact ul.social li.youtube a:before {
        content: '\f167';
        background-color: #0a4396; }
      #footer-contact ul.social li.instagram a:before {
        content: '\f16d';
        background-color: #0a4396; }
    #footer-contact ul.social a {
      transition: bottom 0.25s ease;
      font-size: 0;
      color: transparent;
      display: inline-block;
      width: 22px;
      height: 22px;
      position: relative;
      bottom: 0;
      vertical-align: bottom; }
      #footer-contact ul.social a:before {
        font-family: 'FontAwesome';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        font-size: 12px;
        line-height: 22px;
        color: white;
        border-radius: 50%;
        text-align: center; }
      #footer-contact ul.social a:hover {
        bottom: 5px; }
  @media (max-width: 480px) {
    #footer-contact {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 0px; } }
  @media (max-width: 479px) {
    #footer-contact {
      margin-bottom: 0px; }
      #footer-contact .block__content {
        margin: 0 auto; }
        #footer-contact .block__content .footer-titles {
          margin: auto;
          margin-bottom: 20px; }
          #footer-contact .block__content .footer-titles.titles-social {
            margin-top: 3px; }
        #footer-contact .block__content ul {
          padding: 0;
          margin: auto;
          margin-bottom: 10px; }
          #footer-contact .block__content ul li {
            font-size: 12px; }
            #footer-contact .block__content ul li a {
              font-size: 12px;
              line-height: 20px; }
          #footer-contact .block__content ul.social {
            max-width: 100%; }
            #footer-contact .block__content ul.social li {
              margin-right: 3px;
              margin-bottom: 3px; }
              #footer-contact .block__content ul.social li a {
                width: 28px;
                height: 27px; }
                #footer-contact .block__content ul.social li a:before {
                  font-size: 12px;
                  line-height: 27px; } }

#footer-info {
  width: 20.38462%;
  float: left;
  margin-right: 3.84615%;
  padding-left: 3.7037%;
  /*
  @include breakpoint($xs - 1px){
    @include span(12);
    margin-bottom: 30px;
    h2,
    ul{
      max-width: 320px;
      margin: 0 auto;
      padding: 0;
    }
  }
  */ }
  #footer-info .menu {
    font-size: 18px;
    font-weight: 400; }
    @media (min-width: 479px) {
      #footer-info .menu {
        margin: 0;
        padding: 0; } }
    @media (max-width: 480px) {
      #footer-info .menu {
        margin-bottom: 20px; } }
  #footer-info li {
    list-style: none;
    padding: 3px 0;
    margin-bottom: 8px; }
    @media (max-width: 479px) {
      #footer-info li {
        margin-bottom: 0; } }
    #footer-info li:last-child {
      margin-bottom: 0; }
  #footer-info a {
    text-decoration: inherit;
    line-height: 1.2;
    color: black;
    transition: color 0.25s ease; }
    #footer-info a:hover {
      color: #064599; }
  @media (max-width: 1223px) {
    #footer-info {
      width: 16.92308%;
      float: left;
      margin-right: 3.84615%;
      padding-left: 1.7037%; } }
  @media (max-width: 1023px) {
    #footer-info {
      width: 40%;
      float: right;
      margin-right: 0; } }
  @media (max-width: 767px) {
    #footer-info {
      width: 45.45455%;
      float: right;
      margin-right: 0; } }
  @media (max-width: 480px) {
    #footer-info {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 0px; } }
  @media (max-width: 478px) {
    #footer-info {
      margin-bottom: 0px; }
      #footer-info ul {
        padding: 0;
        margin-top: 0; }
        #footer-info ul li a {
          font-size: 12px;
          line-height: 20px; }
      #footer-info h2,
      #footer-info ul {
        margin: auto; }
      #footer-info h2 {
        margin-bottom: 20px; } }

#footer-payments {
  width: 26.44231%;
  float: left;
  margin-right: 3.84615%;
  /*
  @include breakpoint($xs - 1px){
    @include span(12);
    margin-bottom: 30px;
    .block__content{
      max-width: 320px;
      margin: 0 auto;
    }
  }
  */ }
  #footer-payments hr {
    width: 100%;
    max-width: 229px;
    float: left;
    display: block; }
    @media (max-width: 480px) {
      #footer-payments hr {
        display: none !important; } }
  #footer-payments img {
    max-width: 227px;
    height: auto !important;
    text-align: left; }
    @media (max-width: 479px) {
      #footer-payments img {
        margin-left: unset !important; } }
    #footer-payments img:first-child {
      margin-left: unset !important; }
    @media (min-width: 1024px) {
      #footer-payments img:nth-child(3) {
        left: -39px !important;
        position: relative; }
      #footer-payments img:last-child {
        position: relative;
        left: 38px; } }
  @media (max-width: 1223px) {
    #footer-payments {
      width: 23.84615%;
      float: left;
      margin-right: 3.84615%; }
      #footer-payments img:nth-child(3) {
        left: 0 !important; } }
  @media (max-width: 1023px) {
    #footer-payments {
      width: 40%;
      float: left;
      margin-right: 20%; }
      #footer-payments .block__content {
        max-width: 235px; } }
  @media (max-width: 767px) {
    #footer-payments {
      width: 45.45455%;
      float: left;
      margin-right: 9.09091%; }
      #footer-payments .block__content {
        max-width: unset; } }
  @media (max-width: 480px) {
    #footer-payments {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 0px; } }
  @media (max-width: 479px) {
    #footer-payments {
      margin-bottom: 0px; }
      #footer-payments .block__content {
        margin: auto; }
        #footer-payments .block__content .footer-titles {
          margin-bottom: 20px; }
        #footer-payments .block__content p {
          font-size: 12px; }
        #footer-payments .block__content img {
          max-width: 184px;
          width: 100%;
          height: auto !important;
          margin-left: unset !important; } }
  @media (max-width: 480px) {
    #footer-payments .block__content p:last-child {
      margin-bottom: 20px; } }

#footer-certified {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.36;
  width: 17.78846%;
  float: right;
  margin-right: 0;
  /*
  @include breakpoint($xs - 1px){
    @include span(12);
    margin-bottom: 30px;
    .block__content{
      max-width: 320px;
      margin: 0 auto;
    }
  }
  */ }
  #footer-certified .footer-titles:after {
    margin-top: 6px; }
  #footer-certified .block__content p:nth-child(3) {
    margin-bottom: 3px; }
  @media (max-width: 1223px) {
    #footer-certified {
      width: 20.38462%;
      float: right;
      margin-right: 0; } }
  @media (max-width: 1023px) {
    #footer-certified {
      width: 40%;
      float: right;
      margin-right: 0; } }
  @media (max-width: 767px) {
    #footer-certified {
      width: 45.45455%;
      float: right;
      margin-right: 0; } }
  @media (max-width: 480px) {
    #footer-certified {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 0px; } }
  @media (max-width: 478px) {
    #footer-certified {
      margin-bottom: 0px; }
      #footer-certified .block__content {
        margin: auto; }
        #footer-certified .block__content .footer-titles {
          margin-bottom: 20px; }
        #footer-certified .block__content p {
          font-size: 12px; }
          #footer-certified .block__content p img {
            width: 100%;
            height: auto !important; }
            #footer-certified .block__content p img:first-child {
              max-width: 65px;
              vertical-align: top;
              margin-top: 10px;
              margin-right: 10px;
              margin-left: 5px; }
            #footer-certified .block__content p img:last-child {
              max-width: 40px; } }

.l-copyright {
  background-color: #ffec00;
  overflow: hidden; }
  @media (max-width: 479px) {
    .l-copyright {
      padding: 11px 20px; } }
  .l-copyright .l-region {
    max-width: 1327px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1170px; }
    @media (max-width: 1200px) {
      .l-copyright .l-region {
        padding-left: 15px;
        padding-right: 15px; } }
    .l-copyright .l-region:after {
      content: " ";
      display: block;
      clear: both; }
  .l-copyright p {
    color: #000000;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 1.4;
    font-weight: 400;
    padding-top: 17px;
    padding-bottom: 13px; }
    @media (max-width: 479px) {
      .l-copyright p {
        padding: 0;
        line-height: 16px; } }
    .l-copyright p.site {
      width: 49.20456%;
      float: left;
      margin-right: 1.59088%; }
      @media (max-width: 479px) {
        .l-copyright p.site {
          text-align: center;
          width: 100%;
          float: left;
          margin-left: 0;
          margin-right: 0; } }
    .l-copyright p.gim {
      padding-right: 33px;
      width: 49.20456%;
      float: right;
      margin-right: 0;
      text-align: right; }
      @media (max-width: 479px) {
        .l-copyright p.gim {
          text-align: center;
          width: 100%;
          float: left;
          margin-left: 0;
          margin-right: 0; } }
  .l-copyright a {
    text-decoration: inherit;
    color: inherit;
    transition: color 0.25s ease; }
    .l-copyright a:hover {
      color: #064599; }

.l-top_region {
  background-color: #0a4396;
  position: relative;
  z-index: 9999; }

.l-region--top-region {
  max-width: 100%;
  padding: 0 49px;
  text-align: right; }
  @media (max-width: 600px) {
    .l-region--top-region {
      padding: 0 10px; } }
  @media (max-width: 479px) {
    .l-region--top-region {
      line-height: 17px;
      padding-bottom: 40px; } }

#block-block-42 {
  float: left;
  margin-top: 9px; }
  #block-block-42 .social {
    margin: 0; }
    #block-block-42 .social li {
      display: inline-block;
      vertical-align: bottom;
      margin-right: 6px;
      margin-bottom: 0px; }
      #block-block-42 .social li.facebook a:before {
        content: '\f09a';
        background-color: #0a4396; }
      #block-block-42 .social li.twitter a:before {
        content: '\f099';
        background-color: #0a4396; }
      #block-block-42 .social li.google-plus a:before {
        content: '\f0d5';
        background-color: #0a4396; }
      #block-block-42 .social li.linkedin a:before {
        content: '\f0e1';
        background-color: #0a4396; }
      #block-block-42 .social li.youtube a:before {
        content: '\f167';
        background-color: #0a4396; }
      #block-block-42 .social li.instagram a:before {
        content: '\f16d';
        background-color: #0a4396; }
    #block-block-42 .social a {
      border-radius: 50%;
      border: 1px solid #ffec00;
      transition: bottom 0.25s ease;
      font-size: 0;
      color: transparent;
      display: inline-block;
      width: 28px;
      height: 28px;
      position: relative;
      bottom: 0;
      vertical-align: bottom; }
      #block-block-42 .social a:before {
        font-family: 'FontAwesome';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        font-size: 16px;
        line-height: 28px;
        color: #ffec00;
        border-radius: 50%;
        text-align: center; }
      #block-block-42 .social a:hover {
        bottom: 5px; }

.bubble {
  padding-top: 8px;
  padding-bottom: 8px; }
  @media (max-width: 479px) {
    .bubble {
      margin-top: 3px;
      margin-bottom: 4px; } }
  .bubble .block__content {
    display: inline-block;
    vertical-align: bottom; }
    .bubble .block__content a {
      text-decoration: none;
      color: white;
      font-size: 14px;
      font-weight: 400;
      line-height: 27px;
      vertical-align: bottom; }
      @media (max-width: 479px) {
        .bubble .block__content a {
          line-height: 17px; } }
  .bubble:before {
    content: '';
    display: inline-block;
    vertical-align: bottom;
    width: 24px;
    height: 27px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat; }
    @media (max-width: 479px) {
      .bubble:before {
        width: 17px;
        height: 17px; } }

#block-block-16.phone {
  padding-right: 20px;
  margin-right: 8px; }
  @media (max-width: 600px) {
    #block-block-16.phone {
      padding-right: 9px;
      margin-right: 3px; } }
  #block-block-16.phone .block__content a {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 1px; }
    @media (max-width: 600px) {
      #block-block-16.phone .block__content a {
        font-size: 14px; } }
  #block-block-16.phone:before {
    margin-right: 10px; }
    @media (max-width: 600px) {
      #block-block-16.phone:before {
        margin-right: 5px; } }
  @media (max-width: 479px) {
    #block-block-16.phone {
      padding-right: 8px;
      margin-right: 5px;
      position: absolute;
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
      max-width: 300px;
      top: 40px;
      border-right: none; }
      #block-block-16.phone .block__content {
        margin-bottom: 4px; }
        #block-block-16.phone .block__content a {
          font-size: 0.8em; } }

#block-block-17.help {
  padding-right: 13px;
  margin-right: 11px; }
  @media (max-width: 600px) {
    #block-block-17.help {
      padding-right: 8px;
      margin-right: 3px; } }
  #block-block-17.help:before {
    margin-right: 4px; }
    @media (max-width: 600px) {
      #block-block-17.help:before {
        margin-right: 2px; } }
  @media (max-width: 479px) {
    #block-block-17.help {
      padding-right: 8px;
      margin-right: 5px; }
      #block-block-17.help .block__content a {
        font-size: 0.8em; } }

#block-lang-dropdown-language {
  display: none; }
  #block-lang-dropdown-language:before {
    margin-right: 6px; }
    @media (max-width: 600px) {
      #block-lang-dropdown-language:before {
        margin-right: 3px; } }

#lang_dropdown_form_language .form-item-lang-dropdown-select {
  margin-bottom: 0; }

#lang_dropdown_form_language .dd-container {
  width: unset !important; }

#lang_dropdown_form_language .dd-select {
  border: none;
  border-radius: 0;
  background-color: unset !important;
  width: unset !important; }

#lang_dropdown_form_language a.dd-selected {
  padding: 0;
  text-align: left;
  font-size: 18px;
  font-weight: 700;
  color: #ffec00;
  line-height: 27px;
  margin-right: 4px; }
  @media (max-width: 479px) {
    #lang_dropdown_form_language a.dd-selected {
      line-height: 17px;
      font-size: 12px; } }

#lang_dropdown_form_language .dd-pointer {
  display: none; }

#lang_dropdown_form_language ul.dd-options {
  width: 50px !important;
  right: -11px;
  top: calc(100% + 10px); }
  #lang_dropdown_form_language ul.dd-options li a {
    padding-bottom: 2px;
    padding-top: 2px;
    color: #1f5aa9;
    text-align: center; }
    #lang_dropdown_form_language ul.dd-options li a label {
      cursor: pointer; }

header.l-header {
  position: sticky;
  top: 0px;
  z-index: 9998;
  box-shadow: -10px 0 10px rgba(0, 0, 0, 0.75);
  background-color: #1f5aa9; }

.header-container {
  max-width: 1327px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1832px;
  display: block;
  position: relative; }
  @media (max-width: 1862px) {
    .header-container {
      padding-left: 15px;
      padding-right: 15px; } }
  .header-container:after {
    content: " ";
    display: block;
    clear: both; }

.l-branding {
  margin-right: 14.6%;
  float: left;
  padding: 15px 16px 15px 40px; }
  .l-branding:after {
    content: "";
    display: block;
    max-width: 280px;
    width: 100%; }
  @media (max-width: 1620px) {
    .l-branding {
      margin-right: 0; } }
  @media (min-width: 1024px) and (max-width: 1223px) {
    .l-branding {
      margin-right: 0;
      text-align: center;
      padding-left: 0;
      padding-right: 0;
      float: left; } }
  @media (min-width: 1023px) and (max-width: 1100px) {
    .l-branding .l-logo a img {
      max-width: 370px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .l-branding {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0;
      margin-right: 0;
      text-align: center; }
      .l-branding .l-logo {
        display: inline-block; } }
  @media (max-width: 767px) {
    .l-branding {
      width: 74.39864%;
      float: left;
      margin-right: 2.40546%; } }
  @media (max-width: 414px) {
    .l-branding {
      padding-left: 0; } }

.site-logo {
  margin-top: 17px;
  margin-bottom: 17px; }
  .site-logo img {
    display: block; }
  @media (min-width: 768px) and (max-width: 1223px) {
    .site-logo {
      margin-top: 10px;
      margin-bottom: 10px; } }
  @media (max-width: 767px) {
    .site-logo {
      margin-top: 10px;
      margin-bottom: 10px; }
      .site-logo img {
        max-width: 225px;
        height: auto; } }

.l-region--header {
  display: block;
  text-align: center; }
  @media (min-width: 1024px) and (max-width: 1223px) {
    .l-region--header {
      width: 100%;
      float: right;
      margin-right: 0;
      max-width: 500px;
      margin: 0 auto;
      margin-top: 37px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .l-region--header {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0;
      margin: 0 auto;
      float: unset;
      max-width: 600px; } }
  @media (max-width: 767px) {
    .l-region--header {
      width: 23.19591%;
      float: right;
      margin-right: 0; } }

body .ui-widget-content .ui-state-highlight,
body .ui-widget-header .ui-state-highlight {
  border: 0 none;
  background-image: none; }

body .jGrowl.center {
  top: 29px;
  left: 50%;
  width: auto;
  transform: translate(-50%, 0);
  margin: 0; }

body #jGrowl .jGrowl-notification {
  background-position: 4px center;
  background-repeat: no-repeat;
  padding-left: 24px;
  padding-right: 4px;
  width: 480px;
  font-size: 14px;
  line-height: 18px; }
  @media (max-width: 479px) {
    body #jGrowl .jGrowl-notification {
      width: 300px; } }
  body #jGrowl .jGrowl-notification.status {
    color: darkgreen;
    background-color: lightgreen;
    border: 1px solid darkgreen; }
  body #jGrowl .jGrowl-notification.warning {
    color: darkorange;
    background-color: lightyellow;
    border: 1px solid darkorange; }
  body #jGrowl .jGrowl-notification.error {
    color: darkred;
    background-color: #ffdfe4;
    border: 1px solid darkred; }
  body #jGrowl .jGrowl-notification a {
    color: inherit;
    border-bottom: 1px dotted white; }
  body #jGrowl .jGrowl-notification .jGrowl-message {
    word-wrap: break-word;
    padding-right: 34px; }
  body #jGrowl .jGrowl-notification .jGrowl-close {
    font-size: 1.2em; }

body #jGrowl .jGrowl-closer {
  padding-right: 24px;
  padding-left: 24px;
  background-image: none; }

#cboxOverlay {
  background-color: black;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  transition: opacity 0.5s ease; }

#colorbox {
  z-index: 10001;
  overflow: visible !important;
  outline: none; }
  #colorbox #cboxWrapper div #cboxContent {
    position: relative; }
    #colorbox #cboxWrapper div #cboxContent #cboxCurrent {
      width: 100%;
      max-width: 100%;
      text-align: center;
      font-size: 30px;
      color: white;
      font-weight: 700;
      margin-top: 16px;
      position: absolute;
      bottom: 0;
      transform: translate(0, 100%);
      left: 0;
      right: 0; }
    #colorbox #cboxWrapper div #cboxContent #cboxPrevious {
      position: absolute;
      transform: translate(0, -50%);
      top: 50%;
      left: -40px;
      display: block;
      width: 25px;
      height: 60px;
      border: none;
      background: none;
      font-size: 0;
      cursor: pointer; }
      #colorbox #cboxWrapper div #cboxContent #cboxPrevious:before {
        content: '\f104';
        left: -10px;
        font-family: FontAwesome;
        position: absolute;
        top: 0;
        bottom: 0;
        color: white;
        font-size: 50px;
        line-height: 60px;
        display: inline-block;
        transform: translate(50%, 0);
        cursor: pointer;
        transition: color 0.25s ease; }
      #colorbox #cboxWrapper div #cboxContent #cboxPrevious:hover:before {
        color: #064599; }
    #colorbox #cboxWrapper div #cboxContent #cboxNext {
      position: absolute;
      transform: translate(0, -50%);
      top: 50%;
      right: -40px;
      display: block;
      width: 25px;
      height: 60px;
      border: none;
      background: none;
      font-size: 0;
      cursor: pointer; }
      #colorbox #cboxWrapper div #cboxContent #cboxNext:before {
        content: '\f105';
        font-family: FontAwesome;
        position: absolute;
        top: 0;
        right: 10px;
        bottom: 0;
        color: white;
        font-size: 50px;
        line-height: 60px;
        display: inline-block;
        transform: translate(50%, 0);
        cursor: pointer;
        transition: color 0.25s ease; }
      #colorbox #cboxWrapper div #cboxContent #cboxNext:hover:before {
        color: #064599; }
  @media (max-width: 767px) {
    #colorbox #cboxWrapper div #cboxContent #cboxCurrent {
      bottom: -45px;
      font-size: 24px; }
    #colorbox #cboxWrapper div #cboxContent #cboxPrevious {
      top: unset;
      left: calc(50% - 75px);
      bottom: -118px; }
      #colorbox #cboxWrapper div #cboxContent #cboxPrevious:before {
        top: unset;
        font-size: 40px; }
    #colorbox #cboxWrapper div #cboxContent #cboxNext {
      top: unset;
      bottom: -118px;
      right: calc(50% - 75px); }
      #colorbox #cboxWrapper div #cboxContent #cboxNext:before {
        top: unset;
        font-size: 40px; } }
  #colorbox h2.node__title {
    display: none; }
  #colorbox article {
    padding: 15px;
    background: white; }
    @media (min-width: 775px) {
      #colorbox article .node__content .field__item h2:first-child {
        position: sticky;
        left: 0;
        top: 0;
        right: 0;
        background-color: #064599;
        color: white;
        line-height: 32px;
        font-size: 26px;
        text-align: center;
        padding: 8px 16px;
        margin: -15px;
        margin-bottom: 15px;
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.5); } }

#cboxLoadedContent {
  margin-bottom: 0; }
  #cboxLoadedContent img {
    vertical-align: bottom;
    display: block; }

.daterangepicker.show-calendar {
  background-color: #064599;
  border: 1px solid #064599; }
  .daterangepicker.show-calendar:before {
    border-bottom: 7px solid #064599; }
  .daterangepicker.show-calendar:after {
    border-bottom: 6px solid #064599; }
  .daterangepicker.show-calendar .drp-calendar {
    padding-top: 0;
    padding-left: 0; }
    .daterangepicker.show-calendar .drp-calendar .calendar-table {
      padding-right: 0; }
      .daterangepicker.show-calendar .drp-calendar .calendar-table .table-condensed {
        background-color: #064599; }
        .daterangepicker.show-calendar .drp-calendar .calendar-table .table-condensed thead tr:first-child th {
          padding-top: 10px;
          padding-bottom: 5px; }
        .daterangepicker.show-calendar .drp-calendar .calendar-table .table-condensed thead tr th {
          background-color: #064599;
          color: #fff;
          border-radius: 0; }
          .daterangepicker.show-calendar .drp-calendar .calendar-table .table-condensed thead tr th select {
            padding: 3px; }
          .daterangepicker.show-calendar .drp-calendar .calendar-table .table-condensed thead tr th span {
            border-color: #fff; }
        .daterangepicker.show-calendar .drp-calendar .calendar-table .table-condensed tbody tr td:not(.active) {
          background-color: #fff;
          border-radius: 0; }

#cboxClose {
  position: absolute;
  top: -40px;
  right: -40px;
  font-size: 0;
  color: transparent;
  background: none;
  border: none;
  width: 32px;
  height: 32px;
  cursor: pointer; }
  @media (max-width: 775px) {
    #cboxClose {
      top: unset;
      right: 50%;
      bottom: -40px;
      height: unset;
      text-transform: uppercase;
      font-weight: 900;
      line-height: 40px;
      padding: 0 15px;
      font-size: 18px;
      color: white;
      transform: translate(50%, 0);
      width: 100%;
      background-color: rgba(0, 0, 0, 0.75);
      border-radius: 0;
      transition: background-color 0.25s ease; }
      #cboxClose:hover {
        background-color: black; } }
  #cboxClose:before {
    content: '\f00d';
    font-family: 'FontAwesome';
    color: white;
    font-size: 28px;
    line-height: 32px;
    text-align: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    transition: color 0.25s ease; }
    @media (max-width: 775px) {
      #cboxClose:before {
        content: unset; } }
  #cboxClose:hover:before {
    color: #064599; }

.l-region--highlighted {
  position: relative; }

#block-menu-menu-explore-crete-menu {
  float: left;
  width: 100%;
  text-align: center;
  background: #ffec00;
  z-index: 998; }
  #block-menu-menu-explore-crete-menu ul {
    display: inline-block;
    margin: 0;
    padding: 0;
    background: #ffec00;
    vertical-align: top; }
    #block-menu-menu-explore-crete-menu ul li {
      list-style: none;
      position: relative;
      padding-left: 10px;
      padding-right: 10px; }
      #block-menu-menu-explore-crete-menu ul li a {
        padding-left: 10px;
        padding-right: 10px;
        line-height: 38px;
        padding-top: 4px;
        padding-bottom: 4px;
        color: #064599;
        display: inline-block;
        width: 100%; }
      #block-menu-menu-explore-crete-menu ul li:last-child a:after {
        display: none; }
      #block-menu-menu-explore-crete-menu ul li ul {
        position: absolute; }
    @media (min-width: 1024px) {
      #block-menu-menu-explore-crete-menu ul li:hover > ul {
        display: block; } }
  #block-menu-menu-explore-crete-menu > ul {
    text-align: center; }
    #block-menu-menu-explore-crete-menu > ul > li {
      display: inline-block; }
      #block-menu-menu-explore-crete-menu > ul > li > a {
        position: relative; }
        #block-menu-menu-explore-crete-menu > ul > li > a:after {
          content: '';
          position: absolute;
          right: -10px;
          width: 1px;
          top: 4px;
          bottom: 3px;
          background: #064599; }
      #block-menu-menu-explore-crete-menu > ul > li ul {
        width: 170px;
        display: none;
        background: #ffffff;
        border: 1px solid #064599; }
        #block-menu-menu-explore-crete-menu > ul > li ul li {
          padding-left: 0px;
          padding-right: 0px; }
          #block-menu-menu-explore-crete-menu > ul > li ul li.expanded > a {
            position: relative;
            padding-right: 20px; }
            #block-menu-menu-explore-crete-menu > ul > li ul li.expanded > a:after {
              content: '\f105';
              display: block;
              font-family: "FontAwesome";
              text-shadow: none;
              line-height: 14px;
              font-size: 18px;
              color: #064599;
              position: absolute;
              top: 50%;
              right: 10px;
              transform: translateY(-50%); }
          #block-menu-menu-explore-crete-menu > ul > li ul li a {
            color: #064599; }
            #block-menu-menu-explore-crete-menu > ul > li ul li a:after {
              display: none; }
          #block-menu-menu-explore-crete-menu > ul > li ul li ul {
            top: 0;
            left: 100%; }
      #block-menu-menu-explore-crete-menu > ul > li > ul {
        top: 100%;
        left: 0; }
  @media (max-width: 1023px) {
    #block-menu-menu-explore-crete-menu {
      display: inline-block;
      width: 50px;
      height: 50px;
      background: #3d7bcc;
      position: relative;
      cursor: pointer;
      top: 15px;
      border-radius: 5px;
      margin-bottom: 20px;
      margin-top: 10px; }
      #block-menu-menu-explore-crete-menu:after {
        content: '';
        position: absolute;
        width: 70%;
        top: 12px;
        left: 15%;
        height: 5px;
        background: #FFF;
        box-shadow: 0px 10px 0px 0px #FFF, 0px 20px 0px 0px #FFF; }
      #block-menu-menu-explore-crete-menu:before {
        content: 'Please Select';
        position: absolute;
        width: 150px;
        top: 12px;
        left: 100%;
        text-align: left;
        color: #064599;
        font-size: 24px;
        padding-left: 30px; }
      #block-menu-menu-explore-crete-menu .ec_arrow {
        position: absolute;
        left: calc(100% + 7px);
        display: inline-block;
        width: 15px;
        bottom: 0;
        top: 0; }
        #block-menu-menu-explore-crete-menu .ec_arrow:after {
          position: absolute;
          content: '\f104';
          font-family: "FontAwesome";
          text-shadow: none;
          line-height: 50px;
          font-size: 35px;
          opacity: 1;
          color: #3d7bcc;
          top: 0px;
          right: 4%; }
      #block-menu-menu-explore-crete-menu > ul {
        display: none;
        position: absolute;
        top: 50px;
        left: 0;
        width: 220px;
        margin-top: 10px; }
        #block-menu-menu-explore-crete-menu > ul li {
          padding: 0;
          width: 100%; }
          #block-menu-menu-explore-crete-menu > ul li a {
            width: 100%;
            text-align: left; }
            #block-menu-menu-explore-crete-menu > ul li a:after {
              background: none; }
          #block-menu-menu-explore-crete-menu > ul li.expanded > a {
            position: relative;
            padding-right: 20px; }
            #block-menu-menu-explore-crete-menu > ul li.expanded > a:after {
              content: '\f105';
              display: block;
              font-family: "FontAwesome";
              text-shadow: none;
              line-height: 14px;
              font-size: 18px;
              color: #064599;
              position: absolute;
              top: 50%;
              right: 10px;
              transform: translateY(-50%);
              background: none; }
          #block-menu-menu-explore-crete-menu > ul li.expanded ul {
            position: relative;
            width: 100%;
            left: 0; }
            #block-menu-menu-explore-crete-menu > ul li.expanded ul > li > ul > li a {
              padding-right: 20px; } }

@media (max-width: 520px) {
  body.page-node-401 .l-region--highlighted {
    width: 100%;
    float: left; }
    body.page-node-401 .l-region--highlighted #block-menu-menu-explore-crete-menu ul li a {
      line-height: 20px; }
      body.page-node-401 .l-region--highlighted #block-menu-menu-explore-crete-menu ul li a:after {
        display: none; }
    body.page-node-401 .l-region--highlighted #block-menu-menu-explore-crete-menu ul li ul {
      position: relative;
      background-color: #064599;
      width: 100%;
      left: 0; }
      body.page-node-401 .l-region--highlighted #block-menu-menu-explore-crete-menu ul li ul li a {
        color: #fff; }
      body.page-node-401 .l-region--highlighted #block-menu-menu-explore-crete-menu ul li ul li > ul li {
        display: contents; }
        body.page-node-401 .l-region--highlighted #block-menu-menu-explore-crete-menu ul li ul li > ul li a {
          font-size: 12px; }
    body.page-node-401 .l-region--highlighted #block-menu-menu-explore-crete-menu > ul > li {
      margin-bottom: 10px; }
  body.page-node-401 .node--page--full {
    width: 100%;
    float: right; }
    body.page-node-401 .node--page--full .node__content {
      margin-top: 0; } }

#block-views-gallery-with-text-block {
  overflow: hidden;
  margin-top: 64px; }
  #block-views-gallery-with-text-block .block__content {
    padding-top: 0; }

.gallery-with-text-item {
  width: 22.88672%;
  float: left;
  margin-right: 2.81771%;
  text-align: center;
  height: 764px;
  margin-bottom: 2.81771%; }
  @media (max-width: 1023px) {
    .gallery-with-text-item {
      width: 49.20456%;
      float: left;
      margin-right: 1.59088%; } }
  @media (max-width: 767px) {
    .gallery-with-text-item {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0;
      height: unset; } }
  .gallery-with-text-item:nth-child(4n+4) {
    width: 22.88672%;
    float: right;
    margin-right: 0; }
    @media (max-width: 1023px) {
      .gallery-with-text-item:nth-child(4n+4) {
        width: 49.20456%;
        float: left;
        margin-right: 1.59088%; } }
    @media (max-width: 767px) {
      .gallery-with-text-item:nth-child(4n+4) {
        width: 100%;
        float: left;
        margin-left: 0;
        margin-right: 0; } }
  @media (max-width: 1023px) {
    .gallery-with-text-item:nth-child(2n+2) {
      width: 49.20456%;
      float: right;
      margin-right: 0; } }
  @media (max-width: 767px) {
    .gallery-with-text-item:nth-child(2n+2) {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0; } }
  .gallery-with-text-item img {
    max-width: 100%;
    height: auto; }

.gallery-title {
  font-weight: 300;
  color: #064599;
  text-align: left;
  font-size: 24px;
  margin: 32px 0 20px;
  line-height: 1;
  min-height: 48px; }

.gallery-text {
  text-align: left;
  font-weight: 300; }

/* mobile menu */
button.hamburger.gim_mmenu {
  float: right;
  display: inline-block;
  -webkit-appearance: none;
  border: none;
  background: none;
  background: #064599;
  width: 42px;
  height: 30px;
  cursor: pointer;
  position: relative;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px; }
  button.hamburger.gim_mmenu span.hamburger-box {
    position: absolute;
    display: inline-block;
    width: 100%;
    max-width: 34px;
    height: 2px;
    background: #FFFFFF;
    top: 14px;
    left: 4px;
    -webkit-transition: all 0.5s linear;
    -moz-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    transition: all 0.5s linear; }
    button.hamburger.gim_mmenu span.hamburger-box:before, button.hamburger.gim_mmenu span.hamburger-box:after {
      content: '';
      position: absolute;
      display: inline-block;
      width: 100%;
      max-width: 34px;
      height: 2px;
      background: #FFFFFF;
      left: 0;
      -webkit-transition: all 0.5s linear;
      -moz-transition: all 0.5s linear;
      -o-transition: all 0.5s linear;
      transition: all 0.5s linear; }
    button.hamburger.gim_mmenu span.hamburger-box:before {
      top: -8px; }
    button.hamburger.gim_mmenu span.hamburger-box:after {
      top: 8px; }
  button.hamburger.gim_mmenu.is-active {
    -webkit-transition: all 0.5s linear;
    -moz-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    transition: all 0.5s linear; }
    button.hamburger.gim_mmenu.is-active span.hamburger-box {
      -ms-transform: rotate(-390deg);
      /* IE 9 */
      -webkit-transform: rotate(-390deg);
      /* Chrome, Safari, Opera */
      transform: rotate(-390deg);
      left: 4px;
      top: 14px; }
      button.hamburger.gim_mmenu.is-active span.hamburger-box:before {
        visibility: hidden;
        opacity: 0;
        -webkit-transition: all 0.5s linear;
        -moz-transition: all 0.5s linear;
        -o-transition: all 0.5s linear;
        transition: all 0.5s linear; }
      button.hamburger.gim_mmenu.is-active span.hamburger-box:after {
        left: 0;
        top: 0;
        -webkit-transition: all 0.5s linear;
        -moz-transition: all 0.5s linear;
        -o-transition: all 0.5s linear;
        transition: all 0.5s linear;
        -ms-transform: rotate(420deg);
        /* IE 9 */
        -webkit-transform: rotate(420deg);
        /* Chrome, Safari, Opera */
        transform: rotate(420deg); }
  @media (min-width: 768px) {
    button.hamburger.gim_mmenu {
      display: none; } }
  @media (max-width: 767px) {
    button.hamburger.gim_mmenu {
      margin-right: 10px;
      margin-top: 21px; } }

#menu {
  z-index: 99;
  background: #064599;
  color: white; }
  #menu .mm-panels {
    bottom: 40px; }
    #menu .mm-panels #mm-1 {
      padding-top: 110px; }
      #menu .mm-panels #mm-1 .mm-navbar {
        height: 110px;
        padding: 0; }
        #menu .mm-panels #mm-1 .mm-navbar .mm-navbar__title {
          color: transparent;
          display: inline-block;
          width: 100%;
          padding: 0;
          vertical-align: top; }
          #menu .mm-panels #mm-1 .mm-navbar .mm-navbar__title img {
            display: block;
            width: 100%;
            max-width: 200px;
            float: none;
            margin: 0 auto;
            vertical-align: top; }
      #menu .mm-panels #mm-1 .mm-listview .mm-listitem:after {
        background: #FFFFFF; }
  #menu .mm-navbar-social {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    height: 40px;
    border-top: 1px solid #dadada; }
    #menu .mm-navbar-social a {
      width: 25%;
      float: left;
      text-align: center;
      font-size: 20px;
      line-height: 40px;
      color: #FFFFFF; }

/* desktop menu */
#block-system-main-menu {
  float: left; }
  @media (max-width: 1620px) {
    #block-system-main-menu {
      float: right; } }
  #block-system-main-menu > .menu {
    padding: 0;
    margin-top: 37px; }
    #block-system-main-menu > .menu > li {
      display: inline-block; }
      @media (min-width: 1385px) {
        #block-system-main-menu > .menu > li {
          padding-left: 18px;
          padding-right: 10px; }
          #block-system-main-menu > .menu > li:first-child {
            padding-right: 0;
            padding-left: 0; }
          #block-system-main-menu > .menu > li:last-child {
            padding-right: 0; } }
      #block-system-main-menu > .menu > li > a,
      #block-system-main-menu > .menu > li > span {
        cursor: pointer;
        color: #ffffff;
        font-size: 18px;
        font-weight: 400;
        line-height: 2.25;
        padding-left: 11px;
        padding-right: 11px;
        transition: color 0.25s ease; }
        @media (max-width: 1250px) {
          #block-system-main-menu > .menu > li > a,
          #block-system-main-menu > .menu > li > span {
            font-size: 17px; } }
        @media (min-width: 1024px) and (max-width: 1223px) {
          #block-system-main-menu > .menu > li > a,
          #block-system-main-menu > .menu > li > span {
            font-size: 16px; } }
      #block-system-main-menu > .menu > li > a:hover, #block-system-main-menu > .menu > li .nolink:hover {
        color: #ffec00; }
      #block-system-main-menu > .menu > li.active > a,
      #block-system-main-menu > .menu > li.active > span, #block-system-main-menu > .menu > li.active-trail > a,
      #block-system-main-menu > .menu > li.active-trail > span {
        color: #ffec00; }
      #block-system-main-menu > .menu > li.first > a {
        color: transparent;
        display: inline-block;
        position: relative;
        font-size: 0;
        line-height: 0;
        vertical-align: middle;
        /*          &:before{
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: block;
            background-repeat: no-repeat;
            opacity: 1;
            transition: opacity 0.25s ease;
          }*/ }
        #block-system-main-menu > .menu > li.first > a:after {
          content: '';
          position: absolute;
          height: 100%;
          width: 100%;
          top: -2px;
          left: 0;
          display: block;
          background-repeat: no-repeat;
          transition: opacity 0.25s ease; }
        #block-system-main-menu > .menu > li.first > a.active:after, #block-system-main-menu > .menu > li.first > a:hover:after {
          opacity: 1; }
      #block-system-main-menu > .menu > li.last a {
        padding-right: 0; }
      #block-system-main-menu > .menu > li.expanded {
        position: relative; }
        #block-system-main-menu > .menu > li.expanded > ul {
          visibility: hidden;
          opacity: 0;
          position: absolute; }
        #block-system-main-menu > .menu > li.expanded:hover > ul {
          visibility: visible;
          opacity: 1;
          position: absolute;
          left: 0;
          top: calc(100% - 10px);
          width: 220px;
          padding: 25px 0 0; }
          #block-system-main-menu > .menu > li.expanded:hover > ul:before {
            content: '';
            border-bottom: 10px solid white;
            border-right: 10px solid transparent;
            border-left: 10px solid transparent;
            border-top: none;
            top: 16px;
            left: 40px;
            position: absolute; }
          #block-system-main-menu > .menu > li.expanded:hover > ul > li {
            background: #FFFFFF;
            display: inline-block;
            width: 100%;
            border-bottom: 1px solid #cdcdcd;
            border-left: 1px solid #cdcdcd;
            border-right: 1px solid #cdcdcd; }
            #block-system-main-menu > .menu > li.expanded:hover > ul > li.first {
              border-top: 1px solid #cdcdcd; }
            #block-system-main-menu > .menu > li.expanded:hover > ul > li a {
              color: #000;
              padding: 10px;
              display: block;
              font-size: 16px; }
              #block-system-main-menu > .menu > li.expanded:hover > ul > li a:hover {
                color: #064599; }
  @media (min-width: 768px) and (max-width: 1223px) {
    #block-system-main-menu > .menu {
      float: none;
      margin-top: 0;
      margin-bottom: 10px; }
      #block-system-main-menu > .menu > li > a,
      #block-system-main-menu > .menu > li > span {
        padding-left: 7px;
        padding-right: 7px; } }
  @media (min-width: 1024px) and (max-width: 1100px) {
    #block-system-main-menu > .menu > li > a,
    #block-system-main-menu > .menu > li > span {
      padding-left: 6px;
      padding-right: 6px; } }
  @media (max-width: 767px) {
    #block-system-main-menu {
      display: none; } }

/* front page */
.search_box .block__content {
  padding: 10px 0 20px; }
  .search_box .block__content form > div select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; }
  .search_box .block__content form > div select,
  .search_box .block__content form > div .select-title {
    color: #000006;
    font-size: 16px;
    font-weight: 400;
    border: 0.5px solid rgba(6, 69, 153, 0.25);
    background: white;
    padding: 0 35px 0 15px;
    cursor: pointer;
    text-align: left;
    line-height: 37px;
    display: inline-block;
    height: 39px;
    width: 236px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    position: relative; }
    .search_box .block__content form > div select:after,
    .search_box .block__content form > div .select-title:after {
      content: ''; }
  .search_box .block__content form > div .form-item {
    margin-bottom: 0; }
    .search_box .block__content form > div .form-item.form-item-car-type {
      margin-bottom: 16px; }
    .search_box .block__content form > div .form-item.form-item-gearbox {
      margin-bottom: 30px; }
  .search_box .block__content form > div .block-select-edit-pickup-location .select-title:before {
    content: "\f107";
    position: absolute;
    font-family: "FontAwesome";
    display: inline-block;
    color: #064599;
    width: 20px;
    height: 20px;
    font-size: 26px;
    right: 10px;
    top: 50%;
    transform: translateY(-85%);
    z-index: 88; }
  .search_box .block__content form > div .block-select-edit-pickup-location.open .select-title:before {
    content: "\f106"; }
  .search_box .block__content form > div .form-item:not(.form-item-car-type):not(.form-item-gearbox) {
    display: inline-block;
    margin-right: 2px;
    vertical-align: bottom; }
    .search_box .block__content form > div .form-item:not(.form-item-car-type):not(.form-item-gearbox).form-item-pickup-date, .search_box .block__content form > div .form-item:not(.form-item-car-type):not(.form-item-gearbox).form-item-dropoff-date {
      margin-right: 0; }
    .search_box .block__content form > div .form-item:not(.form-item-car-type):not(.form-item-gearbox) .block-select {
      position: relative;
      height: 39px;
      /* width */
      /* Track */
      /* Handle */
      /* Handle on hover */ }
      .search_box .block__content form > div .form-item:not(.form-item-car-type):not(.form-item-gearbox) .block-select ::-webkit-scrollbar {
        width: 4px; }
      .search_box .block__content form > div .form-item:not(.form-item-car-type):not(.form-item-gearbox) .block-select ::-webkit-scrollbar-track {
        background: transparent; }
      .search_box .block__content form > div .form-item:not(.form-item-car-type):not(.form-item-gearbox) .block-select ::-webkit-scrollbar-thumb {
        background: #064599;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px; }
      .search_box .block__content form > div .form-item:not(.form-item-car-type):not(.form-item-gearbox) .block-select ::-webkit-scrollbar-thumb:hover {
        background: #064599; }
      .search_box .block__content form > div .form-item:not(.form-item-car-type):not(.form-item-gearbox) .block-select .locations-dropdown-container {
        height: 0;
        visibility: hidden;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 99;
        transition: visibility 0.25s ease, opacity 0.25s ease; }
        .search_box .block__content form > div .form-item:not(.form-item-car-type):not(.form-item-gearbox) .block-select .locations-dropdown-container ul {
          border: 1px solid #064599;
          border-bottom: none;
          overflow-x: hidden;
          overflow-y: auto;
          width: 100%;
          padding: 0;
          margin: 0;
          background: white;
          border-radius: 5px 5px 0 0;
          max-height: 242px;
          margin-top: 39px;
          border-top: none; }
          .search_box .block__content form > div .form-item:not(.form-item-car-type):not(.form-item-gearbox) .block-select .locations-dropdown-container ul li {
            list-style: none; }
            .search_box .block__content form > div .form-item:not(.form-item-car-type):not(.form-item-gearbox) .block-select .locations-dropdown-container ul li.location-group-name {
              padding: 8px 5px 10px;
              line-height: 20px;
              color: black;
              letter-spacing: -1px;
              position: relative; }
            .search_box .block__content form > div .form-item:not(.form-item-car-type):not(.form-item-gearbox) .block-select .locations-dropdown-container ul li.hidden {
              display: none; }
            .search_box .block__content form > div .form-item:not(.form-item-car-type):not(.form-item-gearbox) .block-select .locations-dropdown-container ul li input {
              visibility: hidden;
              opacity: 0;
              height: 0;
              position: absolute; }
            .search_box .block__content form > div .form-item:not(.form-item-car-type):not(.form-item-gearbox) .block-select .locations-dropdown-container ul li label {
              padding: 8px 15px 10px;
              display: inline-block;
              width: 100%;
              font-size: 16px;
              font-weight: 300;
              line-height: 20px;
              color: #000006;
              cursor: pointer;
              transition: background-color 0.25s ease, color 0.25s ease; }
              .search_box .block__content form > div .form-item:not(.form-item-car-type):not(.form-item-gearbox) .block-select .locations-dropdown-container ul li label:hover {
                background: #064599;
                color: white; }
            .search_box .block__content form > div .form-item:not(.form-item-car-type):not(.form-item-gearbox) .block-select .locations-dropdown-container ul li input:checked ~ label {
              background: #064599;
              color: white; }
        .search_box .block__content form > div .form-item:not(.form-item-car-type):not(.form-item-gearbox) .block-select .locations-dropdown-container .location-search-container {
          border-radius: 0 0 5px 5px;
          border: 1px solid #064599;
          border-top: none;
          position: relative; }
          .search_box .block__content form > div .form-item:not(.form-item-car-type):not(.form-item-gearbox) .block-select .locations-dropdown-container .location-search-container:before {
            content: '';
            position: absolute;
            top: 2px;
            left: 8px;
            right: 8px;
            display: block;
            height: 1px;
            border-top: 1px solid #064599; }
          .search_box .block__content form > div .form-item:not(.form-item-car-type):not(.form-item-gearbox) .block-select .locations-dropdown-container .location-search-container input {
            border: none;
            border-radius: 0 0 5px 5px;
            padding: 0 14px;
            line-height: 40px;
            max-width: 100%;
            width: 100%;
            font-weight: 300; }
      .search_box .block__content form > div .form-item:not(.form-item-car-type):not(.form-item-gearbox) .block-select.open .locations-dropdown-container {
        height: auto;
        visibility: visible;
        opacity: 1; }
        .search_box .block__content form > div .form-item:not(.form-item-car-type):not(.form-item-gearbox) .block-select.open .locations-dropdown-container ul {
          -webkit-transition: all 0.5s ease;
          -moz-transition: all 0.5s ease;
          -o-transition: all 0.5s ease;
          transition: all 0.5s ease; }
  .search_box .block__content form > div .block-select-edit-dropoff-location .select-title:before {
    content: "\f107";
    position: absolute;
    font-family: "FontAwesome";
    display: inline-block;
    color: #064599;
    width: 20px;
    height: 20px;
    font-size: 26px;
    right: 10px;
    top: 50%;
    transform: translateY(-85%);
    z-index: 88; }
  .search_box .block__content form > div .block-select-edit-dropoff-location.open .select-title:before {
    content: "\f106"; }
  .search_box .block__content form > div .form-item-dropoff-date,
  .search_box .block__content form > div .form-item-pickup-date {
    position: relative; }
    .search_box .block__content form > div .form-item-dropoff-date:before,
    .search_box .block__content form > div .form-item-pickup-date:before {
      content: '';
      display: block;
      left: 10px;
      top: 50%;
      position: absolute;
      transform: translateY(-50%); }
  .search_box .block__content form > div .form-item.form-type-textfield input {
    color: #000006;
    font-size: 16px;
    font-weight: 400;
    border: 0.5px solid rgba(6, 69, 153, 0.25);
    padding: 0 8px 0 41px;
    cursor: pointer !important;
    text-align: left;
    line-height: 37px;
    display: inline-block;
    border-radius: 5px 0 0 5px;
    border-right: none;
    width: 139px; }
  .search_box .block__content form > div .form-item.form-type-textfield.form-item-pickup-time, .search_box .block__content form > div .form-item.form-type-textfield.form-item-dropoff-time {
    position: relative; }
    .search_box .block__content form > div .form-item.form-type-textfield.form-item-pickup-time:before, .search_box .block__content form > div .form-item.form-type-textfield.form-item-dropoff-time:before {
      content: '';
      display: block;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%) scale(0.7); }
    .search_box .block__content form > div .form-item.form-type-textfield.form-item-pickup-time span, .search_box .block__content form > div .form-item.form-type-textfield.form-item-dropoff-time span {
      position: absolute;
      height: 12px;
      width: 45px;
      right: 0;
      cursor: pointer; }
      .search_box .block__content form > div .form-item.form-type-textfield.form-item-pickup-time span:before, .search_box .block__content form > div .form-item.form-type-textfield.form-item-dropoff-time span:before {
        position: absolute;
        top: 3px;
        bottom: 4px;
        font-size: 22px;
        font-family: "FontAwesome";
        color: #FFF;
        line-height: 5px;
        left: 50%;
        transform: translate(-50%, 0); }
      .search_box .block__content form > div .form-item.form-type-textfield.form-item-pickup-time span#gim_drop_up, .search_box .block__content form > div .form-item.form-type-textfield.form-item-dropoff-time span#gim_drop_up {
        top: 0; }
        .search_box .block__content form > div .form-item.form-type-textfield.form-item-pickup-time span#gim_drop_up:before, .search_box .block__content form > div .form-item.form-type-textfield.form-item-dropoff-time span#gim_drop_up:before {
          content: "\f106"; }
      .search_box .block__content form > div .form-item.form-type-textfield.form-item-pickup-time span#gim_drop_down, .search_box .block__content form > div .form-item.form-type-textfield.form-item-dropoff-time span#gim_drop_down {
        bottom: 0;
        left: auto; }
        .search_box .block__content form > div .form-item.form-type-textfield.form-item-pickup-time span#gim_drop_down:before, .search_box .block__content form > div .form-item.form-type-textfield.form-item-dropoff-time span#gim_drop_down:before {
          content: "\f107"; }
    .search_box .block__content form > div .form-item.form-type-textfield.form-item-pickup-time input, .search_box .block__content form > div .form-item.form-type-textfield.form-item-dropoff-time input {
      background: #064599;
      padding: 0 41px 0 8px;
      color: white;
      width: 90px;
      text-align: center;
      border-radius: 0 5px 5px 0;
      border: none;
      line-height: 38px; }
  .search_box .block__content form > div input[type="submit"] {
    appearance: none;
    border: none;
    box-shadow: none;
    background-color: transparent;
    font-size: 18px;
    color: #ffec00;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 39px;
    height: 39px;
    cursor: pointer;
    padding-left: 13px;
    padding-right: 40px; }
  .search_box .block__content form > div .arrows {
    background-color: #064599;
    font-size: 18px;
    line-height: 39px;
    height: 39px;
    cursor: pointer;
    margin-left: 10px;
    border-radius: 5px;
    display: inline-block;
    position: relative; }
    .search_box .block__content form > div .arrows:before, .search_box .block__content form > div .arrows:after {
      content: ">";
      position: absolute;
      display: inline-block;
      width: 12px;
      height: 12px;
      color: #ffec00;
      font-size: 19px;
      top: 0;
      z-index: 999;
      font-weight: 700; }
    .search_box .block__content form > div .arrows:before {
      right: 6px; }
    .search_box .block__content form > div .arrows:after {
      right: 19px; }

.search_box#block-rent-a-car-rent-a-car-search-sidebar {
  position: relative;
  margin-bottom: 14px;
  background-color: #064599; }
  @media (min-width: 479px) and (max-width: 767px) {
    .search_box#block-rent-a-car-rent-a-car-search-sidebar {
      text-align: center; } }
  @media (max-width: 479px) {
    .search_box#block-rent-a-car-rent-a-car-search-sidebar {
      text-align: center;
      margin-bottom: 0; } }
  .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content {
    display: inline-block;
    width: 100%;
    color: white;
    position: relative;
    padding: 40px 17px 0;
    z-index: 6; }
    @media (max-width: 1223px) {
      .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content {
        padding: 27px 20px 0; } }
    @media (min-width: 479px) and (max-width: 767px) {
      .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content {
        max-width: 320px;
        margin-left: auto;
        margin-right: auto; } }
    @media (max-width: 479px) {
      .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content {
        max-width: 320px;
        margin: 0; } }
    .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content:after {
      content: '';
      position: absolute;
      border-left: 41px solid transparent;
      border-right: 41px solid transparent;
      border-top: 20px solid #064599;
      left: 50%;
      bottom: 0px;
      -webkit-transform: translate(-50%, 100%);
      -moz-transform: translate(-50%, 100%);
      -ms-transform: translate(-50%, 100%);
      -o-transform: translate(-50%, 100%);
      transform: translate(-50%, 100%); }
      @media (max-width: 479px) {
        .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content:after {
          content: unset; } }
    .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div {
      text-align: center;
      margin-bottom: 30px;
      margin-top: 15px; }
      @media (max-width: 1223px) {
        .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .form-item:first-child {
          margin-top: 5px; } }
      .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .form-item.form-type-select {
        width: 100%;
        float: left;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 16px; }
      .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .form-item .form-select,
      .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .form-item .block-select .select-title {
        width: 100%;
        float: left;
        margin-left: 0;
        margin-right: 0;
        border: none;
        height: 40px; }
        @media (max-width: 1223px) {
          .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .form-item .form-select,
          .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .form-item .block-select .select-title {
            font-size: 14px; } }
      .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .form-item.form-item-pickup-date, .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .form-item.form-item-dropoff-date {
        width: calc(100% - 90px);
        display: inline-block;
        float: left;
        margin: 0 0 10px; }
        @media (max-width: 1223px) {
          .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .form-item.form-item-pickup-date, .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .form-item.form-item-dropoff-date {
            width: calc(100% - 73px); } }
        .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .form-item.form-item-pickup-date input, .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .form-item.form-item-dropoff-date input {
          width: 100%;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-color: white; }
          @media (max-width: 1223px) {
            .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .form-item.form-item-pickup-date input, .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .form-item.form-item-dropoff-date input {
              font-size: 14px;
              background-position: 4px center;
              background-size: auto 50%;
              padding-left: 28px; } }
      .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .form-item.form-item-pickup-time, .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .form-item.form-item-dropoff-time {
        display: inline-block;
        float: left;
        margin: 0 0 10px; }
        @media (max-width: 1223px) {
          .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .form-item.form-item-pickup-time input, .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .form-item.form-item-dropoff-time input {
            font-size: 14px;
            background-size: auto 50%;
            background-position: calc(100% - 4px) center;
            padding-right: 28px;
            width: 73px; } }
      .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .form-item.form-item-old-enough {
        background: white;
        padding: 5px 15px;
        display: inline-block;
        width: 100%;
        position: relative;
        margin-bottom: 13px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px; }
        .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .form-item.form-item-old-enough label {
          color: #010101;
          font-size: 16px;
          font-weight: 300;
          line-height: 32px;
          display: inline-block;
          float: left;
          cursor: pointer; }
          @media (max-width: 1223px) {
            .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .form-item.form-item-old-enough label {
              font-size: 14px; } }
          .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .form-item.form-item-old-enough label .form-required {
            display: none; }
        .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .form-item.form-item-old-enough label:before {
          content: '';
          position: absolute;
          top: 13px;
          right: 13px;
          border: 1px solid #064599;
          background: white;
          width: 17px;
          height: 17px; }
        .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .form-item.form-item-old-enough label:after {
          display: none; }
        .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .form-item.form-item-old-enough input:checked ~ label:before {
          content: '';
          position: absolute;
          top: 13px;
          right: 13px;
          border: 1px solid #064599;
          background: white;
          width: 17px;
          height: 17px; }
        .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .form-item.form-item-old-enough input:checked ~ label:after {
          content: '';
          display: block;
          position: absolute;
          top: 14px;
          right: 18px;
          border-right: 1px solid #064599;
          border-bottom: 1px solid #064599;
          background: none;
          width: 8px;
          height: 11px;
          -ms-transform: rotate(45deg);
          /* IE 9 */
          -webkit-transform: rotate(45deg);
          /* Chrome, Safari, Opera */
          transform: rotate(45deg); }
        .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .form-item.form-item-old-enough input {
          display: none; }
      .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div h4.more-link {
        font-size: 20px;
        color: #ffec00;
        font-weight: 900;
        text-align: center;
        text-transform: uppercase;
        margin: 0 0 15px;
        position: relative; }
        @media (max-width: 1223px) {
          .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div h4.more-link {
            font-size: 14px; } }
        @media (max-width: 640px) {
          .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div h4.more-link {
            background-color: #064599;
            color: white;
            line-height: 20px;
            padding: 3px 25px 2px 10px;
            text-align: left;
            border-radius: 4px;
            cursor: pointer; }
            .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div h4.more-link:after {
              content: '\f106'; } }
        .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div h4.more-link.open:after {
          transform: unset; }
        .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div h4.more-link:after {
          font-family: 'FontAwesome';
          font-size: 32px;
          display: inline-block;
          position: absolute;
          color: inherit;
          top: 0;
          right: 8px;
          bottom: 0;
          transform: rotateX(180deg);
          transition: transform 0.5s ease; }
      .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking {
        text-align: left; }
        @media (max-width: 767px) {
          .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking {
            max-width: 320px;
            margin-left: auto;
            margin-right: auto; } }
        .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .old-enough,
        .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .dropoff-car,
        .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .pickup-car {
          background: white;
          width: 100%;
          margin: 15px auto;
          padding: 15px;
          border-radius: 6px;
          border: 1px solid rgba(6, 69, 153, 0.1); }
          @media (max-width: 1223px) {
            .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .old-enough,
            .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .dropoff-car,
            .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .pickup-car {
              padding: 8px 10px; } }
          .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .old-enough:first-child,
          .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .dropoff-car:first-child,
          .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .pickup-car:first-child {
            margin-top: 0; }
          .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .old-enough h6,
          .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .dropoff-car h6,
          .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .pickup-car h6 {
            margin: 0;
            color: #064599;
            font-size: 16px;
            line-height: 20px; }
            @media (max-width: 1223px) {
              .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .old-enough h6,
              .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .dropoff-car h6,
              .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .pickup-car h6 {
                font-size: 13px; } }
            @media (max-width: 767px) {
              .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .old-enough h6,
              .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .dropoff-car h6,
              .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .pickup-car h6 {
                font-size: 16px; } }
          .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .old-enough .location,
          .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .old-enough .date,
          .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .dropoff-car .location,
          .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .dropoff-car .date,
          .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .pickup-car .location,
          .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .pickup-car .date {
            padding-left: 40px;
            line-height: 30px;
            color: #010101;
            font-size: 16px;
            font-weight: 400;
            position: relative;
            border-color: 1px solid rgba(6, 69, 153, 0.25); }
            @media (max-width: 1223px) {
              .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .old-enough .location,
              .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .old-enough .date,
              .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .dropoff-car .location,
              .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .dropoff-car .date,
              .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .pickup-car .location,
              .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .pickup-car .date {
                font-size: 12px;
                padding-left: 25px; } }
            @media (max-width: 767px) {
              .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .old-enough .location,
              .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .old-enough .date,
              .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .dropoff-car .location,
              .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .dropoff-car .date,
              .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .pickup-car .location,
              .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .pickup-car .date {
                font-size: 16px; } }
            .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .old-enough .location:before,
            .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .old-enough .date:before,
            .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .dropoff-car .location:before,
            .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .dropoff-car .date:before,
            .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .pickup-car .location:before,
            .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .pickup-car .date:before {
              content: '';
              position: absolute;
              top: 5px;
              left: 0;
              bottom: 5px;
              display: block;
              background-repeat: no-repeat; }
          .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .old-enough .location:before,
          .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .dropoff-car .location:before,
          .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .pickup-car .location:before {
            background-size: unset;
            left: 4px; }
          @media (max-width: 1223px) {
            .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .old-enough .date:before,
            .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .dropoff-car .date:before,
            .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .pickup-car .date:before {
              transform: scale(0.8);
              top: 0; } }
        .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .old-enough {
          border-color: 1px solid rgba(6, 69, 153, 0.25);
          color: #010101;
          font-size: 16px;
          font-weight: 400;
          line-height: 32px;
          padding: 0 15px;
          margin-bottom: 30px; }
          @media (max-width: 1223px) {
            .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .old-enough {
              font-size: 12px;
              padding: 0 10px; } }
          @media (max-width: 767px) {
            .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking .old-enough {
              font-size: 16px; } }
        .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking > h4 {
          color: white;
          text-transform: uppercase;
          text-align: center;
          font-weight: 900;
          font-size: 18px;
          margin-top: 35px;
          margin-bottom: 13px; }
          @media (max-width: 1223px) {
            .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .your-booking > h4 {
              margin-top: 30px;
              font-size: 16px; } }
      .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .selected-car {
        text-align: center;
        background-color: white;
        border: 1px solid rgba(6, 69, 153, 0.2);
        border-radius: 6px;
        color: black;
        margin: 0 auto 23px; }
        .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .selected-car .title {
          color: #064599;
          font-size: 20px;
          font-weight: 900;
          line-height: 1.2;
          margin: 24px 20px 5px; }
          @media (max-width: 1223px) {
            .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .selected-car .title {
              font-size: 18px; } }
        .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .selected-car > img {
          max-width: 100%;
          height: auto; }
        .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .selected-car .dates {
          margin-bottom: 10px; }
          .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .selected-car .dates .duration {
            display: block;
            text-transform: uppercase;
            color: #064599;
            font-weight: bold;
            font-size: 28px; }
            @media (max-width: 1223px) {
              .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .selected-car .dates .duration {
                font-size: 22px; } }
          .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .selected-car .dates .dates-selected {
            display: block;
            font-size: 16px; }
            @media (max-width: 1223px) {
              .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .selected-car .dates .dates-selected {
                font-size: 14px; } }
        .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .selected-car .prices {
          margin-bottom: 14px; }
          .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .selected-car .prices .main-price {
            display: block;
            color: #064599;
            font-weight: 700;
            font-size: 46px;
            margin-bottom: 4px; }
            @media (max-width: 1223px) {
              .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .selected-car .prices .main-price {
                font-size: 32px; } }
          .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .selected-car .prices .secondary-prices {
            font-weight: 300; }
        .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .selected-car h6 {
          text-transform: uppercase;
          font-weight: 900;
          font-size: 14px;
          line-height: 18px;
          padding: 11px 0;
          border-width: 1px 0;
          border-style: solid;
          border-color: rgba(0, 0, 0, 0.05);
          margin: 0 0 4px; }
          @media (max-width: 1223px) {
            .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .selected-car h6 {
              font-size: 12px;
              line-height: 16px; } }
        .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .selected-car ul {
          list-style: none;
          padding: 0;
          font-size: 14px; }
          .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .selected-car ul li {
            color: #8f9080;
            font-weight: bold;
            max-width: 210px;
            margin: 0 auto 10px;
            line-height: 1.2;
            font-size: 16px; }
            @media (max-width: 1223px) {
              .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .selected-car ul li {
                font-size: 12px;
                font-weight: normal; } }
      .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .arrows {
        margin: 20px auto 30px; }
        .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .arrows input[type=submit] {
          appearance: none;
          background: #ffec00;
          border: none;
          color: #064599;
          font-size: 20px;
          font-weight: 900;
          line-height: 32px;
          text-transform: uppercase;
          border-radius: 5px;
          display: block;
          transition: background-color 0.1s ease;
          padding-right: 40px;
          padding-left: 16px; }
          @media (max-width: 1223px) {
            .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .arrows input[type=submit] {
              font-size: 16px;
              padding: 11px 40px 10px 16px;
              line-height: 1;
              height: unset; } }
          .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .arrows input[type=submit]:hover {
            background-color: #dacb0b; }
          .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .arrows input[type=submit][disabled] {
            background-color: #c7c9ca; }
        .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .arrows:after, .search_box#block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .arrows:before {
          color: #064599; }
  .search_box#block-rent-a-car-rent-a-car-search-sidebar.cloned_to_article {
    margin-top: 40px; }
    .search_box#block-rent-a-car-rent-a-car-search-sidebar.cloned_to_article .book-title {
      padding-top: 30px; }
      .search_box#block-rent-a-car-rent-a-car-search-sidebar.cloned_to_article .book-title h3 {
        text-align: center;
        color: #FFFFFF;
        margin-bottom: 10px;
        font-size: 25px; }
    .search_box#block-rent-a-car-rent-a-car-search-sidebar.cloned_to_article .block__content {
      max-width: 500px;
      display: block;
      margin: 0 auto;
      padding-bottom: 10px;
      padding-top: 20px; }
      .search_box#block-rent-a-car-rent-a-car-search-sidebar.cloned_to_article .block__content:after {
        display: none; }
      .search_box#block-rent-a-car-rent-a-car-search-sidebar.cloned_to_article .block__content form h4.more-link {
        display: none; }
      .search_box#block-rent-a-car-rent-a-car-search-sidebar.cloned_to_article .block__content form #date-loc-submit {
        text-align: center;
        margin-bottom: 30px;
        margin-top: 15px; }
        .search_box#block-rent-a-car-rent-a-car-search-sidebar.cloned_to_article .block__content form #date-loc-submit .arrows input {
          margin: 0;
          padding-right: 40px;
          padding-left: 16px; }
        .search_box#block-rent-a-car-rent-a-car-search-sidebar.cloned_to_article .block__content form #date-loc-submit .arrows:after, .search_box#block-rent-a-car-rent-a-car-search-sidebar.cloned_to_article .block__content form #date-loc-submit .arrows:before {
          color: #064599; }

.front #car-search-front .block__content .more-link {
  display: none; }

/* inside reservations pages */
#block-rent-a-car-rent-a-car-sidebar-top {
  position: relative;
  z-index: 7; }
  @media (max-width: 767px) {
    #block-rent-a-car-rent-a-car-sidebar-top {
      display: none; } }
  #block-rent-a-car-rent-a-car-sidebar-top .block__content {
    position: relative;
    background: #ffec00;
    color: #064599;
    font-size: 26px;
    font-weight: 300;
    text-align: center;
    padding-top: 20px; }
    @media (max-width: 1223px) {
      #block-rent-a-car-rent-a-car-sidebar-top .block__content {
        font-size: 18px;
        padding-top: 30px; } }
    #block-rent-a-car-rent-a-car-sidebar-top .block__content:after {
      content: '';
      position: absolute;
      border-left: 56px solid transparent;
      border-right: 56px solid transparent;
      border-top: 27px solid #ffec00;
      left: 50%;
      bottom: 0;
      z-index: 9;
      -webkit-transform: translate(-50%, 100%);
      -moz-transform: translate(-50%, 100%);
      -ms-transform: translate(-50%, 100%);
      -o-transform: translate(-50%, 100%);
      transform: translate(-50%, 100%); }
    #block-rent-a-car-rent-a-car-sidebar-top .block__content > span.bolder {
      display: inline-block;
      width: 100%;
      font-size: 30px;
      font-weight: 900;
      color: #064599;
      margin-bottom: 18px; }
      @media (max-width: 1223px) {
        #block-rent-a-car-rent-a-car-sidebar-top .block__content > span.bolder {
          font-size: 22px;
          margin-bottom: 26px;
          margin-top: 3px; } }

/* checkboxes are in front page and also in reservations pages */
.form-type-checkboxes {
  display: block;
  margin: 0; }
  .form-type-checkboxes.form-item-car-type {
    margin-bottom: 15px; }
  .form-type-checkboxes.form-item-gearbox > label {
    margin-right: 10px; }
  .form-type-checkboxes > label {
    color: #000006;
    font-size: 16px;
    font-weight: 700;
    line-height: 23px;
    display: inline;
    margin-right: 38px;
    text-transform: uppercase; }
    @media (max-width: 1223px) {
      .form-type-checkboxes > label {
        font-size: 12px; } }
    .form-type-checkboxes > label .form-required {
      display: none; }
  .form-type-checkboxes .form-checkboxes {
    display: inline; }
    .form-type-checkboxes .form-checkboxes .form-item {
      margin-bottom: 0; }
      .form-type-checkboxes .form-checkboxes .form-item.form-item-car-type--1 label {
        font-weight: bold; }
      .form-type-checkboxes .form-checkboxes .form-item input {
        visibility: hidden;
        opacity: 0;
        max-height: 0;
        position: absolute;
        display: none; }
      .form-type-checkboxes .form-checkboxes .form-item label {
        color: #000006;
        font-size: 14px;
        font-weight: 400;
        line-height: 23px;
        position: relative;
        cursor: pointer;
        display: inline-block;
        padding-left: 30px;
        letter-spacing: -1px;
        margin-right: 5px;
        position: relative; }
        @media (max-width: 1223px) {
          .form-type-checkboxes .form-checkboxes .form-item label {
            font-size: 12px; } }
        .form-type-checkboxes .form-checkboxes .form-item label:before {
          content: '';
          display: block;
          width: 20px;
          height: 20px;
          position: absolute;
          top: 0px;
          left: 0;
          background-color: white;
          border: 1px solid #0a4396;
          border-radius: 50%;
          transition: background-color 0.1s ease; }
        .form-type-checkboxes .form-checkboxes .form-item label:after {
          content: '';
          display: block;
          width: 6px;
          height: 6px;
          position: absolute;
          top: 8px;
          left: 8px;
          border-radius: 50%;
          background-color: #064599;
          transition: background-color 0.1s ease; }
      .form-type-checkboxes .form-checkboxes .form-item input:checked ~ label:before {
        background-color: #064599; }
      .form-type-checkboxes .form-checkboxes .form-item input:checked ~ label:after {
        background-color: white; }

/* timedropper css */
body #td-clock-0 .td-clock .td-time span,
body #td-clock-1 .td-clock .td-time span {
  color: #c7c9ca;
  font-size: 26px;
  transition: color 0.25s ease, font-size 0.25s ease; }
  body #td-clock-0 .td-clock .td-time span.on,
  body #td-clock-1 .td-clock .td-time span.on {
    color: #064599;
    font-size: 32px; }
    @media (max-width: 767px) {
      body #td-clock-0 .td-clock .td-time span.on,
      body #td-clock-1 .td-clock .td-time span.on {
        font-size: 26px; } }

body #td-clock-0 .td-lancette,
body #td-clock-1 .td-lancette {
  border-color: #064599; }

body #td-clock-0 .td-clock,
body #td-clock-1 .td-clock {
  box-shadow: 0 0 0 1px #064599, 0 0 0 8px rgba(0, 0, 0, 0.05); }
  body #td-clock-0 .td-clock:before,
  body #td-clock-1 .td-clock:before {
    border-color: #064599; }
  body #td-clock-0 .td-clock .td-select:after,
  body #td-clock-1 .td-clock .td-select:after {
    box-shadow: 0 0 0 1px #064599; }
  @media (max-width: 500px) {
    body #td-clock-0 .td-clock .td-select:after,
    body #td-clock-1 .td-clock .td-select:after {
      width: 25px;
      height: 25px; } }

@media (max-width: 767px) {
  body #td-clock-0 .td-clock,
  body #td-clock-1 .td-clock {
    width: 125px;
    height: 125px; }
    body #td-clock-0 .td-clock .td-time span,
    body #td-clock-1 .td-clock .td-time span {
      width: 31px; } }

@media (max-width: 500px) {
  body #td-clock-0 .td-clock,
  body #td-clock-1 .td-clock {
    width: 80px;
    height: 80px;
    line-height: 84px; }
    body #td-clock-0 .td-clock .td-time span,
    body #td-clock-1 .td-clock .td-time span {
      font-size: 18px !important;
      width: 20px;
      height: 22px;
      line-height: 37px; } }

body #td-clock-0 .td-clock {
  color: #555;
  background: white;
  box-shadow: 0 0 0 1px #064599, 0 0 0 8px rgba(0, 0, 0, 0.05); }

body .td-select svg {
  stroke: #064599 !important; }

body #td-clock-0 .td-select:after {
  box-shadow: 0 0 0 1px #064599; }

body #td-clock-0 .td-clock:before {
  border-color: #064599; }

body div.datedropper.primary:before,
body div.datedropper.primary .pick-submit,
body div.datedropper.primary .pick-lg-b .pick-sl:before,
body div.datedropper.primary .pick-m,
body div.datedropper.primary .pick-lg-h {
  background-color: #064599 !important; }

body div.datedropper:not(.picker-clean) .pick:first-of-type {
  background-color: #064599 !important; }

body div.datedropper.primary .pick-m li {
  background-color: #064599; }

body div.datedropper.primary .pick-y.pick-jump,
body div.datedropper.primary .pick li span,
body div.datedropper.primary .pick-lg-b .pick-wke,
body div.datedropper.primary .pick-btn {
  color: #064599; }

body div.datedropper.primary .pick-sl.pick-wke {
  color: #fff; }

/* Fleet - info about prices */
#block-block-26 {
  background-color: #ffec00; }
  #block-block-26 .block__content {
    display: inline-block;
    width: 100%;
    padding: 17px;
    position: relative;
    padding-top: 86px; }
    @media (max-width: 1223px) {
      #block-block-26 .block__content {
        padding: 70px 8px 8px; } }
    @media (max-width: 640px) {
      #block-block-26 .block__content {
        padding: 34px 8px 34px;
        display: block;
        max-width: 340px;
        margin: auto; } }
    @media (max-width: 479px) {
      #block-block-26 .block__content {
        padding: 15px 8px 32px;
        max-width: 320px;
        margin-left: auto;
        margin-right: auto;
        display: block; } }
    #block-block-26 .block__content:before {
      content: '';
      position: absolute;
      border-left: 41px solid transparent;
      border-right: 41px solid transparent;
      border-top: 20px solid white;
      left: 50%;
      top: 0;
      -webkit-transform: translate(-50%, 0);
      -moz-transform: translate(-50%, 0);
      -ms-transform: translate(-50%, 0);
      -o-transform: translate(-50%, 0);
      transform: translate(-50%, 0); }
      @media (max-width: 479px) {
        #block-block-26 .block__content:before {
          content: unset; } }
    #block-block-26 .block__content:after {
      content: '';
      position: absolute;
      left: 50%;
      top: 33px;
      width: 38px;
      height: 38px;
      transform: translateX(-50%);
      background-image: url("/sites/all/themes/omgsub/images/info_icon_orange.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain; }
      @media (max-width: 1223px) {
        #block-block-26 .block__content:after {
          width: 26px;
          height: 26px; } }
      @media (max-width: 640px) {
        #block-block-26 .block__content:after {
          left: 34px;
          top: 31px; } }
      @media (max-width: 479px) {
        #block-block-26 .block__content:after {
          width: 30px;
          height: 30px;
          top: 9px; } }
    #block-block-26 .block__content p {
      color: #064599;
      font-size: 19px;
      font-weight: bold; }
      @media (max-width: 1223px) {
        #block-block-26 .block__content p {
          font-size: 14px; } }
      @media (max-width: 479px) {
        #block-block-26 .block__content p {
          font-weight: normal;
          font-size: 18px; } }
      #block-block-26 .block__content p.more-link {
        margin-bottom: 16px;
        text-align: center;
        position: relative;
        text-transform: capitalize;
        font-size: 19px; }
        @media (max-width: 640px) {
          #block-block-26 .block__content p.more-link {
            cursor: pointer;
            width: calc(100% - 60px);
            margin-bottom: 0;
            float: right; } }
        #block-block-26 .block__content p.more-link.open:after {
          transform: unset; }
        #block-block-26 .block__content p.more-link:after {
          color: #ffec00;
          vertical-align: bottom;
          line-height: 20px;
          display: inline-block;
          font-size: 32px;
          font-family: 'FontAwesome';
          transform: rotateX(180deg);
          transition: transform 0.5s ease;
          transform-origin: 50% 11px;
          margin-left: 5px; }
          @media (max-width: 640px) {
            #block-block-26 .block__content p.more-link:after {
              content: '\f106';
              color: #064599; } }
      #block-block-26 .block__content p:last-child {
        text-align: center; }
        #block-block-26 .block__content p:last-child > a {
          padding: 8px 15px;
          font-size: 22px;
          background-color: #064599;
          color: #ffec00;
          display: inline-block;
          transition: 0.3s ease;
          line-height: 27px;
          max-width: 267px;
          font-weight: 700; }
          @media (max-width: 1223px) {
            #block-block-26 .block__content p:last-child > a {
              font-size: 14px;
              padding: 6px 10px; } }
          #block-block-26 .block__content p:last-child > a:hover {
            background-color: #064599;
            opacity: 0.8; }
    @media (max-width: 640px) {
      #block-block-26 .block__content .hidden {
        width: 100%;
        display: inline-block; } }
    #block-block-26 .block__content ul {
      padding-left: 0; }
      #block-block-26 .block__content ul:first-of-type {
        margin-top: 0; }
      #block-block-26 .block__content ul li {
        font-size: 16px;
        line-height: calc(1em + 4px);
        padding: 7px 0 7px 30px; }
        @media (max-width: 1223px) {
          #block-block-26 .block__content ul li {
            font-size: 11px;
            padding: 3px 0 4px 20px; } }
        #block-block-26 .block__content ul li:before {
          height: 32px; }
          @media (max-width: 1223px) {
            #block-block-26 .block__content ul li:before {
              height: 22px;
              background-size: 14px;
              left: -4px;
              top: -5px; } }
      #block-block-26 .block__content ul.checkmarks-list li:before {
        height: 31px; }
      @media (max-width: 640px) {
        #block-block-26 .block__content ul.checkmarks-list {
          margin-top: 20px; } }

.ui-timepicker-container.ui-timepicker-standard {
  z-index: 9999 !important;
  padding: 0; }
  .ui-timepicker-container.ui-timepicker-standard .ui-timepicker.ui-widget {
    border: none;
    padding: 0;
    background: none; }
    .ui-timepicker-container.ui-timepicker-standard .ui-timepicker.ui-widget ul.ui-timepicker-viewport li.ui-menu-item a {
      border: none;
      background: none;
      padding-right: 10px;
      padding-left: 5px;
      padding-top: 1px;
      padding-bottom: 1px;
      font-size: 15px;
      text-align: left;
      cursor: pointer;
      border-radius: 0; }
      .ui-timepicker-container.ui-timepicker-standard .ui-timepicker.ui-widget ul.ui-timepicker-viewport li.ui-menu-item a:hover {
        background: #064599;
        color: #FFFFFF; }

#block-views-front-page-gallery-block:after {
  content: '';
  display: table;
  clear: both; }

#block-views-front-page-gallery-block .view-content .gallery_text {
  padding: 33px 40px;
  width: 49.61832%;
  float: left;
  margin-right: 0.76336%;
  background: #0a4396;
  border-radius: 24px;
  padding-bottom: 20px;
  font-weight: 400;
  font-size: 24px;
  min-height: 439px;
  color: white; }
  #block-views-front-page-gallery-block .view-content .gallery_text p:first-child {
    color: #ffec00;
    font-size: 42px;
    text-align: center;
    margin-bottom: 7px;
    font-weight: 700;
    border-radis: 25px; }
  #block-views-front-page-gallery-block .view-content .gallery_text ul {
    width: 49.20456%;
    float: left;
    margin-right: 1.59088%;
    padding-left: 30px; }
    #block-views-front-page-gallery-block .view-content .gallery_text ul li {
      position: relative;
      list-style: none;
      list-style-type: none;
      line-height: 22px;
      margin-bottom: 22px;
      font-size: 18px;
      padding-left: 25px; }
      #block-views-front-page-gallery-block .view-content .gallery_text ul li:last-child {
        margin-bottom: 0; }
      #block-views-front-page-gallery-block .view-content .gallery_text ul li:before {
        content: '';
        display: inline-block;
        margin-right: 16px;
        transform: scale(0.8);
        top: -6px;
        position: absolute;
        left: -15px; }
    #block-views-front-page-gallery-block .view-content .gallery_text ul + ul {
      width: 49.20456%;
      float: right;
      margin-right: 0; }

#block-views-front-page-gallery-block .view-content .slick-item {
  width: 49.61832%;
  float: right;
  margin-right: 0;
  position: relative; }
  #block-views-front-page-gallery-block .view-content .slick-item .slick__arrow {
    margin-top: 10px;
    position: absolute;
    bottom: 0; }
    #block-views-front-page-gallery-block .view-content .slick-item .slick__arrow .slick-prev,
    #block-views-front-page-gallery-block .view-content .slick-item .slick__arrow .slick-next {
      font-size: 0;
      overflow: hidden;
      position: relative;
      outline: none;
      float: left;
      background: #FFF;
      color: #064599;
      border: none; }
      #block-views-front-page-gallery-block .view-content .slick-item .slick__arrow .slick-prev:hover, #block-views-front-page-gallery-block .view-content .slick-item .slick__arrow .slick-prev:focus,
      #block-views-front-page-gallery-block .view-content .slick-item .slick__arrow .slick-next:hover,
      #block-views-front-page-gallery-block .view-content .slick-item .slick__arrow .slick-next:focus {
        cursor: pointer;
        background: rgba(6, 69, 153, 0.3);
        color: #FFFFFF; }
      #block-views-front-page-gallery-block .view-content .slick-item .slick__arrow .slick-prev:after,
      #block-views-front-page-gallery-block .view-content .slick-item .slick__arrow .slick-next:after {
        content: '\f105';
        display: block;
        font-family: "FontAwesome";
        text-shadow: none;
        line-height: 34px;
        font-size: 56px; }
    #block-views-front-page-gallery-block .view-content .slick-item .slick__arrow .slick-prev:after {
      content: '\f104'; }
    #block-views-front-page-gallery-block .view-content .slick-item .slick__arrow .slick-next {
      border-top-right-radius: 23px; }

#block-views-front-page-gallery-block .view-content .slick-item .slick-slide {
  margin: 0 10px 0 0; }
  #block-views-front-page-gallery-block .view-content .slick-item .slick-slide img {
    border-radius: 25px;
    width: 100%;
    object-fit: cover; }

@media (max-width: 1465px) {
  #block-views-front-page-gallery-block .view-content .gallery_text {
    padding: 35px 5px;
    width: 44.65839%;
    float: left;
    margin-right: 0.62112%; } }
  @media (max-width: 1465px) and (max-width: 1356px) {
    #block-views-front-page-gallery-block .view-content .gallery_text {
      padding: 15px 5px; } }

@media (max-width: 1465px) {
    #block-views-front-page-gallery-block .view-content .gallery_text ul {
      padding-left: 15px; }
      #block-views-front-page-gallery-block .view-content .gallery_text ul li {
        font-size: 17px; }
        #block-views-front-page-gallery-block .view-content .gallery_text ul li:before {
          margin-right: 10px; }
  #block-views-front-page-gallery-block .view-content .slick-item {
    width: 54.7205%;
    float: right;
    margin-right: 0; } }

@media (max-width: 1223px) {
  #block-views-front-page-gallery-block .view-content .gallery_text {
    width: 53.88199%;
    float: left;
    margin-right: 0.62112%;
    padding: 38px 5px; }
    #block-views-front-page-gallery-block .view-content .gallery_text ul li {
      font-size: 16px; }
  #block-views-front-page-gallery-block .view-content .slick-item {
    width: 45.49689%;
    float: right;
    margin-right: 0; } }

@media (min-width: 1024px) and (max-width: 1170px) {
  #block-views-front-page-gallery-block .view-content .gallery_text {
    padding-bottom: 10px; } }

@media (max-width: 1023px) {
  #block-views-front-page-gallery-block .view-content .gallery_text {
    width: 58.91304%;
    float: left;
    margin-right: 0.62112%;
    min-height: 439px;
    padding: 15px 5px;
    padding-bottom: 10px; }
    #block-views-front-page-gallery-block .view-content .gallery_text p:first-child {
      font-size: 35px;
      margin-bottom: 0; }
    #block-views-front-page-gallery-block .view-content .gallery_text ul li {
      font-size: 16px;
      margin-bottom: 15px; }
  #block-views-front-page-gallery-block .view-content .slick-item {
    width: 40.46584%;
    float: right;
    margin-right: 0; } }

@media (max-width: 767px) {
  #block-views-front-page-gallery-block .view-content .gallery_text {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
    padding: 20px 19px;
    min-height: unset;
    margin-bottom: 10px; }
    #block-views-front-page-gallery-block .view-content .gallery_text p:first-child {
      font-size: 35px; }
    #block-views-front-page-gallery-block .view-content .gallery_text ul li {
      font-size: 16px; }
  #block-views-front-page-gallery-block .view-content .slick-item {
    width: 100%;
    float: right;
    margin-right: 0; }
    #block-views-front-page-gallery-block .view-content .slick-item .slick__arrow {
      max-width: 80px;
      margin: auto;
      bottom: 18px; }
  #block-views-front-page-gallery-block .view-content .slick-item .slick-slide {
    margin: 0 5px 0 5px; }
    #block-views-front-page-gallery-block .view-content .slick-item .slick-slide img {
      height: auto; } }

@media (max-width: 480px) {
  #block-views-front-page-gallery-block .view-content .gallery_text {
    padding: 26px 10px; }
    #block-views-front-page-gallery-block .view-content .gallery_text p:first-child {
      font-size: 30px; }
    #block-views-front-page-gallery-block .view-content .gallery_text ul {
      padding-left: 9px; }
      #block-views-front-page-gallery-block .view-content .gallery_text ul li {
        font-size: 15px; }
        #block-views-front-page-gallery-block .view-content .gallery_text ul li:before {
          margin-right: 3px; } }

@media (max-width: 414px) {
  #block-views-front-page-gallery-block .view-content .gallery_text {
    padding: 22px 10px; }
    #block-views-front-page-gallery-block .view-content .gallery_text p:first-child {
      margin-bottom: 15px; }
    #block-views-front-page-gallery-block .view-content .gallery_text ul {
      width: 100%;
      max-width: 300px;
      margin: auto;
      display: block;
      float: unset;
      margin-bottom: 14px; }
      #block-views-front-page-gallery-block .view-content .gallery_text ul li {
        margin-bottom: 14px; }
        #block-views-front-page-gallery-block .view-content .gallery_text ul li:before {
          transform: scale(0.7);
          top: -7px; }
      #block-views-front-page-gallery-block .view-content .gallery_text ul + ul {
        width: 100%;
        max-width: 300px;
        margin: auto;
        display: block;
        float: unset;
        margin-top: 8px; } }

#home_blocks_header {
  max-width: 1327px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1100px;
  margin-top: 55px;
  margin-bottom: 59px;
  text-align: center; }
  @media (max-width: 1023px) {
    #home_blocks_header {
      margin-top: 36px;
      margin-bottom: 36px; } }
  @media (max-width: 1130px) {
    #home_blocks_header {
      padding-left: 15px;
      padding-right: 15px; } }
  #home_blocks_header:after {
    content: " ";
    display: block;
    clear: both; }
  @media (max-width: 479px) {
    #home_blocks_header {
      margin-top: 18px; } }
  #home_blocks_header h2.block__title {
    margin-top: 0;
    color: #0050a3;
    font-size: 42px;
    font-weight: 400;
    line-height: 1.1;
    margin-bottom: 33px; }
    #home_blocks_header h2.block__title:after {
      content: '';
      display: block;
      background-image: linear-gradient(to right, #ffec00 0, #ffec00 41px, transparent 41px, transparent 45px, #ffec00 45px, #ffec00 86px, transparent 86px, transparent 90px, #ffec00 90px, #ffec00 131px);
      height: 6px;
      width: 131px; }
    #home_blocks_header h2.block__title:after {
      margin: 15px auto 0; }
    @media (max-width: 1023px) {
      #home_blocks_header h2.block__title {
        font-size: 32px; } }
    @media (max-width: 479px) {
      #home_blocks_header h2.block__title {
        font-size: 24px;
        line-height: 27px;
        font-weight: normal;
        margin-bottom: 15px; } }
  #home_blocks_header .block__content {
    color: #000000;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.44; }
    @media (max-width: 1023px) {
      #home_blocks_header .block__content {
        font-size: 14px;
        line-height: 20px; } }
    @media (max-width: 479px) {
      #home_blocks_header .block__content {
        font-size: 12px;
        line-height: 18px; } }

#home_blocks {
  max-width: 1327px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1260px;
  background-image: url("/sites/all/themes/omgsub/images/fleet.jpg");
  background-repeat: no-repeat;
  background-position: right center;
  height: 560px;
  margin-top: 120px;
  margin-bottom: 90px; }
  @media (max-width: 1290px) {
    #home_blocks {
      padding-left: 15px;
      padding-right: 15px; } }
  #home_blocks:after {
    content: " ";
    display: block;
    clear: both; }
  @media (max-width: 1223px) {
    #home_blocks {
      max-width: 1327px;
      margin-left: auto;
      margin-right: auto;
      max-width: 768px;
      margin: 40px auto;
      background-size: 396px 295px;
      height: 326px;
      background-position: calc(100% - 40px) center;
      padding: 0 20px; } }
  @media (max-width: 1223px) and (max-width: 798px) {
    #home_blocks {
      padding-left: 15px;
      padding-right: 15px; } }
  @media (max-width: 1223px) {
      #home_blocks:after {
        content: " ";
        display: block;
        clear: both; } }
  @media (max-width: 640px) {
    #home_blocks {
      max-width: 1327px;
      margin-left: auto;
      margin-right: auto;
      max-width: 280px;
      padding-left: 0;
      padding-right: 0;
      margin-top: 20px;
      background-position: center bottom;
      background-size: 100% auto;
      height: 410px;
      margin-bottom: 0; } }
  @media (max-width: 640px) and (max-width: 320px) {
    #home_blocks {
      margin-left: 20px;
      margin-right: 20px; } }
  @media (max-width: 640px) {
      #home_blocks:after {
        content: " ";
        display: block;
        clear: both; } }
  #home_blocks h2.block__title {
    color: #064599;
    font-size: 40px;
    font-weight: 400;
    margin-top: 12px;
    margin-bottom: 45px;
    display: inline-block;
    max-width: 395px;
    line-height: 44px;
    width: 100%; }
    @media (max-width: 1223px) {
      #home_blocks h2.block__title {
        font-size: 31px;
        line-height: 1.125;
        margin-bottom: 17px;
        letter-spacing: -1px;
        max-width: 310px; } }
    @media (max-width: 767px) {
      #home_blocks h2.block__title {
        max-width: 190px; } }
    @media (max-width: 640px) {
      #home_blocks h2.block__title {
        font-size: 24px;
        line-height: 1;
        margin-bottom: 10px;
        text-align: center;
        max-width: 310px; } }
  #home_blocks .block__content .view .view-content .flexslider {
    display: inline-block;
    width: 565px;
    background: #064599;
    border: none;
    padding: 25px;
    margin: 0;
    position: relative;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px; }
    @media (max-width: 1223px) {
      #home_blocks .block__content .view .view-content .flexslider {
        width: 342px;
        padding: 20px; } }
    @media (max-width: 640px) {
      #home_blocks .block__content .view .view-content .flexslider {
        width: 280px;
        padding: 25px 17px; } }
    #home_blocks .block__content .view .view-content .flexslider:after {
      content: '';
      position: absolute;
      top: 50%;
      right: -25px;
      border-left: 25px solid #064599;
      border-right: 0 solid transparent;
      border-top: 40px solid transparent;
      border-bottom: 40px solid transparent;
      -webkit-transform: translate(0, -50%) rotate(0deg);
      -moz-transform: translate(0, -50%) rotate(0deg);
      -ms-transform: translate(0, -50%) rotate(0deg);
      -o-transform: translate(0, -50%) rotate(0deg);
      transform: translate(0, -50%) rotate(0deg); }
      @media (max-width: 1223px) {
        #home_blocks .block__content .view .view-content .flexslider:after {
          border-left-width: 18px;
          border-top-width: 25px;
          border-bottom-width: 25px;
          right: -18px; } }
      @media (max-width: 640px) {
        #home_blocks .block__content .view .view-content .flexslider:after {
          top: unset;
          bottom: -18px;
          right: 50%;
          left: unset;
          transform: translate(50%, 0);
          border-left: 27px solid transparent;
          border-right: 27px solid transparent;
          border-bottom: none;
          border-top: 18px solid #064599; } }
    #home_blocks .block__content .view .view-content .flexslider ul.slides {
      vertical-align: bottom; }
      #home_blocks .block__content .view .view-content .flexslider ul.slides li {
        vertical-align: bottom; }
        @media (max-width: 640px) {
          #home_blocks .block__content .view .view-content .flexslider ul.slides li {
            width: 246px !important; } }
        #home_blocks .block__content .view .view-content .flexslider ul.slides li h3 {
          color: #ffec00;
          font-size: 30px;
          line-height: 27px;
          margin: 0;
          margin-bottom: 10px;
          position: relative; }
          @media (max-width: 1223px) {
            #home_blocks .block__content .view .view-content .flexslider ul.slides li h3 {
              font-size: 20px;
              line-height: 1;
              padding-bottom: 10px; } }
          @media (max-width: 640px) {
            #home_blocks .block__content .view .view-content .flexslider ul.slides li h3 {
              display: none; } }
          #home_blocks .block__content .view .view-content .flexslider ul.slides li h3:after {
            content: '';
            height: 1px;
            width: 283px;
            background: #d8d8d8;
            display: block;
            bottom: -18px;
            position: absolute; }
            @media (max-width: 1223px) {
              #home_blocks .block__content .view .view-content .flexslider ul.slides li h3:after {
                background-color: rgba(216, 216, 216, 0.5);
                bottom: 0; } }
        #home_blocks .block__content .view .view-content .flexslider ul.slides li .box {
          padding: 25px 0;
          display: inline-block;
          width: 100%; }
          @media (max-width: 1223px) {
            #home_blocks .block__content .view .view-content .flexslider ul.slides li .box {
              padding: 0; } }
          #home_blocks .block__content .view .view-content .flexslider ul.slides li .box .left {
            width: 130px;
            display: inline-block;
            float: left; }
            @media (max-width: 1223px) {
              #home_blocks .block__content .view .view-content .flexslider ul.slides li .box .left {
                display: none; } }
            #home_blocks .block__content .view .view-content .flexslider ul.slides li .box .left span.placeholder {
              width: 158px;
              height: 198px;
              display: inline-block; }
          #home_blocks .block__content .view .view-content .flexslider ul.slides li .box .right {
            width: calc(100% - 158px);
            padding: 23px;
            padding-bottom: 0;
            display: inline-block;
            float: right;
            color: white;
            font-size: 16px;
            font-weight: 400;
            line-height: 26px; }
            @media (max-width: 1223px) {
              #home_blocks .block__content .view .view-content .flexslider ul.slides li .box .right {
                width: 100%;
                padding: 0;
                font-size: 14px;
                line-height: 18px;
                height: calc((6 * 18px) + 2px);
                overflow-y: auto; } }
            @media (max-width: 640px) {
              #home_blocks .block__content .view .view-content .flexslider ul.slides li .box .right {
                font-size: 12px;
                height: calc(8 * 18px); } }
            #home_blocks .block__content .view .view-content .flexslider ul.slides li .box .right * {
              margin: 0; }
    #home_blocks .block__content .view .view-content .flexslider ul.flex-direction-nav {
      width: 110px;
      height: 50px;
      display: inline-block;
      position: absolute;
      bottom: -75px;
      left: 50%;
      -webkit-transform: translate(calc( -50% - 55px), 0);
      -moz-transform: translate(calc( -50% - 55px), 0);
      -ms-transform: translate(calc( -50% - 55px), 0);
      -o-transform: translate(calc( -50% - 55px), 0);
      transform: translate(calc( -50% - 55px), 0); }
      @media (max-width: 1223px) {
        #home_blocks .block__content .view .view-content .flexslider ul.flex-direction-nav {
          width: 72px;
          height: 29px;
          bottom: -51px;
          transform: translate(-50%, 0); } }
      @media (max-width: 640px) {
        #home_blocks .block__content .view .view-content .flexslider ul.flex-direction-nav {
          display: none; } }
      #home_blocks .block__content .view .view-content .flexslider ul.flex-direction-nav li {
        position: relative;
        height: 50px;
        width: 50px;
        display: inline-block; }
        @media (max-width: 1223px) {
          #home_blocks .block__content .view .view-content .flexslider ul.flex-direction-nav li {
            height: 29px;
            width: 29px; } }
        #home_blocks .block__content .view .view-content .flexslider ul.flex-direction-nav li.flex-nav-next {
          float: right; }
        #home_blocks .block__content .view .view-content .flexslider ul.flex-direction-nav li a {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          width: unset;
          height: unset;
          background: #EFF1F1;
          text-align: center;
          color: transparent;
          display: block;
          opacity: 1;
          margin: 0;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%; }
          @media (max-width: 1223px) {
            #home_blocks .block__content .view .view-content .flexslider ul.flex-direction-nav li a {
              text-align: center; } }
          #home_blocks .block__content .view .view-content .flexslider ul.flex-direction-nav li a:before {
            font-family: "FontAwesome";
            text-shadow: none;
            color: #B9BCBD;
            line-height: 50px;
            opacity: 1;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: unset;
            height: unset; }
            @media (max-width: 1223px) {
              #home_blocks .block__content .view .view-content .flexslider ul.flex-direction-nav li a:before {
                line-height: 29px;
                font-size: 28px; } }
          #home_blocks .block__content .view .view-content .flexslider ul.flex-direction-nav li a.flex-prev:before {
            content: '\f104';
            bottom: 0;
            left: 0;
            transform: translate(-1px, -1px); }
          #home_blocks .block__content .view .view-content .flexslider ul.flex-direction-nav li a.flex-next:before {
            content: '\f105';
            bottom: 0;
            right: 0;
            transform: translate(1px, -1px); }
          #home_blocks .block__content .view .view-content .flexslider ul.flex-direction-nav li a:hover:before {
            color: #064599;
            -webkit-transition: all 0.5s ease;
            -moz-transition: all 0.5s ease;
            -o-transition: all 0.5s ease;
            transition: all 0.5s ease; }

#home_our_fleet_header {
  max-width: 1327px;
  margin-left: auto;
  margin-right: auto;
  max-width: 920px;
  margin-top: 52px;
  margin-bottom: 52px;
  text-align: center; }
  @media (max-width: 1023px) {
    #home_our_fleet_header {
      margin-top: 90px; } }
  @media (max-width: 950px) {
    #home_our_fleet_header {
      padding-left: 15px;
      padding-right: 15px; } }
  #home_our_fleet_header:after {
    content: " ";
    display: block;
    clear: both; }
  @media (max-width: 479px) {
    #home_our_fleet_header {
      margin-top: 20px; } }
  #home_our_fleet_header h2.block__title {
    color: #000006;
    font-size: 40px;
    font-weight: 400;
    line-height: 48px;
    max-width: 650px;
    margin: 0 auto;
    margin-bottom: 23px; }
    @media (max-width: 1023px) {
      #home_our_fleet_header h2.block__title {
        font-size: 32px;
        line-height: calc(1em + 4px);
        margin-bottom: 19px; } }
    @media (max-width: 479px) {
      #home_our_fleet_header h2.block__title {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 12px; } }
  #home_our_fleet_header .block__content {
    color: #000006;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px; }
    @media (max-width: 1023px) {
      #home_our_fleet_header .block__content {
        font-size: 14px;
        line-height: calc(1em + 6px); } }
    #home_our_fleet_header .block__content p {
      margin-bottom: 0; }
      @media (max-width: 479px) {
        #home_our_fleet_header .block__content p {
          font-size: 12px;
          line-height: 1.5; } }

#home_our_fleet {
  max-width: 1327px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1590px;
  margin-top: 34px;
  padding-left: 10px;
  padding-right: 10px; }
  @media (max-width: 1620px) {
    #home_our_fleet {
      padding-left: 15px;
      padding-right: 15px; } }
  #home_our_fleet:after {
    content: " ";
    display: block;
    clear: both; }
  @media (max-width: 1023px) {
    #home_our_fleet {
      margin-top: 30px; } }
  @media (max-width: 767px) {
    #home_our_fleet {
      margin-top: 50px; } }
  @media (max-width: 479px) {
    #home_our_fleet {
      margin-top: 30px; } }
  @media (max-width: 1223px) {
    #home_our_fleet div.content .left div.box,
    #home_our_fleet div.content .right div.box {
      padding-left: 75px;
      width: 25%;
      float: left; } }
  @media (max-width: 1023px) {
    #home_our_fleet div.content .left div.box,
    #home_our_fleet div.content .right div.box {
      padding-left: 45px;
      padding-right: 11px; } }
  @media (max-width: 1023px) {
    #home_our_fleet div.content .left div.box,
    #home_our_fleet div.content .right div.box {
      padding-left: 25px;
      padding-right: 25px;
      padding-top: 35px; } }
  @media (max-width: 640px) {
    #home_our_fleet div.content .left div.box,
    #home_our_fleet div.content .right div.box {
      padding: 35px 6px 0; } }
  @media (max-width: 479px) {
    #home_our_fleet div.content .left div.box,
    #home_our_fleet div.content .right div.box {
      width: unset;
      float: unset;
      padding-left: 20px;
      padding-right: 18px;
      padding-top: 0;
      margin-bottom: 10px;
      width: 33.33333%;
      float: left;
      height: 34px;
      line-height: 34px;
      position: relative; } }
  #home_our_fleet div.content .left h4,
  #home_our_fleet div.content .right h4 {
    color: #0a4396;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.2;
    text-transform: uppercase;
    width: 100%;
    display: inline-block;
    position: relative;
    margin-bottom: 4px;
    margin-top: 50px; }
    @media (max-width: 1223px) {
      #home_our_fleet div.content .left h4,
      #home_our_fleet div.content .right h4 {
        text-align: left; } }
    @media (max-width: 1023px) {
      #home_our_fleet div.content .left h4,
      #home_our_fleet div.content .right h4 {
        font-size: 12px;
        line-height: 18px;
        margin-top: 0;
        margin-bottom: 2px; } }
    @media (max-width: 767px) {
      #home_our_fleet div.content .left h4,
      #home_our_fleet div.content .right h4 {
        letter-spacing: -0.5px; } }
    @media (max-width: 479px) {
      #home_our_fleet div.content .left h4,
      #home_our_fleet div.content .right h4 {
        color: #1c1c1b;
        font-size: 12px;
        text-transform: unset;
        font-weight: 400;
        display: inline-block;
        position: unset;
        line-height: normal;
        vertical-align: middle;
        letter-spacing: -1px; } }
    #home_our_fleet div.content .left h4:before,
    #home_our_fleet div.content .right h4:before {
      content: '';
      position: absolute;
      width: 60px;
      height: 70px;
      display: block;
      background-position: center top;
      background-repeat: no-repeat;
      background-size: contain; }
      @media (max-width: 1223px) {
        #home_our_fleet div.content .left h4:before,
        #home_our_fleet div.content .right h4:before {
          left: -70px;
          right: auto; } }
      @media (max-width: 1023px) {
        #home_our_fleet div.content .left h4:before,
        #home_our_fleet div.content .right h4:before {
          height: 42px;
          width: 40px; } }
      @media (max-width: 640px) {
        #home_our_fleet div.content .left h4:before,
        #home_our_fleet div.content .right h4:before {
          height: 34px;
          width: 27px;
          transform: translate(-50%, -100%); } }
      @media (max-width: 479px) {
        #home_our_fleet div.content .left h4:before,
        #home_our_fleet div.content .right h4:before {
          transform: unset; } }
  #home_our_fleet div.content .left p,
  #home_our_fleet div.content .right p {
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.36; }
    @media (max-width: 1023px) {
      #home_our_fleet div.content .left p,
      #home_our_fleet div.content .right p {
        font-size: 12px;
        line-height: calc(1em + 6px); } }
    @media (max-width: 479px) {
      #home_our_fleet div.content .left p,
      #home_our_fleet div.content .right p {
        display: none; } }
  #home_our_fleet div.content .center {
    width: 61.05683%;
    float: left;
    margin-right: 1.59088%; }
    @media (max-width: 1223px) {
      #home_our_fleet div.content .center {
        width: 100%;
        float: left;
        margin-left: 0;
        margin-right: 0; } }
    @media (max-width: 1023px) {
      #home_our_fleet div.content .center {
        margin-top: 30px; } }
    @media (max-width: 479px) {
      #home_our_fleet div.content .center {
        margin-top: 25px; } }
    #home_our_fleet div.content .center .view-display-id-block .view-content #slider {
      margin-bottom: 10px; }
      @media (max-width: 1023px) {
        #home_our_fleet div.content .center .view-display-id-block .view-content #slider {
          margin-bottom: 5px; } }
      #home_our_fleet div.content .center .view-display-id-block .view-content #slider ul.slides {
        padding: 0; }
        #home_our_fleet div.content .center .view-display-id-block .view-content #slider ul.slides li .car-type-link {
          text-align: center; }
          @media (max-width: 1223px) {
            #home_our_fleet div.content .center .view-display-id-block .view-content #slider ul.slides li .car-type-link {
              padding-bottom: 30px;
              margin-top: 35px; } }
          @media (max-width: 1023px) {
            #home_our_fleet div.content .center .view-display-id-block .view-content #slider ul.slides li .car-type-link {
              padding-bottom: 0;
              margin-top: 0; } }
          #home_our_fleet div.content .center .view-display-id-block .view-content #slider ul.slides li .car-type-link a {
            margin-top: 0;
            color: #FFFFFF;
            font-size: 34px;
            font-weight: 400;
            line-height: 1.1;
            background: #0a4396;
            padding: 13px 20px;
            padding-right: 60px;
            display: inline-block;
            border-radius: 10px;
            position: relative; }
            #home_our_fleet div.content .center .view-display-id-block .view-content #slider ul.slides li .car-type-link a:after {
              position: absolute;
              content: '\f105';
              font-family: "FontAwesome";
              text-shadow: none;
              line-height: 50px;
              font-size: 35px;
              opacity: 1;
              color: #ffec00;
              top: 7px;
              right: 32px;
              animation: slide 1s ease-in-out infinite; }
            @media (max-width: 1023px) {
              #home_our_fleet div.content .center .view-display-id-block .view-content #slider ul.slides li .car-type-link a {
                font-size: 18px;
                font-weight: 900;
                padding: 7px 27px;
                line-height: 1; }
                #home_our_fleet div.content .center .view-display-id-block .view-content #slider ul.slides li .car-type-link a:after {
                  top: -9px;
                  font-size: 20px;
                  right: 14px; } }
            @media (max-width: 479px) {
              #home_our_fleet div.content .center .view-display-id-block .view-content #slider ul.slides li .car-type-link a {
                padding: 9px 27px;
                font-size: 14px; }
                #home_our_fleet div.content .center .view-display-id-block .view-content #slider ul.slides li .car-type-link a:after {
                  top: -8px;
                  font-size: 18px;
                  right: 14px; } }
        #home_our_fleet div.content .center .view-display-id-block .view-content #slider ul.slides li .views-field.views-field-product-image {
          display: inline-block;
          width: 100%;
          padding: 15px; }
          #home_our_fleet div.content .center .view-display-id-block .view-content #slider ul.slides li .views-field.views-field-product-image .field-content {
            display: inline-block;
            width: 100%;
            padding: 0;
            position: relative; }
            @media (max-width: 767px) {
              #home_our_fleet div.content .center .view-display-id-block .view-content #slider ul.slides li .views-field.views-field-product-image .field-content:before {
                background: none; }
              #home_our_fleet div.content .center .view-display-id-block .view-content #slider ul.slides li .views-field.views-field-product-image .field-content:after {
                background: none; } }
            @media (max-width: 1023px) {
              #home_our_fleet div.content .center .view-display-id-block .view-content #slider ul.slides li .views-field.views-field-product-image .field-content img {
                width: 88%;
                height: auto;
                margin: 0 auto;
                vertical-align: bottom; } }
            @media (max-width: 479px) {
              #home_our_fleet div.content .center .view-display-id-block .view-content #slider ul.slides li .views-field.views-field-product-image .field-content img {
                width: 100%;
                max-width: 320px;
                display: block; } }
      #home_our_fleet div.content .center .view-display-id-block .view-content #slider ul.flex-direction-nav {
        height: 0; }
        #home_our_fleet div.content .center .view-display-id-block .view-content #slider ul.flex-direction-nav li {
          height: 50px;
          width: 50px;
          display: inline-block; }
          @media (max-width: 479px) {
            #home_our_fleet div.content .center .view-display-id-block .view-content #slider ul.flex-direction-nav li {
              height: 25px;
              width: 15px; } }
          #home_our_fleet div.content .center .view-display-id-block .view-content #slider ul.flex-direction-nav li.flex-nav-next {
            float: right; }
          #home_our_fleet div.content .center .view-display-id-block .view-content #slider ul.flex-direction-nav li a {
            bottom: auto;
            left: inherit;
            right: inherit;
            height: 50px;
            width: 50px;
            margin: 0;
            top: 50%;
            transform: translateY(-50%);
            text-align: center;
            color: transparent;
            display: block;
            opacity: 1;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%; }
            @media (max-width: 479px) {
              #home_our_fleet div.content .center .view-display-id-block .view-content #slider ul.flex-direction-nav li a {
                height: 25px;
                width: 15px;
                margin: 0; } }
            #home_our_fleet div.content .center .view-display-id-block .view-content #slider ul.flex-direction-nav li a:before {
              font-family: "FontAwesome";
              text-shadow: none;
              line-height: 50px;
              font-size: 55px;
              opacity: 1;
              color: #064599; }
              @media (max-width: 479px) {
                #home_our_fleet div.content .center .view-display-id-block .view-content #slider ul.flex-direction-nav li a:before {
                  line-height: 25px;
                  font-size: 28px; } }
            #home_our_fleet div.content .center .view-display-id-block .view-content #slider ul.flex-direction-nav li a.flex-prev:focus {
              outline: none; }
            #home_our_fleet div.content .center .view-display-id-block .view-content #slider ul.flex-direction-nav li a.flex-prev:before {
              content: '\f104';
              bottom: 0;
              left: 0; }
            #home_our_fleet div.content .center .view-display-id-block .view-content #slider ul.flex-direction-nav li a.flex-next:focus {
              outline: none; }
            #home_our_fleet div.content .center .view-display-id-block .view-content #slider ul.flex-direction-nav li a.flex-next:before {
              content: '\f105';
              bottom: 0;
              right: 0; }
    @media (max-width: 767px) {
      #home_our_fleet div.content .center .view-display-id-block .view-footer {
        display: none; } }
    #home_our_fleet div.content .center .view-display-id-block .view-footer .view-home-page-fleet-carousel .view-content #carousel {
      padding-left: 20px;
      padding-right: 20px;
      border: none; }
      @media (max-width: 1023px) {
        #home_our_fleet div.content .center .view-display-id-block .view-footer .view-home-page-fleet-carousel .view-content #carousel {
          margin-bottom: 30px; } }
      #home_our_fleet div.content .center .view-display-id-block .view-footer .view-home-page-fleet-carousel .view-content #carousel .flex-viewport {
        width: 100%; }
        @media (max-width: 1023px) {
          #home_our_fleet div.content .center .view-display-id-block .view-footer .view-home-page-fleet-carousel .view-content #carousel .flex-viewport {
            padding: 30px 0; } }
        #home_our_fleet div.content .center .view-display-id-block .view-footer .view-home-page-fleet-carousel .view-content #carousel .flex-viewport ul.slides {
          padding: 0; }
          #home_our_fleet div.content .center .view-display-id-block .view-footer .view-home-page-fleet-carousel .view-content #carousel .flex-viewport ul.slides li {
            text-align: center;
            cursor: pointer;
            position: relative;
            margin-right: 40px !important;
            /* λόγω του flexslider - για να παίξουμε με breakpoints */ }
            #home_our_fleet div.content .center .view-display-id-block .view-footer .view-home-page-fleet-carousel .view-content #carousel .flex-viewport ul.slides li .views-field-product-image .field-content {
              position: relative;
              display: inline-block; }
              #home_our_fleet div.content .center .view-display-id-block .view-footer .view-home-page-fleet-carousel .view-content #carousel .flex-viewport ul.slides li .views-field-product-image .field-content img {
                width: 121px;
                vertical-align: bottom;
                max-width: 100%;
                height: auto; }
              #home_our_fleet div.content .center .view-display-id-block .view-footer .view-home-page-fleet-carousel .view-content #carousel .flex-viewport ul.slides li .views-field-product-image .field-content:after {
                content: '';
                display: block;
                width: 125px;
                height: 125px;
                position: absolute;
                top: 50%;
                border: 2px solid transparent;
                left: 50%;
                transition: width 0.2s ease, height 0.2s ease, border-color 0.2s ease;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                border-radius: 50%;
                -webkit-transform: translate(-50%, -50%);
                -moz-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                -o-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }
            #home_our_fleet div.content .center .view-display-id-block .view-footer .view-home-page-fleet-carousel .view-content #carousel .flex-viewport ul.slides li .views-field-field-car-type {
              display: inline-block;
              width: 100%;
              margin-top: 30px; }
              @media (max-width: 1023px) {
                #home_our_fleet div.content .center .view-display-id-block .view-footer .view-home-page-fleet-carousel .view-content #carousel .flex-viewport ul.slides li .views-field-field-car-type {
                  margin-top: 15px; } }
              #home_our_fleet div.content .center .view-display-id-block .view-footer .view-home-page-fleet-carousel .view-content #carousel .flex-viewport ul.slides li .views-field-field-car-type a {
                text-decoration: none;
                color: #000000;
                font-size: 16px;
                font-weight: 400;
                line-height: 1.2; }
            #home_our_fleet div.content .center .view-display-id-block .view-footer .view-home-page-fleet-carousel .view-content #carousel .flex-viewport ul.slides li:hover .views-field-product-image .field-content:after {
              width: 100px;
              height: 100px;
              border: 2px solid #064599; }
  #home_our_fleet div.content .left {
    width: 17.8807%;
    float: left;
    margin-right: 1.59088%;
    padding-right: 75px; }
    @media (max-width: 1223px) {
      #home_our_fleet div.content .left {
        width: 100%;
        float: left;
        margin-left: 0;
        margin-right: 0;
        padding: 0; } }
    @media (max-width: 479px) {
      #home_our_fleet div.content .left {
        display: inline;
        width: unset;
        float: unset; } }
    #home_our_fleet div.content .left .box {
      text-align: right; }
      @media (max-width: 1223px) {
        #home_our_fleet div.content .left .box {
          text-align: left; } }
      #home_our_fleet div.content .left .box:nth-of-type(1) h4:before {
        right: -66px; }
        @media (max-width: 1023px) {
          #home_our_fleet div.content .left .box:nth-of-type(1) h4:before {
            left: -34px;
            top: -10px; } }
        @media (max-width: 767px) {
          #home_our_fleet div.content .left .box:nth-of-type(1) h4:before {
            left: 11px;
            top: -55px; } }
        @media (max-width: 479px) {
          #home_our_fleet div.content .left .box:nth-of-type(1) h4:before {
            left: -8px;
            top: -16px; } }
      #home_our_fleet div.content .left .box:nth-of-type(2) h4:before {
        right: -81px; }
        @media (max-width: 1023px) {
          #home_our_fleet div.content .left .box:nth-of-type(2) h4:before {
            top: -13px; } }
        @media (max-width: 767px) {
          #home_our_fleet div.content .left .box:nth-of-type(2) h4:before {
            top: -58px; } }
        @media (max-width: 479px) {
          #home_our_fleet div.content .left .box:nth-of-type(2) h4:before {
            left: -23px;
            top: -22px; } }
      #home_our_fleet div.content .left .box:nth-of-type(3) h4:before {
        right: -78px; }
        @media (max-width: 767px) {
          #home_our_fleet div.content .left .box:nth-of-type(3) h4:before {
            left: 20px;
            top: -41px; } }
        @media (max-width: 479px) {
          #home_our_fleet div.content .left .box:nth-of-type(3) h4:before {
            left: -19px;
            top: -5px; } }
      #home_our_fleet div.content .left .box:nth-of-type(4) h4:before {
        right: -79px; }
        @media (max-width: 767px) {
          #home_our_fleet div.content .left .box:nth-of-type(4) h4:before {
            left: 20px;
            top: -43px; } }
        @media (max-width: 479px) {
          #home_our_fleet div.content .left .box:nth-of-type(4) h4:before {
            left: -20px;
            top: -9px; } }
    #home_our_fleet div.content .left h4:before {
      right: -70px; }
      @media (max-width: 1223px) {
        #home_our_fleet div.content .left h4:before {
          left: -65px; } }
      @media (max-width: 1023px) {
        #home_our_fleet div.content .left h4:before {
          left: -50px;
          transform: scale(0.6); } }
      @media (max-width: 767px) {
        #home_our_fleet div.content .left h4:before {
          left: -7px;
          top: -58px; } }
      @media (max-width: 479px) {
        #home_our_fleet div.content .left h4:before {
          left: 0;
          right: unset;
          top: 0;
          bottom: unset;
          transform: scale(0.4); } }
    #home_our_fleet div.content .left p {
      text-align: right; }
      @media (max-width: 1223px) {
        #home_our_fleet div.content .left p {
          text-align: left; } }
  #home_our_fleet div.content .right {
    width: 17.8807%;
    float: right;
    margin-right: 0;
    padding-left: 75px; }
    @media (max-width: 1223px) {
      #home_our_fleet div.content .right {
        width: 100%;
        float: left;
        margin-left: 0;
        margin-right: 0;
        padding: 0; } }
    @media (max-width: 479px) {
      #home_our_fleet div.content .right {
        display: inline;
        width: unset;
        float: unset; } }
    #home_our_fleet div.content .right .box:nth-of-type(1) h4:before {
      left: -89px; }
      @media (max-width: 1223px) {
        #home_our_fleet div.content .right .box:nth-of-type(1) h4:before {
          left: -79px; } }
      @media (max-width: 1023px) {
        #home_our_fleet div.content .right .box:nth-of-type(1) h4:before {
          left: -50px; } }
      @media (max-width: 767px) {
        #home_our_fleet div.content .right .box:nth-of-type(1) h4:before {
          left: -21px;
          top: -46px; } }
      @media (max-width: 479px) {
        #home_our_fleet div.content .right .box:nth-of-type(1) h4:before {
          left: -29px;
          top: -13px; } }
    @media (max-width: 767px) {
      #home_our_fleet div.content .right .box:nth-of-type(2) h4:before {
        left: 20px;
        top: -46px; } }
    @media (max-width: 479px) {
      #home_our_fleet div.content .right .box:nth-of-type(2) h4:before {
        left: -21px;
        top: -14px; } }
    @media (max-width: 767px) {
      #home_our_fleet div.content .right .box:nth-of-type(3) h4:before {
        left: 7px;
        top: -46px; } }
    @media (max-width: 479px) {
      #home_our_fleet div.content .right .box:nth-of-type(3) h4:before {
        left: -18px;
        top: -16px; } }
    @media (max-width: 767px) {
      #home_our_fleet div.content .right .box:nth-of-type(4) h4:before {
        left: 7px;
        top: -48px; } }
    @media (max-width: 479px) {
      #home_our_fleet div.content .right .box:nth-of-type(4) h4:before {
        left: -25px;
        top: -16px; } }
    #home_our_fleet div.content .right h4:before {
      left: -75px; }
      @media (max-width: 1223px) {
        #home_our_fleet div.content .right h4:before {
          left: -65px; } }
      @media (max-width: 1023px) {
        #home_our_fleet div.content .right h4:before {
          left: -50px;
          transform: scale(0.6); } }
      @media (max-width: 1023px) {
        #home_our_fleet div.content .right h4:before {
          left: -51px;
          top: -12px; } }
      @media (max-width: 640px) {
        #home_our_fleet div.content .right h4:before {
          left: 30%;
          top: 0; } }
      @media (max-width: 479px) {
        #home_our_fleet div.content .right h4:before {
          left: 0;
          right: unset;
          top: 0;
          bottom: unset;
          transform: scale(0.4); } }

#home_newsletter {
  background: #0a4396;
  padding-top: 22px;
  padding-bottom: 18px; }
  #home_newsletter:after {
    content: '';
    display: table;
    clear: both; }
  @media (max-width: 1223px) {
    #home_newsletter {
      padding: 32px 10px; } }
  @media (max-width: 767px) {
    #home_newsletter {
      padding: 20px 10px; } }
  @media (max-width: 479px) {
    #home_newsletter {
      padding: 20px; } }
  #home_newsletter div.block__content {
    position: relative;
    max-width: 1327px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1344px;
    display: block;
    width: 100%; }
    @media (max-width: 1374px) {
      #home_newsletter div.block__content {
        padding-left: 15px;
        padding-right: 15px; } }
    #home_newsletter div.block__content:after {
      content: " ";
      display: block;
      clear: both; }
    @media (max-width: 479px) {
      #home_newsletter div.block__content {
        padding: 0 7px; } }
    #home_newsletter div.block__content .left {
      max-width: 885px;
      float: left;
      padding-right: 10px; }
      @media (max-width: 1223px) {
        #home_newsletter div.block__content .left {
          width: 100%;
          float: left;
          margin-left: 0;
          margin-right: 0;
          display: block;
          text-align: center;
          padding-right: 0;
          max-width: unset; } }
      #home_newsletter div.block__content .left h3 {
        color: #ffec00;
        font-size: 50px;
        font-weight: 700;
        line-height: 1.2;
        text-transform: uppercase;
        margin: 0;
        padding-left: 37px;
        letter-spacing: -1.9px; }
        @media (max-width: 479px) {
          #home_newsletter div.block__content .left h3 {
            font-size: 28px;
            letter-spacing: unset;
            line-height: 35px; } }
        @media (max-width: 1440px) {
          #home_newsletter div.block__content .left h3 {
            font-size: 46px; } }
        @media (max-width: 1223px) {
          #home_newsletter div.block__content .left h3 {
            font-size: 36px;
            letter-spacing: -0.5px; } }
        @media (max-width: 640px) {
          #home_newsletter div.block__content .left h3 {
            font-size: 30px;
            line-height: 30px; } }
        @media (max-width: 479px) {
          #home_newsletter div.block__content .left h3 {
            font-size: 24px; } }
      #home_newsletter div.block__content .left p {
        margin-bottom: 0;
        margin-top: 6px;
        font-size: 28px;
        font-weight: 400;
        line-height: 1.2;
        color: white;
        padding-left: 41px; }
        @media (max-width: 1223px) {
          #home_newsletter div.block__content .left p {
            margin-top: 12px;
            font-size: 24px;
            line-height: calc(1em + 4px);
            margin-bottom: 18px; } }
  @media (max-width: 1223px) and (max-width: 767px) {
    #home_newsletter div.block__content .left p {
      font-size: 20px;
      margin-bottom: 0; } }
  @media (max-width: 1223px) and (max-width: 640px) {
    #home_newsletter div.block__content .left p {
      font-size: 17px; } }
        @media (max-width: 479px) {
          #home_newsletter div.block__content .left p {
            font-size: 18px; } }
    #home_newsletter div.block__content .right {
      margin-top: 0;
      position: relative;
      width: 373px;
      float: right;
      padding-left: 10px; }
      @media (max-width: 1223px) {
        #home_newsletter div.block__content .right {
          width: 100%;
          float: left;
          margin-left: 0;
          margin-right: 0;
          display: block;
          text-align: center;
          padding-left: 0; } }
      #home_newsletter div.block__content .right #mlb2-1339442.ml-form-embedContainer.ml-subscribe-form .ml-form-embedWrapper {
        background-color: transparent; }
        #home_newsletter div.block__content .right #mlb2-1339442.ml-form-embedContainer.ml-subscribe-form .ml-form-embedWrapper .ml-form-embedBody {
          padding: 0; }
          #home_newsletter div.block__content .right #mlb2-1339442.ml-form-embedContainer.ml-subscribe-form .ml-form-embedWrapper .ml-form-embedBody form.ml-block-form .ml-form-fieldRow > div {
            display: inline; }
            #home_newsletter div.block__content .right #mlb2-1339442.ml-form-embedContainer.ml-subscribe-form .ml-form-embedWrapper .ml-form-embedBody form.ml-block-form .ml-form-fieldRow > div > div {
              display: inline; }
          #home_newsletter div.block__content .right #mlb2-1339442.ml-form-embedContainer.ml-subscribe-form .ml-form-embedWrapper .ml-form-embedBody form.ml-block-form .ml-form-fieldRow input[type=email] {
            -moz-appearance: none;
            -webkit-appearance: none;
            background-color: #ffec00 !important;
            border-top-left-radius: 10px !important;
            border-bottom-left-radius: 10px !important;
            border: none;
            border-bottom-right-radius: 0 !important;
            border-top-right-radius: 0 !important;
            height: 62px;
            color: #064599 !important;
            font-size: 28px !important;
            font-weight: 400;
            line-height: 38px !important;
            padding: 12px 15px !important;
            padding-left: 35px !important;
            vertical-align: bottom;
            display: inline-block;
            float: left;
            width: calc( 100% - 68px) !important; }
            @media (max-width: 1223px) {
              #home_newsletter div.block__content .right #mlb2-1339442.ml-form-embedContainer.ml-subscribe-form .ml-form-embedWrapper .ml-form-embedBody form.ml-block-form .ml-form-fieldRow input[type=email] {
                width: 285px;
                font-size: 24px; } }
            @media (max-width: 479px) {
              #home_newsletter div.block__content .right #mlb2-1339442.ml-form-embedContainer.ml-subscribe-form .ml-form-embedWrapper .ml-form-embedBody form.ml-block-form .ml-form-fieldRow input[type=email] {
                width: 173px;
                padding: 9px 8px;
                line-height: 21px;
                font-size: 16px;
                border-radius: 4px 0 0 4px; } }
            #home_newsletter div.block__content .right #mlb2-1339442.ml-form-embedContainer.ml-subscribe-form .ml-form-embedWrapper .ml-form-embedBody form.ml-block-form .ml-form-fieldRow input[type=email]::-webkit-input-placeholder {
              /* Chrome/Opera/Safari */
              color: #1f5aa9;
              opacity: 1;
              font-weight: 300; }
            #home_newsletter div.block__content .right #mlb2-1339442.ml-form-embedContainer.ml-subscribe-form .ml-form-embedWrapper .ml-form-embedBody form.ml-block-form .ml-form-fieldRow input[type=email]::-moz-placeholder {
              /* Firefox 19+ */
              color: #1f5aa9;
              opacity: 1;
              font-weight: 300; }
            #home_newsletter div.block__content .right #mlb2-1339442.ml-form-embedContainer.ml-subscribe-form .ml-form-embedWrapper .ml-form-embedBody form.ml-block-form .ml-form-fieldRow input[type=email]:-ms-input-placeholder {
              /* IE 10+ */
              color: #1f5aa9;
              opacity: 1;
              font-weight: 300; }
            #home_newsletter div.block__content .right #mlb2-1339442.ml-form-embedContainer.ml-subscribe-form .ml-form-embedWrapper .ml-form-embedBody form.ml-block-form .ml-form-fieldRow input[type=email]:-moz-placeholder {
              /* Firefox 18- */
              color: #1f5aa9;
              opacity: 1;
              font-weight: 300; }
            @media (max-width: 767px) {
              #home_newsletter div.block__content .right #mlb2-1339442.ml-form-embedContainer.ml-subscribe-form .ml-form-embedWrapper .ml-form-embedBody form.ml-block-form .ml-form-fieldRow input[type=email] {
                height: 44px;
                font-size: 18px !important; } }
            #home_newsletter div.block__content .right #mlb2-1339442.ml-form-embedContainer.ml-subscribe-form .ml-form-embedWrapper .ml-form-embedBody form.ml-block-form .ml-form-fieldRow input[type=email] ::-webkit-input-placeholder {
              font-weight: 700; }
            #home_newsletter div.block__content .right #mlb2-1339442.ml-form-embedContainer.ml-subscribe-form .ml-form-embedWrapper .ml-form-embedBody form.ml-block-form .ml-form-fieldRow input[type=email] ::-moz-placeholder {
              font-weight: 700; }
            #home_newsletter div.block__content .right #mlb2-1339442.ml-form-embedContainer.ml-subscribe-form .ml-form-embedWrapper .ml-form-embedBody form.ml-block-form .ml-form-fieldRow input[type=email] :-ms-input-placeholder {
              font-weight: 700; }
            #home_newsletter div.block__content .right #mlb2-1339442.ml-form-embedContainer.ml-subscribe-form .ml-form-embedWrapper .ml-form-embedBody form.ml-block-form .ml-form-fieldRow input[type=email] :-moz-placeholder {
              font-weight: 700; }
          #home_newsletter div.block__content .right #mlb2-1339442.ml-form-embedContainer.ml-subscribe-form .ml-form-embedWrapper .ml-form-embedBody form.ml-block-form input[type=submit] {
            -moz-appearance: none;
            -webkit-appearance: none;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            display: inline-block;
            float: left;
            border: none;
            padding: 12px 0;
            line-height: 38px;
            cursor: pointer;
            color: transparent;
            width: 65px;
            background: #ffec00;
            position: relative;
            left: -6px;
            z-index: 0; }
            @media (max-width: 479px) {
              #home_newsletter div.block__content .right #mlb2-1339442.ml-form-embedContainer.ml-subscribe-form .ml-form-embedWrapper .ml-form-embedBody form.ml-block-form input[type=submit] {
                width: 55px;
                padding: 9px;
                line-height: 21px;
                background-size: auto 19px; } }
          #home_newsletter div.block__content .right #mlb2-1339442.ml-form-embedContainer.ml-subscribe-form .ml-form-embedWrapper .ml-form-embedBody .primary {
            background: #ffec00 !important;
            display: inline-block;
            float: left;
            width: 64px !important;
            height: 62px;
            position: relative;
            z-index: 1;
            border-radius: 10px !important;
            border-bottom-left-radius: 0 !important;
            border-top-left-radius: 0 !important;
            left: -1px;
            font-size: 0 !important; }
            @media (max-width: 767px) {
              #home_newsletter div.block__content .right #mlb2-1339442.ml-form-embedContainer.ml-subscribe-form .ml-form-embedWrapper .ml-form-embedBody .primary {
                height: 44px; } }
            #home_newsletter div.block__content .right #mlb2-1339442.ml-form-embedContainer.ml-subscribe-form .ml-form-embedWrapper .ml-form-embedBody .primary:before {
              content: '';
              background: #0a4396;
              position: absolute;
              top: 6px;
              left: -1px;
              bottom: 6px;
              width: 2px; }
              @media (max-width: 1223px) {
                #home_newsletter div.block__content .right #mlb2-1339442.ml-form-embedContainer.ml-subscribe-form .ml-form-embedWrapper .ml-form-embedBody .primary:before {
                  width: 1px;
                  top: 2px;
                  bottom: 2px; } }
            #home_newsletter div.block__content .right #mlb2-1339442.ml-form-embedContainer.ml-subscribe-form .ml-form-embedWrapper .ml-form-embedBody .primary:after {
              content: '\f105';
              display: block;
              position: absolute;
              right: 20px;
              top: 50%;
              transform: translateY(-50%);
              font-family: "FontAwesome";
              text-shadow: none;
              font-size: 52px;
              color: #0a4396; }
              @media (max-width: 767px) {
                #home_newsletter div.block__content .right #mlb2-1339442.ml-form-embedContainer.ml-subscribe-form .ml-form-embedWrapper .ml-form-embedBody .primary:after {
                  font-size: 35px; } }

#home_testimonials {
  padding: 141px 0 91px 0;
  display: block;
  width: 100%;
  background: url("/sites/all/themes/omgsub/images/testimonials.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }
  @media (max-width: 1300px) {
    #home_testimonials {
      padding: 90px 0 35px 0; } }
  #home_testimonials .view-testimonials {
    padding: 0 121px;
    position: relative; }
    @media (max-width: 1023px) {
      #home_testimonials .view-testimonials {
        padding: 0 60px; } }
    @media (max-width: 640px) {
      #home_testimonials .view-testimonials {
        padding: 0 30px; } }
    @media (max-width: 479px) {
      #home_testimonials .view-testimonials {
        padding: 0 15px; } }
    #home_testimonials .view-testimonials .flex-direction-nav a {
      transform: translateY(-50%); }
      #home_testimonials .view-testimonials .flex-direction-nav a:before {
        color: #0050a3; }
    #home_testimonials .view-testimonials .flex-direction-nav .flex-prev {
      left: -50px; }
    #home_testimonials .view-testimonials .flex-direction-nav .flex-next {
      right: -50px; }
    @media (max-width: 640px) {
      #home_testimonials .view-testimonials .flex-direction-nav .flex-prev {
        left: -33px; }
      #home_testimonials .view-testimonials .flex-direction-nav .flex-next {
        right: -33px; } }
    @media (max-width: 640px) {
      #home_testimonials .view-testimonials .flex-direction-nav .flex-prev {
        display: none; }
      #home_testimonials .view-testimonials .flex-direction-nav .flex-next {
        display: none; } }
    #home_testimonials .view-testimonials:after {
      content: '';
      display: block;
      width: 74px;
      height: 63px;
      bottom: -46px;
      right: 17px;
      position: absolute;
      background-size: cover;
      background: url("../images/sprites/svg/optimized/quote1_icon.svg");
      transform: rotate(180deg); }
      @media (max-width: 1300px) {
        #home_testimonials .view-testimonials:after {
          transform: rotate(180deg) scale(0.6);
          bottom: 50px;
          right: -3px; } }
      @media (max-width: 1023px) {
        #home_testimonials .view-testimonials:after {
          bottom: 63px;
          right: -16px; } }
      @media (max-width: 767px) {
        #home_testimonials .view-testimonials:after {
          transform: rotate(180deg) scale(0.4);
          bottom: -23px; } }
      @media (max-width: 640px) {
        #home_testimonials .view-testimonials:after {
          transform: rotate(180deg) scale(0.3);
          right: -24px; } }
      @media (max-width: 479px) {
        #home_testimonials .view-testimonials:after {
          transform: rotate(180deg) scale(0.25);
          right: -31px; } }
    #home_testimonials .view-testimonials:before {
      content: '';
      background: url("../images/sprites/svg/optimized/quote1_icon.svg");
      width: 74px;
      height: 63px;
      background-size: cover;
      display: block;
      position: absolute;
      top: -8px;
      left: 2px; }
      @media (max-width: 1300px) {
        #home_testimonials .view-testimonials:before {
          transform: scale(0.6); } }
      @media (max-width: 1023px) {
        #home_testimonials .view-testimonials:before {
          top: -40px;
          left: -38px; } }
      @media (max-width: 767px) {
        #home_testimonials .view-testimonials:before {
          left: -7px;
          transform: scale(0.4); } }
      @media (max-width: 640px) {
        #home_testimonials .view-testimonials:before {
          transform: scale(0.3);
          left: -28px; } }
      @media (max-width: 479px) {
        #home_testimonials .view-testimonials:before {
          transform: scale(0.25);
          left: -35px; } }
  #home_testimonials .view-footer .contextual-links-region.block--block-39 {
    position: static; }
  #home_testimonials .view-footer div.block__content {
    position: absolute;
    right: 142px;
    bottom: 42px;
    background: #ffec00;
    color: #0050a3;
    font-weight: 700;
    padding: 7px 9px 5px;
    font-size: 18px;
    max-width: 116px;
    border-radius: 8px;
    text-align: center;
    min-height: 0; }
    @media (min-width: 1024px) and (max-width: 1300px) {
      #home_testimonials .view-footer div.block__content {
        bottom: 0px;
        position: relative;
        float: right; } }
    @media (max-width: 1023px) {
      #home_testimonials .view-footer div.block__content {
        bottom: -0px;
        position: relative;
        right: unset; } }
    #home_testimonials .view-footer div.block__content p {
      margin-bottom: 0; }
  @media (max-width: 1023px) {
    #home_testimonials {
      padding-top: 60px;
      padding-bottom: 41px; } }
  @media (max-width: 767px) {
    #home_testimonials {
      padding-left: 30px;
      padding-right: 30px; } }
  @media (max-width: 479px) {
    #home_testimonials {
      padding: 24px 20px;
      padding-top: 60px; } }
  #home_testimonials h2.block__title {
    font-size: 40px;
    font-weight: 100;
    line-height: 26.98px;
    margin-top: 0;
    margin-bottom: 80px;
    color: white;
    text-align: center; }
    @media (max-width: 1023px) {
      #home_testimonials h2.block__title {
        font-size: 32px;
        line-height: 1;
        margin-bottom: 60px; } }
    @media (max-width: 479px) {
      #home_testimonials h2.block__title {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 45px; } }
  #home_testimonials h2.block__title {
    max-width: 1327px;
    margin-left: auto;
    margin-right: auto;
    max-width: 910px;
    letter-spacing: 1.9px;
    margin-left: 35px;
    margin-bottom: 0;
    color: #0050a3;
    position: relative;
    font-size: 42px;
    margin-bottom: -100px;
    z-index: 10; }
    @media (max-width: 940px) {
      #home_testimonials h2.block__title {
        padding-left: 15px;
        padding-right: 15px; } }
    #home_testimonials h2.block__title:after {
      content: " ";
      display: block;
      clear: both; }
    #home_testimonials h2.block__title:after {
      content: '';
      display: block;
      background-image: linear-gradient(to right, #ffec00 0, #ffec00 41px, transparent 41px, transparent 45px, #ffec00 45px, #ffec00 86px, transparent 86px, transparent 90px, #ffec00 90px, #ffec00 131px);
      height: 6px;
      width: 131px; }
    @media (max-width: 1300px) {
      #home_testimonials h2.block__title {
        font-size: 34px;
        max-width: 750px; } }
    @media (max-width: 1023px) {
      #home_testimonials h2.block__title {
        margin: auto;
        margin-bottom: -77px; } }
    @media (max-width: 767px) {
      #home_testimonials h2.block__title {
        margin-left: 0px;
        width: 100%; } }
    @media (max-width: 479px) {
      #home_testimonials h2.block__title {
        font-size: 24px; } }
    #home_testimonials h2.block__title:after {
      margin: 27px auto 0 auto; }
      @media (max-width: 1300px) {
        #home_testimonials h2.block__title:after {
          margin-top: 15px; } }
      @media (max-width: 1023px) {
        #home_testimonials h2.block__title:after {
          margin-top: 10px; } }
  #home_testimonials div.block__content {
    max-width: 1327px;
    margin-left: auto;
    margin-right: auto;
    max-width: 910px;
    margin-left: 32px;
    min-height: 440px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.8);
    padding: 124px 34px 34px 34px;
    position: relative; }
    @media (max-width: 940px) {
      #home_testimonials div.block__content {
        padding-left: 15px;
        padding-right: 15px; } }
    #home_testimonials div.block__content:after {
      content: " ";
      display: block;
      clear: both; }
    @media (max-width: 1300px) {
      #home_testimonials div.block__content {
        max-width: 750px;
        min-height: 390px; } }
    @media (max-width: 1023px) {
      #home_testimonials div.block__content {
        max-width: 1327px;
        margin-left: auto;
        margin-right: auto;
        max-width: 700px;
        padding: 100px 30px 25px 57px;
        min-height: 330px; } }
  @media (max-width: 1023px) and (max-width: 730px) {
    #home_testimonials div.block__content {
      padding-left: 15px;
      padding-right: 15px; } }
    @media (max-width: 1023px) {
        #home_testimonials div.block__content:after {
          content: " ";
          display: block;
          clear: both; } }
    @media (max-width: 1023px) {
      #home_testimonials div.block__content .view-testimonials {
        background-size: 109px auto;
        background-position: top left; } }
    @media (max-width: 479px) {
      #home_testimonials div.block__content .view-testimonials {
        background-position: center top;
        background-size: 61px auto; } }
    #home_testimonials div.block__content .view-testimonials .view-header {
      display: block;
      font-size: 26px;
      color: white;
      font-weight: 400;
      text-align: center; }
      @media (max-width: 1023px) {
        #home_testimonials div.block__content .view-testimonials .view-header {
          font-size: 22px; } }
      @media (max-width: 479px) {
        #home_testimonials div.block__content .view-testimonials .view-header {
          font-size: 18px; } }
    #home_testimonials div.block__content .view-testimonials .view-content .flexslider {
      border: none;
      background: none;
      margin-bottom: 0;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0; }
      @media (max-width: 479px) {
        #home_testimonials div.block__content .view-testimonials .view-content .flexslider {
          padding-left: 0; } }
      #home_testimonials div.block__content .view-testimonials .view-content .flexslider ul.slides li .body {
        color: #000000;
        font-size: 18px;
        line-height: 1.8;
        font-weight: 400; }
        @media (max-width: 1023px) {
          #home_testimonials div.block__content .view-testimonials .view-content .flexslider ul.slides li .body {
            font-size: 13px;
            line-height: 21px;
            font-weight: normal; } }
        @media (max-width: 479px) {
          #home_testimonials div.block__content .view-testimonials .view-content .flexslider ul.slides li .body {
            font-size: 12px;
            line-height: 1.5; } }
        @media (max-width: 1300px) {
          #home_testimonials div.block__content .view-testimonials .view-content .flexslider ul.slides li .body {
            font-size: 16px; } }
        #home_testimonials div.block__content .view-testimonials .view-content .flexslider ul.slides li .body * {
          margin: 0; }
      #home_testimonials div.block__content .view-testimonials .view-content .flexslider ul.slides li .rest {
        display: block;
        margin-top: 22px;
        font-size: 24px;
        color: #0050a3;
        font-weight: 700;
        max-width: 460px; }
        #home_testimonials div.block__content .view-testimonials .view-content .flexslider ul.slides li .rest:before {
          margin-bottom: 8px;
          display: block;
          background: linear-gradient(to right, #0050a3 0, #0050a3 30%, transparent 30%, transparent 33%, #0050a3 33%, #0050a3 63%, transparent 63%, transparent 66%, #0050a3 66%, #0050a3 100%);
          height: 3px;
          width: 70px;
          content: ''; }
        @media (max-width: 479px) {
          #home_testimonials div.block__content .view-testimonials .view-content .flexslider ul.slides li .rest {
            margin-top: 14px; } }
        @media (max-width: 1300px) {
          #home_testimonials div.block__content .view-testimonials .view-content .flexslider ul.slides li .rest {
            font-size: 20px;
            padding-right: 50px; } }
        @media (max-width: 767px) {
          #home_testimonials div.block__content .view-testimonials .view-content .flexslider ul.slides li .rest {
            font-size: 17px; } }
        @media (max-width: 479px) {
          #home_testimonials div.block__content .view-testimonials .view-content .flexslider ul.slides li .rest {
            font-size: 14px; } }
        #home_testimonials div.block__content .view-testimonials .view-content .flexslider ul.slides li .rest strong {
          color: #0050a3;
          font-size: 24px;
          font-weight: 700;
          line-height: 1.2;
          width: 100%;
          position: relative; }
          @media (max-width: 1023px) {
            #home_testimonials div.block__content .view-testimonials .view-content .flexslider ul.slides li .rest strong {
              font-size: 12px; } }
          #home_testimonials div.block__content .view-testimonials .view-content .flexslider ul.slides li .rest strong:after {
            content: '';
            position: absolute;
            height: 1px;
            background: #064599;
            bottom: -8px;
            left: 2px;
            width: 68px; }
        #home_testimonials div.block__content .view-testimonials .view-content .flexslider ul.slides li .rest p {
          font-size: 14px;
          font-weight: 400;
          line-height: 27px;
          color: #064599;
          margin-bottom: 0;
          margin-top: 10px; }
          @media (max-width: 1023px) {
            #home_testimonials div.block__content .view-testimonials .view-content .flexslider ul.slides li .rest p {
              font-size: 12px;
              margin-top: 2px; } }
  #home_testimonials ul.flex-direction-nav li {
    opacity: 1; }
    #home_testimonials ul.flex-direction-nav li a {
      opacity: 0;
      height: 60px;
      display: block;
      transition: opacity 0.5s ease; }
      @media (max-width: 1023px) {
        #home_testimonials ul.flex-direction-nav li a {
          height: 30px; } }
      @media (max-width: 767px) {
        #home_testimonials ul.flex-direction-nav li a {
          height: 24px;
          opacity: 1; } }
      #home_testimonials ul.flex-direction-nav li a:before {
        font-family: "FontAwesome";
        text-shadow: none;
        color: white;
        line-height: 60px;
        opacity: 1;
        bottom: 0;
        display: block;
        font-size: 65px;
        transition: color 0.25s ease; }
        @media (max-width: 1023px) {
          #home_testimonials ul.flex-direction-nav li a:before {
            line-height: 30px;
            font-size: 48px; } }
        @media (max-width: 767px) {
          #home_testimonials ul.flex-direction-nav li a:before {
            font-size: 32px;
            line-height: 24px;
            color: #064599; } }
      #home_testimonials ul.flex-direction-nav li a.flex-prev {
        left: -70px; }
        @media (max-width: 1223px) {
          #home_testimonials ul.flex-direction-nav li a.flex-prev {
            left: -60px; } }
        @media (max-width: 1023px) {
          #home_testimonials ul.flex-direction-nav li a.flex-prev {
            left: -97px; } }
        @media (max-width: 767px) {
          #home_testimonials ul.flex-direction-nav li a.flex-prev {
            left: -3%; } }
        @media (max-width: 479px) {
          #home_testimonials ul.flex-direction-nav li a.flex-prev {
            left: -27px; } }
        #home_testimonials ul.flex-direction-nav li a.flex-prev:before {
          content: '\f104';
          left: 0; }
      #home_testimonials ul.flex-direction-nav li a.flex-next {
        right: -70px; }
        @media (max-width: 1223px) {
          #home_testimonials ul.flex-direction-nav li a.flex-next {
            right: -60px; } }
        @media (max-width: 767px) {
          #home_testimonials ul.flex-direction-nav li a.flex-next {
            right: -3%; } }
        @media (max-width: 479px) {
          #home_testimonials ul.flex-direction-nav li a.flex-next {
            right: -27px; } }
        #home_testimonials ul.flex-direction-nav li a.flex-next:before {
          content: '\f105';
          right: 0; }
      #home_testimonials ul.flex-direction-nav li a:hover:before {
        color: #064599; }
  #home_testimonials:hover .flex-direction-nav a {
    opacity: 1; }

#home_specials {
  display: inline-block;
  width: 100%; }
  #home_specials div.block__content {
    max-width: 1327px;
    margin-left: auto;
    margin-right: auto;
    max-width: 940px;
    color: #000006;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px; }
    @media (max-width: 1023px) {
      #home_specials div.block__content {
        font-size: 13px;
        line-height: 21px; } }
    @media (max-width: 970px) {
      #home_specials div.block__content {
        padding-left: 15px;
        padding-right: 15px; } }
    #home_specials div.block__content:after {
      content: " ";
      display: block;
      clear: both; }
    @media (max-width: 479px) {
      #home_specials div.block__content {
        font-size: 12px;
        line-height: 1.5; } }
    #home_specials div.block__content img {
      max-width: 100%;
      height: auto !important; }

#home-offers {
  margin: 20px 0 10px;
  padding-top: 92px; }
  @media (max-width: 1223px) {
    #home-offers {
      padding-top: 10px;
      margin-bottom: 0; } }
  #home-offers h2.block__title {
    color: #000006;
    font-size: 40px;
    font-weight: 400;
    line-height: 27px;
    margin-top: 0;
    margin-bottom: 23px;
    text-align: center; }
    @media (max-width: 1223px) {
      #home-offers h2.block__title {
        font-size: 32px; } }
    @media (max-width: 479px) {
      #home-offers h2.block__title {
        font-size: 24px;
        margin-bottom: 15px; } }
  #home-offers .flexslider {
    border: none; }
    @media (max-width: 1223px) {
      #home-offers .flexslider {
        margin-bottom: 0; } }
  #home-offers .front-offer-item {
    width: 24.6841%;
    float: left;
    margin-right: 0.4212%;
    height: 355px;
    position: relative;
    overflow: hidden;
    color: white; }
    @media (max-width: 1223px) {
      #home-offers .front-offer-item {
        overflow: unset;
        height: 293px; }
        #home-offers .front-offer-item:first-child {
          width: 49.21851%;
          float: left;
          margin-right: 1.56299%; }
        #home-offers .front-offer-item:nth-child(2) {
          width: 49.21851%;
          float: right;
          margin-right: 0; } }
    #home-offers .front-offer-item:last-child {
      width: 49.7894%;
      float: right;
      margin-right: 0; }
      @media (max-width: 1223px) {
        #home-offers .front-offer-item:last-child {
          width: 100%;
          float: left;
          margin-left: 0;
          margin-right: 0;
          margin-bottom: 62px; }
          #home-offers .front-offer-item:last-child:not(:first-child) {
            margin-top: 101px; } }
      @media (max-width: 479px) {
        #home-offers .front-offer-item:last-child {
          margin-bottom: 98px; } }
      @media (max-width: 1223px) {
        #home-offers .front-offer-item:last-child .front-offer-button {
          bottom: -61px;
          height: 56px;
          padding: 11px 12px; } }
      @media (max-width: 479px) {
        #home-offers .front-offer-item:last-child .front-offer-button {
          bottom: -97px;
          height: 95px;
          padding: 32px 20px;
          width: 115px; } }
      @media (max-width: 1223px) {
        #home-offers .front-offer-item:last-child .front-offer-description {
          padding: 0 36px 0 12px;
          background-color: #064599;
          bottom: -61px;
          line-height: 56px;
          height: 56px;
          border-bottom: 16px solid #064599; } }
      @media (max-width: 479px) {
        #home-offers .front-offer-item:last-child .front-offer-description {
          left: 115px;
          bottom: -97px;
          height: 95px;
          padding: 0 20px 0 10px;
          border-top: 14px solid #064599;
          border-bottom-width: 14px;
          line-height: 16px; } }
    #home-offers .front-offer-item:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: #064599;
      transform: translate(0, 100%);
      transition: transform 0.5s ease;
      z-index: 5; }
      @media (max-width: 1223px) {
        #home-offers .front-offer-item:before {
          content: unset; } }
    #home-offers .front-offer-item:after {
      content: '';
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
      background: -moz-linear-gradient(top, transparent 0%, transparent 70%, rgba(0, 0, 0, 0.8) 100%);
      /* FF3.6-15 */
      background: -webkit-linear-gradient(top, transparent 0%, transparent 70%, rgba(0, 0, 0, 0.8) 100%);
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, transparent 0%, transparent 70%, rgba(0, 0, 0, 0.8) 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
      /* IE6-9 */
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 4; }
    #home-offers .front-offer-item .front-offer-price {
      padding: 12px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      border-radius: 8px;
      width: auto;
      height: 123px;
      display: inline-block;
      background: #064599;
      margin-top: 12px;
      margin-left: 12px;
      z-index: 8; }
      @media (max-width: 1223px) {
        #home-offers .front-offer-item .front-offer-price {
          padding: 7px 8px 6px;
          height: 104px;
          margin-top: 30px;
          margin-left: 40px; } }
      @media (max-width: 479px) {
        #home-offers .front-offer-item .front-offer-price {
          margin-top: 20px;
          margin-left: 20px;
          height: 92px; } }
      #home-offers .front-offer-item .front-offer-price p {
        color: white;
        font-size: 24px;
        font-weight: 400;
        line-height: 27px; }
        @media (max-width: 1223px) {
          #home-offers .front-offer-item .front-offer-price p {
            font-size: 20px;
            line-height: 1; } }
        @media (max-width: 479px) {
          #home-offers .front-offer-item .front-offer-price p {
            font-size: 16px;
            line-height: 1; } }
        #home-offers .front-offer-item .front-offer-price p strong {
          color: #ffec00;
          font-size: 60px;
          line-height: 27px;
          font-weight: 900;
          display: block;
          clear: both;
          margin: 10px auto; }
          @media (max-width: 1223px) {
            #home-offers .front-offer-item .front-offer-price p strong {
              font-size: 48px;
              line-height: 1;
              margin: 0; } }
          @media (max-width: 479px) {
            #home-offers .front-offer-item .front-offer-price p strong {
              font-size: 40px; } }
    #home-offers .front-offer-item .front-offer-title {
      text-overflow: ellipsis;
      padding-right: 60px;
      white-space: nowrap;
      overflow: hidden;
      right: 0;
      font-size: 20px;
      font-weight: 400;
      line-height: 27px;
      text-transform: uppercase;
      margin: 0;
      position: absolute;
      bottom: 26px;
      left: 30px;
      visibility: visible;
      opacity: 1;
      z-index: 8;
      -webkit-transition: all 0.1s linear;
      -moz-transition: all 0.1s linear;
      -o-transition: all 0.1s linear;
      transition: all 0.1s linear; }
      @media (max-width: 1223px) {
        #home-offers .front-offer-item .front-offer-title {
          font-size: 16px;
          bottom: 10px;
          left: 40px;
          padding-right: 60px;
          font-weight: bold;
          line-height: 30px;
          height: 30px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          right: 0; } }
      @media (max-width: 479px) {
        #home-offers .front-offer-item .front-offer-title {
          font-size: 14px;
          left: 20px;
          padding-right: 45px; } }
    #home-offers .front-offer-item .front-offer-button {
      display: block;
      z-index: 8; }
      @media (max-width: 1223px) {
        #home-offers .front-offer-item .front-offer-button {
          position: absolute;
          bottom: -95px;
          left: 0;
          right: calc(100% - 157px);
          height: 89px;
          background-color: #064599;
          text-align: right;
          padding: 17px 12px; } }
      #home-offers .front-offer-item .front-offer-button a {
        position: absolute;
        visibility: hidden;
        opacity: 0;
        display: inline-block;
        font-size: 20px;
        font-weight: 700;
        text-transform: uppercase;
        color: white;
        background: #ffec00;
        line-height: 35px;
        background: #ffec00;
        padding-left: 10px;
        padding-right: 10px;
        text-decoration: none;
        margin-top: 10px;
        margin-left: 12px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        border-radius: 8px;
        transition: background-color 0.25s ease, opacity 0.5s ease; }
        @media (max-width: 1223px) {
          #home-offers .front-offer-item .front-offer-button a {
            visibility: unset;
            opacity: unset;
            font-size: 16px;
            line-height: 1;
            padding: 9px 6px;
            font-weight: 900;
            position: unset;
            margin: 0; } }
        #home-offers .front-offer-item .front-offer-button a:hover {
          background-color: #c7b805; }
    #home-offers .front-offer-item .front-offer-description {
      position: absolute;
      visibility: hidden;
      opacity: 0;
      float: right;
      max-width: 200px;
      margin-right: 10px;
      margin-top: 10px;
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      color: white;
      z-index: 8; }
      @media (max-width: 1500px) {
        #home-offers .front-offer-item .front-offer-description {
          max-width: 163px; } }
      @media (max-width: 1327px) {
        #home-offers .front-offer-item .front-offer-description {
          max-width: 143px; } }
      @media (max-width: 1223px) {
        #home-offers .front-offer-item .front-offer-description {
          visibility: unset;
          opacity: unset;
          float: unset;
          max-width: unset;
          margin: 0;
          font-size: 14px;
          line-height: 1.25;
          padding: 15px 36px 0 12px;
          background-color: #064599;
          bottom: -95px;
          right: 0;
          left: 157px;
          height: 89px;
          overflow: hidden;
          box-sizing: border-box;
          border-bottom: 19px solid #064599; }
          #home-offers .front-offer-item .front-offer-description > * {
            margin-bottom: 1px; } }
      @media (max-width: 479px) {
        #home-offers .front-offer-item .front-offer-description {
          overflow-y: auto; }
          #home-offers .front-offer-item .front-offer-description > * {
            margin-bottom: 1px; } }
    #home-offers .front-offer-item .front-offer-info {
      position: absolute;
      right: 16px;
      bottom: 22px;
      z-index: 8;
      display: inline-block;
      font-size: 0;
      color: transparent;
      width: 37px;
      height: 37px;
      border-radius: 37px;
      vertical-align: bottom;
      padding: 0;
      margin: 0;
      box-sizing: content-box; }
      @media (max-width: 1223px) {
        #home-offers .front-offer-item .front-offer-info {
          width: 30px;
          height: 30px;
          right: 11px;
          bottom: 10px; } }
      #home-offers .front-offer-item .front-offer-info:after {
        content: '\f05a';
        font-family: FontAwesome;
        font-size: 32px;
        position: absolute;
        color: #064599;
        top: 5px;
        right: 5px;
        bottom: 5px;
        left: 5px;
        display: block;
        border-radius: 27px;
        text-align: center;
        line-height: 27px;
        padding: 0;
        margin: 0;
        transition: color 0.5s linear; }
        @media (max-width: 1223px) {
          #home-offers .front-offer-item .front-offer-info:after {
            font-size: 28px;
            border-radius: 24px;
            top: 3px;
            right: 3px;
            bottom: 3px;
            left: 3px;
            line-height: 24px; } }
      #home-offers .front-offer-item .front-offer-info:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        border-radius: 37px;
        border: 6px solid #064599;
        transition: border-color .5s linear; }
        @media (max-width: 1223px) {
          #home-offers .front-offer-item .front-offer-info:before {
            border-radius: 30px;
            border-width: 4px; } }
    #home-offers .front-offer-item:hover:before {
      transform: unset; }
    #home-offers .front-offer-item:hover .front-offer-info:after {
      color: #ffec00; }
    #home-offers .front-offer-item:hover .front-offer-info:before {
      border-color: #ffec00; }
    #home-offers .front-offer-item:hover .front-offer-title {
      visibility: hidden;
      opacity: 0; }
      @media (max-width: 1223px) {
        #home-offers .front-offer-item:hover .front-offer-title {
          visibility: unset;
          opacity: unset; } }
    #home-offers .front-offer-item:hover .front-offer-description {
      position: relative;
      visibility: visible;
      opacity: 1;
      -webkit-transition: all 0.5s linear;
      -moz-transition: all 0.5s linear;
      -o-transition: all 0.5s linear;
      transition: all 0.5s linear; }
      @media (max-width: 1223px) {
        #home-offers .front-offer-item:hover .front-offer-description {
          position: absolute; } }
    #home-offers .front-offer-item:hover .front-offer-button {
      display: block; }
      #home-offers .front-offer-item:hover .front-offer-button a {
        position: relative;
        visibility: visible;
        opacity: 1; }
    #home-offers .front-offer-item > * {
      position: relative; }
  #home-offers .flex-control-nav {
    margin-top: 10px; }
    @media (max-width: 1223px) {
      #home-offers .flex-control-nav {
        display: none; } }
    #home-offers .flex-control-nav li {
      margin: 0 6px 0 0; }
      #home-offers .flex-control-nav li:last-child {
        margin: 0; }
    #home-offers .flex-control-nav a {
      width: 15px;
      height: 15px;
      border: 1px solid #064599;
      background-color: transparent;
      box-shadow: unset; }
      #home-offers .flex-control-nav a.flex-active {
        background-color: #064599; }

body.page-node-223 .l-main .l-content article {
  float: left;
  width: 100%;
  max-width: 100%; }
  body.page-node-223 .l-main .l-content article .node__content {
    margin: 0; }
    body.page-node-223 .l-main .l-content article .node__content .field__item > h2:first-child {
      color: black;
      border-bottom: none;
      font-weight: 300;
      font-size: 40px;
      margin: 17px 0 7px; }

body.page-node-223 .l-main .l-content #block-views-about-crete-block {
  float: left;
  width: 100%;
  max-width: 100%; }
  body.page-node-223 .l-main .l-content #block-views-about-crete-block .block__content .view-about-crete .view-content .location-item .location-title {
    color: #064599;
    border-bottom: 1px solid #064599;
    padding-bottom: 21px;
    font-size: 28px; }
  body.page-node-223 .l-main .l-content #block-views-about-crete-block .block__content .view-about-crete .view-content .location-item .location-carousel {
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 50px; }
    body.page-node-223 .l-main .l-content #block-views-about-crete-block .block__content .view-about-crete .view-content .location-item .location-carousel .slick--view--about-crete {
      position: relative; }
      body.page-node-223 .l-main .l-content #block-views-about-crete-block .block__content .view-about-crete .view-content .location-item .location-carousel .slick--view--about-crete .slick-slider .slick-list .slick-track .slick__slide .slide__content .slick-media .slick__colorbox .slick__image {
        padding-right: 8px;
        padding-left: 8px;
        max-width: 100%;
        height: auto;
        margin: auto;
        width: auto; }
      body.page-node-223 .l-main .l-content #block-views-about-crete-block .block__content .view-about-crete .view-content .location-item .location-carousel .slick--view--about-crete .slick-slider .slick-list .slick-track .slick__slide .slide__content .slick-media .slick__colorbox i:before {
        display: none; }
      body.page-node-223 .l-main .l-content #block-views-about-crete-block .block__content .view-about-crete .view-content .location-item .location-carousel .slick--view--about-crete .slick-slider .slick-list .slick-track .slick__slide .slide__content .slick-media .slick__colorbox i:after {
        display: none; }
    body.page-node-223 .l-main .l-content #block-views-about-crete-block .block__content .view-about-crete .view-content .location-item .location-carousel .slick__arrow {
      height: 0 !important; }
      body.page-node-223 .l-main .l-content #block-views-about-crete-block .block__content .view-about-crete .view-content .location-item .location-carousel .slick__arrow .slick-arrow {
        position: absolute;
        transform: translate(0, -50%);
        top: 50%;
        display: block;
        width: 25px;
        height: 60px;
        border: none;
        background: none;
        font-size: 0;
        cursor: pointer; }
        body.page-node-223 .l-main .l-content #block-views-about-crete-block .block__content .view-about-crete .view-content .location-item .location-carousel .slick__arrow .slick-arrow.slick-prev {
          left: -22px; }
          body.page-node-223 .l-main .l-content #block-views-about-crete-block .block__content .view-about-crete .view-content .location-item .location-carousel .slick__arrow .slick-arrow.slick-prev:before {
            content: '\f104'; }
        body.page-node-223 .l-main .l-content #block-views-about-crete-block .block__content .view-about-crete .view-content .location-item .location-carousel .slick__arrow .slick-arrow.slick-next {
          right: -22px; }
          body.page-node-223 .l-main .l-content #block-views-about-crete-block .block__content .view-about-crete .view-content .location-item .location-carousel .slick__arrow .slick-arrow.slick-next:before {
            content: '\f105'; }
        body.page-node-223 .l-main .l-content #block-views-about-crete-block .block__content .view-about-crete .view-content .location-item .location-carousel .slick__arrow .slick-arrow:before {
          font-family: FontAwesome;
          position: absolute;
          top: 0;
          right: 50%;
          bottom: 0;
          color: #064599;
          font-size: 50px;
          line-height: 60px;
          display: inline-block;
          transform: translate(50%, 0);
          cursor: pointer; }

@media (max-width: 640px) {
  body.page-node-223 .l-main .l-content #block-views-about-crete-block .block__content .view-about-crete .view-content .location-item .location-carousel .slick__arrow .slick-prev:before {
    left: 38px; }
  body.page-node-223 .l-main .l-content #block-views-about-crete-block .block__content .view-about-crete .view-content .location-item .location-carousel .slick__arrow .slick-next:before {
    right: 38px; } }

@media (max-width: 562px) {
  body.page-node-223 .l-main .l-content #block-views-about-crete-block .block__content .view-about-crete .view-content .location-item .location-carousel .slick__arrow .slick-prev:before {
    left: 10px; }
  body.page-node-223 .l-main .l-content #block-views-about-crete-block .block__content .view-about-crete .view-content .location-item .location-carousel .slick__arrow .slick-next:before {
    right: 10px; } }

@media (max-width: 450px) {
  body.page-node-223 .l-main .l-content #block-views-about-crete-block .block__content .view-about-crete .view-content .location-item .location-carousel .slick__arrow .slick-prev:before {
    left: -18px; }
  body.page-node-223 .l-main .l-content #block-views-about-crete-block .block__content .view-about-crete .view-content .location-item .location-carousel .slick__arrow .slick-next:before {
    right: -18px; } }

body.page-node-218 .l-main .l-content article .node__content .field__item > h2:first-child {
  color: black;
  border-bottom: none;
  font-weight: 300;
  font-size: 40px;
  margin: 17px 0 7px; }
  @media (max-width: 1023px) {
    body.page-node-218 .l-main .l-content article .node__content .field__item > h2:first-child {
      font-size: 32px;
      margin-top: 7px;
      margin-bottom: 30px;
      line-height: 1.1; } }

body.page-node-218 h3.section-title {
  color: #064599;
  font-weight: 300;
  font-size: 29px;
  line-height: 1.4;
  margin-top: -10px; }

body.node-type-blog-post div .l-main .l-content article .node__content .field--name-field-image .field__items .field__item, body.node-type-blog-post div .l-main .l-content article .node__content.field--name-title-field .field__items .field__item {
  text-align: center;
  margin-bottom: 20px; }
  body.node-type-blog-post div .l-main .l-content article .node__content .field--name-field-image .field__items .field__item img, body.node-type-blog-post div .l-main .l-content article .node__content.field--name-title-field .field__items .field__item img {
    width: 100%;
    height: auto;
    max-width: 559px; }

body.node-type-blog-post div .l-main .l-content article .node__content .field--name-title-field .field__items .field__item {
  text-align: center;
  color: #064599;
  font-size: 28px;
  line-height: 28px;
  font-weight: 700;
  margin-bottom: 30px;
  margin-top: 30px;
  border-bottom: 1px solid #064599;
  padding-bottom: 15px; }

body.node-type-blog-post div .l-main .l-content article .node__content .field--name-body .field__items .field__item p {
  color: #000;
  font-size: 18px; }

body.page-node-217 div .l-main .l-content #block-views-blog-block .block__content .view-blog .view-content .blog-post-preview {
  padding-left: 8.46591%;
  padding-right: 8.46591%;
  margin-bottom: 30px;
  overflow: hidden; }
  body.page-node-217 div .l-main .l-content #block-views-blog-block .block__content .view-blog .view-content .blog-post-preview a {
    display: inline-block;
    font-size: 28px;
    font-weight: 700;
    padding-left: 25px;
    padding-right: 25px;
    line-height: 27px;
    margin-bottom: 15px;
    width: calc(100% - 400px); }
  body.page-node-217 div .l-main .l-content #block-views-blog-block .block__content .view-blog .view-content .blog-post-preview img {
    width: 400px;
    height: 250px;
    float: left;
    object-fit: contain; }
  body.page-node-217 div .l-main .l-content #block-views-blog-block .block__content .view-blog .view-content .blog-post-preview .summary {
    width: calc(100% - 400px);
    float: right;
    padding-left: 25px;
    padding-right: 25px;
    margin-bottom: 20px; }
    body.page-node-217 div .l-main .l-content #block-views-blog-block .block__content .view-blog .view-content .blog-post-preview .summary p {
      border-top: 1px solid #064599;
      padding-top: 10px;
      font-size: 18px;
      color: #000; }
  body.page-node-217 div .l-main .l-content #block-views-blog-block .block__content .view-blog .view-content .blog-post-preview .link-read-more {
    width: calc(100% - 427px);
    float: right; }
    body.page-node-217 div .l-main .l-content #block-views-blog-block .block__content .view-blog .view-content .blog-post-preview .link-read-more a {
      width: unset;
      border: 1px solid #ffec00;
      background-color: #ffec00;
      color: #ffffff;
      font-weight: 700;
      padding: 5px 13px;
      border-radius: 8px;
      font-size: 18px;
      text-transform: uppercase; }

@media (max-width: 1054px) {
  body.page-node-217 div .l-main .l-content #block-views-blog-block .block__content .view-blog .view-content .blog-post-preview {
    padding-left: 4.23295%;
    padding-right: 4.23295%; }
    body.page-node-217 div .l-main .l-content #block-views-blog-block .block__content .view-blog .view-content .blog-post-preview img {
      height: 270px; } }

@media (max-width: 931px) {
  body.page-node-217 div .l-main .l-content #block-views-blog-block .block__content .view-blog .view-content .blog-post-preview {
    padding-left: 4.23295%;
    padding-right: 4.23295%; }
    body.page-node-217 div .l-main .l-content #block-views-blog-block .block__content .view-blog .view-content .blog-post-preview img {
      width: 40%; }
    body.page-node-217 div .l-main .l-content #block-views-blog-block .block__content .view-blog .view-content .blog-post-preview a {
      width: 60%; }
    body.page-node-217 div .l-main .l-content #block-views-blog-block .block__content .view-blog .view-content .blog-post-preview .summary {
      width: 60%;
      margin-bottom: 0; }
    body.page-node-217 div .l-main .l-content #block-views-blog-block .block__content .view-blog .view-content .blog-post-preview .link-read-more {
      width: calc(60% - 27px); }
      body.page-node-217 div .l-main .l-content #block-views-blog-block .block__content .view-blog .view-content .blog-post-preview .link-read-more a {
        padding: 6px 13px;
        font-size: 17px; } }

@media (max-width: 768px) {
  body.page-node-217 div .l-main .l-content #block-views-blog-block .block__content .view-blog .view-content .blog-post-preview {
    padding-left: 4.23295%;
    padding-right: 4.23295%;
    display: flex;
    flex-flow: column; }
    body.page-node-217 div .l-main .l-content #block-views-blog-block .block__content .view-blog .view-content .blog-post-preview a {
      margin-bottom: 15px;
      margin-top: 15px;
      order: 2;
      padding-left: 0;
      width: 100%; }
    body.page-node-217 div .l-main .l-content #block-views-blog-block .block__content .view-blog .view-content .blog-post-preview img {
      width: 100%;
      display: block;
      order: 1; }
    body.page-node-217 div .l-main .l-content #block-views-blog-block .block__content .view-blog .view-content .blog-post-preview .summary {
      width: 100%;
      margin-bottom: 0;
      overflow: hidden;
      order: 3;
      padding-left: 0;
      padding-right: 0; }
    body.page-node-217 div .l-main .l-content #block-views-blog-block .block__content .view-blog .view-content .blog-post-preview .link-read-more {
      width: 100%;
      order: 4;
      text-align: center; }
      body.page-node-217 div .l-main .l-content #block-views-blog-block .block__content .view-blog .view-content .blog-post-preview .link-read-more a {
        margin-top: 10px; } }

@media (max-width: 480px) {
  body.page-node-217 div .l-main .l-content #block-views-blog-block .block__content .view-blog .view-content .blog-post-preview {
    padding-left: 0%;
    padding-right: 0%; } }

body.page-node-217 div .l-main .l-content #block-views-blog-block .block__content .view-blog .pager {
  width: 100%; }
  body.page-node-217 div .l-main .l-content #block-views-blog-block .block__content .view-blog .pager li {
    display: inline-block; }
  body.page-node-217 div .l-main .l-content #block-views-blog-block .block__content .view-blog .pager .pager-current {
    color: #064599;
    font-weight: 700; }

@media (max-width: 480px) {
  body.page-node-217 div .l-main .l-content {
    padding-left: 0;
    padding-right: 0; } }

body.page-node-121 .l-main {
  padding-bottom: 99px; }
  body.page-node-121 .l-main .l-content article {
    padding: 0; }

#block-rent-a-car-rent-a-car-reservation .block__content {
  padding: 0 0 0 6px; }
  #block-rent-a-car-rent-a-car-reservation .block__content div .form-section {
    color: #064599;
    font-size: 20px;
    line-height: 52px;
    font-weight: 900;
    border-width: 1px 0;
    border-style: solid;
    border-color: #eeefef;
    margin: 0 0 15px; }
    @media (max-width: 640px) {
      #block-rent-a-car-rent-a-car-reservation .block__content div .form-section {
        font-size: 16px; } }
    @media (max-width: 1023px) {
      #block-rent-a-car-rent-a-car-reservation .block__content div .form-section:nth-child(6) {
        line-height: 30px;
        padding: 13px 0px; } }
    #block-rent-a-car-rent-a-car-reservation .block__content div .form-section:not(:first-of-type) {
      margin-top: 12px; }
  #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras-select {
    margin-top: 31px; }
    #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras-select .fieldset-legend {
      position: relative;
      padding-left: 46px; }
      #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras-select .fieldset-legend:before {
        content: "";
        position: absolute;
        display: inline-block;
        width: 30px;
        height: 30px;
        top: -8px;
        left: 0;
        background-image: url("/sites/all/themes/omgsub/images/CHILD_SEAT_ICON.svg");
        background-repeat: no-repeat; }
    #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras-select .fieldset-wrapper {
      width: 100%;
      display: inline-block;
      margin-top: 7px; }
      #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras-select .fieldset-wrapper .form-type-select {
        width: 31.64557%;
        float: left;
        margin-right: 2.53165%;
        max-width: 253px; }
        #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras-select .fieldset-wrapper .form-type-select:last-child {
          width: 31.64557%;
          float: right;
          margin-right: 0;
          float: left; }
        @media (max-width: 1023px) {
          #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras-select .fieldset-wrapper .form-type-select {
            width: 100%;
            max-width: unset;
            margin-right: 0; }
            #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras-select .fieldset-wrapper .form-type-select:last-child {
              width: 100%; } }
        #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras-select .fieldset-wrapper .form-type-select label {
          font-size: 14px;
          color: #000;
          font-weight: 700;
          margin-bottom: 6px;
          margin-top: 6px; }
        #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras-select .fieldset-wrapper .form-type-select select {
          width: 58px;
          height: 24px;
          border-radius: 5px;
          border: 1px solid #e2e3e3;
          position: relative;
          display: inline; }
          #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras-select .fieldset-wrapper .form-type-select select:before {
            content: "";
            position: absolute;
            float: left;
            width: 50px;
            height: 50px;
            background-color: red;
            left: 0; }
        #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras-select .fieldset-wrapper .form-type-select .description {
          display: inline;
          width: 180px; }
          #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras-select .fieldset-wrapper .form-type-select .description .ext_price {
            background-color: #d9dada;
            padding: 3px 8px;
            border: 1px solid #d9dada;
            border-bottom-left-radius: 5px;
            border-top-left-radius: 5px;
            margin-left: 6px; }
          #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras-select .fieldset-wrapper .form-type-select .description .price {
            width: 100%;
            max-width: 95px;
            height: 23px;
            border-radius: 5px;
            border: 1px solid #e2e3e3;
            display: inline-block;
            text-align: left;
            padding-left: 19px;
            top: 1px;
            position: relative;
            border-left: none;
            right: 2px;
            line-height: 24px; }
            @media (max-width: 1060px) {
              #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras-select .fieldset-wrapper .form-type-select .description .price {
                max-width: 84px; } }
          #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras-select .fieldset-wrapper .form-type-select .description .info {
            margin-top: 15px;
            padding-right: 20px; }
            #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras-select .fieldset-wrapper .form-type-select .description .info strong {
              color: #064599; }
  #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras {
    counter-reset: extras; }
    #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras .form-item.error {
      border-color: #ff0000; }
    #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras .form-item.form-type-checkbox {
      border-bottom: 1px solid #eeefef;
      padding-left: 55px;
      padding-right: calc(28.2% + 100px);
      position: relative;
      margin-bottom: 10px; }
      @media (max-width: 1327px) {
        #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras .form-item.form-type-checkbox {
          padding-right: calc(10% + 100px); } }
  @media (max-width: 1327px) and (max-width: 1023px) {
    #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras .form-item.form-type-checkbox {
      padding-right: calc(10% + 50px); } }
  @media (max-width: 1327px) and (max-width: 479px) {
    #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras .form-item.form-type-checkbox {
      padding-right: calc(5% + 20px); } }
      #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras .form-item.form-type-checkbox:before {
        content: '';
        display: block;
        width: 42px;
        height: 42px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        position: absolute;
        margin-right: 23px;
        top: 0;
        left: 0; }
      @media (max-width: 479px) {
        #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras .form-item.form-type-checkbox {
          padding-left: 20px; }
          #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras .form-item.form-type-checkbox:before {
            left: -24px;
            transform: scale(0.7); } }
    #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras .form-item.form-item-extras-9:before, #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras .form-item.form-item-extras-10:before, #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras .form-item.form-item-extras-11:before, #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras .form-item.form-item-extras-12:before, #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras .form-item.form-item-extras-13:before {
      background-image: url("/sites/all/themes/omgsub/images/icon_insurance.svg"); }
    #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras .form-item.form-item-extras-14:before, #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras .form-item.form-item-extras-15:before, #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras .form-item.form-item-extras-16:before {
      background-image: url("/sites/all/themes/omgsub/images/icon_child_seat.svg"); }
    #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras .form-item.form-item-extras-17:before {
      background-image: url("/sites/all/themes/omgsub/images/GPS_ICON.svg");
      background-size: 75%; }
      @media (max-width: 479px) {
        #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras .form-item.form-item-extras-17:before {
          background-size: 100%;
          width: 28px;
          height: 28px;
          left: -15px; } }
    #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras .form-item .form-checkbox {
      display: none; }
      #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras .form-item .form-checkbox:checked + .option:after {
        content: "\f00c"; }
    #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras .form-item .option {
      color: #064599;
      line-height: 29px;
      font-weight: bold;
      position: absolute;
      text-transform: uppercase;
      padding-right: 52px;
      top: 50%;
      right: 28.2%;
      transform: translate(0, -50%); }
      #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras .form-item .option:after {
        content: "";
        display: inline-block;
        position: absolute;
        width: 27px;
        height: 27px;
        top: 0;
        right: 0;
        border: 1px solid #064599;
        cursor: pointer;
        color: #064599;
        text-align: center;
        line-height: 27px;
        font-family: 'FontAwesome'; }
      @media (max-width: 1327px) {
        #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras .form-item .option {
          right: 10%; } }
      @media (max-width: 1023px) {
        #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras .form-item .option {
          right: 5px; } }
      @media (max-width: 767px) {
        #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras .form-item .option {
          padding-right: 37px; } }
      @media (max-width: 479px) {
        #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras .form-item .option {
          right: -9px;
          font-size: 0; }
          #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras .form-item .option:after {
            font-size: 13px; } }
    #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras .form-item .description {
      margin-left: 0;
      font-weight: 300;
      font-size: 16px;
      line-height: 1.25;
      padding-bottom: 17px; }
      #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras .form-item .description strong,
      #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras .form-item .description b {
        font-weight: bold; }
      #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras .form-item .description .extra-title {
        color: #064599;
        font-size: 18px;
        line-height: 29px;
        margin-bottom: 5px;
        font-weight: 900; }
        @media (max-width: 479px) {
          #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras .form-item .description .extra-title {
            font-size: 15px;
            line-height: 19px; } }
        #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras .form-item .description .extra-title:before {
          content: counter(extras) ". ";
          counter-increment: extras; }
      #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras .form-item .description p {
        color: black;
        max-width: 500px; }
        #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras .form-item .description p:last-child {
          margin-bottom: 0; }
          @media (max-width: 479px) {
            #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras .form-item .description p:last-child {
              font-size: 13px; } }
    #block-rent-a-car-rent-a-car-reservation .block__content div #edit-extras .form-item:last-child {
      border-bottom: none; }
  #block-rent-a-car-rent-a-car-reservation .block__content div .insurance_info {
    padding-left: 0;
    width: 100%;
    display: inline-block; }
    #block-rent-a-car-rent-a-car-reservation .block__content div .insurance_info:before {
      display: none; }
    #block-rent-a-car-rent-a-car-reservation .block__content div .insurance_info .desc {
      font-size: 16px;
      font-weight: 400;
      width: 63.66197%;
      float: left;
      margin-right: 1.40845%; }
    #block-rent-a-car-rent-a-car-reservation .block__content div .insurance_info .link {
      width: 34.08451%;
      float: right;
      margin-right: 0;
      background-color: #064599;
      border-radius: 7px;
      margin-top: 9px; }
      #block-rent-a-car-rent-a-car-reservation .block__content div .insurance_info .link a {
        color: #ffec00;
        font-size: 20px;
        font-weight: 700;
        line-height: 41px;
        text-align: center;
        width: 100%;
        display: inline-block;
        padding-left: 19px;
        padding-right: 19px; }
    @media (max-width: 1285px) {
      #block-rent-a-car-rent-a-car-reservation .block__content div .insurance_info .desc {
        width: 54.11765%;
        float: left;
        margin-right: 5.88235%; }
      #block-rent-a-car-rent-a-car-reservation .block__content div .insurance_info .link {
        width: 40%;
        float: right;
        margin-right: 0; } }
    @media (max-width: 1023px) {
      #block-rent-a-car-rent-a-car-reservation .block__content div .insurance_info .desc {
        width: 100%;
        margin-right: 0;
        display: inline-block;
        margin-bottom: 20px; }
      #block-rent-a-car-rent-a-car-reservation .block__content div .insurance_info .link {
        width: 100%;
        float: unset;
        margin: auto;
        display: block;
        margin-top: 20px;
        overflow: hidden; } }
  #block-rent-a-car-rent-a-car-reservation .block__content div .form-wrapper {
    border: none;
    margin: 0;
    padding: 0; }
    #block-rent-a-car-rent-a-car-reservation .block__content div .form-wrapper#edit-travel-info {
      margin-bottom: 0px; }
      #block-rent-a-car-rent-a-car-reservation .block__content div .form-wrapper#edit-travel-info .form-type-textarea {
        width: 100%; }
    #block-rent-a-car-rent-a-car-reservation .block__content div .form-wrapper#edit-heading {
      margin-top: 15px;
      width: 100%;
      display: inline-block; }
    #block-rent-a-car-rent-a-car-reservation .block__content div .form-wrapper .fieldset-legend {
      color: #3d7bcc;
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 9px;
      display: inline-block; }
      @media (max-width: 479px) {
        #block-rent-a-car-rent-a-car-reservation .block__content div .form-wrapper .fieldset-legend {
          font-size: 16px;
          margin-bottom: 0; } }
    #block-rent-a-car-rent-a-car-reservation .block__content div .form-wrapper .fieldset-wrapper {
      margin-top: 5px; }
    #block-rent-a-car-rent-a-car-reservation .block__content div .form-wrapper .info-block {
      margin: 2px 0;
      margin-bottom: 14px;
      padding-bottom: 20px;
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0;
      font-size: 16px;
      line-height: 42px; }
    @media (min-width: 1023px) {
      #block-rent-a-car-rent-a-car-reservation .block__content div .form-wrapper .form-item:nth-of-type(-n+6), #block-rent-a-car-rent-a-car-reservation .block__content div .form-wrapper .form-item:nth-of-type(n+10) {
        width: 50%;
        float: left;
        margin-bottom: 10px; }
      #block-rent-a-car-rent-a-car-reservation .block__content div .form-wrapper .form-item.form-item-driving-license-no, #block-rent-a-car-rent-a-car-reservation .block__content div .form-wrapper .form-item.form-item-city, #block-rent-a-car-rent-a-car-reservation .block__content div .form-wrapper .form-item.form-item-birth-date, #block-rent-a-car-rent-a-car-reservation .block__content div .form-wrapper .form-item.form-item-country, #block-rent-a-car-rent-a-car-reservation .block__content div .form-wrapper .form-item.form-item-drivers-age {
        width: 25%;
        float: left; }
      #block-rent-a-car-rent-a-car-reservation .block__content div .form-wrapper .form-item.form-item-no-of-people {
        width: 50%;
        float: left;
        margin-bottom: 0; }
      #block-rent-a-car-rent-a-car-reservation .block__content div .form-wrapper .form-item.form-item-last-name input, #block-rent-a-car-rent-a-car-reservation .block__content div .form-wrapper .form-item.form-item-phone input, #block-rent-a-car-rent-a-car-reservation .block__content div .form-wrapper .form-item.form-item-no-of-people input, #block-rent-a-car-rent-a-car-reservation .block__content div .form-wrapper .form-item.form-item-location input, #block-rent-a-car-rent-a-car-reservation .block__content div .form-wrapper .form-item.form-item-ship-route input, #block-rent-a-car-rent-a-car-reservation .block__content div .form-wrapper .form-item.form-item-notes input {
        margin-right: 0;
        margin-left: 17px;
        width: calc(100% - 13px); }
      #block-rent-a-car-rent-a-car-reservation .block__content div .form-wrapper .form-item.form-item-country, #block-rent-a-car-rent-a-car-reservation .block__content div .form-wrapper .form-item.form-item-drivers-age {
        margin-bottom: 0; } }
    #block-rent-a-car-rent-a-car-reservation .block__content div .form-wrapper .form-item label {
      font-weight: 400;
      font-size: 16px;
      padding-left: 5px;
      margin-bottom: 8px; }
    #block-rent-a-car-rent-a-car-reservation .block__content div .form-wrapper .form-item input {
      border: 1px solid #3d7bcc;
      height: 39px;
      border-radius: 5px;
      padding-left: 5px;
      width: calc(100% - 14px);
      margin-right: 14px;
      max-width: 480px;
      display: inline-block; }
      #block-rent-a-car-rent-a-car-reservation .block__content div .form-wrapper .form-item input#edit-city, #block-rent-a-car-rent-a-car-reservation .block__content div .form-wrapper .form-item input#edit-country {
        max-width: 210px;
        margin-right: 0; }
    #block-rent-a-car-rent-a-car-reservation .block__content div .form-wrapper .form-item.form-item-notes .form-textarea-wrapper {
      width: 100%;
      display: inline-block; }
      #block-rent-a-car-rent-a-car-reservation .block__content div .form-wrapper .form-item.form-item-notes .form-textarea-wrapper #edit-notes {
        border: 1px solid #3d7bcc;
        height: 38px;
        border-radius: 5px;
        padding-left: 5px;
        padding-top: 10px; }
      #block-rent-a-car-rent-a-car-reservation .block__content div .form-wrapper .form-item.form-item-notes .form-textarea-wrapper .grippie {
        background: none;
        border: none;
        height: 5px; }
    #block-rent-a-car-rent-a-car-reservation .block__content div .form-wrapper .form-item.form-item-terms-acceptance {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0;
      margin-top: 30px;
      text-align: center;
      float: none; }
      #block-rent-a-car-rent-a-car-reservation .block__content div .form-wrapper .form-item.form-item-terms-acceptance input {
        display: none; }
        #block-rent-a-car-rent-a-car-reservation .block__content div .form-wrapper .form-item.form-item-terms-acceptance input:checked + .option:before {
          color: #064599; }
      #block-rent-a-car-rent-a-car-reservation .block__content div .form-wrapper .form-item.form-item-terms-acceptance label {
        position: relative;
        padding-right: 30px;
        cursor: pointer;
        line-height: 21px;
        display: inline-block; }
        #block-rent-a-car-rent-a-car-reservation .block__content div .form-wrapper .form-item.form-item-terms-acceptance label:before {
          content: '\f00c';
          font-family: 'FontAwesome';
          display: block;
          width: 21px;
          height: 21px;
          position: absolute;
          top: 0;
          right: 0;
          border: 1px solid #064599;
          border-radius: 2px;
          color: white;
          font-size: 16px;
          line-height: 21px;
          text-align: center;
          transition: color 0.05s ease; }
    #block-rent-a-car-rent-a-car-reservation .block__content div .form-wrapper#edit-accommodation-info .form-item label, #block-rent-a-car-rent-a-car-reservation .block__content div .form-wrapper#edit-travel-info .form-item label, #block-rent-a-car-rent-a-car-reservation .block__content div .form-wrapper#edit-personal-info .form-item label {
      display: none; }
  #block-rent-a-car-rent-a-car-reservation .block__content div .form-item-gdpr {
    width: 100%;
    display: inline-block; }
    #block-rent-a-car-rent-a-car-reservation .block__content div .form-item-gdpr label {
      padding-left: 30px;
      line-height: 33px; }
      #block-rent-a-car-rent-a-car-reservation .block__content div .form-item-gdpr label a {
        transition: 0.3s ease;
        font-weight: 700; }
        #block-rent-a-car-rent-a-car-reservation .block__content div .form-item-gdpr label a:hover {
          color: #ffec00; }
    #block-rent-a-car-rent-a-car-reservation .block__content div .form-item-gdpr input {
      visibility: hidden; }
    #block-rent-a-car-rent-a-car-reservation .block__content div .form-item-gdpr input[type=checkbox]:checked ~ label:before {
      content: "\f00c"; }
    #block-rent-a-car-rent-a-car-reservation .block__content div .form-item-gdpr input[type=checkbox] ~ label {
      position: relative; }
      #block-rent-a-car-rent-a-car-reservation .block__content div .form-item-gdpr input[type=checkbox] ~ label:before {
        content: '';
        display: block;
        width: 27px;
        height: 27px;
        font-family: FontAwesome;
        color: #064599;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        position: absolute;
        margin-right: 23px;
        top: -6px;
        left: -6px; }
      #block-rent-a-car-rent-a-car-reservation .block__content div .form-item-gdpr input[type=checkbox] ~ label:after {
        content: "";
        display: inline-block;
        position: absolute;
        width: 27px;
        height: 27px;
        top: -5px;
        left: -12px;
        border: 1px solid #064599;
        cursor: pointer;
        color: #064599;
        text-align: center;
        line-height: 27px;
        font-family: 'FontAwesome'; }
  #block-rent-a-car-rent-a-car-reservation .block__content div .form-submit {
    line-height: 51px;
    border: none;
    background-color: #064699;
    color: #ffec00;
    border-radius: 8px;
    padding-left: 52px;
    padding-right: 17px;
    text-transform: uppercase;
    transition: background-color 0.25s ease;
    cursor: pointer;
    float: right;
    display: block;
    font-size: 30px;
    font-weight: 700;
    background-image: url(/sites/all/themes/omgsub/images/BOOK_BUTTON.svg);
    background-repeat: no-repeat; }
    #block-rent-a-car-rent-a-car-reservation .block__content div .form-submit:hover {
      opacity: 0.9; }

@media (max-width: 1022px) {
  #block-rent-a-car-rent-a-car-reservation .block__content div .form-wrapper .form-item {
    width: 49.50495%;
    float: left;
    margin-right: 0.9901%; }
    #block-rent-a-car-rent-a-car-reservation .block__content div .form-wrapper .form-item.form-item-country input#edit-country {
      max-width: 440px; }
    #block-rent-a-car-rent-a-car-reservation .block__content div .form-wrapper .form-item:nth-child(2n) {
      width: 49.50495%;
      float: right;
      margin-right: 0; }
    #block-rent-a-car-rent-a-car-reservation .block__content div .form-wrapper .form-item.form-item-no-of-people {
      clear: left;
      float: left; } }

@media (max-width: 479px) {
  #block-rent-a-car-rent-a-car-reservation .block__content div .form-wrapper .form-item {
    width: 100%;
    margin-right: 0; }
    #block-rent-a-car-rent-a-car-reservation .block__content div .form-wrapper .form-item.form-item-country input#edit-country {
      max-width: 440px; }
    #block-rent-a-car-rent-a-car-reservation .block__content div .form-wrapper .form-item:nth-child(2n) {
      width: 100%;
      margin-right: 0; }
    #block-rent-a-car-rent-a-car-reservation .block__content div .form-wrapper .form-item.form-item-no-of-people {
      clear: left;
      float: left; } }

body.node-type-rent-a-car .l-main .l-content article {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0;
  padding-top: 0; }

body.node-type-rent-a-car .l-main .l-content #flexslider-1 {
  max-width: 559px;
  margin: 0 auto 0px; }

body.section-car-choice .l-main .l-content .l-title {
  float: left; }

body.section-car-choice .l-main .l-content #block-views-car-slider-block {
  float: right;
  argin-right: 0; }
  body.section-car-choice .l-main .l-content #block-views-car-slider-block #flexslider-1 {
    max-width: 300px; }

.l-title {
  padding-top: 8px; }
  .l-title h2 {
    font-size: 28px;
    color: #064599;
    line-height: 86px;
    height: 84px;
    margin-bottom: 9px;
    max-width: 320px;
    display: inline-block;
    overflow: hidden; }
    @media (max-width: 640px) {
      .l-title h2 {
        font-size: 24px;
        margin-bottom: 0; } }
  .l-title a {
    font-size: 16px;
    float: right;
    text-decoration: inherit;
    color: black;
    line-height: 84px;
    font-weight: normal;
    transition: color 0.25s ease; }
    .l-title a:hover {
      color: #064599; }
    .l-title a:after {
      content: '';
      display: inline-block;
      width: 40px;
      height: 84px;
      background-image: url("/sites/all/themes/omgsub/images/back_arrow.svg");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      vertical-align: bottom;
      margin-left: 10px; }
  @media (max-width: 420px) {
    .l-title h2 {
      width: 100%;
      height: auto;
      line-height: 35px; }
    .l-title a {
      line-height: 43px;
      margin-bottom: 20px; }
      .l-title a:after {
        height: 47px; } }

#car-insurance-info {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0; }

.car-analysis-features .section-title {
  color: #064599;
  margin: 0 0 16px;
  font-size: 22px;
  font-weight: 900;
  line-height: 2.4;
  padding-left: 6px;
  border-width: 1px 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.1); }
  @media (max-width: 640px) {
    .car-analysis-features .section-title {
      font-size: 16px; } }

.car-analysis-features .feature {
  display: inline-block;
  line-height: 38px;
  min-width: 58px;
  margin-right: 2px; }
  @media (max-width: 1023px) {
    .car-analysis-features .feature {
      min-width: 66px; } }
  .car-analysis-features .feature:before {
    height: 38px;
    width: 31px;
    position: relative; }

#car-insurance-info .block__content {
  font-weight: 300;
  text-align: center; }
  #car-insurance-info .block__content * {
    text-align: left; }
  #car-insurance-info .block__content h4 {
    color: #064599;
    margin: 0 0 12px;
    font-size: 22px;
    font-weight: 900;
    line-height: 2.6;
    border-width: 1px 0;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.1); }
    @media (max-width: 849px) {
      #car-insurance-info .block__content h4 {
        line-height: 25px;
        padding-top: 12px;
        padding-bottom: 12px; } }
  #car-insurance-info .block__content > p:first-of-type {
    padding-left: 50px;
    position: relative; }
    #car-insurance-info .block__content > p:first-of-type:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background-image: url("/sites/all/themes/omgsub/images/insurance_shield.svg");
      background-position: center top;
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
      width: 39px;
      height: 100%; }
  #car-insurance-info .block__content h5 {
    color: #064599;
    font-size: 18px;
    line-height: 2; }
  #car-insurance-info .block__content h6 {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 5px; }
    #car-insurance-info .block__content h6:before {
      content: '\f105';
      font-family: 'FontAwesome';
      color: inherit;
      font-size: 16px;
      line-height: inherit;
      vertical-align: bottom;
      display: inline-block;
      margin-right: 5px; }
  #car-insurance-info .block__content .checkmarks-list li:before {
    left: -5px;
    height: 19px;
    background-size: 80%; }
  #car-insurance-info .block__content #check-availability {
    display: inline-block;
    text-align: unset;
    background-color: #064599;
    text-transform: uppercase;
    padding: 10px 12px;
    font-size: 20px;
    line-height: 1.5;
    color: #ffec00;
    border-radius: 8px;
    vertical-align: bottom;
    transition: background-color 0.25s ease;
    cursor: pointer;
    float: right;
    position: relative;
    background-image: linear-gradient(to right, #0a5dcc 23%, #0f4b97 23%);
    padding-left: 47px;
    font-weight: 700; }
    @media (max-width: 1023px) {
      #car-insurance-info .block__content #check-availability {
        margin-top: 35px; } }
    #car-insurance-info .block__content #check-availability:before {
      content: "\f058";
      position: absolute;
      display: inline-block;
      width: 23px;
      font-family: FontAwesome;
      height: 23px;
      left: 4px;
      background-color: #0a5dcc;
      z-index: 999;
      top: 50%;
      transform: translateY(-55%);
      font-size: 17px; }
    #car-insurance-info .block__content #check-availability:after {
      content: "";
      position: absolute;
      display: inline-block;
      width: 0;
      height: 0;
      border-top: 9px solid transparent;
      border-bottom: 9px solid transparent;
      border-left: 9px solid #0a5dcc;
      left: 23%;
      top: 50%;
      transform: translateY(-50%); }
    #car-insurance-info .block__content #check-availability:hover {
      background-color: #0c3b7a; }
    #car-insurance-info .block__content #check-availability br {
      display: none; }

.optional-insurance {
  color: red; }

@media (max-width: 640px) {
  body.page-search-car .l-main {
    max-width: 1327px;
    margin-left: auto;
    margin-right: auto;
    max-width: 340px; } }
  @media (max-width: 640px) and (max-width: 340px) {
    body.page-search-car .l-main {
      margin-left: 0;
      margin-right: 0; } }

@media (max-width: 640px) {
    body.page-search-car .l-main:after {
      content: " ";
      display: block;
      clear: both; } }

@media (max-width: 479px) {
  body.page-search-car .l-main {
    max-width: none; } }

body.page-search-car #search-car-info .block__content {
  line-height: 20px;
  padding-top: 7px;
  margin-bottom: 22px; }

body.page-search-car #search-car-info h3 {
  display: none;
  color: #064599;
  font-size: 28px;
  line-height: 1.25;
  border-bottom: 1px solid #064599;
  height: 75px;
  margin: 20px 0 15px; }
  @media (max-width: 1223px) {
    body.page-search-car #search-car-info h3 {
      font-size: 23px; } }
  @media (max-width: 1023px) {
    body.page-search-car #search-car-info h3 {
      height: 57px;
      margin: 5px 0 15px;
      font-size: 19px; } }
  @media (max-width: 640px) {
    body.page-search-car #search-car-info h3 {
      font-size: 16px; } }

body.page-search-car #search-car-info p {
  font-weight: 400; }
  @media (max-width: 1223px) {
    body.page-search-car #search-car-info p {
      font-size: 14px; } }

body.page-search-car #block-rent-a-car-rent-a-car-type-select .block__content {
  padding: 0; }

body.page-search-car #rent-a-car-type-select-form label,
body.page-search-car #rent-a-car-type-select-form .form-checkboxes,
body.page-search-car #rent-a-car-type-select-form .form-type-checkbox {
  display: inline-block; }

body.page-search-car #car-search-view {
  padding-top: 22px;
  text-align: center; }
  body.page-search-car #car-search-view .view-empty {
    text-align: center; }
  body.page-search-car #car-search-view .ajax-error-message {
    display: inline-block;
    text-align: center;
    padding: 10px 15px 10px 78px;
    position: relative;
    background-color: #ffdfe4;
    color: darkred;
    border: 1px solid darkred;
    border-radius: 5px;
    font-size: 14px;
    line-height: 18px;
    min-height: 58px;
    max-width: 90%; }
    body.page-search-car #car-search-view .ajax-error-message:before {
      content: '\f06a';
      font-family: 'FontAwesome';
      color: inherit;
      vertical-align: bottom;
      display: block;
      width: 48px;
      position: absolute;
      top: 50%;
      left: 10px;
      text-align: center;
      font-size: 48px;
      line-height: 1;
      transform: translate(0, -50%); }
  body.page-search-car #car-search-view .view-content {
    text-align: left; }
  body.page-search-car #car-search-view .fleet-car {
    width: 100%;
    border-radius: 15px;
    display: inline-block;
    border: 1px solid #064599;
    margin-bottom: 20px;
    transition: border-color 0.25s ease, box-shadow 0.25s ease;
    position: relative;
    padding: 3px 12px;
    padding-bottom: 16px;
    padding-right: 23px; }
    @media (max-width: 1023px) {
      body.page-search-car #car-search-view .fleet-car {
        width: 49.68944%;
        float: left;
        margin-right: 0.62112%;
        margin-bottom: 1.59088%;
        padding: 0;
        min-height: 476px; } }
  @media (max-width: 1023px) and (min-width: 767px) {
    body.page-search-car #car-search-view .fleet-car:nth-child(2n) {
      width: 49.68944%;
      float: right;
      margin-right: 0; } }
    @media (max-width: 766px) {
      body.page-search-car #car-search-view .fleet-car {
        width: 100%;
        float: left;
        margin-left: 0;
        margin-right: 0;
        min-height: unset; } }
    body.page-search-car #car-search-view .fleet-car:hover {
      box-shadow: 0 0 15px rgba(4, 4, 4, 0.29);
      border-color: #ffec00; }
  body.page-search-car #car-search-view .title {
    width: 66.45963%;
    float: right;
    margin-right: 0;
    font-size: 28px;
    letter-spacing: -0.5px;
    font-weight: bold;
    color: purple;
    line-height: 30px;
    margin: 17px 0px 0 0px;
    padding-right: 126px; }
    body.page-search-car #car-search-view .title.no-price {
      padding-right: 0; }
    @media (max-width: 1023px) {
      body.page-search-car #car-search-view .title {
        font-size: 18px;
        margin-top: 14px;
        width: 100%;
        padding-left: 15px;
        padding-right: 15px; } }
  body.page-search-car #car-search-view .body-summary {
    width: 66.45963%;
    float: right;
    margin-right: 0;
    font-size: 18px;
    color: #424242;
    line-height: 24px;
    margin: 17px 0px 0 0px;
    padding-right: 160px;
    min-height: 65px;
    overflow: hidden;
    position: relative; }
    body.page-search-car #car-search-view .body-summary.no-price {
      padding-right: 0; }
    @media (max-width: 1023px) {
      body.page-search-car #car-search-view .body-summary {
        font-size: 16px;
        margin-top: 14px;
        width: 100%;
        padding-left: 5px;
        padding-right: 5px; } }
  body.page-search-car #car-search-view .info-bubble {
    position: absolute;
    display: none;
    top: 14px;
    right: 12px;
    border-radius: 50%; }
    @media (max-width: 1023px) {
      body.page-search-car #car-search-view .info-bubble {
        top: 11px;
        right: 11px; } }
    body.page-search-car #car-search-view .info-bubble:before {
      content: "";
      position: absolute;
      display: inline-block;
      width: 0;
      height: 0;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
      border-left: 8px solid #ffec00;
      right: 0px;
      bottom: -1px;
      transform: rotate(45deg); }
    body.page-search-car #car-search-view .info-bubble:hover .tooltip {
      visibility: visible;
      opacity: 1;
      top: 42px;
      z-index: 9; }
    body.page-search-car #car-search-view .info-bubble .tooltip {
      position: absolute;
      top: 70px;
      left: 50%;
      transform: translate(-50%, 0);
      visibility: hidden;
      opacity: 0;
      display: inline-block;
      background-color: white;
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
      z-index: -1;
      transition: top 0.5s ease, opacity 0.5s ease;
      min-width: 120px;
      padding: 5px;
      border-radius: 7px;
      border: 1px solid #064599;
      font-size: 12px;
      font-weight: 300;
      color: black; }
      body.page-search-car #car-search-view .info-bubble .tooltip:before {
        content: '';
        position: absolute;
        display: block;
        top: -8px;
        left: 50%;
        transform: translate(-50%, 0);
        border-width: 0 4px 4px;
        border-style: solid;
        border-color: #064599 transparent;
        height: 4px; }
    body.page-search-car #car-search-view .info-bubble a {
      display: inline-block;
      font-size: 0;
      color: transparent;
      background-color: #ffec00;
      position: relative;
      width: 38px;
      height: 37px;
      border-radius: 50%;
      vertical-align: bottom;
      transition: background-color 0.25s ease; }
      @media (max-width: 1023px) {
        body.page-search-car #car-search-view .info-bubble a {
          width: 28px;
          height: 28px; } }
      body.page-search-car #car-search-view .info-bubble a:hover {
        background-color: #064599; }
      body.page-search-car #car-search-view .info-bubble a:after {
        content: '\f129';
        font-family: 'FontAwesome';
        font-size: 24px;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        text-align: center;
        line-height: 37px;
        color: white; }
        @media (max-width: 1227px) {
          body.page-search-car #car-search-view .info-bubble a:after {
            line-height: 28px;
            font-size: 18px; } }
        @media (max-width: 640px) {
          body.page-search-car #car-search-view .info-bubble a:after {
            line-height: 38px;
            font-size: 24px; } }
  body.page-search-car #car-search-view .image {
    text-align: center;
    width: 32.08075%;
    float: left;
    margin-right: 0.62112%; }
    body.page-search-car #car-search-view .image img {
      margin: 5px 0;
      vertical-align: bottom;
      max-width: 99%;
      height: auto; }
    @media (max-width: 1023px) {
      body.page-search-car #car-search-view .image {
        width: 100%;
        margin-right: 0; } }
  body.page-search-car #car-search-view .car-features {
    width: 66.45963%;
    float: right;
    margin-right: 0;
    padding: 0 7px;
    margin-top: 0px; }
    @media (max-width: 1023px) {
      body.page-search-car #car-search-view .car-features {
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px; } }
    body.page-search-car #car-search-view .car-features .features_price {
      width: calc(100% - 220px);
      margin-right: 10px;
      float: left;
      display: inline-block; }
      @media (max-width: 1390px) {
        body.page-search-car #car-search-view .car-features .features_price {
          width: calc(100% - 180px); } }
      @media (max-width: 1023px) {
        body.page-search-car #car-search-view .car-features .features_price {
          width: 100%;
          margin-bottom: 20px; } }
      body.page-search-car #car-search-view .car-features .features_price .features_scroll {
        overflow: auto;
        white-space: nowrap; }
        body.page-search-car #car-search-view .car-features .features_price .features_scroll::-webkit-scrollbar-thumb {
          background-color: #ffec00;
          outline: 1px solid #45586d;
          border-radius: 5px;
          border: 2px solid #064599; }
        body.page-search-car #car-search-view .car-features .features_price .features_scroll::-webkit-scrollbar {
          max-height: 14px; }
        body.page-search-car #car-search-view .car-features .features_price .features_scroll::-webkit-scrollbar-track {
          border: 1px solid #064599;
          border-left: none;
          border-right: none; }
        body.page-search-car #car-search-view .car-features .features_price .features_scroll::-webkit-scrollbar-button {
          background-size: 10px 10px;
          background-repeat: no-repeat;
          background-position: center center;
          height: 16px;
          width: 1em;
          transform: rotate(45deg); }
        body.page-search-car #car-search-view .car-features .features_price .features_scroll::-webkit-scrollbar-button:end:increment {
          background-image: url("/sites/all/themes/omgsub/images/Arrow_RIGHT.svg");
          border: 1px solid #064599;
          border-left: none;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px; }
        body.page-search-car #car-search-view .car-features .features_price .features_scroll::-webkit-scrollbar-button:start:decrement {
          background-image: url("/sites/all/themes/omgsub/images/Arrow_LEFT.svg");
          border: 1px solid #064599;
          border-right: none;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px; }
        body.page-search-car #car-search-view .car-features .features_price .features_scroll .feature {
          font-size: 12px;
          margin-bottom: 0;
          text-align: left;
          display: inline-block;
          width: 13%;
          min-width: 48px; }
          @media (min-width: 1024px) and (max-width: 1223px) {
            body.page-search-car #car-search-view .car-features .features_price .features_scroll .feature {
              font-size: 11px; } }
    body.page-search-car #car-search-view .car-features .book-button-form {
      width: 210px;
      float: right;
      position: relative; }
      @media (max-width: 1023px) {
        body.page-search-car #car-search-view .car-features .book-button-form {
          float: none;
          margin: 0 auto;
          clear: both; } }
      body.page-search-car #car-search-view .car-features .book-button-form span {
        width: 100%;
        display: inline-block; }
      body.page-search-car #car-search-view .car-features .book-button-form:before {
        content: "";
        position: absolute;
        display: inline-block;
        width: 14%;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        height: 100%;
        left: 0;
        background-color: #0a5dcc;
        z-index: 999; }
      body.page-search-car #car-search-view .car-features .book-button-form:after {
        content: "";
        position: absolute;
        display: inline-block;
        width: 0;
        height: 0;
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent;
        border-left: 13px solid #0a5dcc;
        left: 14%;
        top: 50%;
        transform: translateY(-50%); }
      @media (max-width: 1390px) {
        body.page-search-car #car-search-view .car-features .book-button-form {
          width: 170px; } }
    body.page-search-car #car-search-view .car-features .book-button {
      padding: 19px 55px 19px 54px;
      border: none;
      cursor: pointer;
      background-color: #064599;
      font-weight: 700;
      border-radius: 5px;
      width: 100%;
      position: relative;
      color: #ffec00;
      font-size: 25px;
      text-transform: uppercase; }
      @media (max-width: 1390px) {
        body.page-search-car #car-search-view .car-features .book-button {
          font-size: 18px;
          padding: 20px 47px 20px 51px; } }
      @media (min-width: 1024px) and (max-width: 1100px) {
        body.page-search-car #car-search-view .car-features .book-button {
          font-size: 18px;
          padding: 20px 32px 20px 51px; } }
      @media (max-width: 1023px) {
        body.page-search-car #car-search-view .car-features .book-button {
          font-size: 17px; } }
      body.page-search-car #car-search-view .car-features .book-button:before, body.page-search-car #car-search-view .car-features .book-button:after {
        content: ">";
        position: absolute;
        display: inline-block;
        width: 16px;
        height: 24px;
        color: #ffec00;
        top: 50%;
        font-size: 26px;
        transform: translateY(-57%);
        z-index: 999;
        font-weight: 700; }
      body.page-search-car #car-search-view .car-features .book-button:before {
        right: 13px; }
      body.page-search-car #car-search-view .car-features .book-button:after {
        right: 30px; }
      @media (max-width: 1390px) {
        body.page-search-car #car-search-view .car-features .book-button:before, body.page-search-car #car-search-view .car-features .book-button:after {
          font-size: 20px;
          transform: translateY(-43%); }
        body.page-search-car #car-search-view .car-features .book-button:before {
          right: 6px; }
        body.page-search-car #car-search-view .car-features .book-button:after {
          right: 22px; } }
      @media (max-width: 1023px) {
        body.page-search-car #car-search-view .car-features .book-button:before {
          right: 17px; }
        body.page-search-car #car-search-view .car-features .book-button:after {
          right: 33px; } }
      body.page-search-car #car-search-view .car-features .book-button:hover {
        background-color: #ffec00;
        color: #064599; }
        body.page-search-car #car-search-view .car-features .book-button:hover:before, body.page-search-car #car-search-view .car-features .book-button:hover:after {
          color: #064599; }
  body.page-search-car #car-search-view .section-title {
    border-width: 1px 0;
    color: #000;
    font-weight: 400;
    text-align: left;
    padding: 5px 0 8px;
    margin: 0 -6px 17px;
    font-size: 24px;
    margin-top: 20px; }
    @media (max-width: 1023px) {
      body.page-search-car #car-search-view .section-title {
        font-size: 14px;
        padding-left: 5px; } }
  body.page-search-car #car-search-view .prices {
    margin-top: 0;
    padding-top: 0;
    position: absolute;
    top: 12px;
    right: 25px;
    width: 23.09859%;
    float: right;
    margin-right: 0; }
    body.page-search-car #car-search-view .prices .book-button-form {
      width: 100%; }
    body.page-search-car #car-search-view .prices .main-price {
      display: block;
      text-align: right;
      color: #064599;
      font-weight: 900;
      font-size: 36px;
      line-height: 1;
      margin-bottom: 6px; }
      @media (max-width: 640px) {
        body.page-search-car #car-search-view .prices .main-price {
          font-size: 26px;
          top: 8px; } }
    @media (max-width: 1023px) {
      body.page-search-car #car-search-view .prices {
        padding-bottom: 0;
        text-align: center;
        width: 100%;
        position: relative;
        top: unset;
        left: unset;
        right: unset; }
        body.page-search-car #car-search-view .prices .main-price {
          text-align: center; } }
  body.page-search-car #car-search-view .perdaycharge {
    color: #064599;
    display: inline-block;
    position: absolute;
    top: 70px;
    right: 25px; }
    @media (max-width: 1023px) {
      body.page-search-car #car-search-view .perdaycharge {
        width: 100%;
        display: inline-block;
        text-align: center;
        margin-top: 5px;
        margin-bottom: 5px;
        position: relative;
        top: unset;
        left: unset;
        right: unset; } }
  body.page-search-car #car-search-view .sendofferpopup {
    width: 23.09859%;
    float: right;
    margin-right: 0;
    margin-top: 19px;
    position: relative;
    color: transparent; }
    body.page-search-car #car-search-view .sendofferpopup:before {
      content: "";
      position: absolute;
      width: 42px;
      height: 40px;
      background-image: url("/sites/all/themes/omgsub/images/HEART_ICON.svg");
      background-repeat: no-repeat;
      right: -1px;
      display: none;
      top: 50%;
      transform: translateY(-50%); }
    body.page-search-car #car-search-view .sendofferpopup a {
      display: inline-block;
      max-width: 191px;
      width: 100%;
      color: #064599;
      background-color: transparent;
      font-size: 0;
      font-weight: 700;
      padding-left: 10px;
      line-height: 30px;
      transition: 0.4s ease;
      pointer-events: none; }
    @media (max-width: 1390px) {
      body.page-search-car #car-search-view .sendofferpopup:before {
        transform: translateY(-43%);
        background-size: 36px;
        right: -21px; } }
    @media (max-width: 1023px) {
      body.page-search-car #car-search-view .sendofferpopup {
        width: 100%;
        float: unset;
        display: block;
        overflow: hidden;
        max-width: 186px;
        margin: auto;
        padding-top: 20px;
        padding-bottom: 8px;
        display: none; } }
  body.page-search-car #car-search-view .secondary-prices {
    display: block;
    text-align: center;
    font-weight: 300;
    line-height: 1; }
    @media (max-width: 1023px) {
      body.page-search-car #car-search-view .secondary-prices {
        font-size: 12px; } }
    @media (max-width: 640px) {
      body.page-search-car #car-search-view .secondary-prices {
        font-size: 15px; } }

.page-node-242 .branch {
  width: 100%;
  border: 2px solid #064599;
  margin-bottom: 25px;
  padding: 12px 25px;
  text-align: center;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px; }
  @media (min-width: 1023px) {
    .page-node-242 .branch:nth-of-type(2n + 2) {
      width: 49.20456%;
      float: right;
      margin-right: 0; } }
  @media (max-width: 1022px) {
    .page-node-242 .branch {
      width: 100%;
      height: unset; } }
  .page-node-242 .branch h4 {
    position: relative;
    display: inline-block; }
    .page-node-242 .branch h4:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -4px;
      height: 2px;
      background: #064599;
      right: -30px; }
  .page-node-242 .branch p {
    margin: 0; }

.page-node-242 .office-hours {
  display: inline-block;
  width: 100%;
  margin-top: 30px; }
  @media (max-width: 767px) {
    .page-node-242 .office-hours {
      margin-top: 5px; } }
  .page-node-242 .office-hours h3 {
    background: #064599;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 10px 5px;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 0; }
  .page-node-242 .office-hours > p {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 2px solid #064599;
    text-align: center;
    padding: 10px 5px; }

#google-map {
  height: 600px; }

#contact-form .block__title {
  text-align: center; }

#contact-form .block__content {
  display: block;
  width: 100%;
  max-width: 560px;
  margin: 0 auto; }

#contact-form input[type="text"],
#contact-form input[type="email"],
#contact-form textarea {
  border: 1px solid #064599;
  border-radius: 5px;
  padding: 7px 15px;
  font-size: 18px;
  line-height: 24px;
  position: relative;
  width: 100%;
  display: inline-block; }
  #contact-form input[type="text"]::-webkit-input-placeholder,
  #contact-form input[type="email"]::-webkit-input-placeholder,
  #contact-form textarea::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #4d4d4d;
    opacity: 1;
    font-weight: 300; }
  #contact-form input[type="text"]::-moz-placeholder,
  #contact-form input[type="email"]::-moz-placeholder,
  #contact-form textarea::-moz-placeholder {
    /* Firefox 19+ */
    color: #4d4d4d;
    opacity: 1;
    font-weight: 300; }
  #contact-form input[type="text"]:-ms-input-placeholder,
  #contact-form input[type="email"]:-ms-input-placeholder,
  #contact-form textarea:-ms-input-placeholder {
    /* IE 10+ */
    color: #4d4d4d;
    opacity: 1;
    font-weight: 300; }
  #contact-form input[type="text"]:-moz-placeholder,
  #contact-form input[type="email"]:-moz-placeholder,
  #contact-form textarea:-moz-placeholder {
    /* Firefox 18- */
    color: #4d4d4d;
    opacity: 1;
    font-weight: 300; }

#contact-form .form-item {
  width: 48.07692%;
  float: left;
  margin-right: 3.84615%; }
  #contact-form .form-item:nth-child(2n) {
    width: 48.07692%;
    float: right;
    margin-right: 0; }
  #contact-form .form-item.webform-component-textarea, #contact-form .form-item.webform-component-checkboxes, #contact-form .form-item.form-type-checkbox {
    display: inline-block;
    width: 100%; }
    #contact-form .form-item.webform-component-textarea .form-item, #contact-form .form-item.webform-component-checkboxes .form-item, #contact-form .form-item.form-type-checkbox .form-item {
      width: 100%; }

#contact-form .grippie {
  display: none; }

#contact-form .webform-component-checkboxes {
  margin-bottom: 5px; }

#contact-form .webform-component--checkboxes .description,
#contact-form .form-type-checkbox .description {
  font-weight: 300;
  margin-bottom: 10px; }

#contact-form .webform-component--checkboxes input,
#contact-form .form-type-checkbox input {
  display: none; }
  #contact-form .webform-component--checkboxes input:checked + .option:before,
  #contact-form .form-type-checkbox input:checked + .option:before {
    color: #064599; }

#contact-form .webform-component--checkboxes .option,
#contact-form .form-type-checkbox .option {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  display: inline-block; }
  #contact-form .webform-component--checkboxes .option:before,
  #contact-form .form-type-checkbox .option:before {
    content: '\f00c';
    font-family: 'FontAwesome';
    display: block;
    width: 21px;
    height: 21px;
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid #064599;
    border-radius: 2px;
    color: white;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    transition: color 0.05s ease; }
  #contact-form .webform-component--checkboxes .option .form-required,
  #contact-form .form-type-checkbox .option .form-required {
    display: none; }

#contact-form .form-item-gdpr-form-compliance .option .form-required {
  display: initial;
  color: inherit; }

#contact-form .form-actions {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0; }

#contact-form .form-submit {
  background-color: #064599;
  text-transform: uppercase;
  border: none;
  padding: 12px 25px 11px;
  margin-top: 12px; }
  @media (max-width: 767px) {
    #contact-form .form-submit {
      display: block;
      margin: auto;
      margin-top: 20px; } }
  #contact-form .form-submit:hover {
    background-color: #0c3b7a; }

#map {
  width: 100%;
  height: 400px; }

.view-faq .faq-list li {
  font-size: 18px;
  font-weight: 700; }
  .view-faq .faq-list li h3 {
    cursor: pointer;
    font-size: 18px; }
    .view-faq .faq-list li h3:hover {
      color: #064599; }
  .view-faq .faq-list li .faq-answer {
    font-weight: 400;
    position: absolute;
    visibility: hidden;
    opacity: 0; }
  .view-faq .faq-list li.open h3 {
    color: #064599; }
  .view-faq .faq-list li.open .faq-answer {
    position: relative;
    visibility: visible;
    opacity: 1;
    -webkit-transition: all 0.5s linear;
    -moz-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    transition: all 0.5s linear; }

body.front #car-search-front {
  position: absolute;
  z-index: 5;
  bottom: 22%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center; }
  @media (max-width: 767px) {
    body.front #car-search-front {
      position: static;
      transform: unset;
      background-color: #f3f8fb;
      padding: 12px 0 31px;
      padding-bottom: 0; } }
  @media (max-width: 767px) and (max-width: 767px) {
    body.front #car-search-front .rent-a-car-block-view-search {
      max-width: 400px;
      margin: auto;
      display: block; } }
  @media (max-width: 1023px) {
    body.front #car-search-front {
      bottom: 4%; } }
  @media (max-width: 479px) {
    body.front #car-search-front {
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;
      padding-top: 0; } }
  body.front #car-search-front:hover ~ .slider-home .flex-prev {
    left: 10px; }
  body.front #car-search-front:hover ~ .slider-home .flex-next {
    right: 10px; }
  body.front #car-search-front .block__title {
    font-size: 24px;
    font-weight: 700;
    color: #ffec00;
    padding: 14px 35px 15px;
    line-height: 1;
    background-color: #064599;
    border-radius: 12px 12px 0 0;
    text-align: center;
    max-width: 219px;
    margin: 0 auto;
    position: relative;
    top: 1px;
    left: 10px;
    display: inline-block;
    width: 100%; }
    @media (max-width: 1223px) {
      body.front #car-search-front .block__title {
        font-size: 16px;
        padding: 11px 22px; } }
    @media (max-width: 767px) {
      body.front #car-search-front .block__title {
        padding: 11px 9px 11px;
        border-radius: 6px 6px 0 0;
        font-size: 18px;
        box-shadow: 0px 0px 7px 2px #fff;
        top: -52px;
        z-index: 999; } }
    @media (max-width: 479px) {
      body.front #car-search-front .block__title {
        top: -40px; } }
  body.front #car-search-front .block__content {
    position: relative;
    background-color: rgba(255, 255, 255, 0.8);
    background-clip: padding-box;
    border: 1px solid rgba(6, 69, 153, 0.1);
    border-radius: 25px;
    padding: 22px 30px 34px 26px;
    text-align: center;
    width: 1160px;
    display: block; }
    @media (max-width: 1100px) {
      body.front #car-search-front .block__content {
        width: 678px;
        padding: 20px 10px 31px; } }
    @media (max-width: 767px) {
      body.front #car-search-front .block__content {
        width: unset;
        background-color: rgba(255, 255, 255, 0.85);
        border-radius: 4px;
        max-width: 1327px;
        margin-left: auto;
        margin-right: auto;
        max-width: 280px;
        padding: 10px 13px; } }
  @media (max-width: 767px) and (max-width: 320px) {
    body.front #car-search-front .block__content {
      margin-left: 20px;
      margin-right: 20px; } }
    @media (max-width: 767px) {
        body.front #car-search-front .block__content:after {
          content: " ";
          display: block;
          clear: both; } }
    @media (max-width: 1223px) {
      body.front #car-search-front .block__content {
        width: 1000px; } }
    @media (max-width: 1023px) {
      body.front #car-search-front .block__content {
        width: 730px; } }
    @media (max-width: 767px) {
      body.front #car-search-front .block__content {
        max-width: unset;
        width: 100%;
        margin-top: -52px;
        border-radius: unset; } }
    @media (max-width: 479px) {
      body.front #car-search-front .block__content {
        margin-top: -40px; } }
    @media (max-width: 320px) {
      body.front #car-search-front .block__content {
        margin-left: 0px; } }
    body.front #car-search-front .block__content * {
      text-align: left; }
    body.front #car-search-front .block__content > form {
      display: block; }
      @media (max-width: 1223px) {
        body.front #car-search-front .block__content > form > div {
          text-align: center; } }
  body.front #car-search-front .form-type-checkboxes {
    display: block;
    overflow: hidden; }
    @media (max-width: 767px) {
      body.front #car-search-front .form-type-checkboxes {
        text-align: center; } }
    body.front #car-search-front .form-type-checkboxes.form-item-car-type {
      margin-bottom: 13px; }
      @media (max-width: 1023px) {
        body.front #car-search-front .form-type-checkboxes.form-item-car-type {
          margin-bottom: 5px; } }
      body.front #car-search-front .form-type-checkboxes.form-item-car-type #edit-car-type {
        margin-bottom: 3px;
        display: inline-block; }
        @media (max-width: 1023px) {
          body.front #car-search-front .form-type-checkboxes.form-item-car-type #edit-car-type {
            width: 100%;
            max-width: 440px;
            margin: auto;
            margin-top: 7px;
            display: none; } }
  @media (max-width: 1023px) and (min-width: 768px) {
    body.front #car-search-front .form-type-checkboxes.form-item-car-type #edit-car-type .form-item {
      width: 31.64557%;
      float: left;
      margin-right: 2.53165%; }
      body.front #car-search-front .form-type-checkboxes.form-item-car-type #edit-car-type .form-item:nth-child(3n) {
        width: 31.64557%;
        float: right;
        margin-right: 0; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    body.front #car-search-front .form-type-checkboxes.form-item-car-type #edit-car-type {
      max-width: 282px; }
      body.front #car-search-front .form-type-checkboxes.form-item-car-type #edit-car-type .form-item {
        width: 48.07692%;
        float: left;
        margin-right: 3.84615%; }
        body.front #car-search-front .form-type-checkboxes.form-item-car-type #edit-car-type .form-item:nth-child(2n) {
          width: 48.07692%;
          float: right;
          margin-right: 0; } }
    body.front #car-search-front .form-type-checkboxes.form-item-gearbox {
      margin-bottom: 26px; }
      @media (max-width: 1023px) {
        body.front #car-search-front .form-type-checkboxes.form-item-gearbox {
          margin-bottom: 18px; } }
      @media (max-width: 767px) {
        body.front #car-search-front .form-type-checkboxes.form-item-gearbox {
          margin-bottom: 10px; } }
      body.front #car-search-front .form-type-checkboxes.form-item-gearbox .form-checkboxes .form-type-checkbox:last-child .option {
        text-transform: uppercase; }
    body.front #car-search-front .form-type-checkboxes > label {
      font-weight: 700;
      font-size: 16px;
      text-transform: uppercase;
      color: #070011; }
      body.front #car-search-front .form-type-checkboxes > label[for='edit-car-type'] {
        margin-right: 44px;
        word-spacing: -3px;
        position: relative; }
        @media (min-width: 1023px) {
          body.front #car-search-front .form-type-checkboxes > label[for='edit-car-type']:after {
            content: ":";
            position: absolute;
            display: inline-block;
            right: -7px;
            color: #000;
            top: -4px; } }
        @media (max-width: 1023px) {
          body.front #car-search-front .form-type-checkboxes > label[for='edit-car-type'] {
            width: 90px;
            float: none;
            margin: auto;
            display: block;
            position: relative; }
            body.front #car-search-front .form-type-checkboxes > label[for='edit-car-type']:before {
              content: "\f107";
              position: absolute;
              font-family: "FontAwesome";
              display: inline-block;
              color: #064599;
              width: 20px;
              height: 20px;
              font-size: 26px;
              right: -13px;
              top: 50%;
              transform: translateY(-57%);
              z-index: 88; }
            body.front #car-search-front .form-type-checkboxes > label[for='edit-car-type'].active:before {
              content: "\f106";
              transform: translateY(-63%); } }
      body.front #car-search-front .form-type-checkboxes > label[for='edit-gearbox'] {
        word-spacing: 1px;
        letter-spacing: -0.9px;
        margin-right: 17px;
        position: relative;
        top: 2px;
        position: relative; }
        @media (min-width: 1023px) {
          body.front #car-search-front .form-type-checkboxes > label[for='edit-gearbox']:after {
            content: ":";
            position: absolute;
            display: inline-block;
            right: -4px;
            color: #000;
            top: -4px; } }
        @media (max-width: 1023px) {
          body.front #car-search-front .form-type-checkboxes > label[for='edit-gearbox'] {
            display: block;
            margin: auto;
            float: none;
            max-width: 124px;
            position: relative; }
            body.front #car-search-front .form-type-checkboxes > label[for='edit-gearbox']:before {
              content: "\f107";
              position: absolute;
              font-family: "FontAwesome";
              display: inline-block;
              color: #064599;
              width: 20px;
              height: 20px;
              font-size: 26px;
              right: -19px;
              top: 50%;
              transform: translateY(-57%);
              z-index: 88; }
            body.front #car-search-front .form-type-checkboxes > label[for='edit-gearbox'].active:before {
              content: "\f106";
              transform: translateY(-63%); } }
      @media (max-width: 767px) {
        body.front #car-search-front .form-type-checkboxes > label {
          display: block;
          text-align: center;
          font-size: 14px; } }
    @media (max-width: 1023px) {
      body.front #car-search-front .form-type-checkboxes #edit-gearbox {
        display: none;
        width: 100%;
        max-width: 440px;
        margin: auto;
        margin-top: 7px; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    body.front #car-search-front .form-type-checkboxes #edit-gearbox {
      max-width: 282px; }
      body.front #car-search-front .form-type-checkboxes #edit-gearbox .form-item {
        width: 48.07692%;
        float: left;
        margin-right: 3.84615%; }
        body.front #car-search-front .form-type-checkboxes #edit-gearbox .form-item:nth-child(2n) {
          width: 48.07692%;
          float: right;
          margin-right: 0; } }
    body.front #car-search-front .form-type-checkboxes label.option {
      font-weight: 400;
      text-transform: none;
      font-size: 16px;
      color: #070011;
      line-height: 1.5;
      letter-spacing: 0.03em; }
      body.front #car-search-front .form-type-checkboxes label.option:before {
        margin-right: 5px; }
    @media (max-width: 1223px) {
      body.front #car-search-front .form-type-checkboxes .form-type-checkbox input:checked + label:before {
        background-color: #064599; } }
    @media (max-width: 1223px) {
      body.front #car-search-front .form-type-checkboxes .form-type-checkbox > label {
        padding-left: 16px;
        margin-right: 0; } }
    @media (max-width: 1223px) {
      body.front #car-search-front .form-type-checkboxes .form-type-checkbox > label:before {
        width: 10px;
        height: 10px;
        top: 5px;
        background-color: rgba(255, 255, 255, 0.5); } }
    @media (max-width: 1223px) {
      body.front #car-search-front .form-type-checkboxes .form-type-checkbox > label:after {
        width: 4px;
        height: 4px;
        top: 9px;
        left: 4px; } }
  @media (max-width: 767px) {
    body.front #car-search-front .form-type-select {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 6px; } }
  @media (max-width: 1223px) {
    body.front #car-search-front .form-type-select {
      width: 172px; } }
  @media (max-width: 1023px) {
    body.front #car-search-front .form-type-select {
      width: 229px;
      margin-bottom: 7px; }
      body.front #car-search-front .form-type-select.form-item-dropoff-location {
        margin-left: 6px; } }
  @media (max-width: 767px) {
    body.front #car-search-front .form-type-select {
      width: 100%; }
      body.front #car-search-front .form-type-select.form-item-dropoff-location {
        margin-left: 0px; } }
  @media (max-width: 767px) {
    body.front #car-search-front .form-item-pickup-date,
    body.front #car-search-front .form-item-dropoff-date {
      width: 66.66667%;
      float: left;
      margin-bottom: 6px; } }
  body.front #car-search-front .form-item-pickup-time,
  body.front #car-search-front .form-item-dropoff-time {
    margin-left: -6px; }
    @media (max-width: 767px) {
      body.front #car-search-front .form-item-pickup-time,
      body.front #car-search-front .form-item-dropoff-time {
        width: 33.33333%;
        float: right;
        margin-right: 0;
        margin-bottom: 6px; } }
  body.front #car-search-front .form-item-pickup-time {
    margin-right: 0; }
  @media (max-width: 767px) {
    body.front #car-search-front .form-type-textfield input#edit-pickup-date, body.front #car-search-front .form-type-textfield input#edit-dropoff-date {
      width: 100%; } }
  @media (max-width: 767px) {
    body.front #car-search-front .form-type-textfield input#edit-pickup-time, body.front #car-search-front .form-type-textfield input#edit-dropoff-time {
      width: 100%; } }
  @media (max-width: 1023px) {
    body.front #car-search-front .form-type-textfield {
      margin-bottom: 7px; } }
  @media (max-width: 1223px) {
    body.front #car-search-front select,
    body.front #car-search-front .select-title {
      width: 172px; } }
  @media (max-width: 1023px) {
    body.front #car-search-front select,
    body.front #car-search-front .select-title {
      width: 229px; } }
  @media (max-width: 767px) {
    body.front #car-search-front select,
    body.front #car-search-front .select-title {
      width: 100%; } }
  body.front #car-search-front .block-select > ul {
    top: 0; }
    body.front #car-search-front .block-select > ul li label {
      padding: 8px 15px 10px;
      line-height: 20px;
      transition: background-color 0.25s ease, color 0.25s ease; }
      @media (max-width: 1223px) {
        body.front #car-search-front .block-select > ul li label {
          font-size: 12px;
          line-height: 1.333;
          padding: 5px 15px 6px; } }
  @media (max-width: 1223px) {
    body.front #car-search-front .arrows .form-submit {
      font-size: 18px;
      line-height: 1;
      font-weight: 400; } }
  @media (max-width: 1023px) {
    body.front #car-search-front .arrows .form-submit {
      display: block;
      margin: auto; } }
  @media (max-width: 767px) {
    body.front #car-search-front .arrows .form-submit {
      display: block;
      margin: 2px auto 0;
      width: 100%;
      text-align: center; } }
  @media (max-width: 1223px) {
    body.front #car-search-front .arrows {
      margin: 0; } }
  @media (max-width: 1023px) {
    body.front #car-search-front .arrows {
      margin-bottom: 7px; } }
  @media (max-width: 767px) {
    body.front #car-search-front .arrows {
      margin: 2px auto 0px;
      clear: both; } }

body.front #flexslider-1 {
  border: none;
  background-color: initial;
  margin-bottom: 0;
  overflow: hidden; }
  body.front #flexslider-1 .slider-image {
    max-height: 699px;
    min-height: 560px;
    height: calc(100vh - 149px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }
    @media (max-width: 1023px) {
      body.front #flexslider-1 .slider-image {
        height: 421px; } }
    @media (max-width: 479px) {
      body.front #flexslider-1 .slider-image {
        height: 180px;
        min-height: 180px; } }
    @media (min-width: 480px) and (max-width: 767px) {
      body.front #flexslider-1 .slider-image {
        height: 100%;
        min-height: 330px; } }
    body.front #flexslider-1 .slider-image h2 {
      padding-top: 90px;
      font-size: 50px;
      font-weight: 400;
      width: 100%;
      max-width: 749px;
      margin: auto;
      letter-spacing: 0.7px;
      color: #0a4396;
      text-shadow: 1px 2px 5px #f3f3f3, 1px 1px 4px #f3f3f3; }
      @media (max-width: 1023px) {
        body.front #flexslider-1 .slider-image h2 {
          padding-top: 62px;
          font-size: 48px; } }
      @media (max-width: 479px) {
        body.front #flexslider-1 .slider-image h2 {
          font-size: 25px;
          padding-top: 35px; } }
      @media (max-width: 767px) {
        body.front #flexslider-1 .slider-image h2 {
          max-width: 400px;
          text-align: center; } }
  body.front #flexslider-1 ol.flex-control-nav {
    display: none; }
  body.front #flexslider-1 ul.flex-direction-nav {
    height: 0; }
    body.front #flexslider-1 ul.flex-direction-nav li {
      display: inline-block; }
      body.front #flexslider-1 ul.flex-direction-nav li.flex-nav-next {
        float: right; }
      body.front #flexslider-1 ul.flex-direction-nav li a {
        top: 38%;
        background: #064599;
        height: 48px;
        width: 48px;
        text-align: center;
        color: transparent;
        display: block;
        opacity: 1;
        font-size: 0;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%; }
        @media (max-width: 1023px) {
          body.front #flexslider-1 ul.flex-direction-nav li a {
            height: 32px;
            width: 32px;
            top: 32%; } }
        @media (max-width: 767px) {
          body.front #flexslider-1 ul.flex-direction-nav li a {
            display: none; } }
        body.front #flexslider-1 ul.flex-direction-nav li a:before {
          font-family: "FontAwesome";
          text-shadow: none;
          color: #fff;
          line-height: 48px;
          opacity: 1;
          transition: color 0.25s ease;
          vertical-align: bottom; }
          @media (max-width: 1023px) {
            body.front #flexslider-1 ul.flex-direction-nav li a:before {
              line-height: 32px;
              font-size: 28px; } }
        body.front #flexslider-1 ul.flex-direction-nav li a.flex-prev:before {
          content: '\f104';
          bottom: 0;
          left: -2px;
          position: relative; }
        body.front #flexslider-1 ul.flex-direction-nav li a.flex-next:before {
          content: '\f105';
          bottom: 0;
          position: relative;
          left: 1px; }
        body.front #flexslider-1 ul.flex-direction-nav li a:hover:before {
          color: #ffec00; }

body.front #icon-blocks:after {
  content: '';
  display: table;
  clear: both; }

@media (max-width: 1023px) {
  body.front #icon-blocks {
    margin-bottom: 80px; }
    body.front #icon-blocks .slick-arrow {
      position: absolute;
      bottom: -160px;
      font-size: 0;
      background-color: transparent;
      border: none; }
      body.front #icon-blocks .slick-arrow:before {
        display: block;
        font-family: "FontAwesome";
        text-shadow: none;
        line-height: 34px;
        font-size: 56px;
        color: #064599; }
      body.front #icon-blocks .slick-arrow.slick-prev {
        left: calc(50% - 40px); }
        body.front #icon-blocks .slick-arrow.slick-prev:before {
          content: '\f104'; }
      body.front #icon-blocks .slick-arrow.slick-next {
        right: calc(50% - 40px); }
        body.front #icon-blocks .slick-arrow.slick-next:before {
          content: '\f105'; } }

body.front #icon-blocks .block__content {
  width: 100%; }
  @media (max-width: 767px) {
    body.front #icon-blocks .block__content {
      padding: 0; } }
  body.front #icon-blocks .block__content div {
    width: 16.66667%;
    float: left;
    text-align: center;
    padding: 20px;
    border-right: 3px solid white;
    background-color: #f3f3f4;
    height: 95px;
    position: relative; }
    @media (max-width: 1023px) {
      body.front #icon-blocks .block__content div {
        height: auto;
        max-height: 110px;
        border-right: none; } }
    body.front #icon-blocks .block__content div:last-child {
      border-right: none; }
    body.front #icon-blocks .block__content div p {
      float: right;
      max-width: 138px;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      text-align: left;
      line-height: 1.1;
      margin-bottom: 0;
      font-size: 20px;
      letter-spacing: 2px;
      text-transform: uppercase; }
      @media (max-width: 1600px) {
        body.front #icon-blocks .block__content div p {
          font-size: 18px; } }
    body.front #icon-blocks .block__content div:before {
      content: '';
      display: block;
      float: left;
      margin-right: 13%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%); }
    body.front #icon-blocks .block__content div.insurance:before {
      position: absolute;
      left: 9%;
      transform: translateY(-46%); }
    body.front #icon-blocks .block__content div.service:before {
      left: 8%; }
    body.front #icon-blocks .block__content div.freekm:before {
      left: 6%; }
    body.front #icon-blocks .block__content div.freedelivery:before {
      left: 8%; }
    body.front #icon-blocks .block__content div.finalprices:before {
      left: 7%; }
    body.front #icon-blocks .block__content div.hiddencharges:before {
      left: 7%; }
    @media (max-width: 1023px) {
      body.front #icon-blocks .block__content div.insurance:before {
        transform: translateY(-50%) scale(0.6);
        left: 2%; }
      body.front #icon-blocks .block__content div.service:before {
        transform: translateY(-50%) scale(0.6);
        left: 2%; }
      body.front #icon-blocks .block__content div.freekm:before {
        transform: translateY(-50%) scale(0.6);
        left: 2%; }
      body.front #icon-blocks .block__content div.freedelivery:before {
        transform: translateY(-50%) scale(0.6);
        left: 2%; }
      body.front #icon-blocks .block__content div.finalprices:before {
        transform: translateY(-50%) scale(0.6);
        left: 2%; }
      body.front #icon-blocks .block__content div.hiddencharges:before {
        transform: translateY(-50%) scale(0.6);
        left: 2%; } }
    @media (max-width: 767px) {
      body.front #icon-blocks .block__content div.freekm:before {
        left: -14px; } }
    @media (max-width: 479px) {
      body.front #icon-blocks .block__content div.freekm:before {
        left: -4px; } }
  body.front #icon-blocks .block__content .insurance p {
    position: absolute;
    left: 42%; }
  body.front #icon-blocks .block__content .insurance:before {
    left: 12px;
    margin-right: 19%; }
  body.front #icon-blocks .block__content .freekm p {
    position: absolute;
    left: 38%; }
    @media (max-width: 1600px) {
      body.front #icon-blocks .block__content .freekm p {
        left: 34%; } }
  body.front #icon-blocks .block__content .freekm:before {
    margin-right: 12%; }
  body.front #icon-blocks .block__content .service {
    padding-left: 27px; }
    @media (max-width: 1023px) {
      body.front #icon-blocks .block__content .service {
        padding-left: 20px; } }
    body.front #icon-blocks .block__content .service p {
      position: absolute;
      left: 37%; }
  body.front #icon-blocks .block__content .freedelivery,
  body.front #icon-blocks .block__content .finalprices {
    padding-left: 30px;
    padding-top: 25px; }
    @media (max-width: 1023px) {
      body.front #icon-blocks .block__content .freedelivery,
      body.front #icon-blocks .block__content .finalprices {
        padding: 20px; } }
    body.front #icon-blocks .block__content .freedelivery p,
    body.front #icon-blocks .block__content .finalprices p {
      position: absolute;
      left: 33%; }
  body.front #icon-blocks .block__content .hiddencharges {
    padding-left: 35px; }
    @media (max-width: 1023px) {
      body.front #icon-blocks .block__content .hiddencharges {
        padding-left: 20px; } }
    body.front #icon-blocks .block__content .hiddencharges p {
      position: absolute;
      left: 33%; }
  @media (max-width: 1390px) {
    body.front #icon-blocks .block__content .insurance p {
      left: 50%;
      transform: translate(-50%, -50%); }
    body.front #icon-blocks .block__content .freekm p {
      left: 50%;
      transform: translate(-50%, -50%); }
    body.front #icon-blocks .block__content .service p {
      left: 50%;
      transform: translate(-50%, -50%); }
    body.front #icon-blocks .block__content .freedelivery p,
    body.front #icon-blocks .block__content .finalprices p {
      left: 50%;
      transform: translate(-50%, -50%); }
    body.front #icon-blocks .block__content .hiddencharges p {
      left: 50%;
      transform: translate(-50%, -50%); } }
  @media (min-width: 479px) and (max-width: 767px) {
    body.front #icon-blocks .block__content .insurance p {
      transform: translate(-38%, -50%); }
    body.front #icon-blocks .block__content .freekm p {
      transform: translate(-44%, -50%); }
    body.front #icon-blocks .block__content .service p {
      transform: translate(-35%, -50%); }
    body.front #icon-blocks .block__content .freedelivery p,
    body.front #icon-blocks .block__content .finalprices p {
      transform: translate(-35%, -50%); }
    body.front #icon-blocks .block__content .hiddencharges p {
      transform: translate(-35%, -50%); } }

@media (min-width: 1024px) and (max-width: 1390px) {
  body.front #icon-blocks .block__content > div {
    width: 33.33333%;
    float: left;
    margin-bottom: 2px; }
    body.front #icon-blocks .block__content > div:nth-child(3n) {
      width: 33.33333%;
      float: right;
      margin-right: 0;
      border-right: none; } }

@media (max-width: 1023px) {
  body.front #icon-blocks .block__content > div {
    width: 100%; } }

@media (max-width: 767px) {
  body.front #icon-blocks .block__content > div {
    margin-bottom: 0px !important; } }

@media (max-width: 1023px) {
  body.page-node-216 .l-main .l-content article .node__content .field__item > h2:first-child {
    font-size: 20px; } }

.offer-item {
  width: 32.25806%;
  float: left;
  margin-right: 1.6129%;
  border: 1px solid #c7c9ca;
  margin-bottom: 1.6129%;
  min-height: 650px;
  text-align: center;
  padding-bottom: 20px; }
  @media (max-width: 1223px) {
    .offer-item {
      width: 49.20456%;
      float: left;
      margin-right: 1.59088%; } }
  @media (max-width: 1023px) {
    .offer-item {
      min-height: 625px; } }
  @media (max-width: 767px) {
    .offer-item {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0;
      min-height: 0;
      float: unset;
      max-width: 320px;
      margin-left: auto;
      margin-right: auto; } }
  .offer-item:nth-child(3n+3) {
    width: 32.25806%;
    float: right;
    margin-right: 0; }
    @media (max-width: 1223px) {
      .offer-item:nth-child(3n+3) {
        width: 49.20456%;
        float: left;
        margin-right: 1.59088%; } }
    @media (max-width: 767px) {
      .offer-item:nth-child(3n+3) {
        width: 100%;
        float: left;
        margin-left: 0;
        margin-right: 0;
        float: unset;
        max-width: 320px;
        margin-left: auto;
        margin-right: auto; } }
  @media (max-width: 1223px) {
    .offer-item:nth-child(2n+2) {
      width: 49.20456%;
      float: right;
      margin-right: 0; } }
  @media (max-width: 767px) {
    .offer-item:nth-child(2n+2) {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0;
      float: unset;
      max-width: 320px;
      margin-left: auto;
      margin-right: auto; } }

.offer-title {
  margin: 0;
  height: calc(2.4em + 30px);
  font-size: 20px;
  font-weight: 700;
  color: #064599;
  line-height: 1.2;
  padding: 15px;
  text-align: left; }
  @media (max-width: 1327px) {
    .offer-title {
      font-size: 18px;
      letter-spacing: -0.3px; } }
  @media (max-width: 1023px) {
    .offer-title {
      height: calc(3.6em + 30px); } }

.offer-image {
  padding-bottom: 75%;
  width: 100%; }

.offer-car {
  border-width: 0 0 1px;
  border-color: #c7c9ca;
  border-style: solid;
  font-weight: 700;
  padding: 10px 0;
  margin: 0 auto;
  max-width: 95%; }

.offer-description {
  text-align: left;
  padding: 10px;
  height: 150px;
  overflow-y: auto; }

.offer-prices {
  background-color: #f3f8fb;
  width: 100%;
  overflow: hidden;
  margin-top: 5px;
  border-width: 1px 0;
  border-style: solid;
  border-color: #ddd; }
  .offer-prices > span {
    width: 50%;
    float: left;
    display: inline-block;
    text-align: center;
    margin: 15px 0; }
    .offer-prices > span:first-child {
      border-right: 1px solid #ddd; }
    .offer-prices > span .price-value {
      color: #ffec00;
      font-weight: 900;
      font-size: 32px;
      margin-bottom: 8px;
      display: block; }

.offer-button {
  color: white;
  text-transform: uppercase;
  padding: 10px 25px;
  background-color: #064599;
  margin-top: 20px; }
  .offer-button:hover {
    color: white;
    background-color: #0c3b7a; }

body.page-checkout .info-block {
  padding-left: 5px;
  position: relative;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  padding-top: 0; }
  body.page-checkout .info-block:before {
    display: none;
    left: 0;
    transform: unset; }

#commerce-checkout-form-checkout h2.page-title,
#commerce-checkout-form-complete h2.page-title {
  color: #064599;
  font-size: 28px;
  border-bottom: 1px solid #064599;
  margin-top: 37px;
  padding-bottom: 36px;
  margin-bottom: 0; }
  @media (max-width: 767px) {
    #commerce-checkout-form-checkout h2.page-title,
    #commerce-checkout-form-complete h2.page-title {
      font-size: 24px; } }
  @media (max-width: 640px) {
    #commerce-checkout-form-checkout h2.page-title,
    #commerce-checkout-form-complete h2.page-title {
      margin-top: 18px;
      padding-bottom: 15px; } }

#commerce-checkout-form-checkout fieldset,
#commerce-checkout-form-complete fieldset {
  border: none;
  padding: 0;
  margin: 0; }
  #commerce-checkout-form-checkout fieldset#edit-personal-info,
  #commerce-checkout-form-complete fieldset#edit-personal-info {
    margin-bottom: 30px; }
    @media (max-width: 479px) {
      #commerce-checkout-form-checkout fieldset#edit-personal-info,
      #commerce-checkout-form-complete fieldset#edit-personal-info {
        display: contents; } }
    #commerce-checkout-form-checkout fieldset#edit-personal-info legend,
    #commerce-checkout-form-complete fieldset#edit-personal-info legend {
      margin-bottom: 0;
      cursor: pointer; }
      #commerce-checkout-form-checkout fieldset#edit-personal-info legend span,
      #commerce-checkout-form-complete fieldset#edit-personal-info legend span {
        position: relative; }
        #commerce-checkout-form-checkout fieldset#edit-personal-info legend span:after,
        #commerce-checkout-form-complete fieldset#edit-personal-info legend span:after {
          content: '';
          position: absolute;
          border: solid #064599;
          border-width: 0 5px 5px 0;
          display: inline-block;
          padding: 5px;
          transform: rotate(45deg) translateY(-50%);
          top: 45%;
          transition: all 0.35s ease;
          cursor: pointer;
          right: -18px; }
      #commerce-checkout-form-checkout fieldset#edit-personal-info legend.active span:after,
      #commerce-checkout-form-complete fieldset#edit-personal-info legend.active span:after {
        right: -25px;
        transform: rotate(-45deg) translateY(-50%); }
    #commerce-checkout-form-checkout fieldset#edit-personal-info .fieldset-wrapper,
    #commerce-checkout-form-complete fieldset#edit-personal-info .fieldset-wrapper {
      display: none; }

#commerce-checkout-form-checkout legend,
#commerce-checkout-form-complete legend {
  color: #064599;
  font-weight: 900;
  font-size: 22px;
  line-height: 1;
  padding: 16px 0;
  border-width: 1px 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.075);
  display: block;
  width: 100%;
  margin-bottom: 15px; }

#commerce-checkout-form-checkout .checkout-completion-message,
#commerce-checkout-form-complete .checkout-completion-message {
  margin-top: 20px; }

#review-personal-info {
  min-height: 268px;
  background-color: #f3f3f3;
  width: 100%;
  table-layout: fixed;
  margin-bottom: 22px; }
  @media (max-width: 1023px) {
    #review-personal-info {
      display: block;
      overflow: hidden; }
      #review-personal-info > tbody {
        display: block; } }
  @media (max-width: 1023px) {
    #review-personal-info tr {
      display: block;
      width: 100%;
      padding: 0 4px;
      overflow: hidden; }
      #review-personal-info tr:nth-child(4) td:nth-child(3) {
        display: none; } }
  @media (max-width: 1023px) and (max-width: 1023px) {
    #review-personal-info tr > td {
      display: block; } }
  #review-personal-info tr:first-child td {
    padding-top: 15px; }
    @media (max-width: 1023px) {
      #review-personal-info tr:first-child td {
        padding: 5px 15px; } }
  #review-personal-info tr:last-child td {
    padding-bottom: 20px; }
    @media (max-width: 1023px) {
      #review-personal-info tr:last-child td {
        padding: 5px 15px;
        border-bottom: none; } }
  #review-personal-info tr td {
    padding: 8px 15px; }
    #review-personal-info tr td .heading {
      font-weight: 700; }
    @media (max-width: 1823px) {
      #review-personal-info tr td {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; } }
    @media (max-width: 1023px) {
      #review-personal-info tr td {
        line-height: 1.2;
        height: calc(2.4em + 12px);
        border-bottom: 1px solid rgba(143, 144, 128, 0.5);
        white-space: unset;
        overflow: unset;
        text-overflow: unset; } }
    @media (max-width: 767px) {
      #review-personal-info tr td {
        height: initial;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; } }
    #review-personal-info tr td:first-child {
      width: 180px;
      font-weight: 300; }
      @media (max-width: 1023px) {
        #review-personal-info tr td:first-child {
          width: 41.66667%;
          float: left; } }
      @media (max-width: 767px) {
        #review-personal-info tr td:first-child {
          width: 100%;
          float: left;
          margin-left: 0;
          margin-right: 0;
          padding-bottom: 2px;
          border-bottom: none; }
          #review-personal-info tr td:first-child:after {
            content: ':'; } }
    #review-personal-info tr td:nth-child(2) {
      width: calc(50% - 140px); }
      @media (max-width: 1023px) {
        #review-personal-info tr td:nth-child(2) {
          width: 58.33333%;
          float: right;
          margin-right: 0; } }
      @media (max-width: 767px) {
        #review-personal-info tr td:nth-child(2) {
          width: 100%;
          float: left;
          margin-left: 0;
          margin-right: 0;
          padding-top: 2px; } }
    #review-personal-info tr td:nth-child(3) {
      width: 170px;
      font-weight: 300; }
      @media (max-width: 1023px) {
        #review-personal-info tr td:nth-child(3) {
          width: 41.66667%;
          float: left; } }
      @media (max-width: 767px) {
        #review-personal-info tr td:nth-child(3) {
          width: 100%;
          float: left;
          margin-left: 0;
          margin-right: 0;
          padding-bottom: 2px;
          border-bottom: none; }
          #review-personal-info tr td:nth-child(3):after {
            content: ':'; } }
    #review-personal-info tr td:nth-child(4) {
      width: calc(50% - 170px); }
      @media (max-width: 1023px) {
        #review-personal-info tr td:nth-child(4) {
          width: 58.33333%;
          float: right;
          margin-right: 0; } }
      @media (max-width: 767px) {
        #review-personal-info tr td:nth-child(4) {
          width: 100%;
          float: left;
          margin-left: 0;
          margin-right: 0;
          padding-top: 2px; } }

.view-commerce-cart-summary .view-content {
  margin: 0 0 25px; }

#edit-commerce-payment-payment-method {
  margin-top: 35px; }
  #edit-commerce-payment-payment-method .form-type-radio {
    margin-bottom: 40px;
    width: 33%;
    float: left; }
    @media (max-width: 1023px) {
      #edit-commerce-payment-payment-method .form-type-radio {
        width: 100%; } }
    #edit-commerce-payment-payment-method .form-type-radio input {
      display: none; }
      #edit-commerce-payment-payment-method .form-type-radio input:checked + label:after {
        background-color: #064599; }
    #edit-commerce-payment-payment-method .form-type-radio label {
      position: relative;
      padding-left: 30px;
      cursor: pointer;
      font-size: 18px;
      font-weight: 300;
      line-height: 21px;
      display: inline-block; }
      #edit-commerce-payment-payment-method .form-type-radio label:before {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        border: 1px solid #064599;
        border-radius: 20px; }
      #edit-commerce-payment-payment-method .form-type-radio label .label {
        padding-left: 0; }
      #edit-commerce-payment-payment-method .form-type-radio label img {
        max-width: 100%;
        height: auto;
        right: 30px; }
        @media (min-width: 768px) and (max-width: 1023px) {
          #edit-commerce-payment-payment-method .form-type-radio label img {
            max-width: 400px; } }
      #edit-commerce-payment-payment-method .form-type-radio label:after {
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        position: absolute;
        top: 7px;
        left: 7px;
        border-radius: 8px;
        background-color: transparent;
        transition: background-color 0.25s ease; }

#payment-details .styled {
  box-shadow: 0px 0px 13px #dedede;
  max-width: 450px;
  display: block !important;
  margin: auto;
  border-radius: 5px;
  padding: 17px;
  margin-bottom: 30px; }
  #payment-details .styled p:first-child {
    color: #064599;
    font-weight: 700;
    margin-bottom: 8px; }

#edit-commerce-payment .fieldset-wrapper {
  text-align: center;
  margin-top: 10px; }
  #edit-commerce-payment .fieldset-wrapper .payment_info_checkout {
    font-size: 20px;
    text-align: center;
    max-width: 750px;
    padding-left: 55px;
    padding-right: 55px;
    clear: both;
    display: block;
    margin: 0 auto;
    position: relative; }
    @media (max-width: 767px) {
      #edit-commerce-payment .fieldset-wrapper .payment_info_checkout {
        padding-left: 20px;
        padding-right: 20px; } }
    #edit-commerce-payment .fieldset-wrapper .payment_info_checkout:before, #edit-commerce-payment .fieldset-wrapper .payment_info_checkout:after {
      content: '';
      width: 34px;
      height: 63px;
      background: url(../images/sprites/svg/optimized/quote1_icon.svg) no-repeat;
      background-size: contain;
      display: block;
      position: absolute; }
      @media (max-width: 767px) {
        #edit-commerce-payment .fieldset-wrapper .payment_info_checkout:before, #edit-commerce-payment .fieldset-wrapper .payment_info_checkout:after {
          background-size: 60%; } }
    #edit-commerce-payment .fieldset-wrapper .payment_info_checkout:before {
      top: -8px;
      left: 8px; }
      @media (max-width: 767px) {
        #edit-commerce-payment .fieldset-wrapper .payment_info_checkout:before {
          left: -4px; } }
    #edit-commerce-payment .fieldset-wrapper .payment_info_checkout:after {
      bottom: -8px;
      right: 8px;
      transform: rotate(180deg); }
      @media (max-width: 767px) {
        #edit-commerce-payment .fieldset-wrapper .payment_info_checkout:after {
          right: -4px; } }
  #edit-commerce-payment .fieldset-wrapper > * {
    text-align: left;
    font-size: 14px;
    width: 100%;
    display: inline-block; }
  #edit-commerce-payment .fieldset-wrapper .subsection h6, #edit-commerce-payment .fieldset-wrapper #commerce-checkout-form-checkout .checkout-completion-message h6, #commerce-checkout-form-checkout #edit-commerce-payment .fieldset-wrapper .checkout-completion-message h6, #edit-commerce-payment .fieldset-wrapper
  #commerce-checkout-form-complete .checkout-completion-message h6,
  #commerce-checkout-form-complete #edit-commerce-payment .fieldset-wrapper .checkout-completion-message h6 {
    margin-top: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.075);
    padding: 15px 0 15px 2px;
    font-size: 20px; }
  #edit-commerce-payment .fieldset-wrapper .subsection .info-block, #edit-commerce-payment .fieldset-wrapper #commerce-checkout-form-checkout .checkout-completion-message .info-block, #commerce-checkout-form-checkout #edit-commerce-payment .fieldset-wrapper .checkout-completion-message .info-block, #edit-commerce-payment .fieldset-wrapper
  #commerce-checkout-form-complete .checkout-completion-message .info-block,
  #commerce-checkout-form-complete #edit-commerce-payment .fieldset-wrapper .checkout-completion-message .info-block {
    margin-top: 20px;
    margin-bottom: 30px;
    padding-left: 55px;
    padding-top: 0; }
    @media (max-width: 767px) {
      #edit-commerce-payment .fieldset-wrapper .subsection .info-block:before, #edit-commerce-payment .fieldset-wrapper #commerce-checkout-form-checkout .checkout-completion-message .info-block:before, #commerce-checkout-form-checkout #edit-commerce-payment .fieldset-wrapper .checkout-completion-message .info-block:before, #edit-commerce-payment .fieldset-wrapper
      #commerce-checkout-form-complete .checkout-completion-message .info-block:before,
      #commerce-checkout-form-complete #edit-commerce-payment .fieldset-wrapper .checkout-completion-message .info-block:before {
        left: 0;
        transform: unset; } }

.terms-agreement {
  font-size: 14px;
  font-weight: 300; }
  .terms-agreement a {
    color: black;
    text-decoration: underline; }

.form-item-commerce-payment-terms-agreement input {
  display: none; }
  .form-item-commerce-payment-terms-agreement input:checked + .option:before {
    color: #064599; }

.form-item-commerce-payment-terms-agreement .option {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 300;
  line-height: 21px;
  display: inline-block;
  margin-top: 11px; }
  .form-item-commerce-payment-terms-agreement .option:before {
    content: '\f00c';
    font-family: 'FontAwesome';
    display: block;
    width: 21px;
    height: 21px;
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid #064599;
    border-radius: 2px;
    color: white;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    transition: color 0.05s ease; }
  .form-item-commerce-payment-terms-agreement .option .form-required {
    display: none; }

#edit-commerce-payment-continue {
  border: none;
  display: inline-block;
  text-align: center;
  background-color: #064599;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 700;
  padding: 10px 26px;
  padding-top: 12px;
  max-width: 266px;
  margin-top: 5px;
  color: #ffec00;
  font-size: 20px !important; }
  @media (max-width: 767px) {
    #edit-commerce-payment-continue {
      font-size: 18px; } }
  @media (max-width: 479px) {
    #edit-commerce-payment-continue {
      font-size: 16px;
      letter-spacing: -0.5px; } }
  #edit-commerce-payment-continue:hover {
    background-color: #0c3b7a; }

.element-invisible {
  display: none !important; }

body.node-type-simple-page .l-main .l-content article img {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px; }

#block-block-40 .boxes {
  width: 100%;
  display: inline-block;
  margin-top: 19px; }
  #block-block-40 .boxes .box {
    width: 31.44654%;
    float: left;
    margin-right: 2.83019%;
    min-height: 76px;
    padding-top: 17px;
    border-top-left-radius: 35px;
    border-bottom-left-radius: 35px;
    border-right: 15px solid #064599;
    padding-left: 80px; }
    #block-block-40 .boxes .box:not(.active) {
      filter: grayscale(100%); }
    #block-block-40 .boxes .box span {
      width: 100%;
      display: inline-block;
      text-align: left;
      position: relative; }
    #block-block-40 .boxes .box:last-child {
      width: 31.44654%;
      float: right;
      margin-right: 0; }
    #block-block-40 .boxes .box.box_1 {
      background: url("/sites/all/themes/omgsub/images/Step1.png") no-repeat;
      background-color: #f1f1f1; }
    #block-block-40 .boxes .box.box_2 {
      background: url("/sites/all/themes/omgsub/images/Step2.png") no-repeat;
      background-color: #f1f1f1; }
    #block-block-40 .boxes .box.box_3 {
      background: url("/sites/all/themes/omgsub/images/Step3.png") no-repeat;
      background-color: #f1f1f1; }
    #block-block-40 .boxes .box h3 {
      color: #064599;
      font-size: 24px;
      font-weight: 700;
      line-height: 27px;
      word-spacing: 3px;
      letter-spacing: -1.8px;
      margin: auto; }
    #block-block-40 .boxes .box span {
      font-size: 14px;
      font-weight: 400;
      line-height: 27px; }

@media (max-width: 1330px) {
  #block-block-40 .boxes .box {
    padding-top: 8px;
    width: 32.67974%;
    float: left;
    margin-right: 0.98039%; }
    #block-block-40 .boxes .box:last-child {
      width: 32.67974%;
      float: right;
      margin-right: 0; }
    #block-block-40 .boxes .box.box_2 h3 {
      margin-bottom: 10px; }
    #block-block-40 .boxes .box.box_2 span {
      left: auto; }
    #block-block-40 .boxes .box h3 {
      font-size: 20px;
      max-width: 100%;
      text-align: left;
      margin-bottom: 4px; }
    #block-block-40 .boxes .box span {
      font-size: 12px;
      margin: auto;
      display: block;
      line-height: 13px; } }

@media (max-width: 1023px) {
  #block-block-40 .boxes .box {
    padding-top: 8px;
    margin-bottom: 7px;
    padding-left: 71px;
    position: relative;
    border-right: 11px solid #064599; }
    #block-block-40 .boxes .box h3 {
      display: none; }
    #block-block-40 .boxes .box span {
      position: absolute;
      left: 71px;
      width: auto;
      top: 50%;
      transform: translateY(-50%); } }

@media (max-width: 767px) {
  #block-block-40 .boxes {
    max-width: 263px;
    display: block;
    margin: auto;
    margin-top: 12px;
    margin-bottom: 12px; }
    #block-block-40 .boxes .box {
      background-color: #fff;
      border-right: none;
      max-width: 90px;
      padding-left: 0; }
      #block-block-40 .boxes .box.box_1 {
        background-position: center;
        background-color: #fff; }
      #block-block-40 .boxes .box.box_2 {
        background-position: center;
        background-color: #fff; }
      #block-block-40 .boxes .box.box_3 {
        background-position: center;
        background-color: #fff;
        max-width: 90px;
        padding-left: 0;
        float: unset;
        display: inline-block; }
      #block-block-40 .boxes .box h3 {
        display: none; }
      #block-block-40 .boxes .box span {
        display: none; } }

body.page-admin-commerce .view-commerce-line-item-table {
  width: 100%;
  margin-bottom: 30px; }
  body.page-admin-commerce .view-commerce-line-item-table .view-content .item-list .views-row {
    list-style-type: none; }
    body.page-admin-commerce .view-commerce-line-item-table .view-content .item-list .views-row .views-field {
      margin-bottom: 4px;
      width: 100%;
      display: inline-block; }
      body.page-admin-commerce .view-commerce-line-item-table .view-content .item-list .views-row .views-field.megatitle {
        font-weight: 900;
        font-size: 25px; }
      body.page-admin-commerce .view-commerce-line-item-table .view-content .item-list .views-row .views-field .views-label {
        width: 150px;
        display: inline-block;
        float: left;
        font-weight: 700; }
      body.page-admin-commerce .view-commerce-line-item-table .view-content .item-list .views-row .views-field .field-content {
        width: calc(100% - 150px);
        display: inline-block;
        float: right; }
      body.page-admin-commerce .view-commerce-line-item-table .view-content .item-list .views-row .views-field.views-field-php > .field-content {
        width: 100%; }

@media (max-width: 1023px) {
  body.page-checkout- .l-main .l-content #commerce-checkout-form-checkout .price-table .final-price .price-value {
    font-size: 22px; } }

@media (max-width: 1023px) {
  body.page-checkout- .l-main .l-content #commerce-checkout-form-checkout .price-table .downpayment-price .price-value {
    font-size: 22px; } }

#block-views-car-slider-block {
  width: 66.13637%;
  float: left;
  margin-right: 1.59088%; }
  #block-views-car-slider-block #flexslider-1 {
    max-width: 559px;
    margin: 0 auto 0px; }
    @media (max-width: 640px) {
      #block-views-car-slider-block #flexslider-1 {
        border: 8px solid #fff; } }
  @media (max-width: 1327px) {
    #block-views-car-slider-block {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0; } }
  #block-views-car-slider-block .flex-control-thumbs {
    margin: 36px -20px 0; }
    @media (max-width: 1023px) {
      #block-views-car-slider-block .flex-control-thumbs {
        margin: 5px 0 0; } }
    #block-views-car-slider-block .flex-control-thumbs li {
      width: 18.83665%;
      float: left;
      margin-right: 1.45419%; }
      #block-views-car-slider-block .flex-control-thumbs li img {
        height: 58px;
        object-fit: cover;
        margin-bottom: 8px; }
        @media (max-width: 1223px) {
          #block-views-car-slider-block .flex-control-thumbs li img {
            height: auto;
            max-width: 100%; } }
      #block-views-car-slider-block .flex-control-thumbs li:nth-child(5n + 5) {
        width: 18.83665%;
        float: right;
        margin-right: 0; }
  @media (max-width: 1023px) {
    #block-views-car-slider-block .flex-direction-nav {
      display: none; } }
  @media (max-width: 640px) {
    #block-views-car-slider-block .flex-direction-nav {
      display: initial; } }
  #block-views-car-slider-block .flex-direction-nav li {
    font-size: 0;
    color: transparent; }
    #block-views-car-slider-block .flex-direction-nav li a {
      opacity: 1;
      display: inline-block;
      width: unset;
      height: unset;
      margin: 0;
      transform: translate(0, -50%);
      text-shadow: unset;
      top: 50%; }
      #block-views-car-slider-block .flex-direction-nav li a:before {
        font-family: 'FontAwesome';
        color: #064599;
        text-shadow: unset;
        line-height: 1;
        vertical-align: bottom; }
  #block-views-car-slider-block .flex-prev {
    left: -25px; }
    @media (max-width: 640px) {
      #block-views-car-slider-block .flex-prev {
        left: -15px; } }
    #block-views-car-slider-block .flex-prev:before {
      content: '\f104'; }
  #block-views-car-slider-block .flex-next {
    right: -25px; }
    @media (max-width: 640px) {
      #block-views-car-slider-block .flex-next {
        right: -15px; } }
    #block-views-car-slider-block .flex-next:before {
      content: '\f105'; }

#block-views-car-description-video-block {
  width: 32.27275%;
  float: right;
  margin-right: 0;
  position: relative; }
  @media (max-width: 1327px) {
    #block-views-car-description-video-block {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0; } }
  #block-views-car-description-video-block h4 {
    color: #064599;
    margin: 0 0 15px;
    font-size: 20px;
    font-weight: 900; }
    @media (max-width: 1327px) {
      #block-views-car-description-video-block h4 {
        margin: 0 0 12px;
        line-height: 2.6;
        border-width: 1px 0;
        border-style: solid;
        border-color: rgba(0, 0, 0, 0.1); } }
  #block-views-car-description-video-block .view-content .car-description p {
    margin-bottom: 0; }
  #block-views-car-description-video-block .view-content a {
    transition: color 0.25s ease;
    display: inline-block;
    position: relative; }
    #block-views-car-description-video-block .view-content a:hover:before {
      box-shadow: 1px 1px 4px rgba(255, 255, 255, 0.25); }
    #block-views-car-description-video-block .view-content a:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: inline-block;
      width: 38px;
      height: 38px;
      background: transparent url("/sites/all/themes/omgsub/images/play_button.svg") no-repeat center top;
      background-size: contain;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.75);
      border-radius: 50%;
      z-index: 9;
      transition: box-shadow 0.25s ease; }
    #block-views-car-description-video-block .view-content a img {
      display: inline-block;
      width: 240px;
      height: auto;
      vertical-align: bottom;
      box-sizing: content-box; }
  #block-views-car-description-video-block .view-footer {
    width: 100%;
    display: inline-block;
    position: absolute;
    bottom: 0; }
    #block-views-car-description-video-block .view-footer .sendofferpopup {
      width: 100%;
      margin-top: 22px;
      position: relative;
      max-width: 206px;
      display: none; }
      #block-views-car-description-video-block .view-footer .sendofferpopup:before {
        content: "";
        position: absolute;
        width: 42px;
        height: 40px;
        background-image: url("/sites/all/themes/omgsub/images/HEART_ICON.svg");
        background-repeat: no-repeat;
        right: -1px;
        top: 50%;
        transform: translateY(-50%); }
      #block-views-car-description-video-block .view-footer .sendofferpopup a {
        display: inline-block;
        max-width: 188px;
        width: 100%;
        color: #064599;
        background-color: #ffec00;
        font-size: 20px;
        font-weight: 700;
        padding-left: 10px;
        line-height: 30px;
        transition: 0.4s ease;
        pointer-events: none; }

#block-views-car-features-block {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0;
  padding-top: 0;
  margin-top: 0px; }

body.page-node-223 .node__content,
body.page-node-401 .node__content {
  margin-top: 50px;
  margin-bottom: 50px;
  float: left;
  width: 100%;
  max-width: 100%; }
  body.page-node-223 .node__content .taxonomy-term-description hr,
  body.page-node-401 .node__content .taxonomy-term-description hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #e8e8e8;
    margin: 1em 0;
    padding: 0; }

body.section-explore-crete .l-content .term-listing-heading {
  margin-top: 50px;
  margin-bottom: 50px;
  float: left;
  width: 100%;
  max-width: 100%; }

body.section-explore-crete .l-content .taxonomy-term-description hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #e8e8e8;
  margin: 1em 0;
  padding: 0; }

@media (max-width: 520px) {
  body.section-explore-crete .l-content .l-region--highlighted {
    width: 100%;
    float: left; }
    body.section-explore-crete .l-content .l-region--highlighted #block-menu-menu-explore-crete-menu ul li a {
      line-height: 20px; }
      body.section-explore-crete .l-content .l-region--highlighted #block-menu-menu-explore-crete-menu ul li a:after {
        display: none; }
    body.section-explore-crete .l-content .l-region--highlighted #block-menu-menu-explore-crete-menu ul li ul {
      position: relative;
      background-color: #064599;
      width: 100%;
      left: 0; }
      body.section-explore-crete .l-content .l-region--highlighted #block-menu-menu-explore-crete-menu ul li ul li a {
        color: #fff; }
      body.section-explore-crete .l-content .l-region--highlighted #block-menu-menu-explore-crete-menu ul li ul li > ul li {
        display: contents; }
        body.section-explore-crete .l-content .l-region--highlighted #block-menu-menu-explore-crete-menu ul li ul li > ul li a {
          font-size: 12px; }
    body.section-explore-crete .l-content .l-region--highlighted #block-menu-menu-explore-crete-menu > ul > li {
      margin-bottom: 10px; } }

body.page-node-369 .l-main strong {
  color: #064599; }

body:not(.front) .form-type-checkboxes.form-item-car-type {
  margin-bottom: 8px; }
  @media (max-width: 1274px) {
    body:not(.front) .form-type-checkboxes.form-item-car-type {
      width: 100%;
      display: inline-block;
      margin-bottom: 5px; } }
  @media (max-width: 1274px) {
    body:not(.front) .form-type-checkboxes.form-item-car-type > label {
      width: 135px;
      float: left; } }
  body:not(.front) .form-type-checkboxes.form-item-car-type #edit-car-type {
    position: relative;
    bottom: 5px; }
    @media (max-width: 1274px) {
      body:not(.front) .form-type-checkboxes.form-item-car-type #edit-car-type {
        width: calc(100% - 145px);
        float: left; } }

body:not(.front) .form-type-checkboxes.form-item-gearbox #edit-gearbox {
  position: relative;
  bottom: 5px; }
  @media (max-width: 1274px) {
    body:not(.front) .form-type-checkboxes.form-item-gearbox #edit-gearbox {
      width: calc(100% - 145px);
      float: left;
      margin-bottom: 10px; } }
  @media (max-width: 640px) {
    body:not(.front) .form-type-checkboxes.form-item-gearbox #edit-gearbox {
      margin-bottom: 0; } }

body:not(.front) .form-type-checkboxes.form-item-gearbox > label {
  margin-right: 10px; }
  @media (max-width: 1274px) {
    body:not(.front) .form-type-checkboxes.form-item-gearbox > label {
      width: 135px;
      float: left;
      margin-bottom: 10px; } }

body:not(.front) .form-type-checkboxes > label {
  margin-right: 10px; }

body:not(.front) .form-type-checkboxes .form-checkboxes .form-item {
  margin-bottom: 0; }
  body:not(.front) .form-type-checkboxes .form-checkboxes .form-item label {
    margin-right: 17px;
    padding-left: 28px; }
    body:not(.front) .form-type-checkboxes .form-checkboxes .form-item label:before {
      top: 0px; }
    body:not(.front) .form-type-checkboxes .form-checkboxes .form-item label:after {
      top: 8px; }

body:not(.front) .block__content form > div .form-item:not(.form-item-car-type):not(.form-item-gearbox) .block-select {
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */ }
  body:not(.front) .block__content form > div .form-item:not(.form-item-car-type):not(.form-item-gearbox) .block-select .locations-dropdown-container ul li label:hover {
    background-color: #085ef7; }
  body:not(.front) .block__content form > div .form-item:not(.form-item-car-type):not(.form-item-gearbox) .block-select .locations-dropdown-container ul li input:checked ~ label {
    background-color: #085ef7; }
  body:not(.front) .block__content form > div .form-item:not(.form-item-car-type):not(.form-item-gearbox) .block-select.open .locations-dropdown-container ul {
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease; }

body:not(.front) .block__content form > div .form-item.form-type-textfield input {
  padding: 0 8px 0 40px; }

body:not(.front) .block__content form > div .form-item.form-type-textfield.form-item-pickup-time input, body:not(.front) .block__content form > div .form-item.form-type-textfield.form-item-dropoff-time input {
  width: 120px;
  background-color: #085ef7; }

body:not(.front) .block__content form > div input[type="submit"] {
  padding-left: 9px;
  padding-right: 9px; }

@media (max-width: 479px) {
  body:not(.front) #block-rent-a-car-rent-a-car-search-sidebar .block__content {
    padding-top: 12px; } }

body:not(.front) #block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .form-item.form-type-select {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0; }

body:not(.front) #block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .form-item .form-select,
body:not(.front) #block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .form-item .block-select .select-title {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0; }

body:not(.front) #block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .form-item.form-item-pickup-date, body:not(.front) #block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .form-item.form-item-dropoff-date {
  width: calc(100% - 120px);
  margin-bottom: 39px; }
  @media (max-width: 1223px) {
    body:not(.front) #block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .form-item.form-item-pickup-date, body:not(.front) #block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .form-item.form-item-dropoff-date {
      width: calc(100% - 94px); } }
  body:not(.front) #block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .form-item.form-item-pickup-date input, body:not(.front) #block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .form-item.form-item-dropoff-date input {
    padding-left: 50px; }
    @media (max-width: 1223px) {
      body:not(.front) #block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .form-item.form-item-pickup-date input, body:not(.front) #block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .form-item.form-item-dropoff-date input {
        padding-left: 41px; } }

body:not(.front) #block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .form-item.form-item-dropoff-date {
  margin-bottom: 10px; }

body:not(.front) #block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .form-item.form-item-pickup-time, body:not(.front) #block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .form-item.form-item-dropoff-time {
  margin-bottom: 39px; }
  @media (max-width: 1223px) {
    body:not(.front) #block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .form-item.form-item-pickup-time input, body:not(.front) #block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .form-item.form-item-dropoff-time input {
      width: 94px;
      padding: 0;
      padding-right: 40px;
      text-align: right; } }

body:not(.front) #block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .form-item.form-item-dropoff-time {
  margin-bottom: 10px; }

body:not(.front) #block-rent-a-car-rent-a-car-search-sidebar .block__content form > div .form-item.form-item-old-enough {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px; }

@media (min-width: 768px) and (max-width: 1100px) {
  body:not(.front) .form-type-checkboxes.form-item-car-type label {
    width: 100px;
    float: left; }
  body:not(.front) .form-type-checkboxes.form-item-car-type #edit-car-type {
    width: calc(100% - 110px);
    float: left; }
  body:not(.front) .form-type-checkboxes.form-item-gearbox #edit-gearbox {
    width: calc(100% - 110px);
    float: left; }
  body:not(.front) .form-type-checkboxes.form-item-gearbox > label {
    width: 100px;
    float: left; }
  body:not(.front) .block__content form > div .form-item:not(.form-item-car-type):not(.form-item-gearbox) .block-select {
    /* width */
    /* Track */
    /* Handle */
    /* Handle on hover */ }
    body:not(.front) .block__content form > div .form-item:not(.form-item-car-type):not(.form-item-gearbox) .block-select.open .locations-dropdown-container ul {
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease; } }

@media (max-width: 767px) {
  body:not(.front) .form-type-checkboxes {
    width: 100%;
    display: inline-block; }
    body:not(.front) .form-type-checkboxes.form-item-car-type label {
      width: 100%;
      margin-bottom: 7px; }
    body:not(.front) .form-type-checkboxes.form-item-car-type #edit-car-type {
      width: 100%; }
    body:not(.front) .form-type-checkboxes.form-item-gearbox #edit-gearbox {
      width: 100%; }
    body:not(.front) .form-type-checkboxes.form-item-gearbox > label {
      width: 100%;
      margin-bottom: 7px; }
  body:not(.front) .block__content form > div .form-item:not(.form-item-car-type):not(.form-item-gearbox) .block-select {
    /* width */
    /* Track */
    /* Handle */
    /* Handle on hover */ }
    body:not(.front) .block__content form > div .form-item:not(.form-item-car-type):not(.form-item-gearbox) .block-select.open .locations-dropdown-container ul {
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease; } }

body.section-car-rental-policy .l-main .l-content article .node__content .field .field__item p img {
  max-width: 17px;
  margin-right: 5px;
  position: relative;
  top: 2px; }

body.page-node-368 .l-main .l-content #block-webform-client-block-400 {
  max-width: 900px;
  margin: auto;
  width: 100%;
  display: block;
  overflow: hidden; }
  body.page-node-368 .l-main .l-content #block-webform-client-block-400 .block__content {
    width: 100%;
    display: inline-block; }
    body.page-node-368 .l-main .l-content #block-webform-client-block-400 .block__content form input[type="text"],
    body.page-node-368 .l-main .l-content #block-webform-client-block-400 .block__content form input[type="email"],
    body.page-node-368 .l-main .l-content #block-webform-client-block-400 .block__content form textarea {
      border: 1px solid #064599;
      border-radius: 5px;
      padding: 7px 15px;
      font-size: 18px;
      line-height: 24px;
      position: relative;
      width: 100%;
      display: inline-block; }
      body.page-node-368 .l-main .l-content #block-webform-client-block-400 .block__content form input[type="text"]::-webkit-input-placeholder,
      body.page-node-368 .l-main .l-content #block-webform-client-block-400 .block__content form input[type="email"]::-webkit-input-placeholder,
      body.page-node-368 .l-main .l-content #block-webform-client-block-400 .block__content form textarea::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: #4d4d4d;
        opacity: 1;
        font-weight: 300; }
      body.page-node-368 .l-main .l-content #block-webform-client-block-400 .block__content form input[type="text"]::-moz-placeholder,
      body.page-node-368 .l-main .l-content #block-webform-client-block-400 .block__content form input[type="email"]::-moz-placeholder,
      body.page-node-368 .l-main .l-content #block-webform-client-block-400 .block__content form textarea::-moz-placeholder {
        /* Firefox 19+ */
        color: #4d4d4d;
        opacity: 1;
        font-weight: 300; }
      body.page-node-368 .l-main .l-content #block-webform-client-block-400 .block__content form input[type="text"]:-ms-input-placeholder,
      body.page-node-368 .l-main .l-content #block-webform-client-block-400 .block__content form input[type="email"]:-ms-input-placeholder,
      body.page-node-368 .l-main .l-content #block-webform-client-block-400 .block__content form textarea:-ms-input-placeholder {
        /* IE 10+ */
        color: #4d4d4d;
        opacity: 1;
        font-weight: 300; }
      body.page-node-368 .l-main .l-content #block-webform-client-block-400 .block__content form input[type="text"]:-moz-placeholder,
      body.page-node-368 .l-main .l-content #block-webform-client-block-400 .block__content form input[type="email"]:-moz-placeholder,
      body.page-node-368 .l-main .l-content #block-webform-client-block-400 .block__content form textarea:-moz-placeholder {
        /* Firefox 18- */
        color: #4d4d4d;
        opacity: 1;
        font-weight: 300; }
    body.page-node-368 .l-main .l-content #block-webform-client-block-400 .block__content form .right-group {
      width: 48.07692%;
      float: left;
      margin-right: 3.84615%;
      margin-left: 0;
      border: none;
      padding: 0; }
      body.page-node-368 .l-main .l-content #block-webform-client-block-400 .block__content form .right-group .form-item {
        margin-bottom: 27px; }
    body.page-node-368 .l-main .l-content #block-webform-client-block-400 .block__content form .left-group {
      width: 48.07692%;
      float: right;
      margin-right: 0;
      border: none;
      margin-left: 0;
      padding: 0;
      margin-bottom: 0; }
    body.page-node-368 .l-main .l-content #block-webform-client-block-400 .block__content form .form-actions {
      width: 48.07692%;
      float: right;
      margin-right: 0;
      text-align: center; }
    body.page-node-368 .l-main .l-content #block-webform-client-block-400 .block__content form .form-submit {
      background-color: #064599;
      text-transform: uppercase;
      border: none;
      padding: 12px 25px 11px;
      margin-top: 12px; }
      body.page-node-368 .l-main .l-content #block-webform-client-block-400 .block__content form .form-submit:hover {
        background-color: #0c3b7a; }

body.page-node-368 .l-main .l-content .block__title {
  max-width: 900px;
  margin: 20px auto; }

body.page-node-368 .l-main .l-content .view-testimonials {
  max-width: 900px;
  margin: auto; }
  body.page-node-368 .l-main .l-content .view-testimonials .view-content .item {
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 30px;
    width: 100%;
    display: inline-block;
    box-shadow: 0px 0px 13px #dedede; }
    body.page-node-368 .l-main .l-content .view-testimonials .view-content .item .body p {
      margin-bottom: 5px; }
    body.page-node-368 .l-main .l-content .view-testimonials .view-content .item .rest {
      text-align: right;
      color: #064599;
      font-weight: 700;
      display: inline-block;
      float: right;
      position: relative;
      padding-bottom: 7px;
      border-bottom: 2px dashed #ffec00;
      margin-top: 40px; }
  body.page-node-368 .l-main .l-content .view-testimonials #block-block-39 {
    display: none; }

@media (max-width: 640px) {
  body.page-node-368 .l-main .l-content #block-webform-client-block-400 .block__content form .right-group {
    width: 100%;
    margin: 0; }
    body.page-node-368 .l-main .l-content #block-webform-client-block-400 .block__content form .right-group .form-item {
      margin-bottom: 20px; }
  body.page-node-368 .l-main .l-content #block-webform-client-block-400 .block__content form .left-group {
    width: 100%;
    margin: 0; }
  body.page-node-368 .l-main .l-content #block-webform-client-block-400 .block__content form .form-actions {
    width: 100%;
    margin: 0; }
  body.page-node-368 .l-main .l-content .view-testimonials .view-content .item .rest {
    margin-top: 15px; } }

body.i18n-de #car-search-view .feature.insurance:before {
  margin-right: 4px; }
  @media (max-width: 1023px) {
    body.i18n-de #car-search-view .feature.insurance:before {
      margin-right: 2px; } }
