#block-views-front-page-gallery-block {
  @include clearfix;

  .view-content {
    .gallery_text {
      padding: 33px 40px;
      @include span(6 (gutters: 0.1));
      background: $dblue;
      border-radius: 24px;
      padding-bottom: 20px;
      font-weight: 400;
      font-size: 24px;
      min-height: 439px;

      p:first-child {
        color: $orange;
        font-size: 42px;
        text-align: center;
        margin-bottom: 7px;
        font-weight: 700;
        border-radis: 25px;
      }

      ul {
        @include span(6);
        padding-left: 30px;
        li {
          position: relative;
          list-style: none;
          list-style-type: none;
          line-height: 22px;
          margin-bottom: 22px;
          font-size: 18px;
          padding-left: 25px;
          &:last-child {
            margin-bottom: 0;
          }

          &:before {
            content: '';
            display: inline-block;
            margin-right: 16px;
            //position: relative;
            transform: scale(0.8);
            top: -6px;
            position: absolute;
            left: -15px;
            @extend .svg-ckeck_icon;
            @extend .svg-ckeck_icon-dims;
          }
        }

        + ul {
          @include span(6 last);
        }
      }

      color: white;
    }

    .slick-item {
      @include span(6 (gutters: 0.1) last);
      position: relative;
      .slick__arrow {
        margin-top: 10px;
        position: absolute;
        bottom: 0;
        .slick-prev,
        .slick-next {
          font-size: 0;
          overflow: hidden;
          position: relative;
          outline: none;
          float: left;
          background: #FFF;
          color: $purple;
          border: none;
          &:hover,
          &:focus {
            cursor: pointer;
            background: rgba($purple, 0.3);
            color: #FFFFFF;
          }
          &:after {
            content: '\f105';
            display: block;
            font-family: "FontAwesome";
            text-shadow: none;
            line-height: 34px;
            font-size: 56px;
          }

        }
        .slick-prev {
          //border-bottom-left-radius:23px;
          &:after {
            content: '\f104';
          }
        }
        .slick-next {
          border-top-right-radius:23px;
        }
      }
    }

    .slick-item {
      .slick-slide {
        margin: 0 10px 0 0;

        img {
          border-radius: 25px;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
@include breakpoint(1465px){
  #block-views-front-page-gallery-block {
    .view-content {
      .gallery_text {
        padding: 35px 5px;
        @include breakpoint(1356px){
          padding: 15px 5px;
        }
        @include span(5.4 (gutters: 0.08));

        p:first-child {

        }

        ul {
          padding-left: 15px;
          li {
            font-size: 17px;
            &:last-child {

            }

            &:before {
              margin-right: 10px;
            }
          }

          + ul {

          }
        }


      }

      .slick-item {
        @include span(6.6 (gutters: 0.08) last);

        .slick__arrow {


          .slick-prev,
          .slick-next {


            &:hover,
            &:focus {

            }

            &:after {

            }

          }

          .slick-prev {
            &:after {

            }
          }
        }
      }

      .slick-item {
        .slick-slide {


          img {

          }
        }
      }
    }
  }
}
@include breakpoint($lg){
  #block-views-front-page-gallery-block {
    .view-content {
      .gallery_text {
        @include span(6.5 (gutters: 0.08));
        padding: 38px 5px;

        p:first-child {

        }

        ul {

          li {
            font-size: 16px;
            //margin-bottom: 25px;
            &:last-child {

            }

            &:before {

            }
          }

          + ul {

          }
        }


      }

      .slick-item {
        @include span(5.5 (gutters: 0.08) last);

        .slick__arrow {


          .slick-prev,
          .slick-next {


            &:hover,
            &:focus {

            }

            &:after {

            }

          }

          .slick-prev {
            &:after {

            }
          }
        }
      }

      .slick-item {
        .slick-slide {


          img {

          }
        }
      }
    }
  }
}
@include breakpoint(1170px $md +1){
  #block-views-front-page-gallery-block {
    .view-content {
      .gallery_text {

        padding-bottom: 10px;

        p:first-child {

        }

        ul {

          li {

            //margin-bottom: 25px;
            &:last-child {

            }

            &:before {

            }
          }

          + ul {

          }
        }


      }

      .slick-item {


        .slick__arrow {


          .slick-prev,
          .slick-next {


            &:hover,
            &:focus {

            }

            &:after {

            }

          }

          .slick-prev {
            &:after {

            }
          }
        }
      }

      .slick-item {
        .slick-slide {


          img {

          }
        }
      }
    }
  }
}
@include breakpoint($md){
  #block-views-front-page-gallery-block {
    .view-content {
      .gallery_text {
        @include span(7.1 (gutters: 0.08));
        min-height: 439px;
        padding: 15px 5px;
        padding-bottom: 10px;
        p:first-child {
          font-size: 35px;
          margin-bottom: 0;
        }

        ul {

          li {
            font-size: 16px;
            margin-bottom: 15px;
            &:last-child {

            }

            &:before {

            }
          }

          + ul {

          }
        }


      }

      .slick-item {
        @include span(4.9 (gutters: 0.08) last);

        .slick__arrow {


          .slick-prev,
          .slick-next {


            &:hover,
            &:focus {

            }

            &:after {

            }

          }

          .slick-prev {
            &:after {

            }
          }
        }
      }

      .slick-item {
        .slick-slide {


          img {

          }
        }
      }
    }
  }
}
@include breakpoint($sm){
  #block-views-front-page-gallery-block {
    .view-content {
      .gallery_text {
        @include span(12 (gutters: 0));
        padding: 20px 19px;
        min-height: unset;
        margin-bottom: 10px;
        p:first-child {
          font-size: 35px;
        }

        ul {

          li {
            font-size: 16px;
            &:last-child {

            }

            &:before {

            }
          }

          + ul {

          }
        }


      }

      .slick-item {
        @include span(12(gutters: 0) last);

        .slick__arrow {
          max-width: 80px;
          margin: auto;
          bottom: 18px;

          .slick-prev,
          .slick-next {


            &:hover,
            &:focus {

            }

            &:after {

            }

          }

          .slick-prev {
            &:after {

            }
          }
        }
      }

      .slick-item {
        .slick-slide {
          margin: 0 5px 0 5px;

          img {
            height: auto;
          }
        }
      }
    }
  }
}
@include breakpoint(480px){
  #block-views-front-page-gallery-block {
    .view-content {
      .gallery_text {
        padding: 26px 10px;

        p:first-child {
          font-size: 30px;
        }

        ul {
          padding-left: 9px;
          li {
            font-size: 15px;
            &:last-child {

            }

            &:before {
              margin-right: 3px;
            }
          }

          + ul {

          }
        }


      }

      .slick-item {


        .slick__arrow {


          .slick-prev,
          .slick-next {


            &:hover,
            &:focus {

            }

            &:after {

            }

          }

          .slick-prev {
            &:after {

            }
          }
        }
      }

      .slick-item {
        .slick-slide {


          img {

          }
        }
      }
    }
  }
}
@include breakpoint($sxs){
  #block-views-front-page-gallery-block {
    .view-content {
      .gallery_text {
        padding: 22px 10px;
        p:first-child {
          margin-bottom: 15px;
        }

        ul {

          width: 100%;
          max-width: 300px;
          margin: auto;
          display: block;
          float: unset;
          margin-bottom: 14px;
          li {
            margin-bottom: 14px;
            &:last-child {

            }

            &:before {
              transform: scale(0.7);
              top: -7px;
            }
          }

          + ul {
            width: 100%;
            max-width: 300px;
            margin: auto;
            display: block;
            float: unset;
            margin-top: 8px;
          }
        }


      }

      .slick-item {


        .slick__arrow {


          .slick-prev,
          .slick-next {


            &:hover,
            &:focus {

            }

            &:after {

            }

          }

          .slick-prev {
            &:after {

            }
          }
        }
      }

      .slick-item {
        .slick-slide {


          img {

          }
        }
      }
    }
  }
}

#home_blocks_header{
  @include breakpoint($md){
    margin-top: 36px;
    margin-bottom: 36px;
  }

  @include gim_container(1100px);
  @include breakpoint($xs){
    margin-top: 18px;
  }
  margin-top: 55px;
  margin-bottom: 59px;
  text-align: center;

  h2.block__title{
    @include underlines($orange);

    &:after {
      margin: 15px auto 0;
    }

    @include breakpoint($md){
      font-size: 32px;
    }
    @include breakpoint($xs){
      font-size: 24px;
      line-height: 27px;
      font-weight: normal;
      margin-bottom: 15px;
    }

    margin-top: 0;
    color: $block-title;
    font-size: 42px;
    font-weight: 400;
    line-height: 1.1;
    margin-bottom: 33px;
  }
  .block__content{
    @include breakpoint($md){
      font-size: 14px;
      line-height: 20px;
    }
    @include breakpoint($xs){
      font-size: 12px;
      line-height: 18px;
    }

    color: $black;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.44;
  }
}

#home_blocks{
  @include gim_container(1260px);
  @include breakpoint($lg){
    @include gim_container(768px);
    margin: 40px auto;
    background-size: 396px 295px;
    height: 326px;
    background-position: calc(100% - 40px) center;
    padding: 0 20px;
  }
  @include breakpoint($smaller){
    @include gim_container2(280px, 20px);
    padding-left: 0;
    padding-right: 0;
    margin-top: 20px;
    background-position: center bottom;
    background-size: 100% auto;
    height: 410px;
    margin-bottom: 0;
  }
  background-image: url('/sites/all/themes/omgsub/images/fleet.jpg');
  background-repeat: no-repeat;
  background-position: right center;
  height: 560px;
  margin-top: 120px;
  margin-bottom: 90px;
  h2.block__title{
    @include breakpoint($lg){
      font-size: 31px;
      line-height: 1.125;
      margin-bottom: 17px;
      letter-spacing: -1px;
      max-width: 310px;
    }
    @include breakpoint($sm){
      max-width: 190px;
    }
    @include breakpoint($smaller){
      font-size: 24px;
      line-height: 1;
      margin-bottom: 10px;
      text-align: center;
      max-width: 310px;
    }
    color: $purple;
    font-size: 40px;
    font-weight: 400;
    margin-top: 12px;
    margin-bottom: 45px;
    display: inline-block;
    max-width: 395px;
    line-height: 44px;
    width: 100%;
  }
  .block__content{
    .view{
      .view-content{
        .flexslider{
          @include breakpoint($lg){
            width: 342px;
            padding: 20px;
          }
          @include breakpoint($smaller){
            width: 280px;
            padding: 25px 17px;
          }
          display: inline-block;
          width: 565px;
          background: $purple;
          border: none;
          padding: 25px;
          margin: 0;
          position: relative;
          @include borderradius(8px);
          &:after{
            @include breakpoint($lg){
              border-left-width: 18px;
              border-top-width: 25px;
              border-bottom-width: 25px;
              right: -18px;
            }
            @include breakpoint($smaller){
              top: unset;
              bottom: -18px;
              right: 50%;
              left: unset;
              transform: translate(50%, 0);
              border-left: 27px solid transparent;
              border-right: 27px solid transparent;
              border-bottom: none;
              border-top: 18px solid $purple;
            }
            content: '';
            position: absolute;
            top: 50%;
            right: -25px;
            border-left: 25px solid $purple;
            border-right: 0 solid transparent;
            border-top: 40px solid transparent;
            border-bottom: 40px solid transparent;
            @include rotatetranslate(0, -50%, 0deg);
          }
          ul.slides{
            vertical-align: bottom;
            li{
              @include breakpoint($smaller){
                width: 246px !important;
              }
              vertical-align: bottom;
              h3{
                @include breakpoint($lg){
                  font-size: 20px;
                  line-height: 1;
                  padding-bottom: 10px;
                }
                @include breakpoint($smaller){
                  display: none;

                }
                color: $orange;
                font-size: 30px;
                line-height: 27px;
                margin: 0;
                margin-bottom: 10px;
                position: relative;
                &:after{
                  @include breakpoint($lg){
                    background-color: rgba(#d8d8d8, 0.5);
                    bottom: 0;
                  }
                  content:'';
                  height: 1px;
                  width: 283px;
                  background: #d8d8d8;
                  display: block;
                  bottom: -18px;
                  position: absolute;
                }
              }
              .box{
                @include breakpoint($lg){
                  padding: 0;
                }
                padding: 25px 0;
                display: inline-block;
                width: 100%;
                .left{
                  @include breakpoint($lg){
                    display: none;
                  }
                  width: 130px;
                  display: inline-block;
                  float: left;
                  span.placeholder{
                    width: 158px;
                    height: 198px;
                    display: inline-block;
                  }
                }
                .right{
                  @include breakpoint($lg){
                    width: 100%;
                    padding: 0;
                    font-size: 14px;
                    line-height: 18px;
                    height: calc((6 * 18px) + 2px);
                    overflow-y: auto;
                  }
                  @include breakpoint($smaller){
                    font-size: 12px;
                    height: calc(8 * 18px);
                  }
                  width: calc(100% - 158px);
                  padding: 23px;
                  padding-bottom: 0;
                  display: inline-block;
                  float: right;
                  color: white;
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 26px;
                  *{
                    margin: 0;
                  }
                }
              }
            }
          }
          ol.flex-control-nav{

          }
          ul.flex-direction-nav{
            @include breakpoint($lg){
              width: 72px;
              height: 29px;
              bottom: -51px;
              transform: translate(-50%, 0);
            }
            @include breakpoint($smaller){
              display: none;
            }
            width: 110px;
            height: 50px;
            display: inline-block;
            position: absolute;
            bottom: -75px;
            left: 50%;
            @include translate(calc( -50% - 55px ), 0);
            li {
              @include breakpoint($lg){
                height: 29px;
                width: 29px;
              }
              position: relative;
              height: 50px;
              width: 50px;
              display: inline-block;
              &.flex-nav-next{
                float: right;
              }
              a {
                @include breakpoint($lg){
                  text-align: center;
                }
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                width: unset;
                height: unset;
                background: #EFF1F1;
                text-align: center;
                color: transparent;
                display: block;
                opacity: 1;
                margin: 0;
                @include borderradius(50%);
                &:before{
                  @include breakpoint($lg){
                    line-height: 29px;
                    font-size: 28px;
                  }
                  font-family: "FontAwesome";
                  text-shadow: none;
                  color: #B9BCBD;
                  line-height: 50px;
                  opacity: 1;
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  left: 0;
                  right: 0;
                  width: unset;
                  height: unset;
                }
                &.flex-prev{
                  &:before {
                    content: '\f104';
                    bottom: 0;
                    left: 0;
                    transform: translate(-1px, -1px);
                  }
                }
                &.flex-next{
                  &:before {
                    content: '\f105';
                    bottom: 0;
                    right: 0;
                    transform: translate(1px, -1px);
                  }
                }
                &:hover{
                  &:before {
                    color: $purple;

                    @include transition;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

#home_our_fleet_header{
  @include breakpoint($md){
    margin-top: 90px;
  }
  @include gim_container(920px);
  @include breakpoint($xs){
    margin-top: 20px;
  }
  margin-top: 52px;
  margin-bottom: 52px;
  text-align: center;
  h2.block__title{
    @include breakpoint($md){
      font-size: 32px;
      line-height: calc(1em + 4px);
      margin-bottom: 19px;
    }
    @include breakpoint($xs){
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 12px;
    }
    color: #000006;
    font-size: 40px;
    font-weight: 400;
    line-height: 48px;
    max-width: 650px;
    margin: 0 auto;
    margin-bottom: 23px;
  }
  .block__content{
    @include breakpoint($md){
      font-size: 14px;
      line-height: calc(1em + 6px);
    }
    color: #000006;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    p{
      @include breakpoint($xs){
        font-size: 12px;
        line-height: 1.5;
      }
      margin-bottom: 0;
    }
  }
}

#home_our_fleet{
  @include gim_container(1590px);
  @include breakpoint($md){
    margin-top: 30px;
  }
  @include breakpoint($sm){
    margin-top: 50px;
  }
  @include breakpoint($xs){
    margin-top: 30px;
  }
  margin-top: 34px;
  padding-left: 10px;
  padding-right: 10px;
  div.content {
    .left,
    .right {
      div.box{
        @include breakpoint($lg){
          padding-left: 75px;
          @include span(3 (gutters: 0));
        }
        @include breakpoint($md){
          padding-left: 45px;
          padding-right: 11px;
        }
        @include breakpoint($md){
          padding-left: 25px;
          padding-right: 25px;
          padding-top: 35px;
        }
        @include breakpoint($smaller){
          padding: 35px 6px 0;
        }
        @include breakpoint($xs){
          width: unset;
          float: unset;
          padding-left: 20px;
          padding-right: 18px;
          padding-top: 0;
          margin-bottom: 10px;
          @include span(4 (gutters: 0));
          height: 34px;
          line-height: 34px;
          position: relative;
        }
      }
      h4 {
        @include breakpoint($lg){
          text-align: left;
        }
        @include breakpoint($md){
          font-size: 12px;
          line-height: 18px;
          margin-top: 0;
          margin-bottom: 2px;
        }
        @include breakpoint($sm){
          letter-spacing: -0.5px;
        }
        @include breakpoint($xs){
          color: #1c1c1b;
          font-size: 12px;
          text-transform: unset;
          font-weight: 400;
          display: inline-block;
          position: unset;
          line-height: normal;
          vertical-align: middle;
          letter-spacing: -1px;
        }

        color: $dblue;
        font-size: 18px;
        font-weight: 700;
        line-height: 1.2;
        text-transform: uppercase;
        width: 100%;
        display: inline-block;
        position: relative;
        margin-bottom: 4px;
        margin-top: 50px;

        &:before{
          @include breakpoint($lg){
            left: -70px;
            right: auto;
          }
          @include breakpoint($md){
            height: 42px;
            width: 40px;
          }
          @include breakpoint($smaller){
            height: 34px;
            width: 27px;
            transform: translate(-50%, -100%);
          }
          @include breakpoint($xs){
            transform: unset;
          }
          content: '';
          position: absolute;
          width: 60px;
          height: 70px;
          display: block;
          background-position: center top;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
      p {
        @include breakpoint($md){
          font-size: 12px;
          line-height: calc(1em + 6px);
        }
        @include breakpoint($xs){
          display: none;
        }
        color: $black;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.36;
      }
    }
    .center {
      @include breakpoint($lg){
        @include span(12);
      }
      @include breakpoint($md){
        margin-top: 30px;
      }
      @include breakpoint($xs){
        margin-top: 25px;
      }
      @include span(7.4);
      .view-display-id-block {
        .view-content {
          #slider {
            @include breakpoint($md){
              margin-bottom: 5px;
            }
            margin-bottom: 10px;
            ul.slides {
              padding: 0;
              li {
                .car-type-link {
                  text-align: center;
                  @include breakpoint($lg){
                    padding-bottom: 30px;
                    margin-top: 35px;
                  }
                  @include breakpoint($md){
                    padding-bottom: 0;
                    margin-top: 0;
                  }
                  a {
                    margin-top: 0;
                    color: #FFFFFF;
                    font-size: 34px;
                    font-weight: 400;
                    line-height: 1.1;
                    background: $dblue;
                    padding: 13px 20px;
                    padding-right: 60px;
                    display: inline-block;
                    border-radius: 10px;
                    position: relative;
                    &:after{
                      position: absolute;
                      content: '\f105';
                      font-family: "FontAwesome";
                      text-shadow: none;
                      line-height: 50px;
                      font-size: 35px;
                      opacity: 1;
                      color: $orange;
                      top: 7px;
                      right: 32px;
                      animation: slide 1s ease-in-out infinite;
                    }
                    @include breakpoint($md){
                      font-size: 18px;
                      font-weight: 900;
                      padding: 7px 27px;
                      line-height: 1;
                      &:after{
                        top: -9px;
                        font-size: 20px;
                        right: 14px;
                      }
                    }
                    @include breakpoint($xs){
                      padding: 9px 27px;
                      font-size: 14px;
                      &:after{
                        top: -8px;
                        font-size: 18px;
                        right: 14px;
                      }
                    }
                  }
                }
                .views-field {
                  &.views-field-product-image {
                    display: inline-block;
                    width: 100%;
                    padding: 15px;
                    .field-content {
                      display: inline-block;
                      width: 100%;
                      padding: 0;
                      position: relative;
                      //&:before,
                      //&:after {
                      //  content: '';
                      //  position: absolute;
                      //  top: 0;
                      //  bottom: 0;
                      //  left: 0;
                      //  right: 0;
                      //}
                      //&:before {
                      //  background: transparent; /* Old browsers */
                      //  background: -moz-linear-gradient(top, white 0%, transparent 5%, transparent 95%, white 100%); /* FF3.6-15 */
                      //  background: -webkit-linear-gradient(top, white 0%, transparent 5%, transparent 95%, white 100%); /* Chrome10-25,Safari5.1-6 */
                      //  background: linear-gradient(to bottom, white 0%, transparent 5%, transparent 95%, white 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                      //}
                      //&:after {
                      //  background: transparent; /* Old browsers */
                      //  background: -moz-linear-gradient(left, white 0%, transparent 5%, transparent 95%, white 100%); /* FF3.6-15 */
                      //  background: -webkit-linear-gradient(left, white 0%, transparent 5%, transparent 95%, white 100%); /* Chrome10-25,Safari5.1-6 */
                      //  background: linear-gradient(to right, white 0%, transparent 5%, transparent 95%, white 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                      //}
                      @include breakpoint($sm){
                        &:before {
                         background: none;
                        }
                        &:after {
                          background: none;
                        }
                      }
                      img {
                        @include breakpoint($md){
                          width: 88%;
                          height: auto;
                          margin: 0 auto;
                          vertical-align: bottom;
                        }
                        @include breakpoint($xs){
                          width: 100%;
                          max-width: 320px;
                          display: block;
                        }
                      }
                    }
                  }
                }
              }
            }
            ul.flex-direction-nav {
              height: 0;
              li {
                @include breakpoint($xs){
                  height: 25px;
                  width: 15px;
                }
                height: 50px;
                width: 50px;
                display: inline-block;
                &.flex-nav-next {
                  float: right;
                }
                a {
                  @include breakpoint($xs){
                    height: 25px;
                    width: 15px;
                    margin: 0;
                  }
                  bottom: auto;
                  left: inherit;
                  right: inherit;
                  height: 50px;
                  width: 50px;
                  margin: 0;
                  top: 50%;
                  transform: translateY(-50%);
                  text-align: center;
                  color: transparent;
                  display: block;
                  opacity: 1;
                  @include borderradius(50%);
                  &:before {
                    @include breakpoint($xs){
                      line-height: 25px;
                      font-size: 28px;
                    }
                    font-family: "FontAwesome";
                    text-shadow: none;
                    line-height: 50px;
                    font-size: 55px;
                    opacity: 1;
                    color: $purple;
                  }
                  &.flex-prev {

                    &:focus {
                      outline: none;
                    }

                    &:before {
                      content: '\f104';
                      bottom: 0;
                      left: 0;
                    }
                  }
                  &.flex-next {

                    &:focus {
                      outline: none;
                    }

                    &:before {
                      content: '\f105';
                      bottom: 0;
                      right: 0;
                    }
                  }
                }
              }
            }
          }
        }
        .view-footer{
          @include breakpoint($sm){
            display: none;
          }
          .view-home-page-fleet-carousel{
            .view-content{
              #carousel{
                padding-left: 20px;
                padding-right: 20px;
                @include breakpoint($md){
                  margin-bottom: 30px;
                }
                border: none;
                .flex-viewport{
                  @include breakpoint($md){
                    padding: 30px 0;
                  }
                  width: 100%;
                  ul.slides{
                    padding: 0;
                    li{
                      text-align: center;
                      cursor: pointer;
                      position: relative;
                      //padding-top: 50px;
                      margin-right: 40px !important; /* λόγω του flexslider - για να παίξουμε με breakpoints */
                      //&:last-child{
                      //  margin-right: 0 !important; /* λόγω του flexslider - για να παίξουμε με breakpoints */
                      //}
                      .views-field-product-image{
                        .field-content{
                          position: relative;
                          display: inline-block;
                          img{
                            width: 121px;
                            vertical-align: bottom;
                            max-width: 100%;
                            height: auto;
                          }
                          &:after{
                            content:'';
                            display: block;
                            width: 125px;
                            height: 125px;
                            position: absolute;
                            top: 50%;
                            border: 2px solid transparent;
                            left: 50%;
                            transition: width 0.2s ease, height 0.2s ease, border-color 0.2s ease;
                            @include borderradius(50%);
                            @include translate(-50%,-50%);
                          }
                        }
                      }
                      .views-field-field-car-type{
                        @include breakpoint($md){
                          margin-top: 15px;
                        }
                        display: inline-block;
                        width: 100%;
                        margin-top: 30px;
                        a{
                          text-decoration: none;
                          color: $black;
                          font-size: 16px;
                          font-weight: 400;
                          line-height: 1.2;
                        }
                      }
                      &:hover{
                        .views-field-product-image {
                          .field-content {
                            &:after{
                              width: 100px;
                              height: 100px;
                              border: 2px solid $purple;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .left {
      @include breakpoint($lg){
        @include span(12);
        padding: 0;
      }
      @include breakpoint($xs){
        display: inline;
        width: unset;
        float: unset;
      }
      @include span(2.3);
      padding-right: 75px;
      .box {
        @include breakpoint($lg){
          text-align: left;
        }
        text-align: right;

        &:nth-of-type(1) {
          h4:before {
          //  background-image: url('/sites/all/themes/omgsub/images/features_passengers.svg');
            @extend .svg-persons_icon;
            @extend .svg-persons_icon-dims;
            right: -66px;
            @include breakpoint($md){
              left: -34px;
              top: -10px;
            }
            @include breakpoint($sm){
              left: 11px;
              top: -55px;
            }
            @include breakpoint($xs){
              left: -8px;
              top: -16px;
            }
          }
        }
        &:nth-of-type(2) {
          h4:before {
            @extend .svg-doors_icon;
            @extend .svg-doors_icon-dims;
            right: -81px;
            @include breakpoint($md){
              top: -13px;
            }
            @include breakpoint($sm){
              top: -58px;
            }
            @include breakpoint($xs){
              left: -23px;
              top: -22px;
            }
          }
        }
        &:nth-of-type(3) {
          h4:before {
            @extend .svg-gear_icon;
            @extend .svg-gear_icon-dims;
            right: -78px;
            @include breakpoint($sm){
              left: 20px;
              top: -41px;
            }
            @include breakpoint($xs){
              left: -19px;
              top: -5px;
            }
          }
        }
        &:nth-of-type(4) {
          h4:before {
            @extend .svg-baggage_space_icon;
            @extend .svg-baggage_space_icon-dims;
            right: -79px;
            @include breakpoint($sm){
              left: 20px;
              top: -43px;
            }
            @include breakpoint($xs){
              left: -20px;
              top: -9px;
            }
          }
        }
      }
      h4:before {
        @include breakpoint($lg){
          left: -65px;
        }
        @include breakpoint($md){
          left: -50px;
          transform: scale(0.6);
        }
        @include breakpoint($sm){
          left: -7px;
          top: -58px;
        }
        //@include breakpoint($smaller){
        //  left: 30%;
        //  top: 0;
        //}
        @include breakpoint($xs){
          left: 0;
          right: unset;
          top: 0;
          bottom: unset;
          transform: scale(0.4);
        }
        right: -70px;
      }
      p {
        @include breakpoint($lg){
          text-align: left;
        }
        text-align: right;
      }
    }
    .right {
      @include breakpoint($lg){
        @include span(12);
        padding: 0;
      }
      @include breakpoint($xs){
        display: inline;
        width: unset;
        float: unset;
      }
      @include span(2.3 last);
      padding-left: 75px;
      .box{
        &:nth-of-type(1) {
          h4:before {
            @extend .svg-fuel_icon;
            @extend .svg-fuel_icon-dims;
            left: -89px;
            @include breakpoint($lg){
              left: -79px;
            }
            @include breakpoint($md){
              //left: -60px;
              left: -50px;
            }
            @include breakpoint($sm){
              left: -21px;
              top: -46px;
            }
            @include breakpoint($xs){
              left: -29px;
              top: -13px;
            }
          }
        }
        &:nth-of-type(2) {
          h4:before {
            @extend .svg-air_condition_icon;
            @extend .svg-air_condition_icon-dims;
            @include breakpoint($sm){
              left: 20px;
              top: -46px;
            }
            @include breakpoint($xs){
              left: -21px;
              top: -14px;
            }
          }
        }
        &:nth-of-type(3) {
          h4:before {
            @extend .svg-kids_seat_icon;
            @extend .svg-kids_seat_icon-dims;
            @include breakpoint($sm){
              left: 7px;
              top: -46px;
            }
            @include breakpoint($xs){
              left: -18px;
              top: -16px;
            }
          }
        }
        &:nth-of-type(4) {
          h4:before {
            @extend .svg-insurance_icon;
            @extend .svg-insurance_icon-dims;
            @include breakpoint($sm){
              left: 7px;
              top: -48px;
            }
            @include breakpoint($xs){
              left: -25px;
              top: -16px;
            }
          }
        }
      }
      h4:before {
        @include breakpoint($lg){
          left: -65px;
        }
        @include breakpoint($md){
          left: -50px;
          transform: scale(0.6);
        }
        @include breakpoint($md){
          left: -51px;
          top: -12px;
        }
        @include breakpoint($smaller){
          left: 30%;
          top: 0;
        }
        @include breakpoint($xs){
          left: 0;
          right: unset;
          top: 0;
          bottom: unset;
          transform: scale(0.4);
        }
        left: -75px;
      }
    }
  }
}

#home_newsletter{
  @include  clearfix;

  @include breakpoint($lg){
    padding: 32px 10px;
  }
  @include breakpoint($sm){
    padding: 20px 10px;
  }
  @include breakpoint($xs){
    padding: 20px;
  }
  background: $dblue;
  padding-top: 22px;
  padding-bottom: 18px;
  div.block__content{
    position: relative;

    @include gim_container(1344px);
    @include breakpoint($xs){
      padding: 0 7px;
    }
    display: block;
    width: 100%;
    .left{
      @include breakpoint($lg){
        @include span(12);
        display: block;
        text-align: center;
        padding-right: 0;
        max-width: unset;
      }
     // width: calc( 100% - 360px );
      max-width: 885px;
      float: left;
      padding-right: 10px;
      h3{

        @include breakpoint($xs){
          font-size: 28px;
          letter-spacing: unset;
          line-height: 35px;
        }
        color: $orange;
        font-size: 50px;
        font-weight: 700;
        line-height: 1.2;
        text-transform: uppercase;
        margin: 0;
        padding-left: 37px;
        letter-spacing: -1.9px;
        @include breakpoint(1440px){
          font-size: 46px;
        }
        @include breakpoint($lg){
          font-size: 36px;
          letter-spacing: -0.5px;
        }
        @include breakpoint($smaller){
          font-size: 30px;
          line-height: 30px;
        }
        @include breakpoint($xs){
          font-size: 24px;
        }
      }
      p{
        @include breakpoint($lg){
          margin-top: 12px;
          font-size: 24px;
          line-height: calc(1em + 4px);
          margin-bottom: 18px;
          @include breakpoint($sm){
            font-size: 20px;
            margin-bottom: 0;
          }
          @include breakpoint($smaller){
            font-size: 17px;
          }
        }
        @include breakpoint($xs){
          font-size: 18px;
        }
        margin-bottom: 0;
        margin-top: 6px;
        font-size: 28px;
        font-weight: 400;
        line-height: 1.2;
        color: white;
        padding-left: 41px;
      }
    }
    .right{
      margin-top: 0;
      position: relative;

      @include breakpoint($lg){
        @include span(12);
        display: block;
        text-align: center;
        padding-left: 0;
      }

      width: 373px;
      float: right;
      padding-left: 10px;
      #mlb2-1339442.ml-form-embedContainer.ml-subscribe-form{
        .ml-form-embedWrapper{
          background-color: transparent;
          .ml-form-embedBody{
            padding: 0;
            form.ml-block-form{
              .ml-form-fieldRow{
                @include breakpoint($lg){
                  //display: inline-block;
                }
                >div{
                  display: inline;
                  >div{
                    display: inline;
                  }
                }
                input[type=email]{
                  @include breakpoint($lg){
                    width: 285px;
                    font-size: 24px;
                  }
                  @include breakpoint($xs){
                    width: 173px;
                    padding: 9px 8px;
                    line-height: 21px;
                    font-size: 16px;
                    border-radius: 4px 0 0 4px;
                  }
                  @include placeholder($blue);
                  -moz-appearance: none;
                  -webkit-appearance: none;
                  background-color: $orange!important;
                  border-top-left-radius: 10px!important;
                  border-bottom-left-radius: 10px!important;
                  border: none;
                  border-bottom-right-radius: 0!important;
                  border-top-right-radius: 0!important;
                  height: 62px;
                  color: $purple!important;
                  font-size: 28px!important;
                  font-weight: 400;
                  line-height: 38px!important;
                  padding: 12px 15px!important;
                  padding-left: 35px!important;
                  vertical-align: bottom;
                  display: inline-block;
                  float: left;
                  width: calc( 100% - 68px )!important;
                  @include breakpoint($sm){
                    height: 44px;
                    font-size: 18px!important;
                  }
                  ::-webkit-input-placeholder {
                    font-weight: 700;
                  }
                  ::-moz-placeholder {
                  font-weight: 700;
                  }
                  :-ms-input-placeholder {
                  font-weight: 700;
                  }
                  :-moz-placeholder {
                  font-weight: 700;
                  }
                }
              }

              input[type=submit]{
                @include breakpoint($lg){
                  //width: 53px;
                }
                @include breakpoint($xs){
                  width: 55px;
                  padding: 9px;
                  line-height: 21px;
                  background-size: auto 19px;
                }
                -moz-appearance: none;
                -webkit-appearance: none;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                display: inline-block;
                float: left;
                border: none;
                padding: 12px 0;
                line-height: 38px;
                cursor: pointer;
                color: transparent;
                width: 65px;
                background: $orange;
                position: relative;
                left: -6px;
                z-index: 0;
              }
            }
            .primary{
              @include breakpoint($xs){
                //height: 39px;
              }

              background: $orange!important;
              display: inline-block;
              float: left;
              width: 64px!important;
              height: 62px;
              position: relative;
              z-index: 1;
              border-radius: 10px !important;
              border-bottom-left-radius: 0!important;
              border-top-left-radius: 0!important;
              left: -1px;
              font-size: 0!important;
              @include breakpoint($sm){
                height: 44px;
              }
              &:before{
                @include breakpoint($lg){
                  width: 1px;
                  top: 2px;
                  bottom: 2px;
                }
                content: '';
                background: $dblue;
                position: absolute;
                top: 6px;
                left: -1px;
                bottom: 6px;
                width: 2px;
              }

              &:after {
                content: '\f105';
                display: block;
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
                //   @extend .svg-arrow_right_icon;
                font-family: "FontAwesome";
                text-shadow: none;
                font-size: 52px;
                color: #0a4396;
                @include breakpoint($sm){
                  font-size: 35px;
                }
                //   @extend .svg-arrow_right_icon-dims;
              }
            }
          }
        }
      }
    }
  }
}

#home_testimonials{
  padding: 141px 0 91px 0;
@include breakpoint(1300px){
  padding: 90px 0 35px 0;
}
  .view-testimonials {
    padding: 0 121px;
    @include breakpoint($md){
      padding: 0 60px;
    }
    @include breakpoint($smaller){
      padding: 0 30px;
    }
    @include breakpoint($xs){
      padding: 0 15px;
    }
    position: relative;

    .flex-direction-nav {
      a {

        &:before {
          color: $block-title;
        }

        transform: translateY(-50%);
      }

      .flex-prev {
        left: -50px;
      }

      .flex-next {
        right: -50px;
      }
      @include breakpoint($smaller){
        .flex-prev {
          left: -33px;
        }

        .flex-next {
          right: -33px;
        }
      }
      @include breakpoint($smaller){
        .flex-prev {
          display: none;
        }

        .flex-next {
          display: none;
        }
      }
    }

    &:after {
      content: '';
      display: block;
      width: 74px;
      height: 63px;
      bottom: -46px;
      right: 17px;
      position: absolute;
      background-size: cover;
      background: url('../images/sprites/svg/optimized/quote1_icon.svg');
      transform: rotate(180deg);
      @include breakpoint(1300px){
        transform: rotate(180deg)scale(0.6);
        bottom: 50px;
        right: -3px;
      }
      @include breakpoint($md){
        bottom: 63px;
        right: -16px;
      }
      @include breakpoint($sm){
        transform: rotate(180deg)scale(0.4);
        bottom: -23px;
      }
      @include breakpoint($smaller){
        transform: rotate(180deg) scale(0.3);
        right: -24px;
      }
      @include breakpoint($xs){
        transform: rotate(180deg) scale(0.25);
        right: -31px;
      }
    }

    &:before {
      content: '';
      background: url('../images/sprites/svg/optimized/quote1_icon.svg');
      width: 74px;
      height: 63px;
      background-size: cover;
      display: block;
      position: absolute;
      top: -8px;
      left: 2px;
      @include breakpoint(1300px){
        transform: scale(0.6);
      }
      @include breakpoint($md){
        top: -40px;
        left: -38px;
      }
      @include breakpoint($sm){
        left: -7px;
        transform: scale(0.4);
      }
      @include breakpoint($smaller){
        transform: scale(0.3);
        left: -28px;
      }
      @include breakpoint($xs){
        transform: scale(0.25);
        left: -35px;
      }
    }
  }

  .view-footer {

    // for logged in user
    .contextual-links-region.block--block-39 {
      position: static;
    }

    div.block__content {
      position: absolute;
      right: 142px;
      bottom: 42px;
      background: $orange;
      color: $block-title;
      font-weight: 700;
      padding: 7px 9px 5px;
      font-size: 18px;
      max-width: 116px;
      border-radius: 8px;
      text-align: center;
      min-height: 0;
      @include breakpoint(1300px $md +1){
        bottom: 0px;
        position: relative;
        float: right;
      }
      @include breakpoint($md){
        bottom: -0px;
       position: relative;
        right: unset;
      }

      p {
        margin-bottom: 0;
      }
    }
  }

  @include breakpoint($md){
    padding-top: 60px;
    padding-bottom: 41px;
  }
  @include breakpoint($sm){
    padding-left: 30px;
    padding-right: 30px;
  }
  @include breakpoint($xs){
    padding: 24px 20px;
    padding-top: 60px;
  }
  display: block;
  width: 100%;
  background: url('/sites/all/themes/omgsub/images/testimonials.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  h2.block__title{
    @include breakpoint($md){
      font-size: 32px;
      line-height: 1;
      margin-bottom: 60px;
    }
    @include breakpoint($xs){
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 45px;
    }
    font-size: 40px;
    font-weight: 100;
    line-height: 26.98px;
    margin-top: 0;
    margin-bottom: 80px;
    color: white;
    text-align: center;
  }

  h2.block__title {
    @include gim_container(910px);
    letter-spacing: 1.9px;
    margin-left: 35px;
    margin-bottom: 0;
    color: $block-title;
    position: relative;
    font-size: 42px;
    margin-bottom: -100px;
    z-index: 10;
    @include underlines($orange);
    @include breakpoint(1300px){
      font-size: 34px;
      max-width: 750px;
    }
    @include breakpoint($md){
      margin: auto;
      margin-bottom: -77px;
    }
    @include breakpoint($sm){
      margin-left: 0px;
      width: 100%;
    }
    @include breakpoint($xs){
      font-size: 24px;
    }

    &:after {
      margin: 27px auto 0 auto;
      @include breakpoint(1300px){
        margin-top: 15px;
      }
      @include breakpoint($md){
        margin-top: 10px;
      }
    }
  }

  div.block__content{
    @include gim_container(910px);
    margin-left: 32px;
    min-height: 440px;
    @include breakpoint(1300px){
      max-width: 750px;
      min-height: 390px;
    }
    @include breakpoint($md){
      @include gim_container(700px);
      padding: 100px 30px 25px 57px;
      min-height: 330px;
    }
    @include breakpoint($sm){
      //@include gim_container(440px);
    }
    @include breakpoint($xs){
      //padding: 12px 30px;
    }

    @include borderradius(20px);
    background: rgba(255, 255, 255, 0.8);
    padding: 124px 34px 34px 34px;
    position: relative;
    .view-testimonials{
      @include breakpoint($md){
        background-size: 109px auto;
        background-position: top left;
      }
      @include breakpoint($xs){
        background-position: center top;
        background-size: 61px auto;
      }
      .view-header{
        @include breakpoint($md){
          font-size: 22px;
        }
        @include breakpoint($xs){
          font-size: 18px;
        }
      //  position: absolute;
      //  top: 0;
       // display: inline-block;
        display: block;
      //  left: 50%;
      //  transform: translate(-50%, -175%);
        font-size: 26px;
        color: white;
        font-weight: 400;
        text-align: center;
      }
      .view-content{
        .flexslider{
          @include breakpoint($md){
            //padding-left: 131px;
          }
          @include breakpoint($xs){
            padding-left: 0;
            //padding-top: 60px;
          }
          border: none;
          background: none;
          margin-bottom: 0;
          @include borderradius(0);
          ul.slides{
            li{
              .body{
                @include breakpoint($md){
                  font-size: 13px;
                  line-height: 21px;
                  font-weight: normal;
                }
                @include breakpoint($xs){
                  font-size: 12px;
                  line-height: 1.5;
                }
                color: $black;
                font-size: 18px;
                line-height: 1.8;
                font-weight: 400;
                @include breakpoint(1300px){
                 font-size: 16px;
                }
                *{
                  margin: 0;
                }
              }
              .rest{
                &:before {
                  margin-bottom: 8px;
                  display: block;
                  background: linear-gradient(to right, $block-title 0, $block-title 30%, transparent 30%, transparent 33%, $block-title 33%, $block-title 63%, transparent 63%, transparent 66%, $block-title 66%, $block-title 100%);
                  height: 3px;
                  width: 70px;
                  content: '';
                }

                @include breakpoint($xs){
                  margin-top: 14px;
                }
                display: block;
              //  width: calc(100% - 150px);
                margin-top: 22px;
                font-size: 24px;
                color: $block-title;
                font-weight: 700;
                max-width: 460px;
                @include breakpoint(1300px){
                  font-size: 20px;
                  padding-right: 50px;
                }
                @include breakpoint($sm){
                  font-size: 17px;
                }
                @include breakpoint($xs){
                  font-size: 14px;
                }

                strong{
                  @include breakpoint($md){
                    font-size: 12px;
                  }
                  color: $block-title;
                  font-size: 24px;
                  font-weight: 700;
                  line-height: 1.2;
                  width: 100%;
                  position: relative;
                  &:after{
                    content: '';
                    position: absolute;
                    height: 1px;
                    background: $purple;
                    bottom: -8px;
                    left: 2px;
                    width: 68px;
                  }
                }
                p{
                  @include breakpoint($md){
                    font-size: 12px;
                    margin-top: 2px;
                  }
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 27px;
                  color: $purple;
                  margin-bottom: 0;
                  margin-top: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
  ul.flex-direction-nav{
    li {
      opacity: 1;
      &.flex-nav-next{
      }
      a {
        @include breakpoint($md){
          height: 30px;
        }
        @include breakpoint($sm){
          height: 24px;
          opacity: 1;
        }
        opacity: 0;
        height: 60px;
        display: block;
        transition: opacity 0.5s ease;
        &:before{
          @include breakpoint($md){
            line-height: 30px;
            font-size: 48px;
          }
          @include breakpoint($sm){
            font-size: 32px;
            line-height: 24px;
            color: $purple;
          }
          font-family: "FontAwesome";
          text-shadow: none;
          color: white;
          line-height: 60px;
          opacity: 1;
          bottom: 0;
          display: block;
          font-size: 65px;
          transition: color 0.25s ease;
        }
        &.flex-prev{
          @include breakpoint($lg){
            left: -60px;
          }
          @include breakpoint($md){
            left: -97px;
          }
          @include breakpoint($sm){
            left: -3%;
          }
          @include breakpoint($xs){
            left: -27px;
          }
          left: -70px;
          &:before {
            content: '\f104';
            left: 0;
          }
        }
        &.flex-next{
          @include breakpoint($lg){
            right: -60px;
          }
          @include breakpoint($sm){
            right: -3%;
          }
          @include breakpoint($xs){
            right: -27px;
          }
          right: -70px;
          &:before {
            content: '\f105';
            right: 0;
          }
        }
        &:hover{
          &:before {
            color: $purple;
          }
        }
      }
    }
  }
  &:hover{
    .flex-direction-nav{
      a{
        opacity: 1;
      }
    }
  }
}

#home_specials{
  display: inline-block;
  width: 100%;
  div.block__content{
    @include breakpoint($md){
      font-size: 13px;
      line-height: 21px;
    }
    @include gim_container(940px);
    @include breakpoint($xs){
      font-size: 12px;
      line-height: 1.5;
    }
    color: #000006;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    img{
      max-width: 100%;
      height: auto !important;
    }
  }
}

#home-offers{
  @include breakpoint($lg){
    padding-top: 10px;
    margin-bottom: 0;
  }
  margin: 20px 0 10px;
  padding-top: 92px;
  h2.block__title{
    @include breakpoint($lg){
      font-size: 32px;
    }
    @include breakpoint($xs){
      font-size: 24px;
      margin-bottom: 15px;
    }
    color: #000006;
    font-size: 40px;
    font-weight: 400;
    line-height: 27px;
    margin-top: 0;
    margin-bottom: 23px;
    text-align: center;
  }
  .flexslider{
    @include breakpoint($lg){
      margin-bottom: 0;
    }
    border: none;
  }

  .front-offer-item{
    @include breakpoint($lg){
      &:first-child{
        @include span(6 (gutters: 0.2265));
      }
      &:nth-child(2){
        @include span(6 (gutters: 0.2265) last);
      }
      overflow: unset;
      height: 293px;
    }
    @include span(3 (gutters: 0.053));
    height: 355px;
    position: relative;
    overflow: hidden;
    color: white;
    &:last-child{
      @include breakpoint($lg){
        @include span(12);
        &:not(:first-child){
          margin-top: 101px;
        }
        margin-bottom: 62px;
      }
      @include breakpoint($xs){
        margin-bottom: 98px;
      }
      @include span(6 (gutters: 0.053) last);
      .front-offer-button {
        @include breakpoint($lg) {
          bottom: -61px;
          height: 56px;
          padding: 11px 12px;
        }
        @include breakpoint($xs){
          bottom: -97px;
          height: 95px;
          padding: 32px 20px;
          width: 115px;
        }
      }
      .front-offer-description{
        @include breakpoint($lg){
          padding: 0 36px 0 12px;
          background-color: $purple;
          bottom: -61px;
          line-height: 56px;
          height: 56px;
          border-bottom: 16px solid $purple;
        }
        @include breakpoint($xs){
          left: 115px;
          bottom: -97px;
          height: 95px;
          padding: 0 20px 0 10px;
          border-top: 14px solid $purple;
          border-bottom-width: 14px;
          line-height: 16px;
        }
      }
    }
    &:before{
      @include breakpoint($lg){
        content: unset;
      }
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: $purple;
      transform: translate(0, 100%);
      transition: transform 0.5s ease;
      z-index: 5;
    }
    &:after{
      content: '';
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
      background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 70%, rgba(0,0,0,0.8) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 70%, rgba(0,0,0,0.8) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 70%, rgba(0,0,0,0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 4;
    }
    .front-offer-price{
      @include breakpoint($lg){
        padding: 7px 8px 6px;
        height: 104px;
        margin-top: 30px;
        margin-left: 40px;
      }
      @include breakpoint($xs){
        margin-top: 20px;
        margin-left: 20px;
        height: 92px;
      }
      padding: 12px;
      @include borderradius(8px);
      width: auto;
      height: 123px;
      display: inline-block;
      background: $purple;
      margin-top: 12px;
      margin-left: 12px;
      z-index: 8;
      p {
        @include breakpoint($lg){
          font-size: 20px;
          line-height: 1;
        }
        @include breakpoint($xs){
          font-size: 16px;
          line-height: 1;
        }
        color: white;
        font-size: 24px;
        font-weight: 400;
        line-height: 27px;
        strong {
          @include breakpoint($lg){
            font-size: 48px;
            line-height: 1;
            margin: 0;
          }
          @include breakpoint($xs){
            font-size: 40px;
          }
          color: $orange;
          font-size: 60px;
          line-height: 27px;
          font-weight: 900;
          display: block;
          clear: both;
          margin: 10px auto;
        }
      }
    }
    .front-offer-title{
      @include breakpoint($lg){
        font-size: 16px;
        bottom: 10px;
        left: 40px;
        padding-right: 60px;
        font-weight: bold;
        line-height: 30px;
        height: 30px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        right: 0;
      }
      @include breakpoint($xs){
        font-size: 14px;
        left: 20px;
        padding-right: 45px;
      }
      text-overflow: ellipsis;
      padding-right: 60px;
      white-space: nowrap;
      overflow: hidden;
      right: 0;
      font-size: 20px;
      font-weight: 400;
      line-height: 27px;
      text-transform: uppercase;
      margin: 0;
      position: absolute;
      bottom: 26px;
      left: 30px;
      visibility: visible;
      opacity: 1;
      z-index: 8;
      @include transition(all 0.1s linear);
      //&:after{
      //  @include breakpoint($md){
      //    font-size: 40px;
      //    line-height: 30px;
      //  }
      //  content: '\f105';
      //  font-family: 'FontAwesome';
      //  font-size: 40px;
      //  line-height: 30px;
      //  vertical-align: bottom;
      //  color: $purple;
      //  margin-left: 10px;
      //}
    }
    .front-offer-button {
      @include breakpoint($lg){
        position: absolute;
        bottom: -95px;
        left: 0;
        right: calc(100% - 157px);
        height: 89px;
        background-color: $purple;
        text-align: right;
        padding: 17px 12px;
      }
      display: block;
      z-index: 8;
      a{
        @include breakpoint($lg){
          visibility: unset;
          opacity: unset;
          font-size: 16px;
          line-height: 1;
          padding: 9px 6px;
          font-weight: 900;
          position: unset;
          margin: 0;
        }
        position: absolute;
        visibility: hidden;
        opacity: 0;
        display: inline-block;
        font-size: 20px;
        font-weight: 700;
        text-transform: uppercase;
        color: white;
        background: $orange;
        line-height: 35px;
        background: $orange;
        padding-left: 10px;
        padding-right: 10px;
        text-decoration: none;
        margin-top: 10px;
        margin-left: 12px;
        @include borderradius(8px);
        transition: background-color 0.25s ease, opacity 0.5s ease;
        &:hover{
          background-color: darken(desaturate($orange, 5%), 10%);
        }
      }
    }
    .front-offer-image{
    }
    .front-offer-description{
      @include breakpoint(1500px){
        max-width: 163px;
      }
      @include breakpoint($container){
        max-width: 143px;

      }
      @include breakpoint($lg){
        > *{
          margin-bottom: 1px;
        }
        visibility: unset;
        opacity: unset;
        float: unset;
        max-width: unset;
        margin: 0;
        font-size: 14px;
        line-height: 1.25;
        padding: 15px 36px 0 12px;
        background-color: $purple;
        bottom: -95px;
        right: 0;
        left: 157px;
        height: 89px;
        overflow: hidden;
        box-sizing: border-box;
        border-bottom: 19px solid $purple;
      }
      @include breakpoint($xs){
        > *{
          margin-bottom: 1px;
        }
        overflow-y: auto;
      }
      position: absolute;
      visibility: hidden;
      opacity: 0;
      float: right;
      max-width: 200px;
      margin-right: 10px;
      margin-top: 10px;
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      color: white;
      z-index: 8;
    }
    .front-offer-info{
      @include breakpoint($lg){
        width: 30px;
        height: 30px;
        right: 11px;
        bottom: 10px;
      }
      position: absolute;
      right: 16px;
      bottom: 22px;
      z-index: 8;
      display: inline-block;
      font-size: 0;
      color: transparent;
      width: 37px;
      height: 37px;
      border-radius: 37px;
      vertical-align: bottom;
      padding: 0;
      margin: 0;
      box-sizing: content-box;
      &:after{
        @include breakpoint($lg){
          font-size: 28px;
          border-radius: 24px;
          top: 3px;
          right: 3px;
          bottom: 3px;
          left: 3px;
          line-height: 24px;
        }
        content: '\f05a';
        font-family: FontAwesome;
        font-size: 32px;
        position: absolute;
        color: $purple;
        top: 5px;
        right: 5px;
        bottom: 5px;
        left: 5px;
        display: block;
        border-radius: 27px;
        text-align: center;
        line-height: 27px;
        padding: 0;
        margin: 0;
        transition: color 0.5s linear;
      }
      &:before{
        @include breakpoint($lg){
          border-radius: 30px;
          border-width: 4px;
        }
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        border-radius: 37px;
        border: 6px solid $purple;
        transition: border-color .5s linear;
      }
    }
    &:hover{
      &:before{
        transform: unset;
      }
      .front-offer-info{
        &:after{
          color: $orange;
        }
        &:before{
          border-color: $orange;
        }
      }
      .front-offer-title{
        @include breakpoint($lg){
          visibility: unset;
          opacity: unset;
        }
        visibility: hidden;
        opacity: 0;
      }
      .front-offer-description{
        @include breakpoint($lg){
          position: absolute;
        }
        position: relative;
        visibility: visible;
        opacity: 1;
        @include transition(all 0.5s linear);
      }
      .front-offer-button {
        display: block;
        a{
          position: relative;
          visibility: visible;
          opacity: 1;
        }
      }
    }
    > * {
      position: relative;
    }
  }

  .flex-control-nav{
    @include breakpoint($lg){
      display: none;
    }
    margin-top: 10px;
    li{
      margin: 0 6px 0 0;
      &:last-child{
        margin: 0;
      }
    }
    a{
      width: 15px;
      height: 15px;
      border: 1px solid $purple;
      background-color: transparent;
      box-shadow: unset;
      &.flex-active{
        background-color: $purple;
      }
    }
  }
}

///*width*/
//::-webkit-scrollbar {
//  width: 17px;
//}
//
///*Track*/
//::-webkit-scrollbar-track {
//  background: #f1f1f1;
//}
//
///*Handle*/
//::-webkit-scrollbar-thumb {
//  background: #888;
//}
//
///*Handle on hover*/
//::-webkit-scrollbar-thumb:hover {
//  background: #555;
//}