body.page-admin-commerce{
  .view-commerce-line-item-table{
    width: 100%;
    margin-bottom: 30px;
    .view-content{
      .item-list{
        .views-row{
          list-style-type: none;
          .views-field{
            margin-bottom: 4px;
            width: 100%;
            display: inline-block;
            &.megatitle{
              font-weight:900;
              font-size: 25px;
            }
            .views-label{
              width: 150px;
              display: inline-block;
              float: left;
              font-weight: 700;
            }
            .field-content{
              width: calc(100% - 150px);
              display: inline-block;
              float: right;
            }
            &.views-field-php{
              > .field-content{
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}