/* mobile menu */
button.hamburger.gim_mmenu{
  float: right;
  display: inline-block;
  -webkit-appearance: none;
  border: none;
  background: none;
  background: $purple;
  width: 42px;
  height: 30px;
  cursor: pointer;
  position: relative;
  @include borderradius(5px);
  span.hamburger-box{
    position: absolute;
    display: inline-block;
    width: 100%;
    max-width: 34px;
    height: 2px;
    background: #FFFFFF;
    top: 14px;
    left:4px;
    @include transition(all 0.5s linear);
    &:before,
    &:after{
      content:'';
      position: absolute;
      display: inline-block;
      width: 100%;
      max-width: 34px;
      height: 2px;
      background: #FFFFFF;
      left: 0;
      @include transition(all 0.5s linear);
    }
    &:before{
      top: -8px;
    }
    &:after{
      top: 8px;
    }
  }
  &.is-active{
    @include transition(all 0.5s linear);
    span.hamburger-box{
      @include rotate(-390deg);
      left: 4px;
      top: 14px;
      &:before{
        visibility: hidden;
        opacity: 0;
        @include transition(all 0.5s linear);
      }
      &:after{
        left: 0;
        top: 0;
        @include transition(all 0.5s linear);
        @include rotate(420deg);
      }
    }
  }
  @include breakpoint(min-width $sm + 1px){
    display: none;
  }
  @include breakpoint($sm){
    margin-right: 10px;
    margin-top: 21px;
  }
}
#menu{
  z-index: 99;
  background: $purple;
  color: rgb(255, 255, 255);
  .mm-panels{
    bottom: 40px;
    #mm-1{
      padding-top: 110px;
      .mm-navbar{
        height: 110px;
        padding: 0;
        .mm-navbar__title{
          color: transparent;
          display: inline-block;
          width: 100%;
          padding: 0;
          vertical-align: top;
          img {
            display: block;
            width: 100%;
            max-width: 200px;
            float: none;
            margin: 0 auto;
            vertical-align: top;
          }
        }
      }
      .mm-listview{
        .mm-listitem{
          &:after{
            background: #FFFFFF;
          }
        }
      }
    }
  }
  .mm-navbar-social{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    height: 40px;
    border-top: 1px solid #dadada;
    a{
      @include span(3 (gutters:0));
      text-align: center;
      font-size: 20px;
      line-height: 40px;
      color: #FFFFFF;
    }
  }
}

/* desktop menu */
#block-system-main-menu{
  float: left;
@include breakpoint(1620px){
  float: right;
}
  > .menu{
    padding: 0;
    margin-top: 37px;

    > li{
      display: inline-block;
      @include breakpoint(min-width 1385px){
        padding-left: 18px;
        padding-right: 10px;
        &:first-child{
          padding-right: 0;
          padding-left: 0;
        }
        &:last-child{
          padding-right: 0;
        }
        //&:nth-child(2){
        //  padding-left: 18px;
        //}
        //&:nth-child(3){
        //  padding-left: 30px;
        //}
        //&:nth-child(4){
        //  padding-left: 49px;
        //
        //}
        //&:nth-child(5){
        //  padding-left: 50px;
        //}
        //&:nth-child(6){
        //  padding-left: 28px;
        //}
      }

      > a,
      > span{
        cursor: pointer;
        color: $white;
        font-size: 18px;
        font-weight: 400;
        line-height: 2.25;
        padding-left: 11px;
        padding-right: 11px;
        transition: color 0.25s ease;
        @include breakpoint(1250px){
          font-size: 17px;
        }
        @include breakpoint($lg $md + 1){
          font-size: 16px;
        }
      }
      > a, .nolink{
        &:hover{
          color: $orange;
        }
      }
      &.active,
      &.active-trail{
        > a,
        > span{
          color: $orange;
        }
      }
      &.first{
        > a{
          color: transparent;
          @extend .svg-home_icon-dims;
          display: inline-block;
          position: relative;
          font-size: 0;
          line-height: 0;
          vertical-align: middle;

/*          &:before{
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: block;
            background-repeat: no-repeat;
            opacity: 1;
            transition: opacity 0.25s ease;
          }*/
          &:after{
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            top: -2px;
            left: 0;
            display: block;
          //  background-image: url('/sites/all/themes/omgsub/images/home_icon_active.svg');
          //  background-position: center;
            background-repeat: no-repeat;
            @extend .svg-home_icon;
            transition: opacity 0.25s ease;
          }
          &.active,
          &:hover{
            &:after{
              opacity: 1;
            }
          }
        }
      }
      &.last{
        a{
          padding-right: 0;
        }
      }
      &.expanded{
        position: relative;
        > ul{
          visibility: hidden;
          opacity: 0;
          position: absolute;
        }
        &:hover{
          > ul{
            visibility: visible;
            opacity: 1;
            position: absolute;
            left: 0;
            top: calc(100% - 10px);
            width: 220px;
            padding: 25px 0 0;
            &:before{
              content: '';
              border-bottom: 10px solid white;
              border-right: 10px solid transparent;
              border-left: 10px solid transparent;
              border-top: none;
              top: 16px;
              left: 40px;
              position: absolute;
            }
            > li{
              background: #FFFFFF;
              display: inline-block;
              width: 100%;
              border-bottom: 1px solid #cdcdcd;
              border-left: 1px solid #cdcdcd;
              border-right: 1px solid #cdcdcd;
              &.first{
                border-top: 1px solid #cdcdcd;
              }
              a{
                color: #000;
                padding: 10px;
                display: block;
                font-size: 16px;

                &:hover{
                  color: $purple;
                }
              }
            }
          }
        }
      }
    }
  }
  @include breakpoint($lg $sm+1px){
    > .menu {
      float: none;
      margin-top: 0;
      margin-bottom: 10px;
      > li {
        > a,
        > span {
          padding-left: 7px;
          padding-right: 7px;
        }
      }
    }
  }
  @include breakpoint(1100px $md + 1){
    > .menu {

      > li {
        > a,
        > span {
          padding-left: 6px;
          padding-right: 6px;
        }
      }
    }
  }
  @include breakpoint($sm){
    display: none;
  }
}