@mixin clearfix {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin underlines($color) {
  &:after {
    content: '';
    display: block;
    background-image: linear-gradient(to right, $color 0, $color 41px, transparent 41px, transparent 45px, $color 45px, $color 86px, transparent 86px, transparent 90px, $color 90px, $color 131px);
    height: 6px;
    width: 131px;
  }
}

@mixin gim_container($max-width: $container, $padding: 15px){
  @include breakpoint($max-width + ($padding * 2)){
    padding-left: $padding;
    padding-right: $padding;
  }
  @include container;

  @if $max-width != $container{
    max-width: $max-width;
  }
}

@mixin gim_container2($max-width: $container, $margin: 15px){
  @include breakpoint($max-width + ($margin * 2)){
    margin-left: $margin;
    margin-right: $margin;
  }
  @include container;

  @if $max-width != $container{
    max-width: $max-width;
  }
}

@mixin placeholder($color: lighten(black, 30%), $opacity: 1, $font-weight: 300){
  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $color;
    opacity: $opacity;
    font-weight: $font-weight;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: $color;
    opacity: $opacity;
    font-weight: $font-weight;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: $color;
    opacity: $opacity;
    font-weight: $font-weight;
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: $color;
    opacity: $opacity;
    font-weight: $font-weight;
  }
}

/* Added by BrT */
@mixin borderradius($radius: 0px){
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin rotate($deg){
  -ms-transform: rotate($deg); /* IE 9 */
  -webkit-transform: rotate($deg); /* Chrome, Safari, Opera */
  transform: rotate($deg);
}

@mixin translate($x, $y){
  -webkit-transform: translate($x, $y);
  -moz-transform: translate($x, $y);
  -ms-transform: translate($x, $y);
  -o-transform: translate($x, $y);
  transform: translate($x, $y);
}

@mixin rotatetranslate($x, $y, $deg){
  -webkit-transform: translate($x, $y) rotate($deg);
  -moz-transform: translate($x, $y) rotate($deg);
  -ms-transform: translate($x, $y) rotate($deg);
  -o-transform: translate($x, $y) rotate($deg);
  transform: translate($x, $y) rotate($deg);
}

@mixin transition($prop: all 0.5s ease){
  -webkit-transition: $prop;
  -moz-transition: $prop;
  -o-transition: $prop;
  transition: $prop;
}