#block-block-40{
  .boxes{
    width: 100%;
    display: inline-block;
    margin-top: 19px;
    .box{
      @include span(1 of 3 (gutters:0.09));
      min-height: 76px;
      padding-top: 17px;
      border-top-left-radius: 35px;
      border-bottom-left-radius: 35px;
      border-right: 15px solid $purple;
      padding-left: 80px;
      &:not(.active){
        filter:grayscale(100%)
      }
      span{
        width: 100%;
        display: inline-block;
        text-align: left;
        position: relative;
      }
      &:last-child{
        @include span(1 of 3 (gutters:0.09) last);
      }
      &.box_1{
        background: url('/sites/all/themes/omgsub/images/Step1.png') no-repeat;
        background-color: #f1f1f1;
        span{
          //left: 21px;
        }
      }
      &.box_2{
        background: url('/sites/all/themes/omgsub/images/Step2.png') no-repeat;
        background-color: #f1f1f1;
        h3{
          //max-width: 125px;
        }
        span{
          //left: -24px;
        }
      }
      &.box_3{
        background: url('/sites/all/themes/omgsub/images/Step3.png') no-repeat;
        background-color: #f1f1f1;
        h3{
          //max-width: 131px;
        }
        span{
          //left: 13px;
        }
      }
      h3{
        color: $purple;
        font-size: 24px;
        font-weight: 700;
        line-height: 27px;
        word-spacing: 3px;
        letter-spacing: -1.8px;
        //max-width: 124px;
        margin: auto;
      }
      span{
        font-size: 14px;
        font-weight: 400;
        line-height: 27px;
      }
    }
  }
}
@include breakpoint(1330px){
  #block-block-40{
    .boxes{

      .box{
        padding-top: 8px;
        @include span(1 of 3 (gutters:0.03));
        &:not(.active){

        }
        span{

        }
        &:last-child{
          @include span(1 of 3 (gutters:0.03) last);
        }
        &.box_1{

          span{
            //left: 8px;
          }
        }
        &.box_2{

          h3{
            margin-bottom: 10px;
          }
          span{
            left: auto;
          }
        }
        &.box_3{

          h3{

          }
          span{
            //left: 7px;
          }
        }
        h3{
          font-size: 20px;
          max-width: 100%;
          text-align: left;
          margin-bottom: 4px;
        }
        span{
          font-size: 12px;
          //max-width: 100px;
          margin: auto;
          display: block;
          line-height: 13px;

        }
      }
    }
  }
}
@include breakpoint($md){
  #block-block-40{
    .boxes{

      .box{
        padding-top: 8px;
        margin-bottom: 7px;
        padding-left: 71px;
        position: relative;
        border-right: 11px solid $purple;
        &:not(.active){

        }
        span{

        }
        &:last-child{
        }
        &.box_1{
          //background-position: center;
          span{

          }
        }
        &.box_2{
          //background-position: center;
          h3{

          }
          span{

          }
        }
        &.box_3{
          //background-position: center;
          h3{

          }
          span{

          }
        }
        h3{
          display: none;
        }
        span{
          position: absolute;
          left: 71px;
          width: auto;
          top: 50%;
          transform: translateY(-50%);

        }
      }
    }
  }
}
@include breakpoint($sm){
  #block-block-40{
    .boxes{
      max-width: 263px;
      display: block;
      margin: auto;
      margin-top: 12px;
      margin-bottom: 12px;
      .box{
        background-color: #fff;
        border-right: none;
        max-width: 90px;
        padding-left: 0;
        &:not(.active){

        }
        span{

        }
        &:last-child{
        }
        &.box_1{
          background-position: center;
          background-color: #fff;
          span{

          }
        }
        &.box_2{
          background-position: center;
          background-color: #fff;
          h3{

          }
          span{

          }
        }
        &.box_3{
          background-position: center;
          background-color: #fff;
          max-width: 90px;
          padding-left: 0;
          float: unset;
          display: inline-block;
          h3{

          }
          span{

          }
        }
        h3{
          display: none;
        }
        span{
         display: none;

        }
      }
    }
  }
}
//@include breakpoint($smaller $xs){
//  #block-block-40{
//    .boxes{
//
//      .box{
//
//        &:not(.active){
//
//        }
//        span{
//
//        }
//        &:last-child{
//
//        }
//        &.box_1{
//          //background-size: 100%;
//          span{
//
//          }
//        }
//        &.box_2{
//          //background-size: 100%;
//          h3{
//
//          }
//          span{
//
//          }
//        }
//        &.box_3{
//          //background-size: 100%;
//          h3{
//
//          }
//          span{
//
//          }
//        }
//        h3{
//
//        }
//        span{
//
//
//        }
//      }
//    }
//  }
//}