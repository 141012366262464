body.page-checkout-{
  .l-main{
    .l-content{
      #commerce-checkout-form-checkout{
        .price-table{
          .final-price{
            .price-text{
            }
            .price-value{
              @include breakpoint($md){
                font-size: 22px;
              }
            }
          }
          .downpayment-price{
            .price-value{
              @include breakpoint($md){
                font-size: 22px;
              }
            }
          }
        }
      }
    }
  }
}