//Top Region
.l-top_region{
  background-color: $dblue;
  position: relative;
  z-index: 9999;
}

.l-region--top-region{
  max-width: 100%;
  padding: 0 49px;
  @include breakpoint(600px){
    padding: 0 10px;
  }
  @include breakpoint($xs){
    line-height: 17px;
    padding-bottom: 40px;
  }
  text-align: right;
}
#block-block-42{
  float: left;
  margin-top: 9px;
  .social{
    margin: 0;
    li{
      display: inline-block;
      vertical-align: bottom;
      margin-right: 6px;
      margin-bottom: 0px;
      &.facebook a:before{
        content: '\f09a';
        background-color: $dblue;
      }
      &.twitter a:before{
        content: '\f099';
        background-color: $dblue;
      }
      &.google-plus a:before{
        content: '\f0d5';
        background-color: $dblue;
      }
      &.linkedin a:before{
        content: '\f0e1';
        background-color: $dblue;
      }
      &.youtube a:before{
        content: '\f167';
        background-color: $dblue;
      }
      &.instagram a:before{
        content: '\f16d';
        background-color: $dblue;
      }
    }
    a{
      border-radius: 50%;
      border: 1px solid $orange;
      transition: bottom 0.25s ease;
      font-size: 0;
      color: transparent;
      display: inline-block;
      width: 28px;
      height: 28px;
      position: relative;
      bottom: 0;
      vertical-align: bottom;
      &:before{
        font-family: 'FontAwesome';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        font-size: 16px;
        line-height: 28px;
        color: $orange;
        border-radius: 50%;
        text-align: center;
      }
      &:hover{
        bottom: 5px;
      }
    }
  }
}
.bubble{
  @include breakpoint($xs){
    margin-top: 3px;
    margin-bottom: 4px;
  }
  padding-top: 8px;
  padding-bottom: 8px;
  .block__content{
    display: inline-block;
    vertical-align: bottom;
    a{
      @include breakpoint($xs){
        line-height: 17px;
      }
      text-decoration: none;
      //text-transform: uppercase;
      color: white;
      font-size: 14px;
      font-weight: 400;
      line-height: 27px;
      vertical-align: bottom;
    }
  }
  &:not(:last-child){
    //border-right: 1px solid $orange;
  }
  &:before{
    @include breakpoint($xs){
      width: 17px;
      height: 17px;
    }
    content: '';
    display: inline-block;
    vertical-align: bottom;
    width: 24px;
    height: 27px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

#block-block-16.phone{
  padding-right: 20px;
  margin-right: 8px;
  @include breakpoint(600px){
    padding-right: 9px;
    margin-right: 3px;
  }
  .block__content{
    a {
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 1px;
      @include breakpoint(600px){
        font-size: 14px;
      }
    }
  }
  &:before {
   // background-image: url('/sites/all/themes/omgsub/images/phone_bubble.svg');
    @extend .svg-telephone_icon;
    @extend .svg-telephone_icon-dims;
    margin-right: 10px;
    @include breakpoint(600px){
      margin-right: 5px;
    }
  }
  @include breakpoint($xs){
    padding-right: 8px;
    margin-right: 5px;
    position: absolute;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    max-width: 300px;
    top: 40px;
    border-right: none;
    .block__content{
      margin-bottom: 4px;
      a{
        font-size: 0.8em;
      }
    }
  }
}

#block-block-17.help{
  padding-right: 13px;
  margin-right: 11px;
  @include breakpoint(600px){
    padding-right: 8px;
    margin-right: 3px;
  }
  &:before{
  //  background-image: url('/sites/all/themes/omgsub/images/help_bubble.svg');
    @extend .svg-help_icon;
    @extend .svg-help_icon-dims;
    margin-right: 4px;
    @include breakpoint(600px){
      margin-right: 2px;
    }
  }
  @include breakpoint($xs){
    padding-right: 8px;
    margin-right: 5px;
    .block__content{
      a{
        font-size: 0.8em;
      }
    }
  }
}

#block-lang-dropdown-language{
  display: none;
  &:before{
  //  background-image: url('/sites/all/themes/omgsub/images/language_bubble.svg');
    @extend .svg-language_icon;
    @extend .svg-language_icon-dims;
    margin-right: 6px;
    @include breakpoint(600px){
      margin-right: 3px;
    }
  }
}

#lang_dropdown_form_language{
  .form-item-lang-dropdown-select{
    margin-bottom: 0;
  }

  .dd-container{
    width: unset !important;
  }

  .dd-select{
    border: none;
    border-radius: 0;
    background-color: unset !important;
    width: unset !important;
  }

  a.dd-selected{
    @include breakpoint($xs){
      line-height: 17px;
      font-size: 12px;
    }
    padding: 0;
    text-align: left;
    font-size: 18px;
    font-weight: 700;
    color: $orange;
    line-height: 27px;
    margin-right: 4px;
  }
  .dd-pointer{
    display: none;
  }
  ul.dd-options{
    width: 50px!important;
    right: -11px;
    top: calc(100% + 10px );
    li{
      a{
        padding-bottom: 2px;
        padding-top: 2px;
        color: $blue;
        text-align: center;
        input{
        }
        label{
          cursor: pointer;

        }
      }
    }
  }
}

//Header region
header.l-header{
  position: sticky;
  top: 0px;
  z-index: 9998;
  box-shadow: -10px 0 10px rgba(0, 0, 0, 0.75);
  background-color: $blue;
}

.header-container{
  @include gim_container(1832px);
  //max-width: 100%;
  display: block;
  position: relative;
}

.l-branding{
  margin-right: 14.6%;
  float: left;
  padding: 15px 16px 15px 40px;
  &:after {
    content: "";
    display: block;
    max-width: 280px;
    width: 100%;
  }
  @include breakpoint(1620px){
    margin-right: 0;
  }
  @include breakpoint($lg $md + 1px){
    margin-right: 0;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
    float: left;
  }
  @include breakpoint(1100px $md){
   .l-logo{
     a{
       img{
         max-width: 370px;
       }
     }
   }
  }
  @include breakpoint($md $sm + 1px){
    @include span(12);
    margin-right: 0;
    text-align: center;
    .l-logo{
      display: inline-block;
    }
  }
  @include breakpoint($sm){
    @include span(6 of 8);
  }
  @include breakpoint($sxs){
    padding-left: 0;
  }

}

.site-logo{
  margin-top: 17px;
  margin-bottom: 17px;

  img{
    display: block;
  }

  @include breakpoint($lg $sm + 1px){
    margin-top: 10px;
    margin-bottom: 10px;
  }
  @include breakpoint($sm){
    margin-top: 10px;
    margin-bottom: 10px;
    img{
      max-width: 225px;
      height: auto;
    }
  }
}

.l-region--header{
 // @include span(5 of 8 no-gutters);
  display: block;
//  display: table-cell;
 // float: unset;
//  vertical-align: bottom;
  text-align: center;
  @include breakpoint($lg $md + 1px){
    @include span(12 last);
    max-width: 500px;
    margin: 0 auto;
    margin-top: 37px;
  }
  @include breakpoint($md $sm + 1px){
    @include span(12);
    margin: 0 auto;
    float: unset;
    max-width: 600px;
  }
  @include breakpoint($sm){
    @include span(2 of 8 last);
  }
}