
body{
  &:not(.front){
    .form-type-checkboxes{

      &.form-item-car-type{
        margin-bottom: 8px;
        @include breakpoint(1274px){
          width: 100%;
          display: inline-block;
          margin-bottom: 5px;
        }
       > label{
          @include breakpoint(1274px){
            width: 135px;
            float: left;
          }
        }
        #edit-car-type{
          position: relative;
          bottom: 5px;
          @include breakpoint(1274px){
            width: calc(100% - 145px);
            float: left;
          }
        }
      }
      &.form-item-gearbox{
        #edit-gearbox{
          position: relative;
          bottom: 5px;
          @include breakpoint(1274px){
            width: calc(100% - 145px);
            float: left;
            margin-bottom: 10px;
          }
          @include breakpoint($smaller){
            margin-bottom: 0;
          }
        }
        > label{
          margin-right: 10px;
          @include breakpoint(1274px){
            width: 135px;
            float: left;
            margin-bottom: 10px;
          }
        }
      }
      > label{
        @include breakpoint($lg){

        }

        margin-right: 10px;
        .form-required{
        }
      }
      .form-checkboxes{
        .form-item{
          margin-bottom: 0;
          &.form-item-car-type--1{
            label{

            }
          }
          input{

          }
          label{
            @include breakpoint($lg){

            }
            margin-right: 17px;
            padding-left: 28px;
            &:before{
              top: 0px;
            }

            &:after{

              top: 8px;
            }
          }
          input:checked ~ label{
            &:before{
            }
            &:after{

            }
          }
        }
      }
    }
    .block__content{
      form{
        > div{
          select{

          }
          select,
          .select-title{


            &:after {

            }
          }
          .form-item{

            &.form-item-car-type{

            }
            &.form-item-gearbox{

            }
          }
          .form-item:not(.form-item-car-type, .form-item-gearbox) {

            &.form-item-pickup-date,
            &.form-item-dropoff-date {

            }
            .block-select{

              /* width */
              ::-webkit-scrollbar {

              }
              /* Track */
              ::-webkit-scrollbar-track {

              }
              /* Handle */
              ::-webkit-scrollbar-thumb {

              }
              /* Handle on hover */
              ::-webkit-scrollbar-thumb:hover {

              }
              .locations-dropdown-container{

                ul{

                  li{

                    &.location-group-name{

                    }
                    &.hidden{

                    }
                    input{

                    }
                    label{

                      &:hover{
                        background-color: #085ef7;
                      }
                    }
                    input:checked ~ label{
                      background-color: #085ef7;
                    }
                  }
                }
                .location-search-container{

                  &:before{

                  }
                  input{

                  }
                }
              }
              &.open{
                .locations-dropdown-container{

                  ul{
                    @include transition;
                  }
                }
              }
            }
          }

          .form-item-dropoff-date,
          .form-item-pickup-date {


            &:before {

            }
          }

          .form-item.form-type-textfield{
            input {


              padding: 0 8px 0 40px;

            }
            &.form-item-pickup-time,
            &.form-item-dropoff-time{

              input {
                width: 120px;
                background-color: #085ef7;
              }
            }
          }
          input[type="submit"]{
            padding-left: 9px;
            padding-right: 9px;
          }
        }
      }
    }
    #block-rent-a-car-rent-a-car-search-sidebar{

      @include breakpoint($sm $xs){

      }
      @include breakpoint($xs){
        .block__content{
          padding-top: 12px;
        }
      }

      .block__content{
        @include breakpoint($lg){

        }
        @include breakpoint($sm $xs){

        }
        @include breakpoint($xs){

        }

        &:after{
          @include breakpoint($xs){

          }

        }
        form{
          > div{
            .form-item{
              &:first-child{
                @include breakpoint($lg){

                }
              }
              &.form-type-select{
                @include span(12);

              }
              .form-select,
              .block-select .select-title{
                @include breakpoint($lg){

                }
                @include span(12);

              }
              &.form-item-pickup-date,
              &.form-item-dropoff-date {
                @include breakpoint($lg){
                  width: calc(100% - 94px);
                }
                width: calc(100% - 120px);
                margin-bottom: 39px;
                input{
                  padding-left: 50px;
                  @include breakpoint($lg){
                    padding-left: 41px;
                  }
                }
              }
              &.form-item-dropoff-date{
                margin-bottom: 10px;
              }
              &.form-item-pickup-time,
              &.form-item-dropoff-time{
                margin-bottom: 39px;
                input{
                  @include breakpoint($lg){
                    width: 94px;
                    padding: 0;
                    padding-right: 40px;
                    text-align: right;
                  }
                }
              }
              &.form-item-dropoff-time{
                margin-bottom: 10px;
              }
              &.form-item-old-enough{


                @include borderradius(5px);
                label{
                  @include breakpoint($lg){

                  }

                  .form-required{

                  }
                }
                label{
                  &:before{

                  }
                  &:after{

                  }
                }
                input:checked ~ label{
                  &:before{

                  }
                  &:after{

                  }
                }
                input{

                }
              }
            }
            h4.more-link {

              @include breakpoint($lg){

              }
              @include breakpoint($smaller){

                &:after{

                }
              }

              &.open{
                &:after{

                }
              }
              &:after{

              }
            }
            .your-booking {
              @include breakpoint($sm){

              }

              .old-enough,
              .dropoff-car,
              .pickup-car {
                @include breakpoint($lg){

                }
                &:first-child{

                }

                h6 {
                  @include breakpoint($lg){

                  }
                  @include breakpoint($sm){

                  }

                }
                .location,
                .date {
                  @include breakpoint($lg){

                  }
                  @include breakpoint($sm){

                  }

                  &:before{
                    @include breakpoint($lg){

                    }

                  }
                }
                .location:before{

                }
                .date:before{

                }
              }
              .old-enough {
                @include breakpoint($lg){

                }
                @include breakpoint($sm){

                }

              }
              > h4{
                @include breakpoint($lg){

                }

              }
            }
            .selected-car{

              .title{
                @include breakpoint($lg){

                }

              }
              >img{

              }
              .dates{

                .duration{
                  @include breakpoint($lg){

                  }

                }
                .dates-selected{
                  @include breakpoint($lg){

                  }

                }
              }
              .prices{

                .main-price{
                  @include breakpoint($lg){

                  }

                }
                .secondary-prices{

                }
              }
              h6{
                @include breakpoint($lg){

                }

              }
              ul{

                li{
                  @include breakpoint($lg){

                  }

                }
              }
            }
            input[type=submit]{
              @include breakpoint($lg){

              }

              &:hover{

              }
              &[disabled]{

              }
            }
          }
        }
      }
    }
  }
}
@include breakpoint(1100px $sm + 1){
  body{
    &:not(.front){
      .form-type-checkboxes{
        //width: 100%;
        //display: inline-block;
        &.form-item-car-type{
          label{
            width: 100px;
            float: left;
          }
          #edit-car-type{
            width: calc(100% - 110px);
            float: left;
          }
        }
        &.form-item-gearbox{
          #edit-gearbox{
            width: calc(100% - 110px);
            float: left;
          }
          > label{
            width: 100px;
            float: left;
          }
        }
        > label{
          @include breakpoint($lg){

          }

          .form-required{
          }
        }
        .form-checkboxes{
          .form-item{

            &.form-item-car-type--1{
              label{

              }
            }
            input{

            }
            label{
              @include breakpoint($lg){

              }

              &:before{

              }

              &:after{


              }
            }
            input:checked ~ label{
              &:before{
              }
              &:after{

              }
            }
          }
        }
      }
      .block__content{
        form{
          > div{
            select{

            }
            select,
            .select-title{


              &:after {

              }
            }
            .form-item{

              &.form-item-car-type{

              }
              &.form-item-gearbox{

              }
            }
            .form-item:not(.form-item-car-type, .form-item-gearbox) {

              &.form-item-pickup-date,
              &.form-item-dropoff-date {

              }
              .block-select{

                /* width */
                ::-webkit-scrollbar {

                }
                /* Track */
                ::-webkit-scrollbar-track {

                }
                /* Handle */
                ::-webkit-scrollbar-thumb {

                }
                /* Handle on hover */
                ::-webkit-scrollbar-thumb:hover {

                }
                .locations-dropdown-container{

                  ul{

                    li{

                      &.location-group-name{

                      }
                      &.hidden{

                      }
                      input{

                      }
                      label{

                        &:hover{

                        }
                      }
                      input:checked ~ label{

                      }
                    }
                  }
                  .location-search-container{

                    &:before{

                    }
                    input{

                    }
                  }
                }
                &.open{
                  .locations-dropdown-container{

                    ul{
                      @include transition;
                    }
                  }
                }
              }
            }

            .form-item-dropoff-date,
            .form-item-pickup-date {


              &:before {

              }
            }

            .form-item.form-type-textfield{
              input {




              }
              &.form-item-pickup-time,
              &.form-item-dropoff-time{

                input {

                }
              }
            }
            input[type="submit"]{

            }
          }
        }
      }
      #block-rent-a-car-rent-a-car-search-sidebar{

        @include breakpoint($sm $xs){

        }
        @include breakpoint($xs){
          .block__content{

          }
        }

        .block__content{
          @include breakpoint($lg){

          }
          @include breakpoint($sm $xs){

          }
          @include breakpoint($xs){

          }

          &:after{
            @include breakpoint($xs){

            }

          }
          form{
            > div{
              .form-item{
                &:first-child{
                  @include breakpoint($lg){

                  }
                }
                &.form-type-select{


                }
                .form-select,
                .block-select .select-title{
                  @include breakpoint($lg){

                  }


                }
                &.form-item-pickup-date,
                &.form-item-dropoff-date {


                  input{

                  }
                }
                &.form-item-dropoff-date{

                }
                &.form-item-pickup-time,
                &.form-item-dropoff-time{

                  input{

                  }
                }
                &.form-item-dropoff-time{

                }
                &.form-item-old-enough{



                  label{
                    @include breakpoint($lg){

                    }

                    .form-required{

                    }
                  }
                  label{
                    &:before{

                    }
                    &:after{

                    }
                  }
                  input:checked ~ label{
                    &:before{

                    }
                    &:after{

                    }
                  }
                  input{

                  }
                }
              }
              h4.more-link {

                @include breakpoint($lg){

                }
                @include breakpoint($smaller){

                  &:after{

                  }
                }

                &.open{
                  &:after{

                  }
                }
                &:after{

                }
              }
              .your-booking {
                @include breakpoint($sm){

                }

                .old-enough,
                .dropoff-car,
                .pickup-car {
                  @include breakpoint($lg){

                  }
                  &:first-child{

                  }

                  h6 {
                    @include breakpoint($lg){

                    }
                    @include breakpoint($sm){

                    }

                  }
                  .location,
                  .date {
                    @include breakpoint($lg){

                    }
                    @include breakpoint($sm){

                    }

                    &:before{
                      @include breakpoint($lg){

                      }

                    }
                  }
                  .location:before{

                  }
                  .date:before{

                  }
                }
                .old-enough {
                  @include breakpoint($lg){

                  }
                  @include breakpoint($sm){

                  }

                }
                > h4{
                  @include breakpoint($lg){

                  }

                }
              }
              .selected-car{

                .title{
                  @include breakpoint($lg){

                  }

                }
                >img{

                }
                .dates{

                  .duration{
                    @include breakpoint($lg){

                    }

                  }
                  .dates-selected{
                    @include breakpoint($lg){

                    }

                  }
                }
                .prices{

                  .main-price{
                    @include breakpoint($lg){

                    }

                  }
                  .secondary-prices{

                  }
                }
                h6{
                  @include breakpoint($lg){

                  }

                }
                ul{

                  li{
                    @include breakpoint($lg){

                    }

                  }
                }
              }
              input[type=submit]{
                @include breakpoint($lg){

                }

                &:hover{

                }
                &[disabled]{

                }
              }
            }
          }
        }
      }
    }
  }
}
@include breakpoint($sm){
  body{
    &:not(.front){
      .form-type-checkboxes{
        width: 100%;
        display: inline-block;
        &.form-item-car-type{
          label{
            width: 100%;
            margin-bottom: 7px;
          }
          #edit-car-type{
            width: 100%;
          }
        }
        &.form-item-gearbox{
          #edit-gearbox{
            width: 100%;
          }
          > label{
            width: 100%;
            margin-bottom: 7px;
          }
        }
        > label{
          @include breakpoint($lg){

          }

          .form-required{
          }
        }
        .form-checkboxes{
          .form-item{

            &.form-item-car-type--1{
              label{

              }
            }
            input{

            }
            label{
              @include breakpoint($lg){

              }

              &:before{

              }

              &:after{


              }
            }
            input:checked ~ label{
              &:before{
              }
              &:after{

              }
            }
          }
        }
      }
      .block__content{
        form{
          > div{
            select{

            }
            select,
            .select-title{


              &:after {

              }
            }
            .form-item{

              &.form-item-car-type{

              }
              &.form-item-gearbox{

              }
            }
            .form-item:not(.form-item-car-type, .form-item-gearbox) {

              &.form-item-pickup-date,
              &.form-item-dropoff-date {

              }
              .block-select{

                /* width */
                ::-webkit-scrollbar {

                }
                /* Track */
                ::-webkit-scrollbar-track {

                }
                /* Handle */
                ::-webkit-scrollbar-thumb {

                }
                /* Handle on hover */
                ::-webkit-scrollbar-thumb:hover {

                }
                .locations-dropdown-container{

                  ul{

                    li{

                      &.location-group-name{

                      }
                      &.hidden{

                      }
                      input{

                      }
                      label{

                        &:hover{

                        }
                      }
                      input:checked ~ label{

                      }
                    }
                  }
                  .location-search-container{

                    &:before{

                    }
                    input{

                    }
                  }
                }
                &.open{
                  .locations-dropdown-container{

                    ul{
                      @include transition;
                    }
                  }
                }
              }
            }

            .form-item-dropoff-date,
            .form-item-pickup-date {


              &:before {

              }
            }

            .form-item.form-type-textfield{
              input {




              }
              &.form-item-pickup-time,
              &.form-item-dropoff-time{

                input {

                }
              }
            }
            input[type="submit"]{

            }
          }
        }
      }
      #block-rent-a-car-rent-a-car-search-sidebar{

        @include breakpoint($sm $xs){

        }
        @include breakpoint($xs){
          .block__content{

          }
        }

        .block__content{
          @include breakpoint($lg){

          }
          @include breakpoint($sm $xs){

          }
          @include breakpoint($xs){

          }

          &:after{
            @include breakpoint($xs){

            }

          }
          form{
            > div{
              .form-item{
                &:first-child{
                  @include breakpoint($lg){

                  }
                }
                &.form-type-select{


                }
                .form-select,
                .block-select .select-title{
                  @include breakpoint($lg){

                  }


                }
                &.form-item-pickup-date,
                &.form-item-dropoff-date {


                  input{

                  }
                }
                &.form-item-dropoff-date{

                }
                &.form-item-pickup-time,
                &.form-item-dropoff-time{

                  input{

                  }
                }
                &.form-item-dropoff-time{

                }
                &.form-item-old-enough{



                  label{
                    @include breakpoint($lg){

                    }

                    .form-required{

                    }
                  }
                  label{
                    &:before{

                    }
                    &:after{

                    }
                  }
                  input:checked ~ label{
                    &:before{

                    }
                    &:after{

                    }
                  }
                  input{

                  }
                }
              }
              h4.more-link {

                @include breakpoint($lg){

                }
                @include breakpoint($smaller){

                  &:after{

                  }
                }

                &.open{
                  &:after{

                  }
                }
                &:after{

                }
              }
              .your-booking {
                @include breakpoint($sm){

                }

                .old-enough,
                .dropoff-car,
                .pickup-car {
                  @include breakpoint($lg){

                  }
                  &:first-child{

                  }

                  h6 {
                    @include breakpoint($lg){

                    }
                    @include breakpoint($sm){

                    }

                  }
                  .location,
                  .date {
                    @include breakpoint($lg){

                    }
                    @include breakpoint($sm){

                    }

                    &:before{
                      @include breakpoint($lg){

                      }

                    }
                  }
                  .location:before{

                  }
                  .date:before{

                  }
                }
                .old-enough {
                  @include breakpoint($lg){

                  }
                  @include breakpoint($sm){

                  }

                }
                > h4{
                  @include breakpoint($lg){

                  }

                }
              }
              .selected-car{

                .title{
                  @include breakpoint($lg){

                  }

                }
                >img{

                }
                .dates{

                  .duration{
                    @include breakpoint($lg){

                    }

                  }
                  .dates-selected{
                    @include breakpoint($lg){

                    }

                  }
                }
                .prices{

                  .main-price{
                    @include breakpoint($lg){

                    }

                  }
                  .secondary-prices{

                  }
                }
                h6{
                  @include breakpoint($lg){

                  }

                }
                ul{

                  li{
                    @include breakpoint($lg){

                    }

                  }
                }
              }
              input[type=submit]{
                @include breakpoint($lg){

                }

                &:hover{

                }
                &[disabled]{

                }
              }
            }
          }
        }
      }
    }
  }
}