.page-node-242{
  .branch{
    width: 100%;
    border: 2px solid $purple;
    margin-bottom: 25px;
    padding: 12px 25px;
    text-align: center;
    @include borderradius(8px);
    @include breakpoint(min-width $md) {
      &:nth-of-type(2n + 2) {
        @include span(6 last);
      }
    }
    @include breakpoint(1022px) {
      width: 100%;
      height: unset;
    }
    h4{
      position: relative;
      display: inline-block;
      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -4px;
        height: 2px;
        background: $purple;
        right: -30px;
      }
    }
    p{
      margin: 0;
    }
  }
  .office-hours{
    display: inline-block;
    width: 100%;
    margin-top: 30px;
    @include breakpoint($sm){
      margin-top: 5px;
    }
    h3{
      background: $purple;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      padding: 10px 5px;
      color: #FFFFFF;
      text-align: center;
      margin-bottom: 0;
    }
    > p{
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      border: 2px solid $purple;
      text-align: center;
      padding: 10px 5px;
    }
  }
}

#google-map{
  height: 600px;
}

#contact-form{
  .block__title{
    text-align: center;
  }
  .block__content{
    display: block;
    width: 100%;
    max-width: 560px;
    margin: 0 auto;
  }
  input[type="text"],
  input[type="email"],
  textarea{
    @include placeholder;
    border: 1px solid $purple;
    border-radius: 5px;
    padding: 7px 15px;
    font-size: 18px;
    line-height: 24px;
    position: relative;
    width: 100%;
    display: inline-block;
  }
  .form-item{
    @include span(1 of 2 (gutters:0.08));
    &:nth-child(2n){
      @include span(1 of 2 (gutters:0.08) last);
    }
    &.webform-component-textarea,
    &.webform-component-checkboxes,
    &.form-type-checkbox{
      display: inline-block;
      width: 100%;
      .form-item{
        width: 100%;
      }
    }
  }
  .grippie{
    display: none;
  }
  .webform-component-checkboxes{
    margin-bottom: 5px;
  }
  .webform-component--checkboxes,
  .form-type-checkbox{
    .description{
      font-weight: 300;
      margin-bottom: 10px;
    }
    input{
      display: none;
      &:checked + .option:before{
        color: $purple;
      }
    }
    .option{
      position: relative;
      padding-left: 30px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 300;
      line-height: 21px;
      display: inline-block;
      &:before{
        content: '\f00c';
        font-family: 'FontAwesome';
        display: block;
        width: 21px;
        height: 21px;
        position: absolute;
        top: 0;
        left: 0;
        border: 1px solid $purple;
        border-radius: 2px;
        color: white;
        font-size: 16px;
        line-height: 21px;
        text-align: center;
        transition: color 0.05s ease;
      }
      .form-required{
        display: none;
      }
    }
  }

  .form-item-gdpr-form-compliance{
    .option{
      .form-required{
        display: initial;
        color: inherit;
      }
    }
  }

  .form-actions{
    @include span(12);
  }

  .form-submit{
    @extend .button;
    background-color: $purple;
    text-transform: uppercase;
    border: none;
    padding: 12px 25px 11px;
    margin-top: 12px;
    @include breakpoint($sm){
      display: block;
      margin: auto;
      margin-top: 20px;
    }
    &:hover{
      background-color: desaturate(darken($purple, 5%), 10%);
    }
  }
}
#map{
  width: 100%;
  height: 400px;
}