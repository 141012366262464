@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700,800&subset=greek');
@import "breakpoint";
@import "susy";

@import "variables/_defaults.scss";
@import "variables/_mixins.scss";
@import "variables/_susy.scss";
@import "svg/_sprite.scss";
@import "common/_admin.scss";
@import "common/_base.scss";
@import "common/_common_classes.scss";
@import "common/_footer.scss";
@import "common/_header.scss";
@import "common/_language_fixes.scss";
@import "common/_notifications.scss";
@import "blocks/_common_blocks.scss";
@import "blocks/_explore_crete.scss";
@import "blocks/_gallery_with_text.scss";
@import "blocks/_mmenu.scss";
@import "blocks/book_block.scss";
@import "blocks/FRONT_home_blocks.scss";
@import "pages/_about_crete.scss";
@import "pages/_about_us.scss";
@import "pages/_blog_inside.scss";
@import "pages/_blog.scss";
@import "pages/_booking_request.scss";
@import "pages/_car_page_analysis.scss";
@import "pages/_car_search.scss";
@import "pages/_contact.scss";
@import "pages/_faq.scss";
@import "pages/_front.scss";
@import "pages/_offers.scss";
@import "pages/_page_checkout.scss";
@import "pages/_simple_pages.scss";
@import "pages/3_steps.scss";
@import "pages/admin.scss";
@import "pages/checkout_new.scss";
@import "pages/common_car.scss";
@import "pages/explore_crete_pages.scss";
@import "pages/insurance_policy.scss";
@import "pages/new_car.scss";
@import "pages/rental_policy.scss";
@import "pages/testimonials.scss";
@import "language/_german_fixes.scss";
@import "language/_russian_fixes.scss";