.l-region--highlighted{
  position: relative;
}
#block-menu-menu-explore-crete-menu{
  float: left;
  width: 100%;
  text-align: center;
  background: $orange;
  z-index: 998;
  ul{
    display: inline-block;
    margin: 0;
    padding: 0;
    background: $orange;
    vertical-align: top;
    li{
      list-style: none;
      position: relative;
      padding-left: 10px;
      padding-right: 10px;
      a{
        padding-left: 10px;
        padding-right: 10px;
        line-height: 38px;
        padding-top: 4px;
        padding-bottom: 4px;
        color: $purple;
        display: inline-block;
        width: 100%;
      }
      &:last-child{
        a {
          &:after {
            display: none;
          }
        }
      }
      ul{
        position: absolute;
      }
    }
    @include breakpoint(min-width $md +1) {
      li:hover > ul {
        display: block;
      }
    }
  }
  > ul{
    text-align: center;
    > li{
      display: inline-block;
      > a{
        position: relative;
        &:after{
          content: '';
          position: absolute;
          right: -10px;
          width: 1px;
          top: 4px;
          bottom: 3px;
          background: $purple;
        }
      }
      ul{
        width: 170px;
        display: none;
        background: #ffffff;
        border: 1px solid $purple;
        li{
          padding-left: 0px;
          padding-right: 0px;
          &.expanded{
            > a{
              position: relative;
              padding-right: 20px;
              &:after{
                content: '\f105';
                display: block;
                font-family: "FontAwesome";
                text-shadow: none;
                line-height: 14px;
                font-size: 18px;
                color: $purple;
                position: absolute;
                top: 50%;
                right: 10px;
                transform: translateY(-50%);
              }
            }
          }
          a{
            color: $purple;
            &:after{
              display: none;
            }
          }
          ul{
            top: 0;
            left: 100%;
          }
        }
      }
      > ul{
        top: 100%;
        left: 0;
        > li{
          > a{

          }
          > ul{
            > li{
            }
            > a{

            }
            > ul{
              > li{
              }
            }
          }
        }
      }
    }
  }
  @include breakpoint($md){
    display: inline-block;
    width: 50px;
    height: 50px;
    background: $lightblue;
    position: relative;
    cursor: pointer;
    top: 15px;
    border-radius: 5px;
    margin-bottom: 20px;
    margin-top: 10px;
    &:after{
      content: '';
      position: absolute;
      width: 70%;
      top: 12px;
      left: 15%;
      height: 5px;
      //background: $orange;
      //box-shadow: 0px 10px 0px 0px $orange, 0px 20px 0px 0px $orange;
      background: #FFF;
      box-shadow: 0px 10px 0px 0px #FFF, 0px 20px 0px 0px #FFF;
    }
    &:before{
      content: 'Please Select';
      position: absolute;
      width: 150px;
      top: 12px;
      left: 100%;
      text-align: left;
      color: $purple;
      font-size: 24px;
      padding-left: 30px;
    }
    .ec_arrow{
      position: absolute;
      left: calc(100% + 7px);
      display: inline-block;
      width: 15px;
      bottom: 0;
      top: 0;
      &:after{
        position: absolute;
        content: '\f104';
        font-family: "FontAwesome";
        text-shadow: none;
        line-height: 50px;
        font-size: 35px;
        opacity: 1;
        color: $lightblue;
        top: 0px;
        right: 4%;
      }
    }
    > ul{
      display: none;
      position: absolute;
      top: 50px;
      left: 0;
      width: 220px;
      margin-top: 10px;
      li{
        padding: 0;
        width: 100%;
        a{
          width: 100%;
          text-align: left;
          &:after{
            background: none;
          }
        }
        &.expanded{
          > a{
            position: relative;
            padding-right: 20px;
            &:after{
              content: '\f105';
              display: block;
              font-family: "FontAwesome";
              text-shadow: none;
              line-height: 14px;
              font-size: 18px;
              color: $purple;
              position: absolute;
              top: 50%;
              right: 10px;
              transform: translateY(-50%);
              background: none;
            }
          }
          ul{
            position: relative;
            width: 100%;
            left: 0;
            > li {
              > ul {
                > li {
                  a {
                    padding-right: 20px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@include breakpoint(520px){
  body.page-node-401{
    .l-region--highlighted{
      width: 100%;
      float: left;
      #block-menu-menu-explore-crete-menu{
        ul{

          li{

            a{
              line-height: 20px;
              &:after{
                display: none;
              }
            }
            &:last-child{
              a {
                &:after {

                }
              }
            }
            ul{
              position: relative;
              background-color: $purple;
              width: 100%;
              left: 0;
              li{
                a{
                  color: #fff;
                }
                > ul{
                  li{
                    display: contents;
                    a{
                      font-size: 12px;

                    }
                  }
                }
              }
            }
          }
          li:hover > ul {

          }
        }
        > ul{
          > li{
            margin-bottom: 10px;
            > a{
            }
            ul{

              li{
                ul{

                }
              }
            }
            > ul{

              > li{
                > a{

                }
                > ul{
                  > li{
                  }
                  > a{

                  }
                  > ul{
                    > li{
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .node--page--full{
      width: 100%;
      float: right;
      .node__content{
        margin-top: 0;
      }
    }
  }
}