@mixin sview {
	background: url("../images/sprites/svg/build/view/svg/sprite.view-10a6539b.svg") no-repeat;
}

.svg-air_condition_icon {
	@include sview;
	background-position: 61.121142811283654% 29.778443423023294%;
}

.svg-air_condition_icon-dims {
	width: 47.79px;
	height: 54.37px;
}

.svg-aircondition_icon {
	@include sview;
	background-position: 23.160350862031716% 38.35653985308628%;
}

.svg-aircondition_icon-dims {
	width: 65.57px;
	height: 65.58px;
}

.svg-baggage_icon {
	@include sview;
	background-position: 77.77191129883843% 53.27333065164924%;
}

.svg-baggage_icon-dims {
	width: 31.84px;
	height: 49.12px;
}

.svg-baggage_space_icon {
	@include sview;
	background-position: 41.594097726173196% 97.51808681672024%;
}

.svg-baggage_space_icon-dims {
	width: 48.96px;
	height: 48.96px;
}

.svg-blue_underline_icon {
	@include sview;
	background-position: 0 26.61885330365607%;
}

.svg-blue_underline_icon-dims {
	width: 71.73px;
	height: 5.39px;
}

.svg-ckeck_icon {
	@include sview;
	background-position: 96.17732943987258% 36.74442284550588%;
}

.svg-ckeck_icon-dims {
	width: 33.31px;
	height: 31.94px;
}

.svg-date_icon {
	@include sview;
	background-position: 93.3934818633769% 64.89043824701194%;
}

.svg-date_icon-dims {
	width: 25.45px;
	height: 27.12px;
}

.svg-door_icon {
	@include sview;
	background-position: 59.75020619771415% 97.34690806961231%;
}

.svg-door_icon-dims {
	width: 42.39px;
	height: 48.61px;
}

.svg-doors_icon {
	@include sview;
	background-position: 0 39.48645598194131%;
}

.svg-doors_icon-dims {
	width: 53.6px;
	height: 70.8px;
}

.svg-fuel_icon {
	@include sview;
	background-position: 0 72.31211794318588%;
}

.svg-fuel_icon-dims {
	width: 56.49px;
	height: 53.33px;
}

.svg-gear_icon {
	@include sview;
	background-position: 62.96603148301574% 68.28522920203734%;
}

.svg-gear_icon-dims {
	width: 43.53px;
	height: 41.85px;
}

.svg-gearbox_manual_icon {
	@include sview;
	background-position: 97.55356387275454% 20.07868417310518%;
}

.svg-gearbox_manual_icon-dims {
	width: 37.03px;
	height: 37.03px;
}

.svg-help_icon {
	@include sview;
	background-position: 93.92962962962963% 89.34389140271493%;
}

.svg-help_icon-dims {
	width: 27px;
	height: 27px;
}

.svg-home_icon {
	@include sview;
	background-position: 95.70188679245283% 51.541666666666664%;
}

.svg-home_icon-dims {
	width: 32px;
	height: 32px;
}

.svg-icon1 {
	@include sview;
	background-position: 21.17527450183001% 99.00030602876669%;
}

.svg-icon1-dims {
	width: 51.1px;
	height: 51.94px;
}

.svg-icon2 {
	@include sview;
	background-position: 0 99.60997639330799%;
}

.svg-icon2-dims {
	width: 52.07px;
	height: 53.14px;
}

.svg-icon3 {
	@include sview;
	background-position: 23.21824907521578% 69.71572900158478%;
}

.svg-icon3-dims {
	width: 53.7px;
	height: 46.08px;
}

.svg-icon4 {
	@include sview;
	background-position: 82.42535672888604% 73.37465695088483%;
}

.svg-icon4-dims {
	width: 46.81px;
	height: 36.66px;
}

.svg-icon5 {
	@include sview;
	background-position: 44.505028474494125% 69.0929616177481%;
}

.svg-icon5-dims {
	width: 49.41px;
	height: 44.26px;
}

.svg-icon6 {
	@include sview;
	background-position: 78.15186822016874% 97.09854927463729%;
}

.svg-icon6-dims {
	width: 48.1px;
	height: 48.1px;
}

.svg-insurance_icon {
	@include sview;
	background-position: 62.65734265734266% 0;
}

.svg-insurance_icon-dims {
	width: 53.9px;
	height: 57.66px;
}

.svg-kids_seat_icon {
	@include sview;
	background-position: 82.61688233644485% 0;
}

.svg-kids_seat_icon-dims {
	width: 47.39px;
	height: 53.6px;
}

.svg-kidseat_icon {
	@include sview;
	background-position: 82.22488038277511% 27.395859953999487%;
}

.svg-kidseat_icon-dims {
	width: 46.2px;
	height: 52.35px;
}

.svg-language_icon {
	@include sview;
	background-position: 93.92962962962963% 77.12669683257919%;
}

.svg-language_icon-dims {
	width: 27px;
	height: 27px;
}

.svg-location_icon {
	@include sview;
	background-position: 99.72483293428154% 63.88963180886154%;
}

.svg-location_icon-dims {
	width: 17.17px;
	height: 23.66px;
}

.svg-person_icon {
	@include sview;
	background-position: 99.79930741382024% 0;
}

.svg-person_icon-dims {
	width: 42.88px;
	height: 42.36px;
}

.svg-persons_icon {
	@include sview;
	background-position: 44.30276218446782% 37.934399566278124%;
}

.svg-persons_icon-dims {
	width: 28.01px;
	height: 63.55px;
}

.svg-quote1_icon {
	@include sview;
	background-position: 34.48650606774135% 0;
}

.svg-quote1_icon-dims {
	width: 76.16px;
	height: 64.58px;
}

.svg-quote2_icon {
	@include sview;
	background-position: 0 0;
}

.svg-quote2_icon-dims {
	width: 76.16px;
	height: 64.58px;
}

.svg-round_checked_icon {
	@include sview;
	background-position: 99.72864430695756% 98.70706715334887%;
}

.svg-round_checked_icon-dims {
	width: 20.61px;
	height: 20.61px;
}

.svg-round_unchecked_icon {
	@include sview;
	background-position: 92.23187984143723% 99.32734433774395%;
}

.svg-round_unchecked_icon-dims {
	width: 22.03px;
	height: 22.03px;
}

.svg-telephone_icon {
	@include sview;
	background-position: 56.41481481481482% 50.69230769230769%;
}

.svg-telephone_icon-dims {
	width: 27px;
	height: 27px;
}

.svg-time_icon {
	@include sview;
	background-position: 65.77171361502347% 50.093900912180295%;
}

.svg-time_icon-dims {
	width: 24.36px;
	height: 24.36px;
}