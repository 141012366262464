body.node-type-rent-a-car{
  .l-main{
    .l-content{
      article{
        @include span(12);
        padding-top: 0;
      }
      #flexslider-1{
        max-width: 559px;
        margin: 0 auto 0px;
      }
    }
  }
}
body.section-car-choice{
  .l-main{
    .l-content{
      .l-title{
        float: left;
      }
      #block-views-car-slider-block{
        float: right;
        argin-right: 0;
        #flexslider-1{
          max-width: 300px;
        }
      }
    }
  }
}

.l-title{
  padding-top: 8px;
  h2{
    font-size: 28px;
    color: $purple;
    line-height: 86px;
    height: 84px;
    margin-bottom: 9px;
    max-width: 320px;
    display: inline-block;
    overflow: hidden;
    @include breakpoint($smaller){
      font-size: 24px;
      margin-bottom: 0;
    }
  }
  a{
    font-size: 16px;
    float: right;
    text-decoration: inherit;
    color: black;
    line-height: 84px;
    font-weight: normal;
    transition: color 0.25s ease;
    &:hover{
      color: $purple;
    }
    &:after{
      content: '';
      display: inline-block;
      width: 40px;
      height: 84px;
      background-image: url('/sites/all/themes/omgsub/images/back_arrow.svg');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      vertical-align: bottom;
      margin-left: 10px;
    }
  }
  @include breakpoint(420px){
    h2{
      width: 100%;
      height: auto;
      line-height: 35px;
    }
    a{
      line-height: 43px;
      margin-bottom: 20px;
      &:after{
        height: 47px;
      }
    }
  }
}



#car-insurance-info{
  @include span(12);
}

.car-analysis-features{
  .section-title{
    color: $purple;
    margin: 0 0 16px;
    font-size: 22px;
    font-weight: 900;
    line-height: 2.4;
    padding-left: 6px;
    border-width: 1px 0;
    border-style: solid;
    border-color: rgba(black, 0.1);
    @include breakpoint($smaller){
      font-size: 16px;
    }
  }
  .feature{
    display: inline-block;
    line-height: 38px;
    min-width: 58px;
    margin-right: 2px;
    @include breakpoint($md){
      min-width: 66px;
    }
    &:before{
      height: 38px;
      width: 31px;
      position: relative;
    }
  }
}

#car-insurance-info{
  //margin-top: 13px;
  .block__content{
    font-weight: 300;
    text-align: center;
    *{
      text-align: left;
    }
    h4{
      color: $purple;
      margin: 0 0 12px;
      font-size: 22px;
      font-weight: 900;
      line-height: 2.6;
      border-width: 1px 0;
      border-style: solid;
      border-color: rgba(black, 0.1);
      @include breakpoint(849px){
        line-height: 25px;
        padding-top: 12px;
        padding-bottom: 12px;
      }
    }
    > p:first-of-type{
      padding-left: 50px;
      position: relative;
      &:before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background-image: url('/sites/all/themes/omgsub/images/insurance_shield.svg');
        background-position: center top;
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
        width: 39px;
        height: 100%;
      }
    }
    h5{
      color: $purple;
      font-size: 18px;
      line-height: 2;
    }
    h6{
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 5px;
      &:before{
        content: '\f105';
        font-family: 'FontAwesome';
        color: inherit;
        font-size: 16px;
        line-height: inherit;
        vertical-align: bottom;
        display: inline-block;
        margin-right: 5px;
      }
    }
    .checkmarks-list{
      li{
        &:before{
          //height: 22px;
          left: -5px;
          height: 19px;
          background-size: 80%;
        }
        //&:after{
        //  background-color: #fff;
        //  top: 6px;
        //  left: 0px;
        //  box-shadow: 2px 4px 0px -1px #fff;
        //}
      }
    }
    #check-availability{
      display: inline-block;
      text-align: unset;
      background-color: $purple;
      text-transform: uppercase;
      padding: 10px 12px;
      font-size: 20px;
      line-height: 1.5;
      color: $orange;
      border-radius: 8px;
      vertical-align: bottom;
      transition: background-color 0.25s ease;
      cursor: pointer;
      float: right;
      position: relative;
      background-image: linear-gradient(to right, #0a5dcc 23%, #0f4b97 23%);
      padding-left: 47px;
      font-weight: 700;
      @include breakpoint($md){
        margin-top: 35px;
      }
      &:before{
        content: "\f058";
        position: absolute;
        display: inline-block;
        width: 23px;
        font-family: FontAwesome;
        height: 23px;
        left: 4px;
        background-color: #0a5dcc;
        z-index: 999;
        top: 50%;
        transform: translateY(-55%);
        font-size: 17px;
      }
      &:after{
        content: "";
        position: absolute;
        display: inline-block;
        width: 0;
        height: 0;
        border-top: 9px solid transparent;
        border-bottom: 9px solid transparent;
        border-left: 9px solid #0a5dcc;
        left: 23%;
        top: 50%;
        transform: translateY(-50%);
      }
      &:hover{
        background-color: desaturate(darken($purple, 5%), 10%);
      }
      br{
        display: none;
      }
    }
  }
}

.optional-insurance{
  color: red;
}
