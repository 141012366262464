#admin-menu{
  z-index: 9999;
}
.admin-menu{
  .l-main{
    .action-links,
    .tabs.tabs--primary{
      display: inline-block;
      position: fixed;
      left: 0;
      width: 150px;
      list-style: none;
      padding: 0;
      li{
        @include span(12);
        a{
          background-color: #ccc;
          padding: 10px;
          text-decoration: none;
          @include span(12);
          transition: background-color 0.25s ease, color 0.25s ease;
          &:hover{
            background-color: #dfdfdf;
          }
        }
      }
    }
    .action-links{
      top: 189px;
      z-index: 99;
      li{
        border: 1px solid black;
        border-left: none;
      }
    }
    .tabs.tabs--primary{
      top: 250px;
      z-index: 99;
      border: none;
      li{
        border: 1px solid black;
        border-left: none;
        margin-bottom: 5px;
      }
    }
  }
}