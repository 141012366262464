body.page-node-216{
  .l-main{
    .l-content{
      article{
        .node__content{
          .field__item{
            > h2:first-child{
              @include breakpoint($md){
                font-size: 20px;
              }
            }
          }
        }
      }
    }
  }
}

.offer-item{
  @include breakpoint($lg){
    @include span(6);
  }
  @include breakpoint($md){
    min-height: 625px;
  }
  @include breakpoint($sm){
    @include span(12);
    min-height: 0;
    float: unset;
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
  }
  @include span(1 of 3 (gutters: 0.05));
  border: 1px solid $grey;
  margin-bottom: 1.6129%;
  min-height: 650px;
  text-align: center;
  padding-bottom: 20px;
  &:nth-child(3n+3){
    @include breakpoint($lg){
      @include span(6);
    }
    @include breakpoint($sm){
      @include span(12);
      float: unset;
      max-width: 320px;
      margin-left: auto;
      margin-right: auto;
    }
    @include span(1 of 3 (gutters: 0.05) last);
  }
  &:nth-child(2n+2){
    @include breakpoint($lg){
      @include span(6 last);
    }
    @include breakpoint($sm){
      @include span(12);
      float: unset;
      max-width: 320px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.offer-title{
  @include breakpoint($container){
    font-size: 18px;
    letter-spacing: -0.3px;
  }
  @include breakpoint($md){
    height: calc(3.6em + 30px);
  }
  margin: 0;
  height: calc(2.4em + 30px);
  font-size: 20px;
  font-weight: 700;
  color: $purple;
  line-height: 1.2;
  padding: 15px;
  text-align: left;
}

.offer-image{
  padding-bottom: 75%;
  width: 100%;
}

.offer-car{
  border-width: 0 0 1px;
  border-color: $grey;
  border-style: solid;
  font-weight: 700;
  padding: 10px 0;
  margin: 0 auto;
  max-width: 95%;
}

.offer-description{
  text-align: left;
  padding: 10px;
  height: 150px;
  overflow-y: auto;
}

.offer-prices{
  background-color: $light_grey;
  width: 100%;
  overflow: hidden;
  margin-top: 5px;
  border-width: 1px 0;
  border-style: solid;
  border-color: #ddd;
  > span{
    @include span(1 of 2 (gutters: 0));
    display: inline-block;
    text-align: center;
    margin: 15px 0;
    &:first-child{
      border-right: 1px solid #ddd;
    }
    .price-value{
      color: $orange;
      font-weight: 900;
      font-size: 32px;
      margin-bottom: 8px;
      display: block;
    }
  }
}

.offer-button{
  @extend .button;
  color: white;
  text-transform: uppercase;
  padding: 10px 25px;
  background-color: $purple;
  margin-top: 20px;
  &:hover{
    color: white;
    background-color: desaturate(darken($purple, 5%), 10%);
  }
}