body.node-type-simple-page{
  .l-main{
    .l-content{
      article{
        img{
          margin-left: 10px;
          margin-right: 10px;
          margin-bottom: 10px;
        }
      }
    }
  }
}