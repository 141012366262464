body.page-node-217{
  div{
    .l-main{
      .l-content{
        #block-views-blog-block{
          .block__content{
            .view-blog{
              .view-content{
                .blog-post-preview{
                  @include prefix(1);
                  @include suffix(1);
                  margin-bottom: 30px;
                  overflow: hidden;
                  a{
                    display: inline-block;
                    font-size: 28px;
                    font-weight: 700;
                    padding-left: 25px;
                    padding-right: 25px;
                    line-height: 27px;
                    margin-bottom: 15px;
                    width: calc(100% - 400px);
                  }
                  img{
                    width: 400px;
                    height: 250px;
                    float: left;
                    object-fit: contain;
                  }
                  .summary{
                    width: calc(100% - 400px);
                    float: right;
                    padding-left: 25px;
                    padding-right: 25px;
                    margin-bottom: 20px;
                    p{
                      border-top: 1px solid $purple;
                      padding-top: 10px;
                      font-size: 18px;
                      color: #000;
                    }
                  }
                  .link-read-more{
                    width: calc(100% - 427px);
                    float: right;
                    a{
                      width: unset;
                      border:1px solid $orange;
                      background-color: $orange;
                      color: #ffffff;
                      font-weight: 700;
                      padding: 5px 13px;
                      border-radius: 8px;
                      font-size: 18px;
                      text-transform: uppercase;

                    }
                  }
                }
                @include breakpoint(1054px){
                  .blog-post-preview{
                    @include prefix(0.5);
                    @include suffix(0.5);
                    img{
                      height: 270px;
                    }
                  }
                }
                @include breakpoint(931px){
                  .blog-post-preview{
                    @include prefix(0.5);
                    @include suffix(0.5);

                    img{
                      width: 40%;
                    }
                    a{
                      width: 60%;
                    }
                    .summary{
                      width: 60%;
                      margin-bottom: 0;
                    }
                    .link-read-more{
                      width: calc(60% - 27px);
                      a{
                        padding: 6px 13px;
                        font-size: 17px;
                      }
                    }
                  }
                }
                @include breakpoint(768px){
                  .blog-post-preview{
                    @include prefix(0.5);
                    @include suffix(0.5);
                    display: flex;
                    flex-flow: column;
                    a{
                      margin-bottom: 15px;
                      margin-top: 15px;
                      order: 2;
                      padding-left: 0;
                      width: 100%;
                    }
                    img{
                      width: 100%;
                      display: block;
                      order: 1;
                    }
                    .summary{
                      width: 100%;
                      margin-bottom: 0;
                      overflow: hidden;
                      order: 3;
                      padding-left: 0;
                      padding-right: 0;
                      p{
                       
                      }
                    }
                    .link-read-more{
                      width: 100%;
                      order: 4;
                      text-align: center;
                      a{
                        margin-top: 10px;
                      }
                    }
                  }
                }
                @include breakpoint(480px){
                  .blog-post-preview{
                    @include prefix(0);
                    @include suffix(0);
                  }
                }
              }
              .pager{
                width: 100%;
                li{
                  display: inline-block;
                }
                .pager-current{
                  color: $purple;
                  font-weight: 700;
                }
              }
            }
          }
        }
        @include breakpoint(480px){
          padding-left: 0;
          padding-right:0;
        }
      }
    }
  }
}