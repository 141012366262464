body.page-search-car{
  .l-main{
    @include breakpoint($smaller){
      @include gim_container2(340px, 0);
    }
    @include breakpoint($xs){
      max-width: none;
    }
  }
  #search-car-info{
    .block__content{
      line-height: 20px;
      padding-top: 7px;
      margin-bottom: 22px;
    }
    h3{
      display: none;
      @include breakpoint($lg){
        font-size: 23px;
      }
      @include breakpoint($md){
        height: 57px;
        margin: 5px 0 15px;
        font-size: 19px;
      }
      @include breakpoint($smaller){
        font-size: 16px;
      }
      color: $purple;
      font-size: 28px;
      line-height: 1.25;
      border-bottom: 1px solid $purple;
      height: 75px;
      margin: 20px 0 15px;
    }
    p{
      @include breakpoint($lg){
        font-size: 14px;
      }
      font-weight: 400;
    }
  }

  #block-rent-a-car-rent-a-car-type-select{
    .block__content{
      padding: 0;
    }
  }

  #rent-a-car-type-select-form{
    label,
    .form-checkboxes,
    .form-type-checkbox{
      display: inline-block;
    }
  }

  #car-search-view{
    $breakpoint-search: 1227px;
    padding-top: 22px;
    text-align: center;
    .view-empty{
      text-align: center;
    }
    .ajax-error-message{
      display: inline-block;
      text-align: center;
      padding: 10px 15px 10px 78px;
      position: relative;
      background-color: lighten(lightpink, 8%);
      color: darkred;
      border: 1px solid darkred;
      border-radius: 5px;
      font-size: 14px;
      line-height: 18px;
      min-height: 58px;
      max-width: 90%;
      &:before{
        content: '\f06a';
        font-family: 'FontAwesome';
        color: inherit;
        vertical-align: bottom;
        display: block;
        width: 48px;
        position: absolute;
        top: 50%;
        left: 10px;
        text-align: center;
        font-size: 48px;
        line-height: 1;
        transform: translate(0, -50%);
      }
    }
    .view-content{
      //@include breakpoint($breakpoint-search $md + 1px){
      //  @include prefix(1);
      //  @include suffix(1);
      //}
      //@include breakpoint($sm - 1px 600px){
      //  @include prefix(1.5);
      //  @include suffix(1.5);
      //}
      text-align: left;
    }
    .fleet-car{
      @include breakpoint($md){
        @include span(6 (gutters:0.08));
        margin-bottom: 1.59088%;
        padding: 0;
        min-height: 476px;
        @include breakpoint(min-width $sm){
          &:nth-child(2n){
            @include span(6 (gutters:0.08) last);
          }
        }
      }
      @include breakpoint($sm - 1px){
        @include span(12);
        min-height: unset;
      }
      width: 100%;
      border-radius: 15px;
      display: inline-block;
      border: 1px solid $purple;
      margin-bottom: 20px;
      transition: border-color 0.25s ease, box-shadow 0.25s ease;
      position: relative;
      padding: 3px 12px;
      padding-bottom: 16px;
      padding-right: 23px;
      &:hover{
        box-shadow: 0 0 15px rgba(4, 4, 4, 0.29);
        border-color: $orange;
      }
    }
    .title{
      @include span(8 (gutters:0.08) last);
      font-size: 28px;
      letter-spacing: -0.5px;
      font-weight: bold;
      color: purple;
      line-height: 30px;
      margin: 17px 0px 0 0px;
      padding-right: 126px;
      &.no-price{
        padding-right: 0;
      }
      @include breakpoint($md){
        font-size: 18px;
        margin-top: 14px;
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
      }
    }
    .body-summary{
      @include span(8 (gutters:0.08) last);
      font-size: 18px;
      color: #424242;
      line-height: 24px;
      margin: 17px 0px 0 0px;
      padding-right: 160px;
      min-height: 65px;
      overflow: hidden;
      position: relative;
      &.no-price{
        padding-right: 0;
      }
      @include breakpoint($md){
        font-size: 16px;
        margin-top: 14px;
        width: 100%;
        padding-left: 5px;
        padding-right: 5px;
      }
    }
    .info-bubble{
      @include breakpoint($md){
        top: 11px;
        right: 11px;
      }
      position: absolute;
      display: none;
      top: 14px;
      right: 12px;
      border-radius: 50%;
      &:before{
        content: "";
        position: absolute;
        display: inline-block;
        width: 0;
        height: 0;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-left: 8px solid $orange;
        right: 0px;
        bottom: -1px;
        transform: rotate(45deg);
      }
      &:hover{
        .tooltip{
          visibility: visible;
          opacity: 1;
          top: 42px;
          z-index: 9;
        }
      }
      .tooltip{
        position: absolute;
        top: 70px;
        left: 50%;
        transform: translate(-50%, 0);
        visibility: hidden;
        opacity: 0;
        display: inline-block;
        background-color: white;
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
        z-index: -1;
        transition: top 0.5s ease, opacity 0.5s ease;
        min-width: 120px;
        padding: 5px;
        border-radius: 7px;
        border: 1px solid $purple;
        font-size: 12px;
        font-weight: 300;
        color: black;
        &:before{
          content: '';
          position: absolute;
          display: block;
          top: -8px;
          left: 50%;
          transform: translate(-50%, 0);
          border-width: 0 4px 4px;
          border-style: solid;
          border-color: $purple transparent;
          height: 4px;

        }
      }
      a{
        @include breakpoint($md){
          width: 28px;
          height: 28px;
        }
        display: inline-block;
        font-size: 0;
        color: transparent;
        background-color: $orange;
        position: relative;
        width: 38px;
        height: 37px;
        border-radius: 50%;
        vertical-align: bottom;
        transition: background-color 0.25s ease;
        &:hover{
          background-color: $purple;
        }
        &:after{
          @include breakpoint($breakpoint-search){
            line-height: 28px;
            font-size: 18px;
          }
          @include breakpoint($smaller){
            line-height: 38px;
            font-size: 24px;
          }
          content: '\f129';
          font-family: 'FontAwesome';
          font-size: 24px;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          display: block;
          text-align: center;
          line-height: 37px;
          color: white;
        }
      }
    }
    .image{
      text-align: center;
      @include span(3.9 (gutters:0.08));
      img{
        margin: 5px 0;
        vertical-align: bottom;
        max-width: 99%;
        height: auto;
      }
      @include breakpoint($md){
        width: 100%;
        margin-right: 0;
      }
    }
    .car-features{
      @include span(8 (gutters:0.08) last);
      padding: 0 7px;
      margin-top: 0px;
      @include breakpoint($md){
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
      }
      .features_price {
        width: calc(100% - 220px);
        margin-right: 10px;
        float: left;
        display: inline-block;
        @include breakpoint(1390px) {
          width: calc(100% - 180px);
        }
        @include breakpoint($md){
          width: 100%;
          margin-bottom: 20px;
        }
        .features_scroll {
          overflow: auto;
          white-space: nowrap;
          &::-webkit-scrollbar-thumb {
            background-color: $orange;
            outline: 1px solid #45586d;
            border-radius: 5px;
            border: 2px solid $purple;

          }
          &::-webkit-scrollbar {
            max-height: 14px;
          }
          &::-webkit-scrollbar-track {
            border: 1px solid $purple;
            border-left: none;
            border-right: none;
          }
          &::-webkit-scrollbar-button {
            background-size: 10px 10px;
            background-repeat: no-repeat;
            background-position: center center;
            height: 16px;
            width: 1em;
            transform: rotate(45deg);
          }
          &::-webkit-scrollbar-button:end:increment {
            background-image: url('/sites/all/themes/omgsub/images/Arrow_RIGHT.svg');
            border: 1px solid $purple;
            border-left: none;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
          &::-webkit-scrollbar-button:start:decrement {
            background-image: url('/sites/all/themes/omgsub/images/Arrow_LEFT.svg');
            border: 1px solid $purple;
            border-right: none;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }
          .feature{
            font-size: 12px;
            margin-bottom: 0;
            text-align: left;
            display: inline-block;
            width: 13%;
            min-width: 48px;
            @include breakpoint($lg $md +1){
              font-size: 11px;
            }
          }
        }
      }
      .book-button-form {
        width: 210px;
        float: right;
        position: relative;
        @include breakpoint($md){
          float: none;
          margin: 0 auto;
          clear: both;
        }
        span {
          width: 100%;
          display: inline-block;
        }
        &:before {
          content: "";
          position: absolute;
          display: inline-block;
          width: 14%;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          height: 100%;
          left: 0;
          background-color: #0a5dcc;
          z-index: 999;
        }
        &:after {
          content: "";
          position: absolute;
          display: inline-block;
          width: 0;
          height: 0;
          border-top: 12px solid transparent;
          border-bottom: 12px solid transparent;
          border-left: 13px solid #0a5dcc;
          left: 14%;
          top: 50%;
          transform: translateY(-50%);
        }
        @include breakpoint(1390px) {
          width: 170px;
        }
      }
      .book-button {
        @include breakpoint(1390px) {
          font-size: 18px;
          padding: 20px 47px 20px 51px;
        }
        @include breakpoint(1100px $md +1) {
          font-size: 18px;
          padding: 20px 32px 20px 51px;

        }
        @include breakpoint($md) {
          font-size: 17px;
        }
        @extend .button;
        padding: 19px 55px 19px 54px;
        border: none;
        cursor: pointer;
        background-color: $purple;
        font-weight: 700;
        border-radius: 5px;
        width: 100%;
        position: relative;
        color: $orange;
        font-size: 25px;
        text-transform: uppercase;
        &:before,
        &:after {
          content: ">";
          position: absolute;
          display: inline-block;
          width: 16px;
          height: 24px;
          color: $orange;
          top: 50%;
          font-size: 26px;
          transform: translateY(-57%);
          z-index: 999;
          font-weight: 700;
        }
        &:before {
          right: 13px;
        }
        &:after {
          right: 30px;

        }
        @include breakpoint(1390px) {
          &:before,
          &:after {
            font-size: 20px;
            transform: translateY(-43%);
          }
          &:before {
            right: 6px;
          }
          &:after {
            right: 22px;
          }
        }
        @include breakpoint($md) {
          &:before,
          &:after {
          }
          &:before {
            right: 17px;
          }
          &:after {
            right: 33px;
          }
        }
        &:hover {
          background-color: $orange;
          color: $purple;
          &:before,
          &:after {
            color: $purple;
          }
        }
      }
    }
    .section-title{
      @include breakpoint($md){
        font-size: 14px;
        padding-left: 5px;
      }
      //@include breakpoint($smaller){
      //  font-size: 15px;
      //}
      border-width: 1px 0;
      //border-color: $grey;
      //border-style: solid;
      color: #000;
      font-weight: 400;
      text-align: left;
      padding: 5px 0 8px;
      margin: 0 -6px 17px;
      font-size: 24px;
      margin-top: 20px;
    }
    .prices{
      margin-top: 0;
      padding-top: 0;
      position: absolute;
      top: 12px;
      right: 25px;
      @include span(2.9 (gutters:0.2) last);
      .book-button-form{
        width: 100%;
      }
      .main-price{
        display: block;
        text-align: right;
        color: $purple;
        font-weight: 900;
        font-size: 36px;
        line-height: 1;
        margin-bottom: 6px;
        @include breakpoint($smaller){
          font-size: 26px;
          top: 8px;
        }
      }
      @include breakpoint($md){
        padding-bottom: 0;
        text-align: center;
        width: 100%;
        position: relative;
        top: unset;
        left: unset;
        right: unset;
        .main-price{
          text-align: center;
        }
      }
    }
    .perdaycharge{
      color: $purple;
      display: inline-block;
      position: absolute;
      top: 70px;
      right: 25px;
      @include breakpoint($md){
        width: 100%;
        display: inline-block;
        text-align: center;
        margin-top: 5px;
        margin-bottom: 5px;
        position: relative;
        top: unset;
        left: unset;
        right: unset;
      }
    }
    .sendofferpopup{
      @include span(2.9 (gutters:0.2) last);
      margin-top: 19px;
      position: relative;
      color: transparent;
      &:before{
        content: "";
        position: absolute;
        width: 42px;
        height: 40px;
        background-image: url('/sites/all/themes/omgsub/images/HEART_ICON.svg');
        background-repeat: no-repeat;
        right: -1px;
        display: none;
        top: 50%;
        transform: translateY(-50%);
      }
      a{
        display: inline-block;
        max-width: 191px;
        width: 100%;
        color: $purple;
        //background-color: $orange;
        background-color: transparent;
        //font-size: 20px;
        font-size: 0;
        font-weight: 700;
        padding-left: 10px;
        line-height: 30px;
        transition: 0.4s ease;
        pointer-events: none;
      }
      @include breakpoint(1390px){
        &:before{
          transform: translateY(-43%);
          background-size: 36px;
          right: -21px;
        }
        a{
          //font-size: 16px;
        }
      }
      @include breakpoint($md){
        width: 100%;
        float: unset;
        display: block;
        overflow: hidden;
        max-width: 186px;
        margin: auto;
        padding-top: 20px;
        padding-bottom: 8px;
        display: none;
      }
    }
    .secondary-prices{
      @include breakpoint($md){
        font-size: 12px;
      }
      @include breakpoint($smaller){
        font-size: 15px;
      }
      display: block;
      text-align: center;
      font-weight: 300;
      line-height: 1;
    }
  }
}

