body.front{
  #car-search-front{
    @include breakpoint($sm){
      position: static;
      transform: unset;
      background-color: $light_grey;
      padding: 12px 0 31px;
      padding-bottom: 0;
      .rent-a-car-block-view-search{
        @include breakpoint($sm){
          max-width: 400px;
          margin: auto;
          display: block;
        }
      }
    }
    position: absolute;
    z-index: 5;
    bottom: 22%;
    @include breakpoint($md){
      bottom: 4%;
    }
    @include breakpoint($xs){
      position: relative;
      top:auto;
      left:auto;
      right: auto;
      bottom:auto;
      padding-top: 0;
    }
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    &:hover ~ .slider-home{
      .flex-prev{
        left: 10px;
      }
      .flex-next{
        right: 10px;
      }
    }
    .block__title{
      @include breakpoint($lg){
        font-size: 16px;
        padding: 11px 22px;
      }
      @include breakpoint($sm){
        //font-size: 12px;
        //padding: 6px 9px 5px;
        //border-radius: 6px 6px 0 0;
        padding: 11px 9px 11px;
        border-radius: 6px 6px 0 0;
        font-size: 18px;
        box-shadow: 0px 0px 7px 2px #fff;
        top: -52px;
        z-index: 999;
      }
      @include breakpoint($xs){
        top: -40px;
      }
      font-size: 24px;
      font-weight: 700;
      color: $orange;
      padding: 14px 35px 15px;
      line-height: 1;
      background-color: $purple;
      border-radius: 12px 12px 0 0;
      text-align: center;
      max-width: 219px;
      margin: 0 auto;
      position: relative;
      top: 1px;
      left: 10px;
      display: inline-block;
      width: 100%;
    }
    .block__content{
      position: relative;

      @include breakpoint(1100px){
        width: 678px;
        padding: 20px 10px 31px;
      }
      @include breakpoint($sm){
        width: unset;
        background-color: rgba(255, 255, 255, 0.85);
        border-radius: 4px;
        @include gim_container2(280px, 20px);
        padding: 10px 13px;
      }
      background-color: rgba(255, 255, 255, 0.8);
      background-clip: padding-box;
      border: 1px solid rgba($purple, 0.1);
      border-radius: 25px;
      padding: 22px 30px 34px 26px;
      text-align: center;
      width: 1160px;
      @include breakpoint($lg){
        width: 1000px;
      }
      @include breakpoint($md){
        width: 730px;
      }
      @include breakpoint($sm){
        max-width: unset;
        width: 100%;
        margin-top: -52px;
        border-radius: unset;
      }
      @include breakpoint($xs){
        margin-top: -40px;

      }
      @include breakpoint(320px){
        margin-left: 0px;
      }
    //  width: 100%;
      display: block;
     // width: max-content;
      *{
        text-align: left;
      }
      >form{
        display: block;

        >div{
          @include breakpoint($lg){
            text-align: center;
          }
        }
      }
    }
    .form-type-checkboxes {
      @include breakpoint($sm) {
        text-align: center;
      }
      &.form-item-car-type{
        margin-bottom: 13px;
        @include breakpoint($md){
          margin-bottom: 5px;
        }
        #edit-car-type{
          margin-bottom: 3px;
          display: inline-block;
          @include breakpoint($md){
            width: 100%;
            max-width: 440px;
            margin: auto;
            margin-top: 7px;
            display: none;
            @include breakpoint(min-width $sm +1){
              .form-item{
                @include span(1 of 3 (gutters:0.08));
                &:nth-child(3n){
                  @include span(1 of 3 (gutters:0.08) last);
                }
              }
            }
            @include breakpoint($sm){
              max-width: 282px;
              .form-item{
                @include span(1 of 2 (gutters:0.08));
                &:nth-child(2n){
                  @include span(1 of 2 (gutters:0.08) last);
                }
              }
            }
          }
        }
      }
      &.form-item-gearbox {
        margin-bottom: 26px;
        @include breakpoint($md){
          margin-bottom: 18px;
        }
        @include breakpoint($sm){
          margin-bottom: 10px;
        }
        .form-checkboxes {
          .form-type-checkbox:last-child {
            .option {
              text-transform: uppercase;
            }
          }
        }
      }

      display: block;
      overflow: hidden;

      > label {
        font-weight: 700;
        font-size: 16px;
        text-transform: uppercase;
        color: $lblack;

        &[for='edit-car-type'] {
          margin-right: 44px;
          word-spacing: -3px;
          position: relative;
          @include breakpoint(min-width $md){
            &:after{
              content: ":";
              position: absolute;
              display: inline-block;
              right: -7px;
              color: #000;
              top: -4px;
            }
          }
          @include breakpoint($md){
            width: 90px;
            float: none;
            margin: auto;
            display: block;
            position: relative;
            &:before{
              content: "\f107";
              position: absolute;
              font-family: "FontAwesome";
              display: inline-block;
              color: $purple;
              width: 20px;
              height: 20px;
              font-size: 26px;
              right: -13px;
              top: 50%;
              transform: translateY(-57%);
              z-index: 88;
            }
            &.active{
              &:before{
                content: "\f106";
                transform: translateY(-63%);
              }
            }
            //margin-right: 20px;
          }
        }

        &[for='edit-gearbox'] {
          word-spacing: 1px;
          letter-spacing: -0.9px;
          margin-right: 17px;
          position: relative;
          top: 2px;
          position: relative;

          @include breakpoint(min-width $md){
            &:after{
              content: ":";
              position: absolute;
              display: inline-block;
              right: -4px;
              color: #000;
              top: -4px;
            }
          }
          @include breakpoint($md){
            display: block;
            margin: auto;
            float: none;
            max-width: 124px;
            position: relative;
            &:before{
              content: "\f107";
              position: absolute;
              font-family: "FontAwesome";
              display: inline-block;
              color: $purple;
              width: 20px;
              height: 20px;
              font-size: 26px;
              right: -19px;
              top: 50%;
              transform: translateY(-57%);
              z-index: 88;
            }
            &.active{
              &:before{
                content: "\f106";
                transform: translateY(-63%);
              }
            }
          }
          @include breakpoint($sm){
            //width: 106px;
            //float: left;
          }
        }

        @include breakpoint($sm) {
          display: block;
          text-align: center;
          font-size: 14px;
        }
      }
      #edit-gearbox{
        //@include breakpoint($sm){
        //  width: calc(100% - 123px);
        //  float: right;
        //}
        @include breakpoint($md){
          display: none;
          width: 100%;
          max-width: 440px;
          margin: auto;
          margin-top: 7px;
          @include breakpoint($sm){
            max-width: 282px;
            .form-item{
              @include span(1 of 2 (gutters:0.08));
              &:nth-child(2n){
                @include span(1 of 2 (gutters:0.08) last);
              }
            }
          }
        }
      }

      label {
        &.option {
          font-weight: 400;
          text-transform: none;
          font-size: 16px;
          color: $lblack;
          line-height: 1.5;
          letter-spacing: 0.03em;

          &:before {
            margin-right: 5px;
          }
        }
      }

      .form-type-checkbox{
        input:checked + label{
          &:before{
            @include breakpoint($lg){
              background-color: $purple;
            }
          }
        }
        > label{
          @include breakpoint($lg){
            padding-left: 16px;
            margin-right: 0;
          }
          &:before{
            @include breakpoint($lg){
              width: 10px;
              height: 10px;
              top: 5px;
              background-color: rgba(white, 0.5);
            }
          }
          &:after{
            @include breakpoint($lg){
              width: 4px;
              height: 4px;
              top: 9px;
              left: 4px;
            }
          }
        }
      }
    }
    .form-type-select{
      @include breakpoint($sm){
        @include span(12);
        margin-bottom: 6px;
      }
      @include breakpoint($lg){
        width: 172px;
      }
      @include breakpoint($md){
        width: 229px;
        margin-bottom: 7px;
        &.form-item-dropoff-location{
          margin-left: 6px;
        }
      }
      @include breakpoint($sm){
        width: 100%;
        &.form-item-dropoff-location{
          margin-left: 0px;
        }
      }
    }
    .form-item-pickup-date,
    .form-item-dropoff-date{
      @include breakpoint($sm){
        @include span(8 (gutters:0));
        margin-bottom: 6px;
      }
    }
    .form-item-pickup-time,
    .form-item-dropoff-time{
      margin-left: -6px;
      @include breakpoint($sm){
        @include span(4 (gutters:0) last);
        margin-bottom: 6px;
      }
    }
    .form-item-pickup-time{
      margin-right: 0;
    }
    .form-type-textfield{
      input{
        @include breakpoint($lg){
          //font-size: 12px;
          //line-height: 26px;
          //background-size: auto 70%;
        }
        &#edit-pickup-date,
        &#edit-dropoff-date{
          @include breakpoint($lg){
            //font-size: 12px;
            //line-height: 26px;
            //background-size: auto 70%;
            //width: 110px;
          }
          @include breakpoint($sm){
            width: 100%;
          }
        }
        &#edit-pickup-time,
        &#edit-dropoff-time{
          @include breakpoint($lg){
            //padding: 0 32px 0 4px;
            //width: 70px;
            //height: 28px;
          }
          @include breakpoint($sm){
            width: 100%;
          }
        }
      }
      @include breakpoint($md){
        margin-bottom: 7px;
      }
    }
    select,
    .select-title{
      @include breakpoint($lg){
        //font-size: 12px;
        //line-height: 26px;
        //height: 28px;
        //background-size: auto 60%;
        //width: 115px;
        //padding: 0 25px 0 5px;
        width: 172px;
      }
      @include breakpoint($md){
        width: 229px;
      }
      @include breakpoint($sm){
        width: 100%;
      }
    }
    .block-select{
      @include breakpoint($lg){
        //height: 28px;
      }
      >ul{
        top: 0;
        li{
          label{
            padding: 8px 15px 10px;
            line-height: 20px;
            transition: background-color 0.25s ease, color 0.25s ease;
            @include breakpoint($lg){
              font-size: 12px;
              line-height: 1.333;
              padding: 5px 15px 6px;
            }
          }
        }
      }
    }
    .arrows {
      .form-submit {
        @include breakpoint($lg) {
          font-size: 18px;
          line-height: 1;
          font-weight: 400;
        }
        @include breakpoint($md) {
          display: block;
          margin: auto;
        }
        @include breakpoint($sm) {
          display: block;
          margin: 2px auto 0;
          width: 100%;
          text-align: center;
        }
      }
      @include breakpoint($lg) {
        margin: 0;
      }
      @include breakpoint($md) {
        margin-bottom: 7px;
      }
      @include breakpoint($sm) {
        margin: 2px auto 0px;
        clear: both;
      }
    }
  }
  #flexslider-1{
    border: none;
    background-color: initial;
    margin-bottom: 0;
    overflow: hidden;
    .slider-image{
      @include breakpoint($md){
        height: 421px;
      }
      @include breakpoint($xs){
        //height: 122px;
        //min-height: 122px;
        height: 180px;
        min-height: 180px;
      }
      @include breakpoint($sm $xs +1){
        height: 100%;
        min-height: 330px;
      }
      max-height: 699px;
      min-height: 560px;
      height: calc(100vh - 149px);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      h2{
        @include breakpoint($md){
          padding-top: 62px;
          font-size: 48px;
        }
        @include breakpoint($xs){
          font-size: 25px;
          padding-top: 35px;
        }
        @include breakpoint($sm){
          max-width: 400px;
          text-align: center;
        }
        padding-top: 90px;
        font-size: 50px;
        font-weight: 400;
        width: 100%;
        max-width: 749px;
        margin: auto;
        letter-spacing: 0.7px;
        color: $dblue;
        text-shadow: 1px 2px 5px #f3f3f3, 1px 1px 4px #f3f3f3;
       // text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
      }
    }
    ol.flex-control-nav{
      display: none;
    }
    ul.flex-direction-nav{
      height: 0;
      li {
        display: inline-block;
        &.flex-nav-next{
          float: right;
        }
        a {
          @include breakpoint($md){
            height: 32px;
            width: 32px;
            top: 32%;
          }
          @include breakpoint($sm){
            display: none;
          }
          top: 38%;
          background: $purple;
          height: 48px;
          width: 48px;
          text-align: center;
          color: transparent;
          display: block;
          opacity: 1;
          font-size: 0;
          @include borderradius(50%);
          &:before{
            @include breakpoint($md){
              line-height: 32px;
              font-size: 28px;
            }
            font-family: "FontAwesome";
            text-shadow: none;
            color: #fff;
            line-height: 48px;
            opacity: 1;
            transition: color 0.25s ease;
            vertical-align: bottom;
          }
          &.flex-prev{
            &:before {
              content: '\f104';
              bottom: 0;
              left: -2px;
              position: relative;
            }
          }
          &.flex-next{
            &:before {
              content: '\f105';
              bottom: 0;
              position: relative;
              left: 1px;
            }
          }
          &:hover{
            &:before {
              color: $orange;
            }
          }
        }
      }
    }
  }
  #icon-blocks {
    @include clearfix;
    @include breakpoint($md){
      margin-bottom: 80px;
      .slick-arrow{
        position: absolute;
        bottom: -160px;
        font-size: 0;
        background-color: transparent;
        border: none;
        &:before{
          display: block;
          font-family: "FontAwesome";
          text-shadow: none;
          line-height: 34px;
          font-size: 56px;
          color: $purple;
        }
        &.slick-prev{
          left: calc(50% - 40px);
          &:before{
            content: '\f104';

          }
        }
        &.slick-next{
          right: calc(50% - 40px);
          &:before{
            content: '\f105';
          }
        }
      }
    }
    .block__content {
      width: 100%;

      @include breakpoint($sm){
        padding: 0;
      }

      div {
        @include span(2 (gutters: 0));
        text-align: center;
        padding: 20px;
        border-right: 3px solid white;
        background-color: $icon-block;
        height: 95px;
        position: relative;
        @include breakpoint($md){
          height: auto;
          max-height: 110px;
          border-right: none;
        }

        &:last-child {
          border-right: none;
        }
        p {
          float: right;
          max-width: 138px;
          position: relative;
          top: 50%;
          transform: translateY(-50%);
          text-align: left;
          line-height: 1.1;
          margin-bottom: 0;
        //  font-family: 'Roboto', sans-serif;
          font-size: 20px;
          letter-spacing: 2px;
          text-transform: uppercase;
          @include breakpoint(1600px){
            font-size: 18px;
          }
        }

        &:before {
          content: '';
          display: block;
          float: left;
          margin-right: 13%;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }

        &.insurance{
          &:before {
          //  background-image: url('/sites/all/themes/omgsub/images/24hours.svg');
            @extend .svg-icon1;
            @extend .svg-icon1-dims;
            position: absolute;
            left: 9%;
            transform: translateY(-46%);
          }
        }
        &.service{
          &:before {
            @extend .svg-icon2;
            @extend .svg-icon2-dims;
            left: 8%;
          //  background-image: url('/sites/all/themes/omgsub/images/car.svg');
          }
        }
        &.freekm {
          &:before {
            @extend .svg-icon3;
            @extend .svg-icon3-dims;
            left: 6%;
         //   background-image: url('/sites/all/themes/omgsub/images/credit.svg');
          }
        }
        &.freedelivery{
          &:before{
            @extend .svg-icon4;
            @extend .svg-icon4-dims;
            left: 8%;
           // background-image: url('/sites/all/themes/omgsub/images/free_cancelation.svg');
          }
        }
        &.finalprices{
          &:before{
            @extend .svg-icon5;
            @extend .svg-icon5-dims;
            left: 7%;
            // background-image: url('/sites/all/themes/omgsub/images/free_cancelation.svg');
          }
        }
        &.hiddencharges{
          &:before{
            @extend .svg-icon6;
            @extend .svg-icon6-dims;
            left: 7%;
            // background-image: url('/sites/all/themes/omgsub/images/free_cancelation.svg');
          }
        }
        @include breakpoint($md){
          &.insurance{
            &:before {
              transform: translateY(-50%)scale(0.6);
              left: 2%;
            }
          }
          &.service{
            &:before {
              transform: translateY(-50%)scale(0.6);
              left: 2%;

            }
          }
          &.freekm {
            &:before {
              transform: translateY(-50%)scale(0.6);
              left: 2%;
            }
          }
          &.freedelivery{
            &:before{

              transform: translateY(-50%)scale(0.6);
              left: 2%;

            }
          }
          &.finalprices{
            &:before{

              transform: translateY(-50%)scale(0.6);
              left: 2%;

            }
          }
          &.hiddencharges{
            &:before{

              transform: translateY(-50%)scale(0.6);
              left: 2%;

            }
          }
        }
        @include breakpoint($sm){
          &.insurance{
            &:before {

            }
          }
          &.service{
            &:before {


            }
          }
          &.freekm {
            &:before {
              left: -14px;
            }
          }
          &.freedelivery{
            &:before{


            }
          }
          &.finalprices{
            &:before{


            }
          }
          &.hiddencharges{
            &:before{


            }
          }
        }
        @include breakpoint($xs){
          &.insurance{
            &:before {

            }
          }
          &.service{
            &:before {


            }
          }
          &.freekm {
            &:before {
              left: -4px;
            }
          }
          &.freedelivery{
            &:before{


            }
          }
          &.finalprices{
            &:before{


            }
          }
          &.hiddencharges{
            &:before{


            }
          }
        }

      }
      .insurance{
        //padding-left: 31px;
        p{
          position: absolute;
          left: 42%;
        }
        &:before{
          left:12px;
          margin-right: 19%;
        }
      }
      .freekm{
        p{
          position: absolute;
          left: 38%;
          @include breakpoint(1600px){
            left: 34%;
          }
        }
        &:before{
          margin-right: 12%;
        }
      }
      .service{
        padding-left: 27px;
        @include breakpoint($md){
          padding-left: 20px;
        }
        p{
          position: absolute;
          left: 37%;
        }
      }
      .freedelivery,
      .finalprices{
        padding-left: 30px;
        padding-top: 25px;
        @include breakpoint($md){
          padding: 20px;
        }
        p{
          position: absolute;
          left: 33%;
        }
      }
      .hiddencharges{
        padding-left: 35px;
        @include breakpoint($md){
          padding-left: 20px;
        }
        p{
          position: absolute;
          left: 33%;
        }
      }
      @include breakpoint(1390px){
        .insurance{
          p{
            left: 50%;
            transform: translate(-50%,-50%);
          }
          &:before{

          }
        }
        .freekm{
          p{
            left: 50%;
            transform: translate(-50%,-50%);
          }
          &:before{

          }
        }
        .service{

          p{
            left: 50%;
            transform: translate(-50%,-50%);
          }
        }
        .freedelivery,
        .finalprices{
          p{
            left: 50%;
            transform: translate(-50%,-50%);
          }
        }
        .hiddencharges{
          p{
            left: 50%;
            transform: translate(-50%,-50%);
          }
        }
      }
      @include breakpoint($sm 479px){
        .insurance{
          p{
            transform: translate(-38%,-50%);
          }
          &:before{

          }
        }
        .freekm{
          p{
            transform: translate(-44%,-50%);
          }
          &:before{

          }
        }
        .service{

          p{
            transform: translate(-35%,-50%);
          }
        }
        .freedelivery,
        .finalprices{
          p{
            transform: translate(-35%,-50%);
          }
        }
        .hiddencharges{
          p{
            transform: translate(-35%,-50%);
          }
        }
      }
    }
    @include breakpoint(1390px $md + 1){
      .block__content {
        > div {
          @include span(1 of 3 (gutters: 0));

          &:nth-child(3n){
            @include span(1 of 3 (gutters: 0) last);
            border-right: none;
          }
          margin-bottom: 2px;
        }
      }
    }
    @include breakpoint($md ){
      .block__content {
        > div {
          width: 100%;

          &:nth-child(3n){

          }

        }
      }
    }
    @include breakpoint($sm){
      .block__content {
        > div {


          &:nth-child(2n){

          }
          margin-bottom: 0px!important;
        }
      }
    }
    //@include breakpoint(940px 481px){
    //  .block__content {
    //    > div {
    //      @include span(1 of 2 (gutters: 0));
    //
    //      &:nth-child(2n){
    //        @include span(1 of 2 (gutters: 0) last);
    //        border-right: none;
    //      }
    //      margin-bottom: 2px;
    //    }
    //  }
    //}
    //@include breakpoint($lg 1023px){
    //  .block__content {
    //    > div {
    //      @include span(4.2 (gutters: 0));
    //      &.fleet,
    //      &.money,
    //      &.free{
    //        @include span(2.6 (gutters: 0));
    //      }
    //    }
    //  }
    //}
    //@include breakpoint($md $sm + 1px){
    //  .block__content {
    //    padding: 0;
    //    > div {
    //      padding: 10px;
    //      p{
    //        font-size: 14px;
    //        letter-spacing: -0.5px;
    //        height: 3.6em;
    //      }
    //      @include span(3 (gutters: 0));
    //      &.fleet,
    //      &.money,
    //      &.free{
    //        @include span(3 (gutters: 0));
    //      }
    //    }
    //  }
    //}
    //@include breakpoint($sm $xs + 1px){
    //  .block__content{
    //    >div{
    //      @include span(6 (gutters: 0));
    //      text-align: center;
    //      border: 1px solid $grey;
    //      border-bottom: none;
    //      display: table;
    //      table-layout: fixed;
    //      padding: 5px 10px;
    //      &:nth-child(2n + 1){
    //        border-right: none;
    //      }
    //      &:before{
    //        display: table-cell;
    //        width: 55px;
    //        height: 35px;
    //      }
    //      p{
    //        font-size: 14px;
    //        margin-top: 0;
    //        //height: 3.6em;
    //        display: table-cell;
    //        padding-left: 10px;
    //        vertical-align: middle;
    //        letter-spacing: -1px;
    //      }
    //    }
    //  }
    //}
    //@include breakpoint($xs){
    //  .block__content {
    //    > div {
    //      @include span(12);
    //      border: 1px solid $grey;
    //      border-bottom: none;
    //      padding: 10px 20px;
    //      text-align: center;
    //      display: table;
    //      table-layout: fixed;
    //      &:before{
    //        display: table-cell;
    //        width: 71px;
    //        height: 39px;
    //      }
    //      p{
    //        margin-top: 0;
    //        //height: 3.6em;
    //        height: auto;
    //        display: table-cell;
    //        font-size: 15px;
    //        padding-left: 15px;
    //        vertical-align: middle;
    //      }
    //      &.fleet,
    //      &.money,
    //      &.free{
    //        @include span(12);
    //      }
    //    }
    //  }
    //}
  }
}