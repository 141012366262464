body.page-node-218{
  .l-main{
    .l-content{
      article{
        .node__content{
          .field__item{
            > h2:first-child{
              @include breakpoint($md){
                font-size: 32px;
                margin-top: 7px;
                margin-bottom: 30px;
                line-height: 1.1;
              }
              color: black;
              border-bottom: none;
              font-weight: 300;
              font-size: 40px;
              margin: 17px 0 7px;
            }
          }
        }
      }
    }
  }
  .simple-page-section{
  }
  h3.section-title{
    color: $purple;
    font-weight: 300;
    font-size: 29px;
    line-height: 1.4;
    margin-top: -10px;
  }
}