$container: 1327px;

/*$blue: #3f79bd;
$dblue: #205AA8;
$purple: #0F4B97;
$orange: #f5822a;
$light_grey: #f3f3f3;
$grey: #c7c9ca;
$yellow_grey: #eeefea;
$dark_grey: #8f9080;
$sidebar_grey: #eeeeeb;
$line-grey: #eeefef;
$background: $light_grey;*/

// Artemis

$black: #000000;
$lblack: #070011;
$blue: #1f5aa9;
$lightblue: #3d7bcc;
$dblue: #0a4396;
$orange: #ffec00;
$light-grey: #f3f8fb;
$white: #ffffff;
$icon-block: #f3f3f4;
$block-title: #0050a3;

// subject to change
$grey: #c7c9ca;
$yellow_grey: #eeefea;
$dark_grey: #8f9080;
$sidebar_grey: #eeeeeb;
$line-grey: #eeefef;
$background: $light_grey;
$purple: #064599;


*{
  box-sizing: border-box;
}

html{
  font-family: 'M PLUS Rounded 1c', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p{
  margin-top: 0;
}

a {
  color: $purple;
  text-decoration: inherit;
  transition: color 0.25s ease;
  &:hover {
    color: lighten($purple, 8%);
  }
}


@keyframes slide {
  0%,
  100% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(10px, 0);
  }
}
