/* front page */
.search_box{
  h2.block__title{

  }
  .block__content{
    padding: 10px 0 20px;
    form{
      > div{
        select{
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          //line-height: 36px;
          //width: 164px;
        }
        select,
        .select-title{
          color: #000006;
          font-size: 16px;
          font-weight: 400;
          border: 0.5px solid rgba($purple, 0.25);
          //  background: url('/sites/all/themes/omgsub/images/location_icon.svg') no-repeat calc(100% - 10px) center white;
          background: white;
          padding: 0 35px 0 15px;
          cursor: pointer;
          text-align: left;
          line-height: 37px;
          display: inline-block;
          height: 39px;
          width: 236px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          @include borderradius(5px);
          position: relative;

          &:after {
            content: '';
            @extend .svg-location_icon;
            @extend .svg-location_icon-dims;
          }
        }
        .form-item{
          margin-bottom: 0;
          &.form-item-car-type{
            margin-bottom: 16px;
          }
          &.form-item-gearbox{
            margin-bottom: 30px;
          }
        }
        .block-select-edit-pickup-location{
          .select-title{
            &:before{
              content: "\f107";
              position: absolute;
              font-family: "FontAwesome";
              display: inline-block;
              color: $purple;
              width: 20px;
              height: 20px;
              font-size: 26px;
              right: 10px;
              top: 50%;
              transform: translateY(-85%);
              z-index: 88;
            }
          }
          &.open{
            .select-title{
              &:before{
                content: "\f106";
              }
            }
          }
        }
        .form-item:not(.form-item-car-type, .form-item-gearbox) {
          display: inline-block;
          margin-right: 2px;
          vertical-align: bottom;
          &.form-item-pickup-date,
          &.form-item-dropoff-date {
            margin-right: 0;
          }
          .block-select{
            position: relative;
            height: 39px;
            /* width */
            ::-webkit-scrollbar {
              width: 4px;
            }
            /* Track */
            ::-webkit-scrollbar-track {
              background: transparent;
            }
            /* Handle */
            ::-webkit-scrollbar-thumb {
              background: $purple;
              @include borderradius(4px);
            }
            /* Handle on hover */
            ::-webkit-scrollbar-thumb:hover {
              background: $purple;
            }
            .locations-dropdown-container{
              height: 0;
              visibility: hidden;
              opacity: 0;
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              z-index: 99;
              transition: visibility 0.25s ease, opacity 0.25s ease;
              ul{
                border: 1px solid $purple;
                border-bottom: none;
                overflow-x: hidden;
                overflow-y: auto;
                width: 100%;
                padding: 0;
                margin: 0;
                background: white;
                border-radius: 5px 5px 0 0;
                max-height: 242px;
                margin-top: 39px;
                border-top: none;
                li{
                  list-style: none;
                  &.location-group-name{
                    padding: 8px 5px 10px;
                    line-height: 20px;
                    color: black;
                    letter-spacing: -1px;
                    position: relative;
                    //&:before{
                    //  content: "\f107";
                    //  position: absolute;
                    //  font-family: "FontAwesome";
                    //  display: inline-block;
                    //  color: $purple;
                    //  width: 20px;
                    //  height: 20px;
                    //  font-size: 26px;
                    //  right: 10px;
                    //  top: 50%;
                    //  transform: translateY(-50%);
                    //}
                  }
                  &.hidden{
                    display: none;
                  }
                  input{
                    visibility: hidden;
                    opacity: 0;
                    height: 0;
                    position: absolute;
                  }
                  label{
                    padding: 8px 15px 10px;
                    display: inline-block;
                    width: 100%;
                    font-size: 16px;
                    font-weight: 300;
                    line-height: 20px;
                    color: #000006;
                    cursor: pointer;
                    transition: background-color 0.25s ease, color 0.25s ease;
                    &:hover{
                      background: $purple;
                      color: white;
                    }
                  }
                  input:checked ~ label{
                    background: $purple;
                    color: white;
                  }
                }
              }
              .location-search-container{
                border-radius: 0 0 5px 5px;
                border: 1px solid $purple;
                border-top: none;
                position: relative;
                &:before{
                  content: '';
                  position: absolute;
                  top: 2px;
                  left: 8px;
                  right: 8px;
                  display: block;
                  height: 1px;
                  border-top: 1px solid $purple;
                }
                input{
                  border: none;
                  border-radius: 0 0 5px 5px;
                  padding: 0 14px;
                  line-height: 40px;
                  max-width: 100%;
                  width: 100%;
                  font-weight: 300;
                }
              }
            }
            &.open{
              .locations-dropdown-container{
                height: auto;
                visibility: visible;
                opacity: 1;
                ul{
                  @include transition;
                }
              }
            }
          }
        }
        .block-select-edit-dropoff-location{
          .select-title{
            &:before{
              content: "\f107";
              position: absolute;
              font-family: "FontAwesome";
              display: inline-block;
              color: $purple;
              width: 20px;
              height: 20px;
              font-size: 26px;
              right: 10px;
              top: 50%;
              transform: translateY(-85%);
              z-index: 88;
            }
          }
          &.open{
            .select-title{
              &:before{
                content: "\f106";
              }
            }
          }
        }

        .form-item-dropoff-date,
        .form-item-pickup-date {
          position: relative;

          &:before {
            content: '';
            @extend .svg-date_icon;
            @extend .svg-date_icon-dims;
            display: block;
            left: 10px;
            top: 50%;
            position: absolute;
            transform: translateY(-50%);
          }
        }

        .form-item.form-type-textfield{
          input {
            color: #000006;
            font-size: 16px;
            font-weight: 400;
            border: 0.5px solid rgba($purple, 0.25);
            //background: #FFFFFF;
            // background: url('/sites/all/themes/omgsub/images/date_icon.svg') no-repeat 8px center white;
            padding: 0 8px 0 41px;
            cursor: pointer !important;
            text-align: left;
            line-height: 37px;
            display: inline-block;
            border-radius: 5px 0 0 5px;
            border-right: none;
            width: 139px;
          }
          &.form-item-pickup-time,
          &.form-item-dropoff-time{
            position: relative;

            &:before {
              content: '';
              // todo: add time icon sprite
              display: block;
              position: absolute;
              right: 10px;
              top: 50%;
              transform: translateY(-50%) scale(0.7);
              @extend .svg-time_icon;
              @extend .svg-time_icon-dims;
            }

            span{
              position: absolute;
              height: 12px;
              width: 45px;
              right: 0;
              cursor: pointer;
              &:before{
                position: absolute;
                top: 3px;
                bottom: 4px;
                font-size: 22px;
                font-family: "FontAwesome";
                color: #FFF;
                line-height: 5px;
                left: 50%;
                transform: translate(-50%, 0);
              }
              &#gim_drop_up{
                top: 0;

                &:before{
                  content: "\f106";
                }
              }
              &#gim_drop_down{
                bottom: 0;
                left: auto;

                &:before{
                  content: "\f107";
                }
              }
            }
            input {
              // background: url('/sites/all/themes/omgsub/images/time_icon.svg') no-repeat calc(100% - 8px) center $purple;
              background: $purple;
              padding: 0 41px 0 8px;
              color: white;
              width: 90px;
              text-align: center;
              border-radius: 0 5px 5px 0;
              border: none;
              line-height: 38px;
            }
          }
        }
        input[type="submit"]{
          appearance: none;
          border:none;
          box-shadow: none;
          background-color: transparent;
          font-size: 18px;
          color: $orange;
          font-weight: 700;
          text-transform: uppercase;
          line-height: 39px;
          height: 39px;
          cursor: pointer;
          padding-left: 13px;
          padding-right: 40px;
        }
        .arrows{
          background-color: $purple;
          font-size: 18px;
          line-height: 39px;
          height: 39px;
          cursor: pointer;
          margin-left: 10px;
          border-radius: 5px;
          display: inline-block;
          position: relative;
          &:before,
          &:after {
            content: ">";
            position: absolute;
            display: inline-block;
            width: 12px;
            height: 12px;
            color: #ffec00;
            font-size: 19px;
            top: 0;
            z-index: 999;
            font-weight: 700;
          }
          &:before {
            right: 6px;
          }
          &:after {
            right: 19px;
          }
        }
      }
    }
  }
  &#block-rent-a-car-rent-a-car-search-sidebar{
    @include breakpoint($sm $xs){
      text-align: center;
    }
    @include breakpoint($xs){
      text-align: center;
      margin-bottom: 0;
    }
    position: relative;
    margin-bottom: 14px;
    background-color: $purple;
    .block__content{
      @include breakpoint($lg){
        padding: 27px 20px 0;
      }
      @include breakpoint($sm $xs){
        max-width: 320px;
        margin-left: auto;
        margin-right: auto;
      }
      @include breakpoint($xs){
        max-width: 320px;
        margin: 0;
      }
      display: inline-block;
      width: 100%;
      color: white;
      position: relative;
      padding: 40px 17px 0;
      z-index: 6;
      &:after{
        @include breakpoint($xs){
          content: unset;
        }
        content: '';
        position: absolute;
        border-left: 41px solid transparent;
        border-right: 41px solid transparent;
        border-top: 20px solid $purple;
        left: 50%;
        bottom: 0px;
        @include translate(-50%, 100%);
      }
      form{
        > div{
          .form-item{
            &:first-child{
              @include breakpoint($lg){
                margin-top: 5px;
              }
            }
            &.form-type-select{
              @include span(12);
              margin-bottom: 16px;
            }
            .form-select,
            .block-select .select-title{
              @include breakpoint($lg){
                font-size: 14px;
              }
              @include span(12);
              border: none;
              height: 40px;
            }
            &.form-item-pickup-date,
            &.form-item-dropoff-date {
              @include breakpoint($lg){
                width: calc(100% - 73px);
              }
              width: calc(100% - 90px);
              display: inline-block;
              float: left;
              margin: 0 0 10px;
              input{
                @include breakpoint($lg){
                  font-size: 14px;
                  background-position: 4px center;
                  background-size: auto 50%;
                  padding-left: 28px;
                }
                width: 100%;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                border-color: white;
              }
            }
            &.form-item-pickup-time,
            &.form-item-dropoff-time{
              display: inline-block;
              float: left;
              margin: 0 0 10px;
              input{
                @include breakpoint($lg){
                  font-size: 14px;
                  background-size: auto 50%;
                  background-position: calc(100% - 4px) center;
                  padding-right: 28px;
                  width: 73px;
                }
              }
            }
            &.form-item-old-enough{
              background: white;
              padding: 5px 15px;
              display: inline-block;
              width: 100%;
              position: relative;
              margin-bottom: 13px;
              @include borderradius(5px);
              label{
                @include breakpoint($lg){
                  font-size: 14px;
                }
                color: #010101;
                font-size: 16px;
                font-weight: 300;
                line-height: 32px;
                display: inline-block;
                float: left;
                cursor: pointer;
                .form-required{
                  display: none;
                }
              }
              label{
                &:before{
                  content: '';
                  position: absolute;
                  top: 13px;
                  right: 13px;
                  border: 1px solid $purple;
                  background: white;
                  width: 17px;
                  height: 17px;
                }
                &:after{
                  display: none;
                }
              }
              input:checked ~ label{
                &:before{
                  content: '';
                  position: absolute;
                  top: 13px;
                  right: 13px;
                  border: 1px solid $purple;
                  background: white;
                  width: 17px;
                  height: 17px;
                }
                &:after{
                  content: '';
                  display: block;
                  position: absolute;
                  top: 14px;
                  right: 18px;
                  border-right: 1px solid $purple;
                  border-bottom: 1px solid $purple;
                  background: none;
                  width: 8px;
                  height: 11px;
                  @include rotate(45deg);
                }
              }
              input{
                display: none;
              }
            }
          }
          h4.more-link {
            @include breakpoint($lg){
              font-size: 14px;
            }
            @include breakpoint($smaller){
              background-color: $purple;
              color: white;
              line-height: 20px;
              padding: 3px 25px 2px 10px ;
              text-align: left;
              border-radius: 4px;
              cursor: pointer;
              &:after{
                content: '\f106';
              }
            }
            font-size: 20px;
            color: $orange;
            font-weight: 900;
            text-align: center;
            text-transform: uppercase;
            margin: 0 0 15px;
            position: relative;
            &.open{
              &:after{
                transform: unset;
              }
            }
            &:after{
              font-family: 'FontAwesome';
              font-size: 32px;
              display: inline-block;
              position: absolute;
              color: inherit;
              top: 0;
              right: 8px;
              bottom: 0;
              transform: rotateX(180deg);
              transition: transform 0.5s ease;
            }
          }
          .your-booking {
            @include breakpoint($sm){
              max-width: 320px;
              margin-left: auto;
              margin-right: auto;
            }
            text-align: left;
            .old-enough,
            .dropoff-car,
            .pickup-car {
              @include breakpoint($lg){
                padding: 8px 10px;
              }
              &:first-child{
                margin-top: 0;
              }
              background: white;
              width: 100%;
              margin: 15px auto;
              padding: 15px;
              border-radius: 6px;
              border: 1px solid rgba($purple, 0.1);
              h6 {
                @include breakpoint($lg){
                  font-size: 13px;
                }
                @include breakpoint($sm){
                  font-size: 16px;
                }
                margin: 0;
                color: $purple;
                font-size: 16px;
                line-height: 20px;
              }
              .location,
              .date {
                @include breakpoint($lg){
                  font-size: 12px;
                  padding-left: 25px;
                }
                @include breakpoint($sm){
                  font-size: 16px;
                }
                padding-left: 40px;
                line-height: 30px;
                color: #010101;
                font-size: 16px;
                font-weight: 400;
                position: relative;
                border-color: 1px solid rgba($purple, 0.25);
                &:before{
                  @include breakpoint($lg){
                    //width: 25px;
                  }
                  content: '';
                  position: absolute;
                  top: 5px;
                  left: 0;
                  bottom: 5px;
                  //width: 40px;
                  display: block;
                  //background-size: contain;
                  //background-position: left center;
                  background-repeat: no-repeat;
                }
              }
              .location:before{
                //background-image: url('/sites/all/themes/omgsub/images/location_icon.svg');
                @extend .svg-location_icon;
                @extend .svg-location_icon-dims;
                background-size: unset;
                left: 4px;
              }
              .date:before{
                @extend .svg-date_icon;
                @extend .svg-date_icon-dims;
                @include breakpoint($lg){
                  transform: scale(0.8);
                  top: 0;
                }
              }
            }
            .old-enough {
              @include breakpoint($lg){
                font-size: 12px;
                padding: 0 10px;
              }
              @include breakpoint($sm){
                font-size: 16px;
              }
              border-color: 1px solid rgba($purple, 0.25);
              color: #010101;
              font-size: 16px;
              font-weight: 400;
              line-height: 32px;
              padding: 0 15px;
              margin-bottom: 30px;
            }
            > h4{
              @include breakpoint($lg){
                margin-top: 30px;
                font-size: 16px;
              }
              color: white;
              text-transform: uppercase;
              text-align: center;
              font-weight: 900;
              font-size: 18px;
              margin-top: 35px;
              margin-bottom: 13px;
            }
          }
          .selected-car{
            text-align: center;
            background-color: white;
            border: 1px solid rgba($purple, 0.2);
            border-radius: 6px;
            color: black;
            margin: 0 auto 23px;
            .title{
              @include breakpoint($lg){
                font-size: 18px;
              }
              color: $purple;
              font-size: 20px;
              font-weight: 900;
              line-height: 1.2;
              margin: 24px 20px 5px;
            }
            >img{
              max-width: 100%;
              height: auto;
            }
            .dates{
              margin-bottom: 10px;
              .duration{
                @include breakpoint($lg){
                  font-size: 22px;
                }
                display: block;
                text-transform: uppercase;
                color: $purple;
                font-weight: bold;
                font-size: 28px;
              }
              .dates-selected{
                @include breakpoint($lg){
                  font-size: 14px;
                }
                display: block;
                font-size: 16px;
              }
            }
            .prices{
              margin-bottom: 14px;
              .main-price{
                @include breakpoint($lg){
                  font-size: 32px;
                }
                display: block;
                color: $purple;
                font-weight: 700;
                font-size: 46px;
                margin-bottom: 4px;
              }
              .secondary-prices{
                font-weight: 300;
              }
            }
            h6{
              @include breakpoint($lg){
                font-size: 12px;
                line-height: 16px;
              }
              text-transform: uppercase;
              font-weight: 900;
              font-size: 14px;
              line-height: 18px;
              padding: 11px 0;
              border-width: 1px 0;
              border-style: solid;
              border-color: rgba(0, 0, 0, 0.05);
              margin: 0 0 4px;
            }
            ul{
              list-style: none;
              padding: 0;
              font-size: 14px;
              li{
                @include breakpoint($lg){
                  font-size: 12px;
                  font-weight: normal;
                }
                color: $dark_grey;
                font-weight: bold;
                max-width: 210px;
                margin: 0 auto 10px;
                line-height: 1.2;
                font-size: 16px;
              }
            }
          }
          text-align: center;
          margin-bottom: 30px;
          margin-top: 15px;
          .arrows{
            margin: 20px auto 30px;
            input[type=submit]{
              @include breakpoint($lg){
                font-size: 16px;
                padding: 11px 40px 10px 16px;
                line-height: 1;
                height: unset;
              }
              appearance: none;
              background: $orange;
              border: none;
              color: $purple;
              font-size: 20px;
              font-weight: 900;
              line-height: 32px;
              text-transform: uppercase;
              border-radius: 5px;
              display: block;
              transition: background-color 0.1s ease;
              padding-right: 40px;
              padding-left: 16px;
              &:hover{
                background-color: desaturate(darken($orange, 5%), 10%);
              }
              &[disabled]{
                background-color: $grey;
              }
            }
            &:after,
            &:before{
              color: $purple;
            }
          }
        }
      }
    }
    &.cloned_to_article{
      margin-top: 40px;
      .book-title{
        padding-top: 30px;
        h3{
          text-align: center;
          color: #FFFFFF;
          margin-bottom: 10px;
          font-size: 25px;
        }
      }
      .block__content{
        max-width: 500px;
        display: block;
        margin: 0 auto;
        padding-bottom: 10px;
        padding-top: 20px;
        &:after{
          display: none;
        }
        form{
          h4.more-link{
            display: none;
          }
          #date-loc-submit{
            text-align: center;
            margin-bottom: 30px;
            margin-top: 15px;
            .arrows{
              input{
                margin: 0;
                padding-right: 40px;
                padding-left: 16px;
              }
              &:after,
              &:before{
                color: $purple;
              }
            }
          }
        }
      }
    }
  }
}

.front{
  #car-search-front{
    .block__content{
      .more-link{
        display: none;
      }
    }
  }
}

/* inside reservations pages */
#block-rent-a-car-rent-a-car-sidebar-top{
  @include breakpoint($sm){
    display: none;
  }
  position: relative;
  z-index: 7;
  .block__content{
    @include breakpoint($lg){
      font-size: 18px;
      padding-top: 30px;
    }
    position: relative;
    background: $orange;
    color: $purple;
    font-size: 26px;
    font-weight: 300;
    text-align: center;
    padding-top: 20px;
    &:after{
      content: '';
      position: absolute;
      border-left: 56px solid transparent;
      border-right: 56px solid transparent;
      border-top: 27px solid $orange;
      left: 50%;
      bottom: 0;
      z-index: 9;
      @include translate(-50%, 100%);
    }
    > span.bolder{
      @include breakpoint($lg){
        font-size: 22px;
        margin-bottom: 26px;
        margin-top: 3px;
      }
      display: inline-block;
      width: 100%;
      font-size: 30px;
      font-weight: 900;
      color: $purple;
      margin-bottom: 18px;
    }
  }
}
/* checkboxes are in front page and also in reservations pages */
.form-type-checkboxes{
  display: block;
  margin: 0;
  &.form-item-car-type{
    margin-bottom: 15px;
  }
  &.form-item-gearbox{
    > label{
      margin-right: 10px;
    }
  }
  > label{
    @include breakpoint($lg){
      font-size: 12px;
    }
    color: #000006;
    font-size: 16px;
    font-weight: 700;
    line-height: 23px;
    display: inline;
    margin-right: 38px;
    text-transform: uppercase;
    .form-required{
      display: none;
    }
  }
  .form-checkboxes{
    display: inline;
    .form-item{
      margin-bottom: 0;
      &.form-item-car-type--1{
        label{
          font-weight: bold;
        }
      }
      input{
        visibility: hidden;
        opacity: 0;
        max-height: 0;
        position: absolute;
        display: none;
      }
      label{
        @include breakpoint($lg){
          font-size: 12px;
        }
        color: #000006;
        font-size: 14px;
        font-weight: 400;
        line-height: 23px;
        position: relative;
        cursor: pointer;
        display: inline-block;
        padding-left: 30px;
        letter-spacing: -1px;
        margin-right: 5px;
        position: relative;

        &:before{
          content: '';
          display: block;
          width: 20px;
          height: 20px;
          position: absolute;
          top: 0px;
          left: 0;
          background-color: white;
          border: 1px solid $dblue;
          border-radius: 50%;
          transition: background-color 0.1s ease;
        }

        &:after{
          content: '';
          display: block;
          width: 6px;
          height: 6px;
          position: absolute;
          top: 8px;
          left: 8px;
          border-radius: 50%;
          background-color: $purple;
          transition: background-color 0.1s ease;
        }
      }
      input:checked ~ label{
        &:before{
          background-color: $purple;
        }
        &:after{
          background-color: white;
        }
      }
    }
  }
}

/* timedropper css */
body {
  #td-clock-0,
  #td-clock-1{
    .td-clock .td-time span {
      &.on{
        color: $purple;
        font-size: 32px;
        @include breakpoint($sm){
          font-size: 26px;
        }
      }
      color: $grey;
      font-size: 26px;
      transition: color 0.25s ease, font-size 0.25s ease;
    }
    .td-lancette{
      border-color: $purple;
    }
    .td-clock{
      box-shadow: 0 0 0 1px $purple, 0 0 0 8px rgba(0, 0, 0, 0.05);
      &:before{
        border-color: $purple;
      }
      .td-select{
        &:after{
          box-shadow:0 0 0 1px $purple;
        }
        @include breakpoint(500px){
          &:after{
            width: 25px;
            height: 25px;
          }
        }
      }
    }
    @include breakpoint($sm){
      .td-clock{
        width: 125px;
        height: 125px;
        .td-time span{
          width: 31px;
        }
      }
    }
    @include breakpoint(500px){
      .td-clock{
        width: 80px;
        height: 80px;
        line-height: 84px;
        .td-time span{
          font-size: 18px!important;
          width: 20px;
          height: 22px;
          line-height: 37px;
        }
      }
    }
  }

  #td-clock-0 .td-clock {
    color: #555;
    background: white;
    box-shadow: 0 0 0 1px $purple, 0 0 0 8px rgba(0, 0, 0, 0.05);
  }
  .td-select svg{
    stroke: $purple !important;
  }
  #td-clock-0 .td-select:after{
    box-shadow: 0 0 0 1px $purple;
  }
  #td-clock-0 .td-clock:before{
    border-color: $purple;
  }
  div.datedropper.primary:before,
  div.datedropper.primary .pick-submit,
  div.datedropper.primary .pick-lg-b .pick-sl:before,
  div.datedropper.primary .pick-m,
  div.datedropper.primary .pick-lg-h
  {
    background-color: $purple!important;
  }
  div.datedropper:not(.picker-clean) .pick:first-of-type{
    background-color: $purple!important;
  }
  div.datedropper.primary .pick-m{
    li{
      background-color: $purple;
    }
  }
  div.datedropper.primary .pick-y.pick-jump,
  div.datedropper.primary .pick li span,
  div.datedropper.primary .pick-lg-b .pick-wke,
  div.datedropper.primary .pick-btn{
    color: $purple;
  }
  div.datedropper.primary .pick-sl.pick-wke{
    color: #fff;
  }
}

/* Fleet - info about prices */
#block-block-26{
  background-color: $orange;
  .block__content{
    @include breakpoint($lg){
      padding: 70px 8px 8px;
    }
    @include breakpoint($smaller){
      padding: 34px 8px 34px;
      display: block;
      max-width: 340px;
      margin: auto;
    }
    @include breakpoint($xs){
      padding: 15px 8px 32px;
      max-width: 320px;
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
    display: inline-block;
    width: 100%;
    padding: 17px;
    position: relative;
    //padding-top: 100px;
    padding-top: 86px;
    &:before{
      @include breakpoint($xs){
        content: unset;
      }
      content: '';
      position: absolute;
      border-left: 41px solid transparent;
      border-right: 41px solid transparent;
      border-top: 20px solid white;
      left: 50%;
      top: 0;
      @include translate(-50%, 0);
    }
    &:after{
      @include breakpoint($lg){
        width: 26px;
        height: 26px;
      }
      @include breakpoint($smaller){
        left: 34px;
        top: 31px;
      }
      @include breakpoint($xs){
        width: 30px;
        height: 30px;
        top: 9px;
      }
      content: '';
      position: absolute;
      left: 50%;
      //top: 39px;
      top: 33px;
      width: 38px;
      height: 38px;
      transform: translateX(-50%);
      background-image: url('/sites/all/themes/omgsub/images/info_icon_orange.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
    p {
      @include breakpoint($lg){
        font-size: 14px;
      }
      @include breakpoint($xs){
        font-weight: normal;
        font-size: 18px;
      }
      color: $purple;
      font-size: 19px;
      font-weight: bold;
      &.more-link{
        @include breakpoint($smaller){
          cursor: pointer;
          width: calc(100% - 60px);
          margin-bottom: 0;
          float: right;
        }
        margin-bottom: 16px;
        text-align: center;
        position: relative;
        text-transform: capitalize;
        font-size: 19px;
        &.open:after{
          transform: unset;
        }
        &:after{
          @include breakpoint($smaller){
            content: '\f106';
            color: $purple;
          }
          color: $orange;
          vertical-align: bottom;
          line-height: 20px;
          display: inline-block;
          font-size: 32px;
          font-family: 'FontAwesome';
          transform: rotateX(180deg);
          transition: transform 0.5s ease;
          transform-origin: 50% 11px;
          margin-left: 5px;
        }
      }
      &:last-child {
        text-align: center;
        >a {
          @include breakpoint($lg){
            font-size: 14px;
            padding: 6px 10px;
          }
          @extend .button;
          padding: 8px 15px;
          font-size: 22px;
          background-color: $purple;
          color: $orange;
          display: inline-block;
          transition: 0.3s ease;
          line-height: 27px;
          max-width: 267px;
          font-weight: 700;
          &:hover{
            background-color: $purple;
            opacity: 0.8;
          }
        }
      }
    }
    .hidden{
      @include breakpoint($smaller){
        width: 100%;
        display: inline-block;
      }
    }
    ul{
      padding-left: 0;
      &:first-of-type{
        margin-top: 0;
      }
      li{
        @include breakpoint($lg){
          font-size: 11px;
          padding: 3px 0 4px 20px;
        }
        font-size: 16px;
        line-height: calc(1em + 4px);
        //padding: 7px 0 7px 20px;
        padding: 7px 0 7px 30px;
        &:before{
          @include breakpoint($lg){
            height: 22px;
            background-size: 14px;
            left: -4px;
            top: -5px;
          }
          height: 32px;
        }
      }
      &.checkmarks-list{
        li{
          &:before{
            height: 31px;
          }
        }
        @include breakpoint($smaller){
          margin-top: 20px;
        }
      }
    }
  }
}
.ui-timepicker-container.ui-timepicker-standard{
  z-index: 9999!important;
  padding: 0;
  .ui-timepicker.ui-widget{
    border:none;
    padding: 0;
    background: none;
    ul.ui-timepicker-viewport{
      li.ui-menu-item{
        a{
          border:none;
          background: none;
          padding-right: 10px;
          padding-left: 5px;
          padding-top: 1px;
          padding-bottom: 1px;
          font-size: 15px;
          text-align: left;
          cursor: pointer;
          border-radius: 0;
          &:hover{
            background: $purple;
            color: #FFFFFF;
          }
        }
      }
    }
  }
}