body.node-type-blog-post{
  div{
    .l-main{
      .l-content{
        article{
          .node__content{
            .field--name-field-image,
            &.field--name-title-field{
              .field__items{
                .field__item{
                  text-align: center;
                  margin-bottom: 20px;
                  img{
                    width: 100%;
                    height: auto;
                    max-width: 559px;
                  }
                }
              }
            }
            .field--name-title-field{
              .field__items{
                .field__item{
                  text-align: center;
                  color: $purple;
                  font-size: 28px;
                  line-height: 28px;
                  font-weight: 700;
                  margin-bottom: 30px;
                  margin-top: 30px;
                  border-bottom: 1px solid $purple;
                  padding-bottom: 15px;
                }
              }
            }
            .field--name-body{
              .field__items{
                .field__item{
                  p{
                    color: #000;
                    font-size: 18px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}