body.page-checkout{
.info-block{
  padding-left: 5px;
  position: relative;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  padding-top: 0;
  &:before{
    display: none;
    left:0;
    transform: unset;
  }
}
}

#commerce-checkout-form-checkout,
#commerce-checkout-form-complete{
  h2.page-title{
    color: $purple;
    font-size: 28px;
    border-bottom: 1px solid $purple;
    margin-top: 37px;
    padding-bottom: 36px;
    margin-bottom: 0;
    @include breakpoint($sm){
      font-size: 24px;
    }
    @include breakpoint($smaller){
      margin-top: 18px;
      padding-bottom: 15px;
    }
  }
  fieldset{
    border: none;
    padding: 0;
    margin: 0;
    &#edit-personal-info{
      margin-bottom: 30px;
      @include breakpoint($xs){
        display: contents;
      }
      legend{
        margin-bottom: 0;
        cursor: pointer;
        span {
          position: relative;
          &:after {
            content: '';
            position: absolute;
            border: solid $purple;
            border-width: 0 5px 5px 0;
            display: inline-block;
            padding: 5px;
            transform: rotate(45deg) translateY(-50%);
            top: 45%;
            transition: all 0.35s ease;
            cursor: pointer;
            right: -18px;
          }
        }
        &.active{
          span {
            &:after {
              right: -25px;
              transform: rotate(-45deg) translateY(-50%);
            }
          }
        }
      }
      .fieldset-wrapper{
        display: none;
      }
    }
  }
  legend{
    color: $purple;
    font-weight: 900;
    font-size: 22px;
    line-height: 1;
    padding: 16px 0;
    border-width: 1px 0;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.075);
    display: block;
    width: 100%;
    margin-bottom: 15px;
  }

  .checkout-completion-message{
    @extend .subsection;
    margin-top: 20px;
  }
}

#review-personal-info{
  min-height: 268px;
  @include breakpoint($md){
    display: block;
    overflow: hidden;
    >tbody{
      display: block;
    }
  }
  background-color: #f3f3f3;
  width: 100%;
  table-layout: fixed;
  margin-bottom: 22px;
  tr{
    @include breakpoint($md){
      display: block;
      width: 100%;
      padding: 0 4px;
      overflow: hidden;
      &:nth-child(4){
        td{
          &:nth-child(3){
            display: none;
          }
        }
      }
      >td{
        @include breakpoint($md){
          display: block;
        }
      }
    }
    &:first-child{
      td{
        @include breakpoint($md){
          padding: 5px 15px;
        }
        padding-top: 15px;
      }
    }
    &:last-child{
      td{
        @include breakpoint($md){
          padding: 5px 15px;
          border-bottom: none;
        }
        padding-bottom: 20px;
      }
    }
    td{
      .heading{
        font-weight: 700;
      }
      @include breakpoint($xl){
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      @include breakpoint($md){
        line-height: 1.2;
        height: calc(2.4em + 12px);
        border-bottom: 1px solid rgba($dark-grey, 0.5);
        white-space: unset;
        overflow: unset;
        text-overflow: unset;
      }
      @include breakpoint($sm){
        height: initial;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      padding: 8px 15px;
      &:first-child{
        @include breakpoint($md){
          @include span(5 (gutters: 0));
        }
        @include breakpoint($sm){
          @include span(12);
          padding-bottom: 2px;
          border-bottom: none;
          &:after{
            content: ':';
          }
        }
        width: 180px;
        font-weight: 300;
      }
      &:nth-child(2){
        @include breakpoint($md){
          @include span(7 (gutters: 0) last);
        }
        @include breakpoint($sm){
          @include span(12);
          padding-top: 2px;
        }
        width: calc(50% - 140px);
      }
      &:nth-child(3){
        @include breakpoint($md){
          @include span(5 (gutters: 0));
        }
        @include breakpoint($sm){
          @include span(12);
          padding-bottom: 2px;
          border-bottom: none;
          &:after{
            content: ':';
          }
        }
        width: 170px;
        font-weight: 300;
      }
      &:nth-child(4){
        @include breakpoint($md){
          @include span(7 (gutters: 0) last);
        }
        @include breakpoint($sm){
          @include span(12);
          padding-top: 2px;
        }
        width: calc(50% - 170px);
      }
    }
  }
}

.view-commerce-cart-summary{
  .view-content{
    margin: 0 0 25px;
  }
}

#edit-commerce-payment-payment-method{
  margin-top: 35px;
  .form-type-radio{
    margin-bottom: 40px;
    width: 33%;
    float: left;
    @include breakpoint($md){
      width: 100%;
    }
    //&:last-child{
    //  width: 33.4%;
    //  float: right;
    //}
    input{
      display: none;
      &:checked + label:after{
        background-color: $purple;
      }
    }
    label{
      position: relative;
      padding-left: 30px;
      cursor: pointer;
      font-size: 18px;
      font-weight: 300;
      line-height: 21px;
      display: inline-block;
      &:before{
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        border: 1px solid $purple;
        border-radius: 20px;
      }
      .label{
        padding-left: 0;
      }
      img{
        max-width: 100%;
        height: auto;
        right: 30px;
        @include breakpoint($md $sm +1){
          max-width: 400px;
        }
      }
      &:after{
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        position: absolute;
        top: 7px;
        left: 7px;
        border-radius: 8px;
        background-color: transparent;
        transition: background-color 0.25s ease;
      }
    }
  }
}
#payment-details{
  .styled{
    box-shadow: 0px 0px 13px #dedede;
    max-width: 450px;
    display: block !important;
    margin: auto;
    border-radius: 5px;
    padding: 17px;
    margin-bottom: 30px;
    p {
      &:first-child {
        color: $purple;
        font-weight: 700;
        margin-bottom: 8px;
      }
    }
  }
}

#edit-commerce-payment{
  .fieldset-wrapper{
    text-align: center;
    margin-top: 10px;
    .payment_info_checkout{
      font-size: 20px;
      text-align: center;
      //max-width: 640px;
      max-width: 750px;
      padding-left: 55px;
      padding-right: 55px;
      clear: both;
      display: block;
      margin: 0 auto;
      position: relative;
      @include breakpoint($sm){
        padding-left: 20px;
        padding-right: 20px;
      }
      &:before,
      &:after {
        content: '';
        width: 34px;
        height: 63px;
        background: url(../images/sprites/svg/optimized/quote1_icon.svg) no-repeat;
        background-size: contain;
        display: block;
        position: absolute;
        @include breakpoint($sm){
          background-size: 60%;
        }
      }
      &:before {
        top: -8px;
        left: 8px;
        @include breakpoint($sm){
          left: -4px;
        }
      }
      &:after {
        bottom: -8px;
        right: 8px;
        transform: rotate(180deg);
        @include breakpoint($sm){
          right: -4px;
        }
      }
    }
    > *{
      text-align: left;
      font-size: 14px;
      width: 100%;
      display: inline-block;
    }
    .subsection{
      h6{
        margin-top: 0;
        border-top: 1px solid rgba(0, 0, 0, 0.075);
        padding: 15px 0 15px 2px;
        font-size: 20px;
      }
      .info-block{
        margin-top: 20px;
        margin-bottom: 30px;
        padding-left: 55px;
        padding-top: 0;
        @include breakpoint($sm){
          &:before{
            left:0;
            transform: unset;
          }
        }
      }
    }
  }
}

.terms-agreement{
  font-size: 14px;
  font-weight: 300;
  a{
    color: black;
    text-decoration: underline;
  }
}

.form-item-commerce-payment-terms-agreement{
  input{
    display: none;
    &:checked + .option:before{
      color: $purple;
    }
  }
  .option{
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 300;
    line-height: 21px;
    display: inline-block;
    margin-top: 11px;
    &:before{
      content: '\f00c';
      font-family: 'FontAwesome';
      display: block;
      width: 21px;
      height: 21px;
      position: absolute;
      top: 0;
      left: 0;
      border: 1px solid $purple;
      border-radius: 2px;
      color: white;
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      transition: color 0.05s ease;
    }
    .form-required{
      display: none;
    }
  }
}

#edit-commerce-payment-continue{
  @include breakpoint($sm){
    font-size: 18px;
  }
  @include breakpoint($xs){
    font-size: 16px;
    letter-spacing: -0.5px;
  }
  @extend .button;
  border: none;
  display: inline-block;
  text-align: center;
  background-color: $purple;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 700;
  padding: 10px 26px;
  padding-top: 12px;
  max-width: 266px;
  margin-top: 5px;
  color: $orange;
  font-size: 20px!important;
  &:hover{
    background-color: desaturate(darken($purple, 5%), 10%);
  }
}
.element-invisible{
  display: none!important;
}