.view-faq{
  .faq-list{
    li{
      font-size: 18px;
      font-weight: 700;
      h3{
        cursor: pointer;
        font-size: 18px;
        &:hover{
          color: $purple;
        }
      }
      .faq-answer{
        font-weight: 400;
        position: absolute;
        visibility: hidden;
        opacity: 0;
      }
      &.open{
        h3{
          color: $purple;
        }
        .faq-answer{
          position: relative;
          visibility: visible;
          opacity: 1;
          @include transition(all 0.5s linear);
        }
      }
    }
  }
}