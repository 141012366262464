body.page-node-223,
body.page-node-401{
  .node__content{
    margin-top: 50px;
    margin-bottom: 50px;
    float: left;
    width: 100%;
    max-width: 100%;
    .taxonomy-term-description{
      hr{
        display: block;
        height: 1px;
        border: 0;
        border-top: 1px solid #e8e8e8;
        margin: 1em 0;
        padding: 0;
      }
    }
  }
}
body.section-explore-crete{
  .l-content{
    .term-listing-heading {
      margin-top: 50px;
      margin-bottom: 50px;
      float: left;
      width: 100%;
      max-width: 100%;
    }
    .taxonomy-term-description{
      hr{
        display: block;
        height: 1px;
        border: 0;
        border-top: 1px solid #e8e8e8;
        margin: 1em 0;
        padding: 0;
      }
    }
  }
}
@include breakpoint(520px){
  body.section-explore-crete{
    .l-content{
      .l-region--highlighted{
        width: 100%;
        float: left;
        #block-menu-menu-explore-crete-menu{
          ul{

            li{

              a{
                line-height: 20px;
                &:after{
                  display: none;
                }
              }
              &:last-child{
                a {
                  &:after {

                  }
                }
              }
              ul{
                position: relative;
                background-color: $purple;
                width: 100%;
                left: 0;
                li{
                  a{
                    color: #fff;
                  }
                  > ul{
                    li{
                      display: contents;
                      a{
                        font-size: 12px;

                      }
                    }
                  }
                }
              }
            }
            li:hover > ul {

            }
          }
          > ul{
            > li{
              margin-bottom: 10px;
              > a{
              }
              ul{

                li{
                  ul{

                  }
                }
              }
              > ul{

                > li{
                  > a{

                  }
                  > ul{
                    > li{
                    }
                    > a{

                    }
                    > ul{
                      > li{
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}