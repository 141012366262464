body{
  .ui-widget-content,
  .ui-widget-header {
    .ui-state-highlight {
      border: 0 none;
      background-image: none;
    }
  }
  .jGrowl.center {
    top: 29px;
    left: 50%;
    width: auto;
    transform: translate(-50%, 0);
    margin: 0;
  }

  #jGrowl{
    .jGrowl-notification{
      @include breakpoint($xs){
        width: 300px;
      }
      background-position: 4px center;
      background-repeat: no-repeat;
      padding-left: 24px;
      padding-right: 4px;
      width: 480px;
      font-size: 14px;
      line-height: 18px;
      &.status{
        color: darkgreen;
        background-color: lightgreen;
        border: 1px solid darkgreen;
      }
      &.warning{
        color: darkorange;
        background-color: lightyellow;
        border: 1px solid darkorange;
      }
      &.error{
        color: darkred;
        background-color: lighten(lightpink, 8%);
        border: 1px solid darkred;
      }
      a{
        color: inherit;
        border-bottom: 1px dotted white;
      }
      .jGrowl-message{
        word-wrap: break-word;
        padding-right: 34px;
      }
      .jGrowl-close{
        font-size: 1.2em;
      }
    }
    .jGrowl-closer{
      padding-right: 24px;
      padding-left: 24px;
      background-image: none;
    }
  }
}