.inline-block{
  display: inline-block;
}

.button{
  display: inline-block;
  padding: 19px 14px 23px;
  font-size: 20px;
  line-height: 1;
  color: white;
  text-decoration: none;
  background-color: $orange;
  border-radius: 8px;
  vertical-align: bottom;
  font-weight: 900;
  transition: background-color 0.25s ease;
  cursor: pointer;
  &:hover{
    background-color: desaturate(darken($orange, 5%), 10%);
  }
}

.feature{
  line-height: 28px;
  font-weight: 300;
  text-align: center;
  span{
    margin-top: 10px;
    display: inline-block;
    width: 100%;
    text-align:center;
    font-size: 14px;
  }
  &:before{
    @include breakpoint($md){
      margin-right: 2px;
    }
    content: '';
    width: 30px;
    height: 30px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    vertical-align: bottom;
    display: block;
    margin: 0 auto;
  }
  &.passengers:before{
    background-image: url('/sites/all/themes/omgsub/images/persons.svg');
  }
  &.fuel:before{
    background-image: url('/sites/all/themes/omgsub/images/GAS_ICON.svg');
  }
  &.air-condition:before{
    background-image: url('/sites/all/themes/omgsub/images/C_ICON.svg');
  }
  &.doors:before{
    background-image: url('/sites/all/themes/omgsub/images/DOORS_ICON.svg');
  }
  &.luggage:before{
    background-image: url('/sites/all/themes/omgsub/images/BAGGAGES_ICON.svg');
  }
  &.insurance:before{
    background-image: url('/sites/all/themes/omgsub/images/features_insurance.svg');
    background-size: 100%;
  }
  &.child-seat:before{
    background-image: url('/sites/all/themes/omgsub/images/CHILD_SEAT_ICON.svg');
  }
  &.gearbox:before{
    background-image: url('/sites/all/themes/omgsub/images/GEARBOX_ICON.svg');
  }
  &.hp_cc {
    span{
      display: block;
      line-height: 15px;
      font-size: 12px;
      margin-top: 0;
      &:first-child {
        margin-top: 10px;
      }
    }
    &:before {
      background-image: url('/sites/all/themes/omgsub/images/hpcc.png');
    }
  }
}

.no-display{
  display: none;
}

.simple-page-section{
  overflow: hidden;
}

.half-right{
  @include breakpoint($md){
    @include span(12);
  }
  @include span(6 last);
  img{
    margin-bottom: 15px;
  }
}

.half-left{
  @include breakpoint($md){
    @include span(12);
  }
  @include span(6);
  img{
    margin-bottom: 15px;
  }
}

.pager{
  display: inline-block;
  float: right;
  li{
    padding-left: 2px;
    padding-right: 2px;
    font-size: 20px;
    &.pager__item--current{
      color: $purple;
    }
    a{
      text-decoration: none;
      color: black;
      &:hover{
        color: $purple;
      }
    }
  }
}

.subsection{
  h6{
    margin: 35px 0 10px;
    color: $purple;
    font-size: 18px;
    line-height: 24px;
    padding: 7px 0 7px 2px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.075);
  }
}

.price-table{
  display: block;
  background-color: $yellow_grey;
  overflow: hidden;
  >div{
    display: table;
    table-layout: fixed;
    position: relative;
    &.final-price{
      //padding-top: 6px;
      min-height: 90px;
      @include breakpoint($container + 40px){
        @include span(12);
      }
      @include span(5.5 (gutters: 0));
      @include breakpoint($sm){
        //display: inline-block;
      }
      @include breakpoint($xs){
        display: inline-block;
      }
      > div{
        &:first-child{
          @include breakpoint($container + 40px){
            width: 63%;
          }
          @include breakpoint($md){
            width: 57%;
          }
          @include breakpoint($xs){
            @include span(12);
          }
          //width: 46%;
          width: 49%;
        }
        &:nth-child(2){
          @include breakpoint($container + 40px){
            width: 37%;
          }
          @include breakpoint($md){
            width: 43%;
            font-size: 29px;
          }
          @include breakpoint($sm){
            font-size: 22px;
          }
          @include breakpoint($xs){
            width: 56%;
            float: left;
          }
          //width: 54%;
          width: 51%;
          vertical-align: top;
          @include breakpoint($md){
            vertical-align: middle;
          }
        }
      }
    }
    &.downpayment-price{
      @include breakpoint($container + 40px){
        @include span(12);
      }
      @include breakpoint($xs){
        display: inline-block;
      }
      @include span(6.5 (gutters: 0) last);
      position: relative;
      &:before{
        @include breakpoint($container + 40px){
          top: 0;
          right: 17px;
          bottom: unset;
          left: 17px;
          width: unset;
          height: 2px;
          border-top: 1px solid rgba(0, 0, 0, 0.45);
          border-left: none;
        }
        content: '';
        display: block;
        position: absolute;
        top: 17px;
        left: 0;
        bottom: 17px;
        width: 2px;
        border-left: 1px solid rgba(0, 0, 0, 0.45);
      }
      > div{
        &:first-child{
          @include breakpoint($container + 40px){
            width: 63%;
          }
          @include breakpoint($md){
            width: 57%;
          }
          @include breakpoint($xs){
            @include span(12);
          }
          width: 56%;
        }
        &:nth-child(2){
          @include breakpoint($container + 40px){
            width: 37%;
          }
          @include breakpoint($md){
            width: 43%;
            font-size: 29px;
          }
          @include breakpoint($sm){
            font-size: 22px;
          }
          @include breakpoint($xs){
            width: 100%;
            //@include span(12);
          }
          width: 44%;
        }
      }
    }
    >div{
      @include breakpoint($sm){
        &:first-child{
          padding-bottom: 15px;
        }
        &:nth-child(2){
          padding-top: 5px;
        }
      }
      display: table-cell;
      width: 50%;
      vertical-align: middle;
      padding: 20px;
      span{
        display: block;
        color: $purple;
        &.label{
          text-transform: uppercase;
          font-size: 20px;
          letter-spacing: -1px;
        }
        &.explanation{
          text-transform: uppercase;
          font-weight: 900;
          font-size: 11px;
          line-height: 1.333;
        }
      }
      &.price-value{
        width: 50%;
        color: $purple;
        font-weight: 900;
        font-size: 36px;
      }
      &.perdaycharge{
        position: absolute;
        padding: 0;
        //bottom: 4px;
        bottom: 11px;
        right: 0;
        padding-left: 15px;
        color: $purple;
        @include breakpoint($container + 40px){
          width: 37%;
        }
        @include breakpoint($md){
          width: 43%;
          bottom: 3px;
        }
        @include breakpoint($smaller){
          bottom: 12px;
        }
        @include breakpoint($xs){
          width: 44%;
          font-size: 13px;
          float: right;
          position: relative;
          bottom: -11px;
        }
      }
    }
  }
}

.info-block{
  @include breakpoint($sm){
    padding-left: 0;
    //padding-top: 40px;
  }
  padding-left: 55px;
  position: relative;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  &:before{
    @include breakpoint($sm){
      //top: 0;
      //left: 50%;
      //transform: translate(-50%, 0);
      display: none;
    }
    content: '';
    display: block;
    background-image: url('/sites/all/themes/omgsub/images/info_icon.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 38px;
    height: 38px;
    vertical-align: bottom;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.checkmarks-list{
  padding-left: 20px;
  list-style: none;
  li{
    padding-left: 20px;
    position: relative;
    &:before{
      content: '';
      background-image: url('/sites/all/themes/omgsub/images/CHECK.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: auto;
      display: block;
      width: 22px;
      height: inherit;
      margin-right: 7px;
      position: absolute;
      top: 0;
      left: 0;
      vertical-align: bottom;

      //position: absolute;
      //display: inline-block;
      //width: 14px;
      //height: 14px;
      //border:2px solid $purple;
      //border-radius: 50%;
      //left:-2px;
      //top: 50%;
      //transform: translateY(-50%);
    }
    //&:after{
    //  content: '';
    //  display: inline-block;
    //  position: absolute;
    //  border: 2px solid;
    //  border-color: #0F4B97;
    //  border-right-style: none;
    //  border-top-style: none;
    //  height: 3px;
    //  width: 12px;
    //  top: 10px;
    //  -webkit-transform: rotate(-50deg);
    //  transform: rotate(-46deg);
    //  background: #fbec0f;
    //  left: 3px;
    //  box-shadow: 2px 4px 0px -1px #fbec0f;
    //}
  }
}

@keyframes rotating{
  100%{
    transform:rotate(360deg);
  }
}

.ajax-progress-throbber{
  position: fixed;
  bottom: 20px;
  right: 50%;
  background-color: white;
  color: black;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  font-size: 18px;
  font-weight: 300;
  padding: 5px 10px 5px 38px;
  border-radius: 5px;
  transform: translate(50%, 0);
  z-index: 999;
  .throbber{
    background: none;
    position: absolute;
    top: 5px;
    left: 10px;
    display: block;
    width: 18px;
    height: 22px;
    margin: 0;
    float: unset;
    &:before{
      content: '\f110';
      font-family: 'FontAwesome';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      width: 18px;
      height: 22px;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      color: $purple;
      animation: rotating 2s linear infinite;
    }
  }
  .message{
    line-height: 22px;
    padding-left: 0;
  }
}

#focus-overlay{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.5s linear, opacity 0.5s linear;
  z-index: 9997;
  background-color: rgba(0, 0, 0, 0.6);
  &.active{
    visibility: visible;
    opacity: 1;
  }
}

#check-availability-banner{
  @include breakpoint($xs){
    bottom: 0;
    right: 0;
    font-size: 20px;
    line-height: 24px;
    padding: 4px 14px;
    border-radius: 5px 0 0;
  }
  visibility: hidden;
  position: fixed;
  bottom: 50px;
  right: 50px;
  display: inline-block;
  background-color: $purple;
  text-align: center;
  font-size: 24px;
  line-height: 28px;
  padding: 8px 22px;
  color: white;
  border-radius: 5px;
  opacity: 0;
  transition: visibility 0.75s ease 0.15s, opacity 0.75s ease 0.15s;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.6);
  z-index: 99;
  &.shown{
    opacity: 0.75;
    visibility: visible;
    &:hover{
      opacity: 1;
      transition: opacity 0.25s ease;
    }
  }
}

.page-node-242,
.page-node-257,
.page-node-216,
.page-node-402,
.page-node-243 {
  .node__content {
    max-width: 900px;
    margin: auto;
    width: 100%;
    display: block;
  }
}