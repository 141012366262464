@import 'header.scss';
@import 'footer.scss';
//Breadcrumb
#breadcrumb{
  @include breakpoint($md){
    height: 158px;
  }
  @include breakpoint($smaller){
    position: sticky;
    top: 0;
    z-index: -1;
  }
  @include breakpoint($xs){
    display: none;
  }
  height: 273px;
  background-image: url('/sites/all/themes/omgsub/images/road.jpg');
  background-position: center bottom;
  background-size: cover;
  background-repeat: no-repeat;
}

.breadcrumb-container{
  @include gim_container2($container, 40px);
  @include breakpoint($md){
    height: calc(100% - 56px);
  }
  @include breakpoint($md){
    display: none;
  }
  height: calc(100% - 105px);
  position: relative;
  .bottom-aligner{
    @include breakpoint($md){
      padding-bottom: 10px;
    }
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding-bottom: 20px;
  }
  .you-are-here{
    @include breakpoint($md){
      font-size: 18px;
    }
    color: #000;
    display: inline-block;
    font-size: 20px;
    margin: 0 5px 0;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  }
  h1{
    @include breakpoint($md){
      font-size: 24px;
    }
    color: #000;
    display: inline-block;
    font-weight: 700;
    margin: 0;
    font-size: 20px;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  }
}

//Slider div
.l-region--slider{
  position: relative;
}

//Above footer
$call-us-container: 1170px;

.l-above-footer{
  background-color: $blue;
  text-align: center;
}

.l-region--above-footer{
  @include gim_container($call-us-container);
}

#call-us{
  .block__content{
    @include breakpoint($xs){
      text-align: center;
    }
    overflow: hidden;
  }
  .call-us-description{
    @include breakpoint($smaller){
      float: unset;
      max-width: 400px;
      margin: 0 auto;
      padding: 16px 0 0;
      padding-bottom: 16px;
      width: 100%;
    }

    padding: 30px 0 30px;

    .logo {
      margin-bottom: 31px;
      @include breakpoint($smaller){
        margin-bottom: 15px;
      }
      img{
        width: 100%;
        max-width: 580px;
      }
    }
  }
  h5,
  h6{
    margin: 0;
    color: $orange;
    font-weight: 700;
    font-size: 42px;
  }

  h6 {
    font-size: 34px;
    font-weight: 500;
    color: white;
    line-height: 1.2;
  }

  h5{
    @include breakpoint($call-us-container){
      font-size: 43px;
      line-height: 50px;
    }
    @include breakpoint($sm){
      font-size: 36px;
      line-height: 42px;
    }
    @include breakpoint($smaller){
      font-size: 24px;
      line-height: 34px;
    }
    @include breakpoint($xs){
      line-height: 30px;
      margin-bottom: 0;
    }

    line-height: 1.2;
    margin-bottom: 6px;
  }
  h6{
    a {
      color: white;
    }

    @include breakpoint($call-us-container){
      font-size: 29px;
      line-height: 35px;
    }
    @include breakpoint($sm){
      font-size: 22px;
      line-height: 28px;
    }
    @include breakpoint($smaller){
      font-size: 16px;
      line-height: 24px;
    }

    a{
      text-decoration: none;
      color: white;
    }
  }
  .call-us-link{
    @include breakpoint($smaller){
      @include span(12);
    }
    @include span(4 last);
    text-align: center;
    p{
      margin: 0;
    }
    a{
      @include breakpoint($call-us-container){
        font-size: 18px;
        padding: 20px 13px;
        margin: 59px 0 58px;
      }
      @include breakpoint($sm){
        padding: 14px 18px;
        font-size: 15px;
        margin: 58px 0;
      }
      @include breakpoint($smaller){
        padding: 12px 10px;
        font-size: 14px;
        margin: 18px 0;
      }
      @extend .button;
      margin: 64px 0;
    }
  }
  @include breakpoint($xs){
    float: left;
    width: 100%;
  }
}

//If not front page
body.not-front{
  background-color: $background;
  .l-main{
    @include gim_container2($container, 40px);
    @include breakpoint($sm){
      margin: -56px 15px 0;
    }
    @include breakpoint($smaller){
      margin-top: -138px;
    }
    @include breakpoint($xs){
      @include span(12);
      margin: 0;
      max-width: none;
    }
    margin-top: -105px;
    background-color: white;
    padding-bottom: 60px;
    .l-content{
      @include breakpoint($xs){
        padding: 0 20px;
      }
      width: unset;
      float: unset;
      @include gim_container(1268px);
      @include suffix(0);
      .block__content,
      article {
        padding-top: 10px;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        .node__content{
          .field__item{
            > h2:first-child{
              color: $purple;
              font-size: 28px;
              line-height: 1.25;
              border-bottom: 1px solid $purple;
              margin: 20px 0 15px;
              padding-bottom: 5px;
              height: 75px;
            }
            img{
              max-width: 100%;
              height: auto !important;
            }
          }
        }
      }
    }
    aside.l-region--sidebar-left{
      @include breakpoint($lg){
        width: 260px;
        margin-right: 9px;
      }
      @include breakpoint($smaller){
        @include span(12);
        margin-right: 0;
      }
      float: left;
      width: 325px;
      margin-right: 22px;
      z-index: 9;
      //@include breakpoint(min-width $md + 1){
      //  position: sticky;
      //  top: 110px;
      //}
      //@include breakpoint($md $sm +1){
      //  position: sticky;
      //  top: 160px;
      //}
      //@include breakpoint($sm $smaller +1){
      //  position: sticky;
      //  top: 75px;
      //}
    }
    aside.l-region--sidebar-left ~ .l-content{
      @include breakpoint($lg){
        width: calc(100% - 269px);
        padding-right: 6px;
      }
      @include breakpoint($smaller){
        @include span(12);
        padding: 0 15px;
      }
      padding-left: 0;
      padding-right: 33px;
      float: right;
      width: calc(100% - 348px);
      >article{
        overflow: hidden;
      }
    }
  }
}