#cboxOverlay{
  background-color: black;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  transition: opacity 0.5s ease;
}

#colorbox{
  z-index: 10001;
  overflow: visible !important; //Δε φαίνεται το Χ στον chrome αλλιώς
  outline: none;
  #cboxWrapper{
    div{
      #cboxContent{
        position: relative;
        #cboxCurrent{
          width: 100%;
          max-width: 100%;
          text-align: center;
          font-size: 30px;
          color: white;
          font-weight: 700;
          margin-top: 16px;
          position: absolute;
          bottom: 0;
          transform: translate(0, 100%);
          left: 0;
          right: 0;
        }
        #cboxPrevious{
          position: absolute;
          transform: translate(0,-50%);
          top: 50%;
          left: -40px;
          display: block;
          width: 25px;
          height: 60px;
          border: none;
          background: none;
          font-size: 0;
          cursor: pointer;
          &:before{
            content: '\f104';
            left: -10px;
            font-family: FontAwesome;
            position: absolute;
            top: 0;
            bottom: 0;
            color: white;
            font-size: 50px;
            line-height: 60px;
            display: inline-block;
            transform: translate(50%, 0);
            cursor: pointer;
            transition: color 0.25s ease;
          }
          &:hover{
            &:before{
              color: $purple;
            }
          }
        }
        #cboxNext{
          position: absolute;
          transform: translate(0,-50%);
          top: 50%;
          right: -40px;
          display: block;
          width: 25px;
          height: 60px;
          border: none;
          background: none;
          font-size: 0;
          cursor: pointer;
          &:before{
            content: '\f105';
            font-family: FontAwesome;
            position: absolute;
            top: 0;
            right: 10px;
            bottom: 0;
            color: white;
            font-size: 50px;
            line-height: 60px;
            display: inline-block;
            transform: translate(50%, 0);
            cursor: pointer;
            transition: color 0.25s ease;
          }
          &:hover{
            &:before{
              color: $purple;
            }
          }
        }
      }
    }
  }
  @include breakpoint($sm){
    #cboxWrapper{
      div{
        #cboxContent{
          #cboxCurrent{
            bottom: -45px;
            font-size: 24px;
          }
          #cboxPrevious{
            top: unset;
            left: calc(50% - 75px);
            bottom: -118px;
            &:before{
              top: unset;
              font-size: 40px;
            }
          }
          #cboxNext{
            top: unset;
            bottom: -118px;
            right: calc(50% - 75px);
            &:before{
              top: unset;
              font-size: 40px;
            }
          }
        }
      }
    }
  }
  h2.node__title{
    display: none;
  }
  article{
    padding: 15px;
    background: white;
    .node__content{
      .field__item{
        h2:first-child{
          @include breakpoint(min-width 775px){
            position: sticky;
            left: 0;
            top: 0;
            right: 0;
            background-color: $purple;
            color: white;
            line-height: 32px;
            font-size: 26px;
            text-align: center;
            padding: 8px 16px;
            margin: -15px;
            margin-bottom: 15px;
            box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.5);
          }
        }
      }
    }
  }
}

#cboxLoadedContent{
  margin-bottom: 0;
  img{
    vertical-align: bottom;
    display: block;
  }
}

.daterangepicker.show-calendar{
  background-color: $purple;
  border:1px solid $purple;
  &:before{
    border-bottom: 7px solid $purple;
  }
  &:after{
    border-bottom: 6px solid $purple;
  }
  .drp-calendar{
    padding-top: 0;
    padding-left: 0;
    .calendar-table{
      padding-right: 0;
      .table-condensed{
        background-color: $purple;
        thead{
          tr{
            &:first-child{
              th{
                padding-top: 10px;
                padding-bottom: 5px;
              }
            }
            th{
              background-color: $purple;
              color: #fff;
              border-radius: 0;
              select{
                padding: 3px;
              }
              span{
                border-color: #fff;
              }
            }
          }
        }
        tbody{
          tr{
            td{
              &:not(.active){
                background-color: #fff;
                border-radius: 0;
              }
            }
          }
        }
      }
    }
  }
}

#cboxClose{
  @include breakpoint(775px){
    top: unset;
    right: 50%;
    bottom: -40px;
    height: unset;
    text-transform: uppercase;
    font-weight: 900;
    line-height: 40px;
    padding: 0 15px;
    font-size: 18px;
    color: white;
    transform: translate(50%, 0);
    width: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    border-radius: 0;
    transition: background-color 0.25s ease;
    &:hover{
      background-color: rgba(0, 0, 0, 1);
    }
  }
  position: absolute;
  top: -40px;
  right: -40px;
  font-size: 0;
  color: transparent;
  background: none;
  border: none;
  width: 32px;
  height: 32px;
  cursor: pointer;
  &:before{
    @include breakpoint(775px){
      content: unset;
    }
    content: '\f00d';
    font-family: 'FontAwesome';
    color: white;
    font-size: 28px;
    line-height: 32px;
    text-align: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    transition: color 0.25s ease;
  }
  &:hover:before{
    color: $purple;
  }
}