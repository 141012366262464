#block-views-car-slider-block{
  #flexslider-1{
    max-width: 559px;
    margin: 0 auto 0px;
    @include breakpoint($smaller){
      border: 8px solid #fff;
    }
  }
  @include breakpoint($container){
    @include span(12);
  }
  @include span(8);
  .flex-control-thumbs{
    @include breakpoint($md){
      margin: 5px 0 0;
    }
    margin: 36px -20px 0;
    li{
      @include span(1 of 5 (gutters: 0.0772));
      img{
        @include breakpoint($lg){
          height: auto;
          max-width: 100%;
        }
        height: 58px;
        object-fit: cover;
        margin-bottom: 8px;
      }
      &:nth-child(5n + 5){
        @include span(1 of 5 (gutters: 0.0772) last);
      }
    }
  }
  .flex-direction-nav{
    @include breakpoint($md){
      display: none;
    }
    @include breakpoint($smaller){
      display: initial;
    }
    li{
      font-size: 0;
      color: transparent;
      a{
        opacity: 1;
        display: inline-block;
        width: unset;
        height: unset;
        margin: 0;
        transform: translate(0, -50%);
        text-shadow: unset;
        top: 50%;
        &:before{
          font-family: 'FontAwesome';
          color: $purple;
          text-shadow: unset;
          line-height: 1;
          vertical-align: bottom;
        }
      }
    }
  }
  .flex-prev{
    @include breakpoint($smaller){
      left: -15px;
    }
    left: -25px;
    &:before{
      content: '\f104';
    }
  }
  .flex-next{
    @include breakpoint($smaller){
      right: -15px;
    }
    right: -25px;
    &:before{
      content: '\f105';
    }
  }
}

#block-views-car-description-video-block{
  @include breakpoint($container){
    @include span(12);
  }
  @include span(4 last);
  //min-height: 509px;
  position: relative;
  h4{
    @include breakpoint($container){
      margin: 0 0 12px;
      line-height: 2.6;
      border-width: 1px 0;
      border-style: solid;
      border-color: rgba(black, 0.1);
    }
    color: $purple;
    margin: 0 0 15px;
    font-size: 20px;
    font-weight: 900;
  }
  .view-content{
    .car-description{
      p{
        margin-bottom: 0;
      }
    }
    a{
      transition: color 0.25s ease;
      display: inline-block;
      position: relative;
      &:hover{
        &:before{
          box-shadow: 1px 1px 4px rgba(255, 255, 255, 0.25);
        }
      }
      &:before{
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: inline-block;
        width: 38px;
        height: 38px;
        background: transparent url('/sites/all/themes/omgsub/images/play_button.svg') no-repeat center top;
        background-size: contain;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.75);
        border-radius: 50%;
        z-index: 9;
        transition: box-shadow 0.25s ease;
      }
      img{
        display: inline-block;
        width: 240px;
        height: auto;
        vertical-align: bottom;
        box-sizing: content-box;
      }
    }
  }
  .view-footer{
    width: 100%;
    display: inline-block;
    position: absolute;
    bottom:0;
    .sendofferpopup{
      width: 100%;
      margin-top: 22px;
      position: relative;
      max-width: 206px;
      display: none;
      &:before{
        content: "";
        position: absolute;
        width: 42px;
        height: 40px;
        background-image: url('/sites/all/themes/omgsub/images/HEART_ICON.svg');
        background-repeat: no-repeat;
        right: -1px;
        top: 50%;
        transform: translateY(-50%);
      }
      a{
        display: inline-block;
        max-width: 188px;
        width: 100%;
        color: $purple;
        background-color: $orange;
        font-size: 20px;
        font-weight: 700;
        padding-left: 10px;
        line-height: 30px;
        transition: 0.4s ease;
        pointer-events: none;
      }
    }
  }
}

#block-views-car-features-block{
  @include span(12);
  padding-top: 0;
  margin-top: 0px;
  //margin-bottom: 39px;
}
