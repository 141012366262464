body.page-node-121{
  .l-main{
    padding-bottom: 99px;
    .l-content{
      article{
        padding: 0;
      }
    }
  }
}

#block-rent-a-car-rent-a-car-reservation{
  .block__content{
    padding: 0 0 0 6px;
    div{
      .form-section{
        color: $purple;
        font-size: 20px;
        line-height: 52px;
        font-weight: 900;
        border-width: 1px 0;
        border-style: solid;
        border-color: $line-grey;
        margin: 0 0 15px;
        @include breakpoint($smaller){
          font-size: 16px;
        }
        &:nth-child(6){
          @include breakpoint($md){
            line-height: 30px;
            padding: 13px 0px;
          }
        }
        &:not(:first-of-type){
          margin-top: 12px;
        }
      }
      #edit-extras-select{
        margin-top: 31px;
        .fieldset-legend{
          position: relative;
          padding-left: 46px;
          &:before{
            content: "";
            position: absolute;
            display: inline-block;
            width: 30px;
            height: 30px;
            top: -8px;
            left: 0;
            background-image: url('/sites/all/themes/omgsub/images/CHILD_SEAT_ICON.svg');
            background-repeat: no-repeat;

          }
        }
        .fieldset-wrapper{
          width: 100%;
          display: inline-block;
          margin-top: 7px;
          .form-type-select{
            @include span(1 of 3 (gutters:0.08));
            &:last-child{
              @include span(1 of 3 (gutters:0.08) last);
              float: left;
            }
            max-width: 253px;
            @include breakpoint($md){
              width: 100%;
              max-width: unset;
              margin-right: 0;
              &:last-child{
                width: 100%;
              }
            }
            label{
              font-size: 14px;
              color: #000;
              font-weight: 700;
              margin-bottom: 6px;
              margin-top: 6px;
            }
            select{
              width: 58px;
              height: 24px;
              border-radius: 5px;
              border:1px solid #e2e3e3;
              position: relative;
              display: inline;
              &:before{
                content: "";
                position: absolute;
                float: left;
                width: 50px;
                height: 50px;
                background-color: red;
                left:0;
              }
            }
            .description{
              display: inline;
              width: 180px;
              .ext_price{
                background-color: #d9dada;
                padding: 3px 8px;
                border: 1px solid #d9dada;
                border-bottom-left-radius: 5px;
                border-top-left-radius: 5px;
                margin-left: 6px;
              }
              .price{
                width: 100%;
                max-width: 95px;
                height: 23px;
                border-radius: 5px;
                border: 1px solid #e2e3e3;
                display: inline-block;
                text-align: left;
                padding-left: 19px;
                top: 1px;
                position: relative;
                border-left: none;
                right: 2px;
                line-height: 24px;
                @include breakpoint(1060px){
                  max-width: 84px;
                }
              }
              .info{
                margin-top: 15px;
                padding-right: 20px;
                strong{
                  color: $purple;
                }
              }
            }
          }
        }
      }
      #edit-extras{
        counter-reset: extras;
        .form-item{
          &.error{
            border-color: #ff0000;
          }
          &.form-type-checkbox{
            @include breakpoint($container){
              padding-right: calc(10% + 100px);
              @include breakpoint($md){
                padding-right: calc(10% + 50px);
              }
              @include breakpoint($xs){
                padding-right: calc(5% + 20px);
              }
            }
            border-bottom: 1px solid $line-grey;
            padding-left: 55px;
            padding-right: calc(28.2% + 100px);
            position: relative;
            margin-bottom: 10px;
            &:before{
              content: '';
              display: block;
              width: 42px;
              height: 42px;
              background-repeat: no-repeat;
              background-size: contain;
              background-position: center;
              position: absolute;
              margin-right: 23px;
              top: 0;
              left: 0;
            }
            @include breakpoint($xs){
              padding-left: 20px;
              &:before{
                left: -24px;
                transform: scale(0.7);
              }
            }
          }
          &.form-item-extras-9,
          &.form-item-extras-10,
          &.form-item-extras-11,
          &.form-item-extras-12,
          &.form-item-extras-13{
            &:before{
              background-image: url('/sites/all/themes/omgsub/images/icon_insurance.svg');
            }
          }
          &.form-item-extras-14,
          &.form-item-extras-15,
          &.form-item-extras-16{
            &:before{
              background-image: url('/sites/all/themes/omgsub/images/icon_child_seat.svg');
            }
          }
          &.form-item-extras-17{
            &:before{
              background-image: url('/sites/all/themes/omgsub/images/GPS_ICON.svg');
              background-size: 75%;
              @include breakpoint($xs){
                background-size: 100%;
                width: 28px;
                height: 28px;
                left: -15px;
              }
            }
          }
          .form-checkbox{
            display: none;
            &:checked + .option:after{
              content: "\f00c";
            }
          }
          .option{
            color: $purple;
            line-height: 29px;
            font-weight: bold;
            position: absolute;
            text-transform: uppercase;
            padding-right: 52px;
            top: 50%;
            right: 28.2%;
            transform: translate(0, -50%);
            &:after{
              content: "";
              display: inline-block;
              position: absolute;
              width: 27px;
              height: 27px;
              top: 0;
              right: 0;
              border: 1px solid $purple;
              cursor: pointer;
              color: $purple;
              text-align: center;
              line-height: 27px;
              font-family: 'FontAwesome';
            }
            @include breakpoint($container){
              right: 10%;
            }
            @include breakpoint($md){
              right: 5px;
            }
            @include breakpoint($sm){
              padding-right: 37px;
            }
            @include breakpoint($xs){
              right: -9px;
              font-size: 0;
              &:after{
                font-size: 13px;
              }
            }
          }
          .description{
            margin-left: 0;
            font-weight: 300;
            font-size: 16px;
            line-height: 1.25;
            padding-bottom: 17px;
            strong,
            b{
              font-weight: bold;
            }
            .extra-title{
              color: $purple;
              font-size: 18px;
              line-height: 29px;
              margin-bottom: 5px;
              font-weight: 900;
              @include breakpoint($xs){
                font-size: 15px;
                line-height: 19px;
              }
              &:before{
                content: counter(extras) '. ';
                counter-increment: extras;
              }
            }
            p{
              color: black;
              max-width: 500px;
              &:last-child{
                margin-bottom: 0;
                @include breakpoint($xs){
                  font-size: 13px;
                }
              }
            }
          }
          &:last-child{
            border-bottom: none;
          }
        }
      }
      .insurance_info{
        padding-left: 0;
        width: 100%;
        display: inline-block;
        &:before{
          display: none;
        }
        .desc{
          font-size: 16px;
          font-weight: 400;
          @include span(7.7 (gutters:0.2));
          //max-width: 580px;
        }
        .link{
          @include span(4.2 (gutters:0.2) last);
          background-color: $purple;
          border-radius: 7px;
          margin-top: 9px;
          a{
            color: $orange;
            font-size: 20px;
            font-weight: 700;
            line-height: 41px;
            text-align: center;
            width: 100%;
            display: inline-block;
            padding-left: 19px;
            padding-right: 19px;
          }
        }
        @include breakpoint(1285px){
          .desc{

            @include span(6.8 (gutters:2));
          }
          .link{
            @include span(5.2 (gutters:2) last);

            a{

            }
          }
        }
        @include breakpoint($md){
          .desc{
            width: 100%;
            margin-right: 0;
            display: inline-block;
            margin-bottom: 20px;
          }
          .link{
            width: 100%;
            float: unset;
            margin: auto;
            display: block;
            margin-top: 20px;
            overflow: hidden;
            a{

            }
          }
        }
      }
      .form-wrapper{
        border: none;
        margin: 0;
        padding: 0;
        &#edit-travel-info{
          margin-bottom: 0px;
          .form-type-textarea{
            width: 100%;
          }
        }
        &#edit-heading{
          margin-top: 15px;
          width: 100%;
          display: inline-block;
        }
        .fieldset-legend{
          color: $lightblue;
          font-size: 20px;
          font-weight: 700;
          margin-bottom: 9px;
          display: inline-block;
          @include breakpoint($xs){
            font-size: 16px;
            margin-bottom: 0;
          }
        }
        .fieldset-wrapper{
          margin-top: 5px;
        }
        .info-block{
          margin: 2px 0;
          margin-bottom: 14px;
          padding-bottom: 20px;
          @include span(12);
          font-size: 16px;
          line-height: 42px;
        }
        .form-item{
          @include breakpoint(min-width $md){
            &:nth-of-type(-n+6),
            &:nth-of-type(n+10){
              @include span(2 of 4 (gutters: 0));
              margin-bottom: 10px;
            }
            &.form-item-driving-license-no,
            &.form-item-city,
            &.form-item-birth-date,
            &.form-item-country,
            &.form-item-drivers-age{
              @include span(1 of 4 (gutters: 0));
            }
            &.form-item-no-of-people{
              @include span(2 of 4 (gutters: 0));
              margin-bottom: 0;
            }
            &.form-item-last-name,
            &.form-item-phone,
            &.form-item-no-of-people,
            &.form-item-location,
            &.form-item-ship-route,
            &.form-item-notes{
              input{
                margin-right: 0;
                margin-left: 17px;
                width: calc(100% - 13px);
              }
            }
            &.form-item-country,
            &.form-item-drivers-age{
              margin-bottom: 0;
            }
          }
          label{
            font-weight: 400;
            font-size: 16px;
            padding-left: 5px;
            margin-bottom: 8px;
          }
          input{
            border: 1px solid $lightblue;
            height: 39px;
            border-radius: 5px;
            padding-left: 5px;
            width: calc(100% - 14px);
            margin-right: 14px;
            max-width: 480px;
            display: inline-block;
            &#edit-city,
            &#edit-country{
              max-width: 210px;
              margin-right: 0;
            }
          }
          &.form-item-notes{
            .form-textarea-wrapper{
              width: 100%;
              display: inline-block;
              #edit-notes{
                border: 1px solid $lightblue;
                height: 38px;
                border-radius: 5px;
                padding-left: 5px;
                padding-top: 10px;
              }
              .grippie{
                background: none;
                border:none;
                height: 5px;
              }
            }
          }
          &.form-item-terms-acceptance{
            @include span(12);
            margin-top: 30px;
            text-align: center;
            float: none;
            input{
              display: none;
              &:checked + .option:before{
                color: $purple;
              }
            }
            label{
              position: relative;
              padding-right: 30px;
              cursor: pointer;
              line-height: 21px;
              display: inline-block;
              &:before{
                content: '\f00c';
                font-family: 'FontAwesome';
                display: block;
                width: 21px;
                height: 21px;
                position: absolute;
                top: 0;
                right: 0;
                border: 1px solid $purple;
                border-radius: 2px;
                color: white;
                font-size: 16px;
                line-height: 21px;
                text-align: center;
                transition: color 0.05s ease;
              }
            }
          }
        }
        &#edit-accommodation-info,
        &#edit-travel-info,
        &#edit-personal-info{
          .form-item{
            label{
              display: none;
            }
          }
        }
      }
      .form-item-gdpr{
        width: 100%;
        display: inline-block;
        label{
          padding-left: 30px;
          line-height: 33px;
          a{
            transition: 0.3s ease;
            font-weight: 700;
            &:hover{
              color: $orange;
            }
          }
        }
        input{
          visibility: hidden;
        }
        input[type=checkbox]{
          &:checked ~ label:before {
            content: "\f00c";
          }
        }
        input[type=checkbox] ~ label{
          position: relative;
          &:before{
            content: '';
            display: block;
            width: 27px;
            height: 27px;
            font-family: FontAwesome;
            color: $purple;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            position: absolute;
            margin-right: 23px;
            top: -6px;
            left: -6px;
          }
          &:after{
            content: "";
            display: inline-block;
            position: absolute;
            width: 27px;
            height: 27px;
            top: -5px;
            left: -12px;
            border: 1px solid $purple;
            cursor: pointer;
            color: $purple;
            text-align: center;
            line-height: 27px;
            font-family: 'FontAwesome';
          }
        }
      }
      .form-submit{
        //float: right;
        line-height: 51px;
        border: none;
        background-color: #064699;
        color: $orange;
        border-radius: 8px;
        padding-left: 52px;
        padding-right: 17px;
        text-transform: uppercase;
        transition: background-color 0.25s ease;
        cursor: pointer;
        float: right;
        display: block;
        font-size: 30px;
        font-weight: 700;
        background-image: url(/sites/all/themes/omgsub/images/BOOK_BUTTON.svg);
        background-repeat: no-repeat;
        &:hover{
         opacity: 0.9;
        }
      }
    }
  }
}
@include breakpoint($md - 1px){
  #block-rent-a-car-rent-a-car-reservation {
    .block__content {
      div {
        .form-wrapper {
          .form-item {
            @include span(1 of 2 (gutters:0.02));
            &.form-item-country{
              input#edit-country{
                max-width: 440px;
              }
            }
            &:nth-child(2n){
              @include span(1 of 2 (gutters:0.02) last);
            }
            &.form-item-no-of-people{
              clear: left;
              float: left;
            }
          }
        }
      }
    }
  }
}
@include breakpoint($xs){
  #block-rent-a-car-rent-a-car-reservation {
    .block__content {
      div {
        .form-wrapper {
          .form-item {
            width: 100%;
            margin-right: 0;
            &.form-item-country{
              input#edit-country{
                max-width: 440px;
              }
            }
            &:nth-child(2n){
              width: 100%;
              margin-right: 0;
            }
            &.form-item-no-of-people{
              clear: left;
              float: left;
            }
          }
        }
      }
    }
  }
}