body.page-node-223{
  .l-main{
    .l-content{
      article{
        float: left;
        width: 100%;
        max-width: 100%;
        .node__content{
          margin: 0;
          .field__item{
            > h2:first-child{
              color: black;
              border-bottom: none;
              font-weight: 300;
              font-size: 40px;
              margin: 17px 0 7px;
            }
          }
        }
      }
      #block-views-about-crete-block{
        float: left;
        width: 100%;
        max-width: 100%;
        .block__content{
          .view-about-crete{
            .view-content{
              .location-item{
                .location-title{
                  color: $purple;
                  border-bottom: 1px solid $purple;
                  padding-bottom: 21px;
                  font-size: 28px;
                }
                .location-carousel{
                  padding-left: 30px;
                  padding-right: 30px;
                  margin-bottom: 50px;
                  .slick--view--about-crete{
                    position: relative;
                    .slick-slider{
                      .slick-list{
                        .slick-track{
                          .slick__slide{
                            .slide__content{
                              .slick-media{
                                .slick__colorbox{
                                  .slick__image{
                                    padding-right: 8px;
                                    padding-left: 8px;
                                    max-width: 100%;
                                    height: auto;
                                    margin: auto;
                                    width: auto;
                                  }
                                  i{
                                    &:before{
                                      display: none;
                                    }
                                    &:after{
                                      display: none;
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  .slick__arrow{
                    height: 0 !important;
                    .slick-arrow{
                      &.slick-prev{
                        left: -22px;
                        &:before{
                          content: '\f104';
                        }
                      }
                      &.slick-next{
                        right: -22px;
                        &:before{
                          content: '\f105';
                        }
                      }
                      position: absolute;
                      transform: translate(0,-50%);
                      top: 50%;
                      display: block;
                      width: 25px;
                      height: 60px;
                      border: none;
                      background: none;
                      font-size: 0;
                      cursor: pointer;
                      &:before{
                        font-family: FontAwesome;
                        position: absolute;
                        top: 0;
                        right: 50%;
                        bottom: 0;
                        color: $purple;
                        font-size: 50px;
                        line-height: 60px;
                        display: inline-block;
                        transform: translate(50%, 0);
                        cursor: pointer;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@include breakpoint(640px){
  body.page-node-223{
    .l-main{
      .l-content{
        #block-views-about-crete-block{
          .block__content{
            .view-about-crete{
              .view-content{
                .location-item{
                  .location-carousel{
                    .slick__arrow{
                      .slick-prev{
                        &:before{
                          left: 38px;
                        }
                      }
                      .slick-next{
                        &:before{
                          right: 38px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@include breakpoint(562px){
  body.page-node-223{
    .l-main{
      .l-content{
        #block-views-about-crete-block{
          .block__content{
            .view-about-crete{
              .view-content{
                .location-item{
                  .location-carousel{
                    .slick__arrow{
                      .slick-prev{
                        &:before{
                          left: 10px;
                        }
                      }
                      .slick-next{
                        &:before{
                          right: 10px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@include breakpoint(450px){
  body.page-node-223{
    .l-main{
      .l-content{
        #block-views-about-crete-block{
          .block__content{
            .view-about-crete{
              .view-content{
                .location-item{
                  .location-carousel{
                    .slick__arrow{
                      .slick-prev{
                        &:before{
                          left: -18px;
                        }
                      }
                      .slick-next{
                        &:before{
                          right: -18px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}